import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as luxon from 'luxon';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Response } from '../_models/response';
import { TenantModel } from '../_models/tenant';
import { LocalStorageService } from './local.storage.service';

@Injectable({ providedIn: 'root' })
export class TenantService {
  constructor(
    private http: HttpClient,
    private localStorageService: LocalStorageService
  ) {}

  getTenant(): Observable<TenantModel> {
    return this.http
      .get<Response>(`${environment.apiUrl}/tenant/`)
      .pipe(map((response) => response.data as TenantModel));
  }

  updateTenant(tenant: TenantModel) {
    return this.http
      .put<Response>(`${environment.apiUrl}/tenant`, tenant)
      .pipe(map((response) => response.data as TenantModel));
  }
  getInvoiceLogo() {
    //ToDo
  }

  uploadMainLogo(mainLogoFile: File) {
    const formData: FormData = new FormData();
    formData.append('file', mainLogoFile, mainLogoFile.name);
    return this.http.post(`${environment.apiUrl}/tenant/main-logo`, formData);
  }

  getMainLogo() {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/json',
    });
    let url = `${environment.apiUrl}/tenant/main-logo`;
    return this.http.get<Blob>(url, {
      headers: headers,
      responseType: 'blob' as 'json',
    });
  }

  uploadInvoiceLogo(invoiceLogoFile: File) {
    const formData: FormData = new FormData();
    formData.append('file', invoiceLogoFile, invoiceLogoFile.name);
    return this.http.post(
      `${environment.apiUrl}/tenant/invoice-logo`,
      formData
    );
  }

  getTenetUtcDateString(browserDateTime: Date) {
    if (!browserDateTime) {
      return null;
    }
    const dateString =
      browserDateTime.getFullYear() +
      '-' +
      ('0' + (browserDateTime.getMonth() + 1)).slice(-2) +
      '-' +
      ('0' + browserDateTime.getDate()).slice(-2) +
      ' ' +
      ('0' + browserDateTime.getHours()).slice(-2) +
      ':' +
      ('0' + browserDateTime.getMinutes()).slice(-2) +
      ':' +
      ('0' + browserDateTime.getSeconds()).slice(-2) +
      '.000';

    const tenantTimeZone = this.getSetTimeZone();
    return luxon.DateTime.fromSQL(dateString)
      .setZone(tenantTimeZone.name, { keepLocalTime: true })
      .toUTC()
      .toISO();
  }

  getTenetUtcDate(browserDateTime: Date) {
    if (!browserDateTime) {
      return null;
    }
    const dateString =
      browserDateTime.getFullYear() +
      '-' +
      ('0' + (browserDateTime.getMonth() + 1)).slice(-2) +
      '-' +
      ('0' + browserDateTime.getDate()).slice(-2) +
      ' ' +
      ('0' + browserDateTime.getHours()).slice(-2) +
      ':' +
      ('0' + browserDateTime.getMinutes()).slice(-2) +
      ':' +
      ('0' + browserDateTime.getSeconds()).slice(-2) +
      '.000';

    const tenantTimeZone = this.getSetTimeZone();
    const utcString = luxon.DateTime.fromSQL(dateString)
      .setZone(tenantTimeZone.name, { keepLocalTime: true })
      .toUTC()
      .toISO();
    return new Date(utcString);
  }

  getTenantTimeZoneDate(utcDateTime: Date) {
    if (!utcDateTime) {
      return null;
    }
    const tenantTimeZone = this.getSetTimeZone();
    const timeZoneDate = luxon.DateTime.fromISO(utcDateTime.toString()).setZone(
      tenantTimeZone.name,
      {
        keepLocalTime: false,
      }
    );

    const jsDate = new Date(
      timeZoneDate.toSQLDate() +
        'T' +
        ('0' + timeZoneDate.hour).slice(-2) +
        ':' +
        ('0' + timeZoneDate.minute).slice(-2) +
        ':' +
        ('0' + timeZoneDate.second).slice(-2) +
        '.000'
    );
    return jsDate;
  }

  getSetTimeZone(forSetTimeZone?: boolean) {
    let timeZone = this.localStorageService.getTenantTimeZone();
    if (!timeZone || forSetTimeZone) {
      this.getTenant().subscribe({
        next: (response) => {
          timeZone = response.timeZone;
          this.localStorageService.setTenantTimeZone(timeZone);
          return timeZone;
        },
      });
    } else {
      return timeZone;
    }
  }
}
