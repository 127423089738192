<div>
  <nav mat-tab-nav-bar class="navBarManage">
    <a mat-tab-link routerLink="admins" routerLinkActive="active" class="sub-tab">
      {{ "SETTINGS.ADMINISTRATORS" | translate }}
      <span class="margin-left"></span>
      <mat-icon class="iconManageMenu">group</mat-icon>
    </a>

    <a mat-tab-link routerLink="users-dispatchers" routerLinkActive="active" class="sub-tab">
      {{ "SETTINGS.DISPATCHERS" | translate }}
      <span class="margin-left"></span>
      <mat-icon class="iconManageMenu">support_agent</mat-icon>
    </a>
    <!-- ToDo Enable when drivers has option to login -->
    <!-- <a mat-tab-link routerLink="users-drivers" routerLinkActive="active">
      {{ "SETTINGS.DRIVERS" | translate }}
      <span class="margin-left"></span>
      <mat-icon class="iconManageMenu">airline_seat_recline_normal</mat-icon>
    </a> -->
  </nav>
  <router-outlet></router-outlet>
</div>
