<div class="central">
  <div class="border-div">
    <div class="div-icon">
      <mat-icon class="size-icon">library_books</mat-icon>
    </div>
    <div class="description">
      {{ infoText }}
    </div>
  </div>
</div>
