import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CustomerService, ToastrTranslateService } from 'src/app/_services';
import { ComfirmDialogComponent } from '../../trucks/edit-delete-truck/comfirm-dialog/comfirm-dialog.component';

@Component({
  selector: 'app-comfirm-dialog-customer',
  templateUrl: './comfirm-dialog-customer.component.html',
  styleUrls: ['./comfirm-dialog-customer.component.scss'],
})
export class ComfirmDialogCustomerComponent {
  constructor(
    private customerService: CustomerService,
    private dialogRef: MatDialogRef<ComfirmDialogComponent>,
    private toastrTranslateService: ToastrTranslateService,
    @Inject(MAT_DIALOG_DATA) private customerId: number
  ) {}

  deleteCustomer() {
    this.customerService.deleteCustomer(this.customerId).subscribe({
      next: (response) => {
        if (response.success) {
          this.toastrTranslateService.success('CONFIRM_DIALOG.SUCCESS_DELL');
          this.dialogRef.close({ success: response.success });
        } else {
          this.toastrTranslateService.error(
            response.message,
            'CONFIRM_DIALOG.FAILED_DELL'
          );
        }
      },
      error: (response) => {
        this.toastrTranslateService.error(
          response,
          'CONFIRM_DIALOG.FAILED_DELL'
        );
      },
    });
  }
}
