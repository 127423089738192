import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, firstValueFrom, map, of, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import Common from '../_helpers/common';
import { AccountingMapper } from '../_helpers/mappers/accountingMapper';
import { AccountingModel } from '../_models/accounting/accounting';
import { Paginator } from '../_models/common/paginator';
import { Response } from '../_models/response';
import { ResponseData } from '../_models/responseData';
import { AccountingFactory } from './factory/accounting.factory.service';
import { TenantService } from './tenant.service';

@Injectable({ providedIn: 'root' })
export class AccountingService {
  constructor(
    private http: HttpClient,
    private tenantService: TenantService,
    private accountingFactory: AccountingFactory
  ) {}

  getAccountingList(
    dateFrom: Date,
    dateTo: Date,
    paginator: Paginator
  ): Observable<ResponseData> {
    const dateFromString = this.tenantService.getTenetUtcDateString(dateFrom);
    const dateToString = this.tenantService.getTenetUtcDateString(
      Common.getDateEndOfDay(new Date(dateTo))
    );
    if (!dateFromString || !dateToString) {
      return of(undefined);
    }
    const params = new HttpParams({
      fromObject: {
        PageSize: '' + paginator.pageSize,
        PageNumber: '' + paginator.pageNumber,
        SortBy: '' + paginator.sortBy,
        SortOrder: '' + paginator.sortOrder,
        SearchTerm: '' + paginator.searchTerm,
        DateFrom: dateFromString,
        DateTo: dateToString,
      },
    });
    return this.http
      .get<Response>(`${environment.apiUrl}/accountings`, { params: params })
      .pipe(map((response) => response.data as ResponseData));
  }

  getAccounting(id: number): Observable<AccountingModel> {
    return this.http
      .get<Response>(`${environment.apiUrl}/accountings/${id}`)
      .pipe(map((response) => response.data as AccountingModel))
      .pipe(
        tap((responseData) => {
          this.accountingFactory.getAccountingTimeZoneDate(responseData);
        })
      );
  }

  addAccounting(accounting: AccountingModel) {
    accounting = this.accountingFactory.getAccountingUtcDate(accounting);
    const accountingRequest = AccountingMapper.getAccountingRequest(accounting);
    return this.http
      .post<Response>(`${environment.apiUrl}/accountings`, accountingRequest)
      .pipe(map((response) => response.data as AccountingModel))
      .pipe(
        tap((responseData) => {
          this.accountingFactory.getAccountingTimeZoneDate(responseData);
        })
      );
  }

  updateAccounting(accounting: AccountingModel) {
    accounting = this.accountingFactory.getAccountingUtcDate(accounting);
    const accountingRequest = AccountingMapper.getAccountingRequest(accounting);
    return this.http
      .put<Response>(`${environment.apiUrl}/accountings`, accountingRequest)
      .pipe(map((response) => response.data as AccountingModel))
      .pipe(
        tap((responseData) => {
          this.accountingFactory.getAccountingTimeZoneDate(responseData);
        })
      );
  }

  deleteAccounting(id: number) {
    return this.http.delete<Response>(
      `${environment.apiUrl}/accountings/${id}`
    );
  }

  async getCsv(dateFrom: Date, dateTo: Date, paginator: Paginator) {
    //Export will always return document without paging
    const params = new HttpParams({
      fromObject: {
        PageSize: 1000,
        PageNumber: 1,
        SortBy: '' + paginator.sortBy,
        SortOrder: '' + paginator.sortOrder,
        SearchTerm: '' + paginator.searchTerm,
        DateFrom: this.tenantService.getTenetUtcDateString(dateFrom),
        DateTo:  this.tenantService.getTenetUtcDateString(
          Common.getDateEndOfDay(new Date(dateTo))
        ),
      },
    });
    let headers = new HttpHeaders();
    headers = headers.append('Accept', 'application/vnd.ms-excel');
    return await firstValueFrom(
      this.http.get(`${environment.apiUrl}/accountings/csv`, {
        headers: headers,
        params: params,
        responseType: 'blob',
      })
    );
  }
}
