export class Location {
  id: number;
  city: string;
  poBox: string;
  address: string;
  streetNumber: string;
  country: string;
  countryCode: string;
  state: string;
  zipCode: string;
  lat: number;
  lon: number;
  sequence?: number;
}
