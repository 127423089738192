import { Injectable } from '@angular/core';
import { UserRoleName } from '../_models/enums/userRoleName';
import { UserModel } from '../_models/user';
import { UserRole } from '../_models/userRole';

@Injectable({ providedIn: 'root' })
export class UserDataService {
  private _curentUser: UserModel;
  constructor() {}

  setCurentUser(user: UserModel) {
    this._curentUser = user;
  }

  clearCurentUser() {
    this._curentUser = null;
  }

  get user(): UserModel {
    return this._curentUser;
  }

  get role(): UserRole {
    return this._curentUser?.userRole;
  }

  get isSuperAdmin(): boolean {
    return this._curentUser.userRole.name == UserRoleName.SuperAdmin
      ? true
      : false;
  }

  get isAdminDispatcher(): boolean {
    return this._curentUser?.userRole?.name == UserRoleName.SuperAdmin ||
      this._curentUser?.userRole?.name == UserRoleName.AdminDispatcher
      ? true
      : false;
  }
}
