import { Injectable } from '@angular/core';

@Injectable()
export class DocumentFactory {
  private storeDocumentBytes: string;
  private storeDocumentName: string;
  constructor() {}

  public addDocument(documentBytes, documentName): void {
    this.storeDocumentBytes = documentBytes;
    this.storeDocumentName = documentName;
  }

  public getDocumentBytes(): string {
    return this.storeDocumentBytes;
  }
  public getDocumentName(): string {
    return this.storeDocumentName;
  }

  public clearDocument() {
    this.storeDocumentBytes = '';
    this.storeDocumentName = '';
  }

  public isTargetExist(): boolean {
    if (this.storeDocumentBytes === '') {
      return false;
    } else {
      return true;
    }
  }
}
