<div class="modal-dialog formInModal">
  <h2 mat-dialog-title class="text">
    {{ "LOADS.QUICK_LOAD" | translate }}
  </h2>
  <hr />
  <mat-dialog-content class="mat-typography">
    <form #quickLoad="ngForm">
      <mat-form-field
        floatLabel="auto"
        hideRequiredMarker
        appearance="outline"
        class="formWidth"
        required
      >
        <mat-label class="labelQucikLoad"
          >{{ "NEW_LOAD.SELECT_DRIVER" | translate }}
          <span class="star">*</span>
        </mat-label>
        <mat-select
          [(ngModel)]="loadQuickDataPage.driver"
          name="loadDriver"
          class="labelQucikLoad"
          placeholder="{{ 'NEW_LOAD.SELECT_DRIVER' | translate }}"
          (selectionChange)="getTrucks()"
        >
          <ng-container *ngIf="driverList.length > 0">
            <mat-option *ngFor="let driver of driverList" [value]="driver">
              {{ driver.firstname + " " + driver.lastname }}
            </mat-option>
          </ng-container>
        </mat-select>
      </mat-form-field>
      <mat-form-field
        floatLabel="auto"
        hideRequiredMarker
        appearance="outline"
        class="formWidth"
        required
      >
        <mat-label class="labelQucikLoad"
          >{{ "NEW_LOAD.SELECT_DISPATCHER" | translate }}
          <span class="star">*</span>
        </mat-label>
        <mat-select
          [(ngModel)]="loadQuickDataPage.dispatcher"
          name="loadDispatcher"
          class="labelQucikLoad"
          placeholder="{{ 'NEW_LOAD.SELECT_DISPATCHER' | translate }}"
        >
          <ng-container *ngIf="dispatcherList.length > 0">
            <mat-option
              *ngFor="let dispatcher of dispatcherList"
              [value]="dispatcher"
            >
              {{ dispatcher.firstname + " " + dispatcher.lastname }}
            </mat-option>
          </ng-container>
        </mat-select>
      </mat-form-field>
      <br />
      <mat-form-field
        floatLabel="auto"
        hideRequiredMarker
        appearance="outline"
        class="formWidth"
        required
      >
        <mat-label class="labelQucikLoad"
          >{{ "NEW_LOAD.SELECT_TRUCK" | translate }}
          <span class="star">*</span>
        </mat-label>
        <mat-select
          [(ngModel)]="loadQuickDataPage.truck"
          name="loadTruck"
          class="labelQucikLoad"
          [disabled]="isTruckListEmpty"
          placeholder="{{ 'NEW_LOAD.SELECT_TRUCK' | translate }}"
        >
          <ng-container *ngIf="driverTruckList.length > 0">
            <mat-option *ngFor="let truck of driverTruckList" [value]="truck">
              {{ truck.truckNumber }}
            </mat-option>
          </ng-container>
        </mat-select>
      </mat-form-field>
      <mat-form-field
        floatLabel="auto"
        hideRequiredMarker
        appearance="outline"
        class="formWidth"
        required
      >
        <mat-label class="labelQucikLoad"
          >{{ "NEW_LOAD.RATE" | translate }}
          <span class="star">*</span>
        </mat-label>
        <input
          matInput
          autocomplete="off"
          [(ngModel)]="loadQuickDataPage.rate"
          onfocus="this.select()"
          name="loadRate"
          type="number"
          required
        />
        <span matPrefix class="dolarSymbol">$&nbsp;</span>
      </mat-form-field>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions class="mat-dialog">
    <button mat-button class="btnCan" mat-dialog-close>
      {{ "NEW_LOAD.CANCEL" | translate }}
    </button>
    <button
      [disabled]="
        quickLoad.form.invalid ||
        !loadQuickDataPage.driver ||
        !loadQuickDataPage.dispatcher ||
        !loadQuickDataPage.truck ||
        loadQuickDataPage.rate == null
      "
      (click)="save(loadQuickDataPage)"
      mat-flat-button
      class="btn btn-green"
      cdkFocusInitial
    >
      {{ "NEW_LOAD.SAVE" | translate }}
    </button>
  </mat-dialog-actions>
</div>
