import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import Common from '../_helpers/common';
import { Paginator } from '../_models/common/paginator';
import { DispatcherModel } from '../_models/dispatcher';
import { Response } from '../_models/response';
import { ResponseData } from '../_models/responseData';

@Injectable({ providedIn: 'root' })
export class DispatcherService {
  constructor(private http: HttpClient) {}

  getDispatchers(): Observable<ResponseData> {
    return this.http
      .get<Response>(`${environment.apiUrl}/dispatchers`)
      .pipe(map((response) => response.data as ResponseData));
  }

  getAllDispatchers(
    dropDownPaginator?: Paginator,
    isActive?: boolean
  ): Observable<ResponseData> {
    const params = new HttpParams({
      fromObject: {
        PageSize: '' + dropDownPaginator.pageSize,
        SortBy: '' + dropDownPaginator.sortBy,
        SortOrder: '' + dropDownPaginator.sortOrder,
        isActive: Common.getSafeBooleanString(isActive),
      },
    });
    return this.http
      .get<Response>(`${environment.apiUrl}/dispatchers`, { params: params })
      .pipe(map((response) => response.data as ResponseData));
  }

  getDispatcher(id: number): Observable<DispatcherModel> {
    return this.http
      .get<Response>(`${environment.apiUrl}/dispatchers/${id}`)
      .pipe(map((response) => response.data as DispatcherModel));
  }

  updateDispatcher(dispatcher: DispatcherModel) {
    return this.http
      .put<Response>(`${environment.apiUrl}/dispatchers`, dispatcher)
      .pipe(map((response) => response.data as DispatcherModel));
  }

  addDispatcher(dispatcher: DispatcherModel) {
    return this.http
      .post<Response>(`${environment.apiUrl}/dispatchers`, dispatcher)
      .pipe(map((response) => response.data as DispatcherModel));
  }

  deleteDispatcher(id: number): Observable<Response> {
    return this.http.delete<Response>(
      `${environment.apiUrl}/dispatchers/${id}`
    );
  }
}
