<div class="modal-dialog formInModal">
  <div *ngIf="!dataLoading">
    <h2 *ngIf="driver && !driver.id" mat-dialog-title class="text f-16">
      {{ "DRIVERS.ADD_DRIVER" | translate }}
    </h2>
    <h2 *ngIf="driver.id" mat-dialog-title class="mb-0">
      <h2 class="text f-16" [hidden]="readOnly">
        <div class="text f-16">
          {{ "DRIVERS.EDIT_DRIVER" | translate }}
        </div>
        <div>
          {{ driver.firstname + " " + driver.lastname }}
        </div>
      </h2>
      <h2 class="text f-16" [hidden]="!readOnly">
        <div class="text f-16">
          {{ "DRIVERS.PREVIEW_DRIVER" | translate }}
        </div>
        <div>
          {{ driver.firstname + " " + driver.lastname }}
        </div>
      </h2>
    </h2>
  </div>
  <hr />
  <form #editDriver="ngForm">
    <mat-dialog-content class="box">
      <div class="inlineBlock">
        <mat-form-field
          floatLabel="auto"
          hideRequiredMarker
          appearance="outline"
          class="displayNone"
        >
          <mat-label class="global_search">{{
            "DRIVERS.ID" | translate
          }}</mat-label>
          <input matInput name="driverId" [(ngModel)]="driver.id" />
        </mat-form-field>

        <mat-form-field
          floatLabel="auto"
          hideRequiredMarker
          appearance="outline"
          class="formWidth"
        >
          <mat-label class="global_search"
            >{{ "DRIVERS.FIRST_NAME" | translate
            }}<span class="star">*</span></mat-label
          >
          <input
            matInput
            name="firstname"
            [(ngModel)]="driver.firstname"
            autocomplete="off"
            required
            [readonly]="readOnly"
          />
        </mat-form-field>

        <mat-form-field
          floatLabel="auto"
          hideRequiredMarker
          appearance="outline"
          class="formWidth"
        >
          <mat-label class="global_search">{{
            "DRIVERS.MIDDLE_NAME" | translate
          }}</mat-label>
          <input
            matInput
            name="middlename"
            [(ngModel)]="driver.middlename"
            autocomplete="off"
            [readonly]="readOnly"
          />
        </mat-form-field>

        <mat-form-field
          floatLabel="auto"
          hideRequiredMarker
          appearance="outline"
          class="formWidth"
        >
          <mat-label class="global_search"
            >{{ "DRIVERS.LAST_NAME" | translate
            }}<span class="star">*</span></mat-label
          >
          <input
            matInput
            name="lastname"
            [(ngModel)]="driver.lastname"
            autocomplete="off"
            required
            [readonly]="readOnly"
          />
        </mat-form-field>

        <mat-form-field
          floatLabel="auto"
          hideRequiredMarker
          appearance="outline"
          class="formWidth"
        >
          <mat-label class="global_search"
            >{{ "DRIVERS.PHONE_NUMBER" | translate
            }}<span class="star">*</span></mat-label
          >
          <input
            matInput
            name="phoneNumber"
            [(ngModel)]="driver.phoneNumber"
            autocomplete="off"
            required
            [readonly]="readOnly"
            pattern="[- +()0-9]+"
            maxlength="18"
          />
        </mat-form-field>
        <br />

        <mat-slide-toggle
          color="primary"
          [(ngModel)]="isAddressPoBox"
          class="poBoxToggle"
          (change)="clearAddress()"
          name="isAddressPoBoxName"
          >{{ "DRIVERS.ADD_ADDRESS_MANUALLY" | translate }}</mat-slide-toggle
        >

        <mat-form-field
          floatLabel="auto"
          hideRequiredMarker
          appearance="outline"
          class="add_address"
          *ngIf="!isAddressPoBox"
        >
          <mat-label class="global_search"
            >{{ "DRIVERS.SEARCH_ADDRESS" | translate
            }}<span class="star">*</span></mat-label
          >
          <input
            matInput
            #inputAddress
            [matAutocomplete]="driver_address"
            [formControl]="searchAddress"
            (blur)="autofillAddress()"
            autocomplete="off"
            [required]="!isAddressPoBox"
            [readonly]="readOnly"
          />
          <mat-icon matSuffix class="icon_search">search</mat-icon>
          <mat-autocomplete
            #driver_address="matAutocomplete"
            [displayWith]="displayFn"
            (optionSelected)="selectDriverLocation($event.option.value)"
          >
            <ng-container *ngIf="searchResult.length > 0">
              <mat-option
                *ngFor="let location of searchResult"
                [value]="location"
              >
                <span>{{
                  location.address + ", " + location.countryCode
                }}</span>
              </mat-option>
            </ng-container>
          </mat-autocomplete>
        </mat-form-field>

        <mat-form-field
          floatLabel="auto"
          appearance="outline"
          class="inputPObox"
          hideRequiredMarker
          id="poBox"
          *ngIf="isAddressPoBox"
        >
          <mat-label
            >{{ "DRIVERS.ADDRESS" | translate
            }}<span class="star">*</span></mat-label
          >
          <input
            matInput
            name="poBoxValue"
            [(ngModel)]="driver.location.poBox"
            [required]="isAddressPoBox"
            autocomplete="off"
          />
        </mat-form-field>

        <mat-form-field
          floatLabel="auto"
          hideRequiredMarker
          appearance="outline"
          class="formWidth"
        >
          <mat-label class="global_search"
            >{{ "DRIVERS.EMAIL" | translate
            }}<span class="star">*</span></mat-label
          >
          <input
            matInput
            type="email"
            email
            name="email"
            pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}?"
            [(ngModel)]="driver.email"
            autocomplete="off"
            required
            [readonly]="readOnly || driver.id"
            matTooltip="{{ 'APPLICATION.CAN_NOT_CHANGE_EMAIL' | translate }}"
            [matTooltipDisabled]="readOnly || !driver.id"
          />
        </mat-form-field>

        <br />
        <mat-form-field
          floatLabel="auto"
          hideRequiredMarker
          appearance="outline"
          class="formWidth"
        >
          <mat-label class="global_search"
            >{{ "DRIVERS.CDL_NUMBER" | translate
            }}<span class="star">*</span></mat-label
          >
          <input
            matInput
            name="cdlNumber"
            [(ngModel)]="driver.cdlNumber"
            autocomplete="off"
            required
            [readonly]="readOnly"
            autocomplete="off"
            maxlength="20"
          />
        </mat-form-field>

        <mat-form-field
          floatLabel="auto"
          hideRequiredMarker
          appearance="outline"
          class="formWidth"
        >
          <mat-label class="global_search"
            >{{ "DRIVERS.CDL_EXPIRE" | translate
            }}<span class="star">*</span></mat-label
          >
          <input
            matInput
            [matDatepicker]="cdlExpirationDate"
            name="cdlExpirationDate"
            [(ngModel)]="driver.cdlExpirationDate"
            required
            [readonly]="readOnly"
            autocomplete="off"
            [min]="minDate"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="cdlExpirationDate"
            *ngIf="!readOnly"
          ></mat-datepicker-toggle>
          <mat-datepicker #cdlExpirationDate></mat-datepicker>
        </mat-form-field>

        <mat-form-field
          floatLabel="auto"
          hideRequiredMarker
          appearance="outline"
          class="formWidth"
        >
          <mat-label class="global_search"
            >{{ "DRIVERS.MEDICAL_EXP_DATE" | translate
            }}<span class="star">*</span></mat-label
          >
          <input
            matInput
            [matDatepicker]="medicalCardExpirationDate"
            name="medicalCardExpirationDate"
            [(ngModel)]="driver.medicalCardExpirationDate"
            autocomplete="off"
            required
            [readonly]="readOnly"
            [min]="minDate"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="medicalCardExpirationDate"
            *ngIf="!readOnly"
          ></mat-datepicker-toggle>
          <mat-datepicker #medicalCardExpirationDate></mat-datepicker>
        </mat-form-field>

        <mat-form-field
          floatLabel="auto"
          hideRequiredMarker
          appearance="outline"
          class="formWidth"
        >
          <mat-label class="global_search">{{
            "DRIVERS.ANNUAL_DATE" | translate
          }}</mat-label>
          <input
            matInput
            [matDatepicker]="annualDrivingRecordCheckDueDate"
            name="annualDrivingRecordCheckDueDate"
            [(ngModel)]="driver.annualDrivingRecordCheckDueDate"
            autocomplete="off"
            [readonly]="readOnly"
            [min]="minDate"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="annualDrivingRecordCheckDueDate"
            *ngIf="!readOnly"
          ></mat-datepicker-toggle>
          <mat-datepicker #annualDrivingRecordCheckDueDate></mat-datepicker>
        </mat-form-field>
        <br />
        <mat-form-field
          floatLabel="auto"
          hideRequiredMarker
          appearance="outline"
          class="formWidth"
        >
          <mat-label class="global_search">{{
            "DRIVERS.DRUG_TEST_DATE" | translate
          }}</mat-label>
          <input
            matInput
            [matDatepicker]="randomDrugTestDueDate"
            name="randomDrugTestDueDate"
            [(ngModel)]="driver.randomDrugTestDueDate"
            autocomplete="off"
            [readonly]="readOnly"
            [min]="minDate"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="randomDrugTestDueDate"
            *ngIf="!readOnly"
          ></mat-datepicker-toggle>
          <mat-datepicker #randomDrugTestDueDate></mat-datepicker>
        </mat-form-field>

        <mat-form-field
          floatLabel="auto"
          hideRequiredMarker
          appearance="outline"
          class="formWidth"
        >
          <mat-label class="global_search"
            >{{ "DRIVERS.EMPLOYMENT_START" | translate
            }}<span class="star">*</span></mat-label
          >
          <input
            matInput
            [matDatepicker]="employmentStart"
            name="employmentStart"
            [(ngModel)]="driver.employmentStart"
            autocomplete="off"
            required
            [readonly]="readOnly"
            [min]="minDate"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="employmentStart"
            *ngIf="!readOnly"
          ></mat-datepicker-toggle>
          <mat-datepicker #employmentStart></mat-datepicker>
        </mat-form-field>
        <mat-form-field
          floatLabel="auto"
          hideRequiredMarker
          appearance="outline"
          class="formWidth"
        >
          <mat-label class="global_search">{{
            "DRIVERS.EMPLOYMENT_END" | translate
          }}</mat-label>
          <input
            matInput
            [matDatepicker]="employmentEnd"
            name="employmentEnd"
            [(ngModel)]="driver.employmentEnd"
            autocomplete="off"
            [readonly]="readOnly"
            [min]="minDate"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="employmentEnd"
            *ngIf="!readOnly"
          ></mat-datepicker-toggle>
          <mat-datepicker #employmentEnd></mat-datepicker>
        </mat-form-field>
        <mat-form-field
          floatLabel="auto"
          hideRequiredMarker
          appearance="outline"
          class="formWidth"
        >
          <mat-label class="global_search"
            >{{ "DRIVERS.DEDICATED_DISPATCHER" | translate }}
          </mat-label>
          <mat-select
            name="dedicatedDispatcher"
            [(ngModel)]="driver.dedicatedDispatcher"
            class="global_search"
            placeholder="{{ 'DRIVERS.DEDICATED_DISPATCHER' | translate }}"
            [compareWith]="compareObjects"
            [readonly]="readOnly"
            [disabled]="readOnly"
          >
            <ng-container *ngIf="dispatcherList.length > 0">
              <mat-option
                *ngFor="let dispatcher of dispatcherList"
                [value]="dispatcher"
              >
                {{ dispatcher.firstname + " " + dispatcher.lastname }}
              </mat-option>
            </ng-container>
          </mat-select>
        </mat-form-field>
        <br />
        <div class="matToggleDriver">
          <mat-slide-toggle
            [disabled]="readOnly"
            color="primary"
            [checked]="toBool(driver.isActive)"
            (change)="
              setActiveValue($event); showWarning(driver.id, driver.isActive)
            "
            class="matToggleActive"
            >{{ "DRIVERS.ACTIVE" | translate }}</mat-slide-toggle
          >
          <!-- ToDo MYT-246 -->
          <!-- Zameniti liniju kada se zavrsi story Driver app -->
          <!-- [disabled]="readOnly || disableOpenAccountToggle" -->
          <!-- <mat-slide-toggle
            [disabled]="true"
            color="primary"
            matTooltip="{{
              'DRIVERS.CREATE_DRIVER_ACCOUNT_TOOLTIP' | translate
            }}"
            [checked]="toBool(driver.allowLogin)"
            (change)="setDriverAccountOpening($event)"
            >{{ "DRIVERS.CREATE_DRIVER_ACCOUNT" | translate }}</mat-slide-toggle
          > -->
        </div>
      </div>
      <mat-divider></mat-divider>
      <mat-label class="labelPayroll"
        >{{ "DRIVERS.PAYROLL_SET" | translate
        }}<span class="star">*</span></mat-label
      >
      <div class="payrollSetDiv">
        <mat-form-field
          floatLabel="auto"
          hideRequiredMarker
          appearance="outline"
          class="formWidthPayment"
          [ngClass]="{
            'invalid-questions':
              !this.driver.payrollSettings?.type?.id && submited
          }"
          id="payrollSet"
        >
          <mat-label class="global_search"
            >{{ "DRIVERS.PAYROLL_TYPE" | translate }}
          </mat-label>
          <mat-select
            class="global_search"
            placeholder="{{ 'DRIVERS.PR_TYPE' | translate }}"
            [compareWith]="compareObjects"
            name="payrollSettingsType"
            (selectionChange)="payrollTypeChanged(payrollType)"
            [(ngModel)]="payrollType"
            [readonly]="readOnly"
            [disabled]="readOnly"
            required
          >
            <ng-container *ngIf="payrollTypeList.length > 0">
              <mat-option
                *ngFor="let payrollType of payrollTypeList"
                [value]="payrollType"
              >
                {{ "PAYROLL_TYPE_NAME." + payrollType.name | translate }}
              </mat-option>
            </ng-container>
          </mat-select>
        </mat-form-field>

        <mat-form-field
          *ngIf="payrollTypePercentageOrOwnerOperator"
          floatLabel="auto"
          hideRequiredMarker
          appearance="outline"
          floatLabel="always"
          class="formPercentage"
        >
          <mat-label>
            {{ "DRIVERS.PERCENTAGE" | translate }}<span class="star">*</span>
          </mat-label>
          <input
            matInput
            name="payrollSettingsPercentage"
            onfocus="this.select()"
            [ngModel]="driver.payrollSettings.percentage"
            (ngModelChange)="driver.payrollSettings.percentage = $event"
            matTooltip="{{ 'DRIVERS.ENTER_PERCENTAGE' | translate }}"
            autocomplete="off"
            placeholder="0"
            [readonly]="readOnly"
            class="alignLeft"
            required
          /><span matTextSuffix>%&nbsp;</span>
        </mat-form-field>

        <mat-form-field
          *ngIf="payrollTypePerMile"
          floatLabel="auto"
          hideRequiredMarker
          appearance="outline"
          floatLabel="always"
          class="perMile"
        >
          <mat-label>
            {{ "DRIVERS.PER_MILE_L" | translate }}<span class="star">*</span>
          </mat-label>
          <input
            matInput
            onfocus="this.select()"
            name="payrollSettingsPerMileLoaded"
            [(ngModel)]="driver.payrollSettings.perMileLoaded"
            placeholder="0"
            autocomplete="off"
            class="permile-left-align"
            [readonly]="readOnly"
            required
          /><span matTextPrefix class="dolarPrefix">$&nbsp;</span>
        </mat-form-field>

        <mat-form-field
          *ngIf="payrollTypePerMile"
          floatLabel="auto"
          hideRequiredMarker
          appearance="outline"
          floatLabel="always"
          class="perMile"
        >
          <mat-label>
            {{ "DRIVERS.PER_MILE_D" | translate }}<span class="star">*</span>
          </mat-label>
          <input
            matInput
            onfocus="this.select()"
            name="payrollSettingsPerMileDeadhead"
            [(ngModel)]="driver.payrollSettings.perMileDeadhead"
            placeholder="0"
            class="permile-left-align"
            autocomplete="off"
            [readonly]="readOnly"
            required
          /><span matTextPrefix class="dolarPrefix">$&nbsp;</span>
        </mat-form-field>

        <mat-form-field
          *ngIf="payrollTypePerMile"
          floatLabel="auto"
          hideRequiredMarker
          appearance="outline"
          floatLabel="always"
          class="perMile"
        >
          <mat-label>
            {{ "DRIVERS.PER_STOP" | translate }}<span class="star">*</span>
          </mat-label>
          <input
            matInput
            onfocus="this.select()"
            name="payrollSettingsPerStop"
            [(ngModel)]="driver.payrollSettings.perStop"
            autocomplete="off"
            [readonly]="readOnly"
            placeholder="0"
            class="permile-left-align"
            required
          /><span matTextPrefix class="dolarPrefix">$&nbsp;</span>
        </mat-form-field>
        <mat-form-field
          *ngIf="payrollTypePerMile"
          floatLabel="auto"
          hideRequiredMarker
          appearance="outline"
          floatLabel="always"
          class="perMile"
        >
          <mat-label>
            {{ "DRIVERS.OTHER" | translate }}
          </mat-label>
          <input
            matInput
            onfocus="this.select()"
            name="payrollSettingsOther"
            [(ngModel)]="driver.payrollSettings.other"
            autocomplete="off"
            [readonly]="readOnly"
            placeholder="0"
            class="permile-left-align"
          /><span matTextPrefix class="dolarPrefix">$&nbsp;</span>
        </mat-form-field>
      </div>
    </mat-dialog-content>

    <div
      *ngIf="driverId && !driver.accountActivated && disableOpenAccountToggle"
      class="divNoComplete"
    >
      <label class="labelNoComplete">{{
        "APPLICATION.PROFILE_NOT_COMPLETED" | translate
      }}</label>
    </div>
    <div
      *ngIf="
        driverId &&
        !driver.accountActivated &&
        !readOnly &&
        disableOpenAccountToggle
      "
      class="divResend"
    >
      <button class="buttonResend" (click)="resendEmail()" mat-raised-button>
        {{ "APPLICATION.RESEND_ACTIVATION_EMAIL" | translate }}
      </button>
    </div>

    <br />
    <mat-dialog-actions class="mat-dialog">
      <button mat-button class="btnCan" mat-dialog-close>
        {{ "DRIVERS.CANCEL" | translate }}
      </button>

      <button
        mat-button
        class="btn btn-green"
        (click)="save(editDriver.form.invalid)"
        *ngIf="!readOnly && userDataService.isAdminDispatcher"
      >
        {{ "DRIVERS.SAVE" | translate }}
      </button>
    </mat-dialog-actions>
  </form>
</div>
