import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-safety-info',
  templateUrl: './safety-info.component.html',
  styleUrls: ['./safety-info.component.scss']
})
export class SafetyInfoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
