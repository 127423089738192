import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, lastValueFrom } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Escrow } from '../_models/escrow/escrow';
import { EscrowCreateRequest } from '../_models/escrow/escrowCreateRequest';
import { EscrowUpdateRequest } from '../_models/escrow/escrowUpdateRequest';
import { GenericResponse, Response } from '../_models/response';
import { ResponseDataGeneric } from '../_models/responseData';
import { EscrowFactory } from './factory/escrow.factory.service';

@Injectable({ providedIn: 'root' })
export class EscrowService {
  constructor(private http: HttpClient, private escrowFactory: EscrowFactory) {}

  getEscrows(driverId): Observable<Escrow[]> {
    return this.http
      .get<GenericResponse<ResponseDataGeneric<Escrow>>>(
        `${environment.apiUrl}/escrows/driver/${driverId}`
      )
      .pipe(map((response) => response.data.collection as Escrow[]));
  }

  updateEscrow(escrow: EscrowUpdateRequest) {
    return this.http
      .put<Response>(`${environment.apiUrl}/escrows`, escrow)
      .pipe(map((response) => response.data as Escrow))
      .pipe(
        tap((responseData) => {
          this.escrowFactory.getEscrowTimeZoneDate(responseData);
        })
      );
  }

  addEscrow(escrow: EscrowCreateRequest) {
    return this.http
      .post<Response>(`${environment.apiUrl}/escrows`, escrow)
      .pipe(map((response) => response.data as Escrow))
      .pipe(
        tap((responseData) => {
          this.escrowFactory.getEscrowTimeZoneDate(responseData);
        })
      );
  }

  deleteEscrow(id: number) {
    return this.http.delete<Response>(`${environment.apiUrl}/escrows/${id}`);
  }
  async getPdfForDriverId(id: number) {
    let headers = new HttpHeaders();
    headers = headers.append('Accept', 'application/pdf');
    let url = `${environment.apiUrl}/escrows/driver/${id}/pdf`;
    const result = await lastValueFrom(
      this.http.post(url, {}, { headers: headers, responseType: 'blob' })
    );
    return result;
  }
}
