<div class="modal-dialog">
<h2 mat-dialog-title>
  <mat-icon class="iconwarn">info</mat-icon>
</h2>
<h2 mat-dialog-title>{{title}}</h2>
<mat-dialog-content>
  <p class="text">
    {{ infoText }}
  </p>
  <p class="text">
    {{ infoTextPlus }}
  </p>
</mat-dialog-content>
<mat-dialog-actions style="float: none;">
  <button
      mat-button
      class="btnCan"
      [mat-dialog-close]="false"
      cdkFocusInitial
    >
      {{ "CONFIRM_DIALOG.OK" | translate }}
    </button>
</mat-dialog-actions>
</div>
