<div>
  <mat-tab-group mat-align-tabs="left">
    <mat-tab>
      <ng-template mat-tab-label>
        {{ "SETTINGS.LANGUAGES" | translate }}
      </ng-template>
      <app-select_lang></app-select_lang>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>{{
        "SETTINGS.PERSONAL_DATA" | translate
      }}</ng-template>
    </mat-tab>
  </mat-tab-group>
</div>
