import { SelectionModel } from '@angular/cdk/collections';
import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {
  ActivatedRoute,
  NavigationStart,
  Params,
  Router,
} from '@angular/router';
import { debounce } from 'lodash-es';
import { first } from 'rxjs/operators';
import Common from 'src/app/_helpers/common';
import { PayrollAmountImpactTypeEnum } from 'src/app/_models/_payroll/payroll-amount-impact';
import {
  PayrollCalculation,
  PayrollCalculationCreateRequest,
} from 'src/app/_models/_payroll/payroll-calculation';
import { UserTruckPayrollAmountImpact } from 'src/app/_models/_payroll/user-truck-payroll-amount-impact';
import { DriverModel } from 'src/app/_models/driver/driver';
import { LoadModel } from 'src/app/_models/load/load';
import { PayrollLocalStorage } from 'src/app/_models/payrollLocalStorage';
import { TruckModel } from 'src/app/_models/truck';
import {
  DriverService,
  EscrowService,
  LoanService,
  PayrollService,
  TenantService,
  ToastrTranslateService,
  TruckService,
} from 'src/app/_services';
import { LocalStorageService } from '../../../_services/local.storage.service';
import {
  AmountItemComponent,
  AmountItemData,
} from '../../amount-item/amount-item.component';

@Component({
  selector: 'app-payroll-calc',
  templateUrl: './payroll-calc.component.html',
  styleUrls: ['./payroll-calc.component.scss'],
})
export class PayrollCalcComponent implements OnInit, OnDestroy {
  @Input('truckId') truckId: number;
  @Input('driverId') driverId: number;

  @ViewChild('reimbursementsComponent')
  reimbursementsComponent: AmountItemComponent;
  @ViewChild('expensesComponent') expensesComponent: AmountItemComponent;
  @ViewChild('tableIn') set matSortIn(ms: MatSort) {
    this.sortIn = ms;
    this.setDataSourceInAttributes();
  }
  @ViewChild('tableOut') set matSortOut(ms: MatSort) {
    this.sortOut = ms;
    this.setDataSourceOutAttributes();
  }

  private sortIn: MatSort;
  private sortOut: MatSort;

  public displayedColumns: string[] = [
    'loadNumber',
    'dispatcherName',
    'pickupAddress',
    'destinationAddress',
    'pickupDate',
    'deliveryDate',
    'totalInvoiced',
    'check',
  ];

  loadsDataInRange: LoadModel[] = [];
  payrollDataGetCompleted = false;
  loadsDataOutOfRange: LoadModel[] = [];
  calendarPeriodFrom: Date;
  calendarPeriodTo: Date;

  dataSourceInRange = new MatTableDataSource<LoadModel>();
  dataSourceOutOfRange = new MatTableDataSource<LoadModel>();

  selectionInRange = new SelectionModel<LoadModel>(true, []);
  selectionOutOfRange = new SelectionModel<LoadModel>(true, []);

  comment: string = '';

  periodForm: UntypedFormGroup;
  columnsControl = new UntypedFormControl();

  truck: TruckModel;
  total: number = 0;
  payrollSettingsType: string = '';
  payrollCalculation: PayrollCalculation;

  loan: number | null = null;
  escrow: number | null = null;

  userTruckPayrollAmountImpacts: UserTruckPayrollAmountImpact[] = [];

  reimbursementAmountItemData: AmountItemData = null;
  expenseAmountItemData: AmountItemData = null;
  routerSub: any;
  existingPayrollData: PayrollLocalStorage;
  existingPayrollDataUse: boolean;

  constructor(
    private payrollService: PayrollService,
    private localStorageService: LocalStorageService,
    private truckService: TruckService,
    private route: ActivatedRoute,
    public toastrTranslateService: ToastrTranslateService,
    private loanService: LoanService,
    private escrowService: EscrowService,
    private router: Router,
    private driverService: DriverService,
    private tenantService: TenantService
  ) {
    this.routerSub = this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        const currentPayroll = this.makePayrollCalculationRequest();
        this.payrollService.changeCurrentPayroll(currentPayroll);
      }
    });
  }

  public ngOnDestroy() {
    this.routerSub.unsubscribe();
  }

  ngOnInit(): void {
    this.route.parent.params.subscribe(async (params: Params) => {
      this.truckId = +params['truckId'];
      this.driverId = +params['driverId'];
      await this.initializeComponent();
    });
    this.onDateChange = debounce(this.onDateChange, 1000);
  }

  private initializeComponent() {
    this.initUseExixstingData();
    this.initPayrollPeriod();
    this.truckService
      .getTruck(this.truckId)
      .subscribe(async (truck: TruckModel) => {
        if (!truck.driver) {
          this.driverService
            .getDriver(this.driverId)
            .subscribe((driver: DriverModel) => {
              truck.driver = driver;
              this.GetTabData(truck);
            });
        } else {
          this.GetTabData(truck);
        }
      });
  }

  getCalendarData() {
    this.calendarPeriodFrom = this.periodForm.get('start').value;
    this.calendarPeriodTo = Common.getDateEndOfDay(
      this.periodForm.get('end').value
    );
  }

  setDataSourceInAttributes() {
    this.dataSourceInRange.sortingDataAccessor = (item, property) => {
      return this.sortByProperty(item, property);
    };
    this.dataSourceInRange.sort = this.sortIn;
  }
  setDataSourceOutAttributes() {
    this.dataSourceOutOfRange.sortingDataAccessor = (item, property) => {
      return this.sortByProperty(item, property);
    };
    this.dataSourceOutOfRange.sort = this.sortOut;
  }

  sortByProperty(item, property) {
    switch (property) {
      case 'dispatcherName':
        if (item.dispatcher) {
          return item.dispatcher.firstname + item.dispatcher.lastname;
        }
        return '';
      case 'pickupAddress':
        if (item.pickupLocation) {
          return (
            Common.getSafeString(item.pickupLocation?.address) +
            Common.getSafeString(item.pickupLocation?.poBox) +
            Common.getSafeString(item.pickupLocation?.countryCode)
          );
        }
        return '';
      case 'destinationAddress':
        if (item.destinationLocation) {
          return (
            Common.getSafeString(item.destinationLocation?.address) +
            Common.getSafeString(item.destinationLocation?.poBox) +
            Common.getSafeString(item.destinationLocation?.countryCode)
          );
        }
        return '';
      default:
        return item[property];
    }
  }

  initUseExixstingData() {
    this.existingPayrollDataUse =
      this.localStorageService.getExistingPayrollDataUse();
    this.localStorageService.setExistingPayrollDataUse(true);
  }

  initPayrollPeriod() {
    const payrollPeriod = this.localStorageService.getGlobalPayrollPeriod();
    this.periodForm = new UntypedFormGroup({
      start: new UntypedFormControl(payrollPeriod.dateFrom),
      end: new UntypedFormControl(payrollPeriod.dateTo),
    });
  }

  addExpense(): void {
    this.expensesComponent.addvalue();
  }

  addReimbursement(): void {
    this.reimbursementsComponent.addvalue();
  }

  private GetTabData(truck: TruckModel) {
    this.truck = truck;
    this.payrollSettingsType = this.truck.driver.payrollSettings.type.name;

    this.existingPayrollData = this.localStorageService.getPayrollLocalStorage(
      this.truck.id,
      this.truck.driver.id
    );
    if (this.existingPayrollData) {
      if (this.existingPayrollDataUse) {
        let oldDateFrom = this.existingPayrollData.dateFrom;
        let oldDateTo = this.existingPayrollData.dateTo;
        this.periodForm.get('start').setValue(oldDateFrom);
        this.periodForm.get('end').setValue(oldDateTo);
      }
      this.comment = this.existingPayrollData.comment;
    }
    this.getPayrollTabData(
      this.truck.id,
      this.truck.driver.id,
      this.existingPayrollData
    );
    this.getEscrows();
    this.getLoans();
  }

  localDate(value) {
    var utcDate = new Date(value);
    return utcDate.getTime() - utcDate.getTimezoneOffset() * 60 * 1000;
  }

  getPayrollTabData(
    truckId: number,
    driverId: number,
    existingData: PayrollLocalStorage
  ) {
    this.unselectAllRows();
    this.getCalendarData();
    this.payrollService
      .getPayrollTabData(
        driverId,
        truckId,
        this.calendarPeriodFrom,
        this.calendarPeriodTo
      )
      .pipe(first())
      .subscribe((response) => {
        this.dataSourceInRange.data = response.loadsInDateRange;
        this.dataSourceOutOfRange.data = response.loadsOutOfDateRange;

        if (!existingData) this.selectAllRowsInRange();
        this.selectRowsByLoadIds(existingData?.loads ?? []);

        this.userTruckPayrollAmountImpacts =
          response.userTruckPayrollAmountImpacts;

        let rArray = this.userTruckPayrollAmountImpacts.filter(
          (x) =>
            x.payrollAmountImpact.type ==
            PayrollAmountImpactTypeEnum.Reimbursement
        );
        rArray.sort((r1, r2) => r1.id - r2.id);
        this.reimbursementAmountItemData = {
          type: PayrollAmountImpactTypeEnum.Reimbursement,
          amountImpacts: rArray,
          suggestions: response.reimbursementOptions,
          driverId: this.truck.driver.id,
          truckId: this.truckId,
        };

        let eArray = this.userTruckPayrollAmountImpacts.filter(
          (x) =>
            x.payrollAmountImpact.type == PayrollAmountImpactTypeEnum.Expense
        );
        eArray.sort((r1, r2) => r1.id - r2.id);
        this.expenseAmountItemData = {
          type: PayrollAmountImpactTypeEnum.Expense,
          amountImpacts: eArray,
          suggestions: response.expenseOptions,
          driverId: this.truck.driver.id,
          truckId: this.truckId,
        };
        this.calcTotal();
        this.payrollDataGetCompleted = true;
      });
  }

  getLoans() {
    this.loanService.getLoans(this.truck.driver.id).subscribe((loans) => {
      this.loan = loans.map((l) => l.adjustment).reduce((a, b) => a + b, 0);
    });
  }

  getEscrows() {
    this.escrowService.getEscrows(this.truck.driver.id).subscribe((escrows) => {
      this.escrow = escrows.map((l) => l.adjustment).reduce((a, b) => a + b, 0);
    });
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllInRangeSelected() {
    const numSelected = this.selectionInRange.selected.length;
    const numRows = this.dataSourceInRange.data.length;
    return numSelected === numRows;
  }

  isAllOutOfRangeSelected() {
    const numSelected = this.selectionOutOfRange.selected.length;
    const numRows = this.dataSourceOutOfRange.data.length;
    return numSelected === numRows;
  }

  isSelectedPage() {
    const numSelected = this.selectionInRange.selected.length;
    const page = this.dataSourceInRange.paginator.pageSize;
    let endIndex: number;
    // First check whether data source length is greater than current page index multiply by page size.
    // If yes then endIdex will be current page index multiply by page size.
    // If not then select the remaining elements in current page only.
    if (
      this.dataSourceInRange.data.length >
      (this.dataSourceInRange.paginator.pageIndex + 1) *
        this.dataSourceInRange.paginator.pageSize
    ) {
      endIndex =
        (this.dataSourceInRange.paginator.pageIndex + 1) *
        this.dataSourceInRange.paginator.pageSize;
    } else {
      // tslint:disable-next-line:max-line-length
      endIndex =
        this.dataSourceInRange.data.length -
        this.dataSourceInRange.paginator.pageIndex *
          this.dataSourceInRange.paginator.pageSize;
    }
    return numSelected === endIndex;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggleInRange() {
    this.isAllInRangeSelected()
      ? this.selectionInRange.clear()
      : this.selectAllRowsInRange();
  }

  masterToggleOutOfRange() {
    this.isAllOutOfRangeSelected()
      ? this.selectionOutOfRange.clear()
      : this.selectAllRowsOutOfRange();
  }

  selectAllRowsInRange() {
    this.dataSourceInRange.data.forEach((row) =>
      this.selectionInRange.select(row)
    );
  }

  selectAllRowsOutOfRange() {
    this.dataSourceOutOfRange.data.forEach((row) =>
      this.selectionOutOfRange.select(row)
    );
  }
  selectRowsByLoadIds(loadIds: number[]) {
    this.dataSourceInRange.data.forEach((row) => {
      if (loadIds.find((x) => x === row.id)) {
        this.selectionInRange.select(row);
      }
    });
    this.dataSourceOutOfRange.data.forEach((row) => {
      if (loadIds.find((x) => x === row.id)) {
        this.selectionOutOfRange.select(row);
      }
    });
  }

  selectRowsOutOfRangeByDate() {
    this.dataSourceOutOfRange.data.forEach((row) =>
      this.selectionOutOfRange.select(row)
    );
  }
  unselectAllRows() {
    this.selectionInRange.clear();
    this.selectionOutOfRange.clear();
  }
  selectRows() {
    let endIndex: number;
    if (
      this.dataSourceInRange.data.length >
      (this.dataSourceInRange.paginator.pageIndex + 1) *
        this.dataSourceInRange.paginator.pageSize
    ) {
      endIndex =
        (this.dataSourceInRange.paginator.pageIndex + 1) *
        this.dataSourceInRange.paginator.pageSize;
    } else {
      endIndex = this.dataSourceInRange.data.length;
    }

    for (
      let index =
        this.dataSourceInRange.paginator.pageIndex *
        this.dataSourceInRange.paginator.pageSize;
      index < endIndex;
      index++
    ) {
      this.selectionInRange.select(this.dataSourceInRange.data[index]);
    }
  }

  onDateChange() {
    if (
      this.periodForm.get('start').value &&
      this.periodForm.get('end').value
    ) {
      //??Sta li je ovo? //
      // this.existingPayrollData = this.payrollService.getPayrollLocalStorage(this.truck.id, this.truck.driver.id);
      this.getCalendarData();
      this.localStorageService.setGlobalPayrollPeriod(
        this.calendarPeriodFrom,
        this.calendarPeriodTo
      );
      this.getPayrollTabData(this.truck.id, this.truck.driver.id, null);
      this.calcTotal();
    }
  }

  tableInRangeSelectionChanged() {
    this.calcTotal();
  }

  tableOutOfRangeSelectionChanged() {
    this.calcTotal();
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  makePayrollCalculationRequest() {
    let calcReq: PayrollCalculationCreateRequest = {
      dateFrom: this.tenantService.getTenetUtcDate(this.calendarPeriodFrom),
      dateTo: this.tenantService.getTenetUtcDate(this.calendarPeriodTo),
      driverId: this.truck.driver.id,
      truckId: this.truck.id,
      payrollTypeId: this.truck.driver.payrollSettings.type.id,
      percentage: this.truck.driver.payrollSettings.percentage,
      perMileLoaded: this.truck.driver.payrollSettings.perMileLoaded,
      perMileDeadhead: this.truck.driver.payrollSettings.perMileDeadhead,
      perStop: this.truck.driver.payrollSettings.perStop,
      other: this.truck.driver.payrollSettings.other,
      loads: this.selectionInRange.selected
        .map((l) => l.id)
        .concat(this.selectionOutOfRange.selected.map((l) => l.id))
        .filter(this.onlyUnique),
      comment: this.comment,
      includeEscrows: true,
      includeLoans: true,
    };
    return calcReq;
  }

  calcTotal() {
    if (!this.truck) {
      this.total = 0;
      this.payrollService.changeCurrentTotal(this.total);
      return;
    }

    let calcReq = this.makePayrollCalculationRequest();
    this.payrollService
      .getPayrollCalculationTotalSum(calcReq)
      .subscribe((res) => {
        this.total = res?.total;
        this.payrollService.changeCurrentTotal(this.total);
        let payrollLocalStorage: PayrollLocalStorage = {
          total: this.total,
          dateFrom: this.calendarPeriodFrom,
          dateTo: this.calendarPeriodTo,
          driverId: calcReq.driverId,
          truckId: calcReq.truckId,
          loads: calcReq.loads,
          comment: calcReq.comment,
        };
        this.localStorageService.addPayrollLocalStorage(payrollLocalStorage);
      });
  }

  submitPayrollCalculation() {
    let calcReq = this.makePayrollCalculationRequest();
    this.payrollService.submitPayrollCalculation(calcReq).subscribe((pc) => {
      this.payrollCalculation = pc;
      this.getLoans();
      this.getEscrows();
      this.toastrTranslateService.success('NEW_PAYROLL_CALC.SAVED');
      this.initializeComponent();
    });
  }
}
