import { Pipe, PipeTransform } from '@angular/core';
import * as luxon from 'luxon';
import { TenantService } from 'src/app/_services';

@Pipe({
  name: 'spanTodayDays',
})
export class SpanTodayPipe implements PipeTransform {
  constructor(private tenantService: TenantService) {}
  transform(value: any, args?: any): any {
    const timeZone = this.tenantService.getSetTimeZone();
    if (value) {
      const start = luxon.DateTime.fromISO(value).setZone(timeZone.name);
      const end = luxon.DateTime.now().setZone(timeZone.name).startOf('day');
      return start.diff(end, 'days').toObject().days.toFixed(0);
    }
  }
}
