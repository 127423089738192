<h2 mat-dialog-title class="text">
  {{ loadNumber }}
</h2>
<h2 mat-dialog-title class="text">
  {{ "NEW_LOAD.DOWNLOAD_DOCUMENTS" | translate }}
</h2>
<hr />
<table *ngIf="documentList.length > 0" class="tablePDF">
  <thead>
    <tr class="tableDoc">
      <th class="tableDoc" scope="col">
        {{ "NEW_LOAD.FILE_NAME" | translate }}
      </th>
      <th class="tableDoc" scope="col">
        {{ "NEW_LOAD.UPLOAD_DATE" | translate }}
      </th>
      <th class="tableDoc" scope="col">
        {{ "NEW_LOAD.DOWNLOAD" | translate }}
      </th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let document of documentList; index as i">
      <td class="tableDoc">{{ document.fileNameWithExtension }}</td>
      <td class="tableDoc">
        {{ document.createdDate | tenantDate | date : "MM/dd/yyyy h:mm a" }}
      </td>
      <td class="tableDoc" id="iconDownload">
        <span
          [disabled]="loading"
          class="material-icons"
          (click)="downloadPdf(document.id, i)"
        >
          <mat-icon class="download"> download</mat-icon>
        </span>
      </td>
    </tr>
  </tbody>
</table>

<table *ngIf="documentList.length == 0">
  <td class="tableDoc" id="massageNoFile">
    {{ "NEW_LOAD.NO_FILE" | translate }}
  </td>
</table>

<div>
  <mat-slide-toggle
    class="toggleButtonDiv"
    color="primary"
    [(ngModel)]="isStatusChanged"
    [disabled]="!isStatusChangeReady || loading"
  >
    {{ "PRINT_INVOICE_DIALOG.INVOICED_QUESTION" | translate }}
  </mat-slide-toggle>
</div>

<div class="div-buttons">
  <button mat-button class="btnCan" mat-dialog-close>
    {{ "NEW_LOAD.CANCEL" | translate }}
  </button>

  <button
    mat-flat-button
    class="btnDel"
    (click)="downloadAll()"
    [disabled]="loading || documentList.length == 0"
  >
    {{ "NEW_LOAD.DOWNLOAD_ALL" | translate }}
  </button>
</div>
