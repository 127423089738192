<h2 mat-dialog-title class="text">
  {{ loadNumber }}
</h2>
<h2 mat-dialog-subtitle class="text">
  {{ "NEW_LOAD.SEND_ALL_DOCUMENT" | translate }}
</h2>

<hr />
<table *ngIf="documentList.length > 0" class="tablePDF">
  <thead>
    <tr class="tableDoc">
      <th class="tableDoc" scope="col">
        {{ "NEW_LOAD.FILE_NAME" | translate }}
      </th>
      <th class="tableDoc" scope="col">
        {{ "NEW_LOAD.UPLOAD_DATE" | translate }}
      </th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let document of documentList; index as i">
      <td class="tableDoc">{{ document.fileNameWithExtension }}</td>
      <td class="tableDoc">
        {{ document.createdDate | tenantDate | date : "MM/dd/yyyy h:mm a" }}
      </td>
    </tr>
  </tbody>
</table>
<table *ngIf="documentList.length == 0">
  <td class="tableDoc" id="massageNoFile">
    {{ "NEW_LOAD.NO_FILE" | translate }}
  </td>
</table>
<form #editSendEmail="ngForm">
  <span *ngIf="documentList.length > 0">
    <div class="div-context">
      <mat-checkbox
        [checked]="selectedTo"
        (change)="selectTo($event)"
        [disabled]="!toAddress"
        >{{ "NEW_LOAD.TO_SEND_MAIL" | translate }}</mat-checkbox
      >
      <div class="customer-block">
        <div class="text-customer">
          <span class="text-customer-span">{{ customer.name }} </span>
        </div>
        <div>
          <span class="email-address" *ngIf="toAddress">[{{ toAddress }}]</span>
          <span class="email-address" *ngIf="!toAddress">
            [
            <span class="email-message">{{
              "NEW_LOAD.SEND_MISSING_EMAIL" | translate
            }}</span
            >]
          </span>
        </div>
      </div>
    </div>

    <div>
      <mat-checkbox
        [checked]="selectedCc"
        (change)="selectCc($event)"
        class="send-cc"
        >{{ "NEW_LOAD.CC_SEND_MAIL" | translate }}</mat-checkbox
      >
      <mat-form-field
        floatLabel="auto"
        appearance="outline"
        class="send-document-to"
      >
        <input
          [(ngModel)]="ccAddress"
          (change)="ccCheckBoxChange()"
          matInput
          name="sendDocumentTo"
          [required]="selectedCc"
        />
      </mat-form-field>
    </div>
  </span>
  <div class="div-buttons">
    <button mat-button class="btnCan" mat-dialog-close>
      {{ "NEW_LOAD.CANCEL" | translate }}
    </button>

    <button
      mat-flat-button
      class="btn btn-green"
      [disabled]="
        isLoading || !(documentList.length > 0) || (!selectedCc && !selectedTo)
      "
      (click)="sendEmail(editSendEmail.form.invalid)"
    >
      {{ "NEW_LOAD.SEND" | translate }}
    </button>
  </div>
</form>
