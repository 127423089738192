import { formatDate } from '@angular/common';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSort, MatSortable } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Params } from '@angular/router';
import { saveAs } from 'file-saver-es';
import { SortData } from 'src/app/_models/common/sortData';
import { DriverModel } from 'src/app/_models/driver/driver';
import { TableType } from 'src/app/_models/enums/tableType';
import { LoanCreateRequest } from 'src/app/_models/loan/loanCreateRequest';
import { LoanViewModel } from 'src/app/_models/loan/loanViewModel';
import { TruckModel } from 'src/app/_models/truck';
import { UserModel } from 'src/app/_models/user';
import {
  DriverService,
  EscrowService,
  LoanService,
  PayrollService,
  ToastrTranslateService,
  TruckService,
  UserService,
} from 'src/app/_services';
import { LocalStorageSortService } from 'src/app/_services/local.storage.sort.service';
import { ConfirmDeleteLoanDialogComponent } from '../../confirm-delete-loan-dialog/confirm-delete-loan-dialog.component';

@Component({
  selector: 'app-payroll-loan',
  templateUrl: './payroll-loan.component.html',
  styleUrls: ['./payroll-loan.component.scss'],
})
export class PayrollLoanComponent implements OnInit, AfterViewInit {
  campaignOne: UntypedFormGroup;

  @ViewChild(MatSort) sort: MatSort;

  positiveAdjustmentReason: string | null = null;
  positiveAmount: number | null = null;
  escrow: number | null = null;

  negativeAmount: number | null = null;
  negativeAdjustmentReason: string | null = null;
  totalLoan = 0.0;
  driverId = 0;
  displayedColumns: string[] = [
    'date',
    'userName',
    'reason',
    'adjustment',
    'delete',
    'star',
  ];
  dataSource = new MatTableDataSource<LoanViewModel>();
  truck: TruckModel;
  total: number;
  public totalAdjustment = 0;
  truckId: number;
  sortData: SortData;

  changedByUser: UserModel;
  public form: UntypedFormGroup;
  constructor(
    private route: ActivatedRoute,
    private truckService: TruckService,
    private escrowService: EscrowService,
    private loanService: LoanService,
    private dialog: MatDialog,
    public toastrTranslateService: ToastrTranslateService,
    private builder: UntypedFormBuilder,
    private payrollService: PayrollService,
    private driverService: DriverService,
    private localStorageSortService: LocalStorageSortService,
    private userService: UserService
  ) {
    const today = new Date();
    const month = today.getMonth();
    const year = today.getFullYear();
    this.campaignOne = new UntypedFormGroup({
      start: new UntypedFormControl(new Date(year, month, 13)),
      end: new UntypedFormControl(new Date(year, month, 16)),
    });
    this.form = this.builder.group({
      files: ['', Validators.required],
    });
  }

  ngOnInit(): void {
    this.changedByUser = new UserModel();
    this.userService.getAll().subscribe((changedByUser: UserModel) => {
      this.changedByUser = changedByUser;
    });
    this.sortData = this.localStorageSortService.getSortData(
      TableType.PayrollLoan
    );
    this.payrollService.curentTotal.subscribe((total) => (this.total = total));
    this.route.parent.params.subscribe((params: Params) => {
      this.truckId = +params['truckId'];
      this.driverId = +params['driverId'];
      this.truckService
        .getTruck(this.truckId)
        .subscribe((truck: TruckModel) => {
          if (!truck.driver) {
            this.driverService
              .getDriver(this.driverId)
              .subscribe((driver: DriverModel) => {
                truck.driver = driver;
                this.GetTabData(truck);
              });
          } else {
            this.GetTabData(truck);
          }
          this.GetTabData(truck);
        });
    });
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.sort.sort({
        id: this.sortData.activeColumn,
        start: this.sortData.order,
      } as MatSortable);
      this.dataSource.sort = this.sort;
    });
    this.dataSource.sortingDataAccessor = (item, property) => {
      this.localStorageSortService.setSortData(
        TableType.PayrollLoan,
        this.sort
      );
      return item[property];
    };
  }

  private GetTabData(truck: TruckModel) {
    this.truck = truck;
    this.getLoanList(this.driverId);
    this.getEscrows();
  }

  async downloadPdf() {
    try {
      const data = await this.loanService.getPdfForDriverId(this.driverId);
      const blob = new Blob([data], {
        type: 'application/pdf',
      });

      const fileName =
        this.truck.driver.firstname +
        '_' +
        this.truck.driver.lastname +
        '-' +
        formatDate(Date.now(), 'yyyy_MM_dd-h_mm', 'en-US') +
        '-loans.pdf';
      saveAs(blob, fileName);
    } catch (error) {
      this.toastrTranslateService.error(error, 'COMMON.ERROR_DATA');
    }
  }

  getEscrows() {
    this.escrowService.getEscrows(this.truck.driver.id).subscribe((escrows) => {
      this.escrow = escrows.map((l) => l.adjustment).reduce((a, b) => a + b, 0);
      this.setTotalsAdjustment();
    });
  }

  setTotalsAdjustment() {
    this.totalAdjustment = this.getTotalAdjustment();
  }

  getTotalAdjustment() {
    return this.dataSource.data
      .map((t) => t.adjustment)
      .reduce((acc, value) => acc + value, 0);
  }

  calculateTotalLoan() {
    this.dataSource.data.length > 0
      ? (this.totalLoan = this.dataSource.data
          .map((l) => l.adjustment)
          .reduce((a, b) => a + b))
      : (this.totalLoan = 0);
    this.setTotalsAdjustment();
  }

  addPositiveAdjustment(isInvalid) {
    if (!isInvalid && this.positiveAdjustmentReason && this.positiveAmount) {
      const request: LoanCreateRequest = {
        driverId: this.driverId,
        adjustment:
          this.positiveAmount < 0 ? this.positiveAmount : -this.positiveAmount,
        reason: this.positiveAdjustmentReason,
        date: new Date(),
      };
      this.loanService.addLoan(request).subscribe((_) => {
        this.getLoanList(this.driverId);
      });
      this.clear();
    } else {
      this.toastrTranslateService.warning('GENERAL.FORM_INVALID');
    }
  }
  addNegativeAdjustment(isInvalid) {
    if (!isInvalid && this.negativeAdjustmentReason && this.negativeAmount) {
      const request: LoanCreateRequest = {
        driverId: this.driverId,
        adjustment: this.negativeAmount,
        reason: this.negativeAdjustmentReason,
        date: new Date(),
      };
      this.loanService.addLoan(request).subscribe((_) => {
        this.getLoanList(this.driverId);
      });
      this.clear();
    } else {
      this.toastrTranslateService.warning('GENERAL.FORM_INVALID');
    }
  }
  deleteLoan(loan: LoanViewModel) {
    const dialogRef = this.dialog.open(ConfirmDeleteLoanDialogComponent, {
      width: '450px',
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        this.loanService.deleteLoan(loan.id).subscribe((res) => {
          this.toastrTranslateService.success('COMFIRM_DIALOG.SUCCESS_DELL');
          this.getLoanList(this.driverId);
        });
      }
    });
  }
  getLoanList(driverId: number) {
    this.loanService.getLoans(driverId).subscribe((loans) => {
      this.dataSource.data = loans.map((l) => {
        return {
          id: l.id,
          date: l.date,
          driverName: `${l.driver.firstname} ${l.driver.lastname}`,
          userName: `${l?.changedByUser?.firstname} ${l?.changedByUser?.lastname}`,
          reason: l.reason,
          adjustment: l.adjustment,
        };
      });
      this.calculateTotalLoan();
    });
  }

  refresh() {
    this.getLoanList(this.driverId);
  }

  clear() {
    this.negativeAmount = null;
    this.negativeAdjustmentReason = null;
    this.positiveAdjustmentReason = null;
    this.positiveAmount = null;
  }
}
