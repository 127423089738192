import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-payroll-spec',
  templateUrl: './payroll-spec.component.html',
  styleUrls: ['./payroll-spec.component.scss'],
})
export class PayrollSpecComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
