import { Component, Inject, OnInit } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { CodeModel } from 'src/app/_models/code';
import { LoadModel } from 'src/app/_models/load/load';
import { LoadService, ToastrTranslateService } from 'src/app/_services';
import { CodeFactory } from 'src/app/_services/factory/code.factory.service';
import { PayrollPdfPreviewPopupComponent } from 'src/app/payroll/payroll-pdf-preview-popup/payroll-pdf-preview-popup.component';

@Component({
  selector: 'app-print-invoice-dialog',
  templateUrl: './print-invoice-dialog.component.html',
  styleUrls: ['./print-invoice-dialog.component.scss'],
})
export class PrintInvoiceDialogComponent implements OnInit {
  constructor(
    private loadService: LoadService,
    private codeFactory: CodeFactory,
    private toastrTranslateService: ToastrTranslateService,
    public pdfDialog: MatDialog,
    private dialogRef: MatDialogRef<PrintInvoiceDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {
    this.codeFactory.getStatusList().then((data) => {
      this.statusTypeList = data;
    });
  }

  isStatusChanged = true;
  isNoteIncluded = true;
  isStatusChangeReady = false;
  isLoading = false;
  loadData: LoadModel = new LoadModel();
  statusTypeList: CodeModel[] = [];

  ngOnInit(): void {
    this.checkInvoiceStatusChangeState();
  }

  checkInvoiceStatusChangeState() {
    if (
      this.data.statusName.toUpperCase() == 'DELIVERED' ||
      this.data.statusName.toUpperCase() == 'COMPLETED'
    ) {
      this.isStatusChangeReady = true;
      this.isStatusChanged = true;
    } else {
      this.isStatusChangeReady = false;
      this.isStatusChanged = false;
    }
  }

  toBool(boolVariable: any) {
    if (boolVariable && boolVariable != 'undefined') {
      return Boolean(JSON.parse(boolVariable));
    }
    return false;
  }

  print() {
    if (this.isStatusChanged && this.isStatusChangeReady) {
      this.loadService.getLoadById(this.data.loadId).subscribe((response) => {
        this.loadData = response;
        this.loadData.status = this.statusTypeList.find(
          (x) => x.name.toUpperCase() == 'INVOICED'
        );
        this.loadData.statusId = this.loadData.status.id;
        this.updateLoad();
      });
    }
    else{
      this.getPdfInvoice();
    }
  }

  async getPdfInvoice() {
    try {
      this.isLoading = true;
      let load = await this.loadService.getPdfInvoice(
        this.data.loadId,
        this.isNoteIncluded
      );
      let file = new Blob([load], { type: 'application/pdf' });
      this.isLoading = false;
      this.pdfDialog.open(PayrollPdfPreviewPopupComponent, {
        width: '1024px',
        data: file,
      });
      this.dialogRef.close();
    } catch (error) {
      this.isLoading = false;
      this.toastrTranslateService.error(error, 'COMMON.ERROR_DATA');
    }
  }

  async updateLoad() {
    this.loadService.updateLoad(this.loadData).subscribe({
      next: (response) => {
        if (response) {
          this.getPdfInvoice();
        } else {
          this.toastrTranslateService.error(
            'CONFIRM_DIALOG.FAILED_CHANGE_STATUS'
          );
        }
      },
      error: (response) => {
        this.toastrTranslateService.error(
          response,
          'CONFIRM_DIALOG.FAILED_CHANGE_STATUS'
        );
      },
    });
  }
}
