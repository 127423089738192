import { Location } from './location/location';
import { UserRole } from './userRole';
export class UserModel {
  auth0Id: string;
  id: number;
  firstname: string;
  lastname: string;
  middlename: string;
  email: string;
  phoneNumber: string;
  userRoleId: string;
  userRole: UserRole;
  location: Location;
  isActive: boolean;
  accountActivated: boolean;
}
