<mat-card class="notescard note">
  <form #noteAmount="ngForm">
    <mat-card-tittle>
      {{ "PAYROLL.NOTE_AND_AMOUNT" | translate }}
    </mat-card-tittle>
    <mat-card-content>
      <mat-form-field class="full-width" appearance="fill">
        <textarea
          matInput
          required
          name="note"
          placeholder="Note.."
          [(ngModel)]="textNote"
          autocomplete="off"
        ></textarea>
      </mat-form-field>
      <mat-form-field class="small-width" appearance="fill" floatLabel="always">
        <mat-label>{{ "PAYROLL.AMOUNT" | translate }}</mat-label>
        <input
          matInput
          required
          name="amount"
          type="number"
          placeholder="0"
          onfocus="this.select()"
          [(ngModel)]="amount"
          class="amountInput"
          autocomplete="off"
        /><span matTextPrefix>$&nbsp;</span>
      </mat-form-field>
      <button
        mat-flat-button
        class="btnsubnote"
        (click)="addAmount(noteAmount.form.invalid)"
      >
        <span>{{ "PAYROLL.NEXT" | translate }}</span>
      </button>
    </mat-card-content>
  </form>
</mat-card>
<mat-card class="info-section">
  <p>
    {{ "PAYROLL.DRIVER_NAME" | translate }} {{ truck?.driver?.firstname }}
    {{ truck?.driver?.lastname }}
  </p>
  <p>{{ "PAYROLL.TRUCK_NUMBER" | translate }} {{ truck?.truckNumber }}</p>
  <p>
    {{ "PAYROLL.PAYROLL_TYPE" | translate }} :
    {{
      "PAYROLL_TYPE_NAME." + truck?.driver?.payrollSettings?.type?.name
        | translate
    }}
  </p>
  <p *ngIf="truck?.driver?.payrollSettings?.type?.name != 'Per_Mile'">
    {{ "DRIVERS.PERCENTAGE" | translate }}:
    {{ truck?.driver?.payrollSettings?.percentage }}%
  </p>
  <p *ngIf="truck?.driver?.payrollSettings?.type?.name == 'Per_Mile'">
    {{ "DRIVERS.PER_MILE_L" | translate }}:
    {{ truck?.driver?.payrollSettings?.perMileLoaded }}
  </p>

  <p *ngIf="truck?.driver?.payrollSettings?.type?.name == 'Per_Mile'">
    {{ "DRIVERS.PER_MILE_D" | translate }}:
    {{ truck?.driver?.payrollSettings?.perMileDeadhead }}
  </p>

  <p *ngIf="truck?.driver?.payrollSettings?.type?.name == 'Per_Mile'">
    {{ "DRIVERS.PER_STOP" | translate }}:
    {{ truck?.driver?.payrollSettings?.perStop }}
  </p>

  <p *ngIf="truck?.driver?.payrollSettings?.type?.name == 'Per_Mile'">
    {{ "DRIVERS.OTHER" | translate }}:
    {{ truck?.driver?.payrollSettings?.other }}
  </p>
  <p>{{ "PAYROLL.ESCROW_BALANCE" | translate }} {{ escrow | currency }}</p>
  <p>{{ "PAYROLL.LOAN_BALANCE" | translate }} {{ loan | currency }}</p>
</mat-card>

<div class="wrapperTable fornote">
  <button mat-icon-button class="refresh" (click)="refresh()">
    <mat-icon
      matTooltip="{{ 'MANAGE.REFRESH' | translate }}"
      matTooltipPosition="below"
      >refresh</mat-icon
    >
  </button>
  <div class="scrollDiv">
    <table
      mat-table
      matSort
      matSortDisableClear
      [dataSource]="dataSource"
      class="mat-elevation-z8"
      id="mat-table"
    >
      <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "PAYROLL.DATE" | translate }}
        </th>
        <td mat-cell *matCellDef="let note" class="cell-date">
          {{ note.date | tenantDate | date : "MM/dd/y " }}
        </td>
      </ng-container>
      <ng-container matColumnDef="amount">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="alignRight pr-60"
        >
          {{ "PAYROLL.AMOUNT" | translate }}
        </th>
        <td mat-cell *matCellDef="let note" class="alignRight">
          {{ note.amount | currency }}
        </td>
      </ng-container>
      <ng-container matColumnDef="note">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "PAYROLL.NOTE" | translate }}
        </th>
        <td
          mat-cell
          *matCellDef="let note"
          [ngClass]="{ strike: note.completed }"
        >
          {{ note.text }}
        </td>
      </ng-container>
      <ng-container matColumnDef="completed">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="alignCenter center"
        >
          {{ "PAYROLL.COMPLETED" | translate }}
        </th>
        <td mat-cell *matCellDef="let note" class="alignCenter w-170">
          <mat-checkbox
            color="primary"
            [checked]="note.completed"
            value="note.completed"
            name="completedNote"
            (click)="changeValue(note)"
            [(ngModel)]="note.completed"
          >
          </mat-checkbox>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: displayedColumns"
        [ngClass]="{ strike: row.completed }"
      ></tr>
    </table>
  </div>
</div>
<div class="footer">
  <div class="footer-button-div">
  <h3>{{ "PAYROLL.TOTALPAY" | translate }} {{ total | currency }}</h3>
  <button
    mat-button
    class="footer-btn"
    routerLink="{{
      '/payrolls/truck/' + truckId + '/driver/' + driverId + '/payroll-preview'
    }}"
  >
    {{ "PAYROLL.NEXT" | translate }}
  </button>
  </div>
</div>
