import { Pipe, PipeTransform } from '@angular/core';
import * as luxon from 'luxon';
import { TenantService } from 'src/app/_services';

@Pipe({
  name: 'colorSpanTodayDays',
})
export class ColorSpanTodayPipe implements PipeTransform {
  constructor(private tenantService: TenantService) {}

  transform(value: any, args?: any): any {
    const timeZone = this.tenantService.getSetTimeZone();
    const start = luxon.DateTime.fromISO(value).setZone(timeZone.name);
    const end = luxon.DateTime.now().setZone(timeZone.name).startOf('day');
    const dateDiff = start.diff(end, 'days').toObject().days;
    if (dateDiff > 30) {
      return 'whiteDays';
    } else if (dateDiff <= 30 && dateDiff > 7) {
      return 'orangeDays';
    }
    return 'redDays';
  }
}
