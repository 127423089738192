import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { GenericResponse } from '../_models/response';
import { UserRole } from '../_models/userRole';

@Injectable({ providedIn: 'root' })
export class UserRoleService {
  constructor(private http: HttpClient) {}

  getUserRoles(): Promise<UserRole[]> {
    return lastValueFrom(
      this.http
        .get<GenericResponse<UserRole[]>>(`${environment.apiUrl}/user-roles`)
        .pipe(map((response) => response.data as UserRole[]))
    );
  }
}
