import { DriverModel } from './driver/driver';
export class TruckModel {
  id: number;
  licensePlate: string;
  vinNumber: string;
  truckNumber: string;
  registrationExpirationDate: Date;
  annualInspectionExpirationDate: Date;
  quarterlyInspectionDue: Date;
  owner: string;
  driver: DriverModel;
  driverId: number;
  isDriverOwner: boolean;
  isActive: boolean;
}
