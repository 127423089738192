import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export default class Common {
  static getSafeString(item: string): string {
    return '' + (item || '');
  }

  static getSafeBooleanString(item: boolean): string {
    if (typeof item !== 'undefined') {
      return item.toString();
    }
    return '';
  }

  static isNotPartOfString(longString: string, shortString: string): boolean {
    return longString &&
      shortString &&
      longString.toLowerCase().indexOf(shortString.toLowerCase()) === -1
      ? true
      : false;
  }

  static getDateEndOfDay(date: Date): Date {
    return new Date(date.setHours(23, 59, 59, 999));
  }

  static fixMixCityLocation(response): any {
    response.loads.collection.forEach((load) => {
      if (
        load.destinationLocation &&
        Common.isNotPartOfString(
          load?.destinationLocation?.address,
          load?.destinationLocation?.city
        )
      ) {
        load.destinationLocation.city = load?.destinationLocation?.address;
        load.deliveryLocation.city = load?.deliveryLocation?.address;
      }
      if (
        load.originLocation &&
        Common.isNotPartOfString(
          load?.originLocation?.address,
          load?.originLocation?.city
        )
      ) {
        load.originLocation.city = load?.originLocation?.address;
        load.pickupLocation.city = load?.pickupLocation?.address;
      }
    });
    return response;
  }
}
