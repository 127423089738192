import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { first } from 'rxjs';
import { AccountingModel } from 'src/app/_models/accounting/accounting';
import { CodeModel } from 'src/app/_models/code';
import { Paginator } from 'src/app/_models/common/paginator';
import { DriverModel } from 'src/app/_models/driver/driver';
import { TruckModel } from 'src/app/_models/truck';
import { VendorModel } from 'src/app/_models/vendor';
import {
  DriverService,
  ToastrTranslateService,
  TruckService,
} from 'src/app/_services';
import { AccountingService } from 'src/app/_services/accounting.service';
import { VendorService } from 'src/app/_services/vendor.service';
import { CodeService } from '../../../_services/code.service';
import { ConfirmAddDialogComponent } from '../confirm-add-dialog/confirm-add-dialog.component';

@Component({
  selector: 'app-add-edit-dialog',
  templateUrl: './add-edit-dialog.component.html',
  styleUrls: ['./add-edit-dialog.component.scss'],
})
export class AddEditDialogComponent implements OnInit {
  accountingId: number;
  readOnly: boolean;
  dataLoading = true;
  typeList: CodeModel[] = [];
  typeListFiltered: CodeModel[] = [];
  categoryList: CodeModel[] = [];
  categoryListFiltered: CodeModel[] = [];
  statusList: CodeModel[] = [];
  statusListFiltered: CodeModel[] = [];
  vendorList: CodeModel[] = [];
  vendorListFiltered: CodeModel[] = [];
  accounting = new AccountingModel();
  driverList: DriverModel[] = [];
  truckList: TruckModel[] = [];

  constructor(
    private accountingService: AccountingService,
    private codeService: CodeService,
    private toastrTranslateService: ToastrTranslateService,
    private driversService: DriverService,
    private truckService: TruckService,
    private vendorService: VendorService,
    public dialog: MatDialog,
    private cd: ChangeDetectorRef,
    private dialogRef: MatDialogRef<AddEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {
    this.codeService
      .getAccountingTypes()
      .then((data) => {
        this.typeList = data;
        this.typeListFiltered = data;
      })
      .catch((err) =>
        this.toastrTranslateService.error(err, 'ACCOUNTING.ERROR')
      );
    this.codeService
      .getAccountingCategories()
      .then((data) => {
        this.categoryList = data;
        this.categoryListFiltered = data;
      })
      .catch((err) =>
        this.toastrTranslateService.error(err, 'ACCOUNTING.ERROR')
      );
    this.codeService
      .getAccountingStatuses()
      .then((data) => {
        this.statusList = data;
        this.statusListFiltered = data;
      })
      .catch((err) =>
        this.toastrTranslateService.error(err, 'ACCOUNTING.ERROR')
      );
    this.driversService
      .getAllDrivers(this.getFakePaginator(), true)
      .pipe(first())
      .subscribe((response) => {
        this.driverList = response.collection;
      });

    this.truckService
      .getTrucks(true)
      .pipe(first())
      .subscribe((response) => {
        this.truckList = response;
      });
  }
  ngOnInit(): void {
    this.accountingId = this.data.id;
    this.readOnly = this.data.readOnly;

    if (this.accountingId) {
      this.accountingService.getAccounting(this.accountingId).subscribe({
        next: (response) => {
          this.accounting = response;
          if (!this.accounting.vendor) {
            this.accounting.vendor = new VendorModel();
          }
          this.dataLoading = false;
        },
        error: (response) => {
          this.toastrTranslateService.error(response, 'ACCOUNTING.ERROR');
        },
      });
    } else {
      this.dataLoading = false;
    }
  }
  updateModel(event) {
    this.accounting.amount = null;
    this.cd.detectChanges();
    this.accounting.amount = event;
  }
  compareObjects(o1: any, o2: any): boolean {
    if (o1 && o2 && Object.keys(o1).length > 0 && Object.keys(o2).length > 0) {
      return o1.id === o2.id;
    }
    return false;
  }

  getFakePaginator(): Paginator {
    const dropDownFakePaginator = new Paginator();
    dropDownFakePaginator.pageSize = 1000;
    dropDownFakePaginator.sortOrder = 'asc';
    return dropDownFakePaginator;
  }

  statusKeyFilter(value: string) {
    if (!value || value?.trim() == '') {
      this.statusListFiltered = [...this.statusList];
    }
    this.statusListFiltered = this.statusList.filter((s) =>
      s.name.toLowerCase().startsWith(value.toLowerCase())
    );
  }

  typeKeyFilter(value: string) {
    if (!value || value?.trim() == '') {
      this.typeListFiltered = [...this.typeList];
    }
    this.typeListFiltered = this.typeList.filter((s) =>
      s.name.toLowerCase().startsWith(value.toLowerCase())
    );
  }

  categoryKeyFilter(value: string) {
    if (!value || value?.trim() == '') {
      this.categoryListFiltered = [...this.categoryList];
    }
    this.categoryListFiltered = this.categoryList.filter((s) =>
      s.name.toLowerCase().startsWith(value.toLowerCase())
    );
  }

  onStatusBlur() {
    setTimeout(() => {
      let status = this.statusList.find(
        (s) => s.name == this.accounting?.accountingStatus?.name
      );
      if (!status && this.accounting?.accountingStatus?.name) {
        // new status (popup and create  first)
        const dialogRef = this.dialog.open(ConfirmAddDialogComponent, {
          disableClose: true,
          position: { top: '20vh' },
          data: {
            key: 'status',
            value: this.accounting?.accountingStatus?.name,
          },
        });
        dialogRef.afterClosed().subscribe((response) => {
          if (response) {
            let newStatus: CodeModel = {
              id: 0,
              name: this.accounting?.accountingStatus?.name,
            };
            this.codeService.addAccountingStatus(newStatus).subscribe((res) => {
              this.statusList.push(res);
              this.accounting.accountingStatus.name = res.name;
              this.accounting.accountingStatus.id = res.id;
            });
          } else {
            this.accounting.accountingStatus.name = null;
            this.statusListFiltered = this.statusList;
          }
        });
      } else if (this.accounting?.accountingStatus?.name) {
        this.accounting.accountingStatus.name = status.name;
        this.accounting.accountingStatus.id = status.id;
      }
    }, 300);
  }

  onTypeBlur() {
    setTimeout(() => {
      let type = this.typeList.find(
        (s) => s.name == this.accounting?.accountingType?.name
      );
      if (!type && this.accounting?.accountingType?.name) {
        // new type (popup and create  first)
        const dialogRef = this.dialog.open(ConfirmAddDialogComponent, {
          disableClose: true,
          position: { top: '20vh' },
          data: {
            key: 'type',
            value: this.accounting?.accountingType?.name,
          },
        });
        dialogRef.afterClosed().subscribe((response) => {
          if (response) {
            let newType: CodeModel = {
              id: 0,
              name: this.accounting?.accountingType?.name,
            };
            this.codeService.addAccountingType(newType).subscribe((res) => {
              this.typeList.push(res);
              this.accounting.accountingType.name = res.name;
              this.accounting.accountingType.id = res.id;
            });
          } else {
            this.accounting.accountingType.name = null;
            this.typeListFiltered = this.typeList;
          }
        });
      } else if (this.accounting?.accountingType?.name) {
        this.accounting.accountingType.name = type.name;
        this.accounting.accountingType.id = type.id;
      }
    }, 300);
  }

  onCategoryBlur() {
    setTimeout(() => {
      let category = this.categoryList.find(
        (s) => s.name == this.accounting?.accountingCategory?.name
      );
      if (!category && this.accounting?.accountingCategory?.name) {
        // new category (popup and create  first)
        const dialogRef = this.dialog.open(ConfirmAddDialogComponent, {
          disableClose: true,
          position: { top: '20vh' },
          data: {
            key: 'category',
            value: this.accounting?.accountingCategory?.name,
          },
        });
        dialogRef.afterClosed().subscribe((response) => {
          if (response) {
            let newCategory: CodeModel = {
              id: 0,
              name: this.accounting?.accountingCategory?.name,
            };
            this.codeService
              .addAccountingCategory(newCategory)
              .subscribe((res) => {
                this.categoryList.push(res);
                this.accounting.accountingCategory.name = res.name;
                this.accounting.accountingCategory.id = res.id;
              });
          } else {
            this.accounting.accountingCategory.name = null;
            this.categoryListFiltered = this.categoryList;
          }
        });
      } else if (this.accounting?.accountingCategory?.name) {
        this.accounting.accountingCategory.name = category.name;
        this.accounting.accountingCategory.id = category.id;
      }
    }, 300);
  }

  onVendorBlur() {
    setTimeout(() => {
      let vendor = this.vendorList.find(
        (s) => s.name == this.accounting?.vendor?.name
      );
      if (!vendor && this.accounting?.vendor?.name) {
        // new vendor (popup and create  first)
        const dialogRef = this.dialog.open(ConfirmAddDialogComponent, {
          disableClose: true,
          position: { top: '20vh' },
          data: {
            key: 'vendor',
            value: this.accounting?.vendor?.name,
          },
        });
        dialogRef.afterClosed().subscribe((response) => {
          if (response) {
            let newVendor: CodeModel = {
              id: 0,
              name: this.accounting?.vendor?.name,
            };
            this.vendorService.addVendor(newVendor).subscribe((res) => {
              this.vendorList.push(res);
              this.accounting.vendor.name = res.name;
              this.accounting.vendor.id = res.id;
            });
          } else {
            this.accounting.vendor.name = null;
          }
        });
      } else if (this.accounting?.vendor?.name) {
        this.accounting.vendor.name = vendor.name;
        this.accounting.vendor.id = vendor.id;
      }
    }, 300);
  }

  searchVendor() {
    setTimeout(() => {
      this.vendorService
        .getVendorList(this.accounting?.vendor?.name)
        .subscribe({
          next: (response) => {
            this.vendorList = response;
          },
          error: (response) => {
            this.toastrTranslateService.error(response, 'ACCOUNTING.ERROR');
          },
        });
    }, 300);
  }

  cancel() {
    this.accounting.accountingCategory.name = null;
    this.accounting.accountingStatus.name = null;
    this.accounting.accountingType.name = null;
    this.accounting.vendor.name = null;
  }

  save(isInvalid, accounting) {
    if (isInvalid) {
      this.toastrTranslateService.warning('GENERAL.FORM_INVALID');
      return;
    }
    if (this.accountingId) {
      this.accountingService.updateAccounting(accounting).subscribe({
        next: () => {
          this.toastrTranslateService.success('ACCOUNTING.SAVED');
          this.dialogRef.close();
        },
        error: (response) => {
          this.toastrTranslateService.error(response, 'ACCOUNTING.NOT_SAVED');
        },
      });
    } else {
      this.accountingService.addAccounting(accounting).subscribe({
        next: () => {
          this.toastrTranslateService.success('ACCOUNTING.SAVED');
          this.dialogRef.close();
        },
        error: (response) => {
          this.toastrTranslateService.error(response, 'ACCOUNTING.NOT_SAVED');
        },
      });
    }
  }
}
