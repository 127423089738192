import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, lastValueFrom } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Loan } from '../_models/loan/loan';
import { LoanCreateRequest } from '../_models/loan/loanCreateRequest';
import { LoanUpdateRequest } from '../_models/loan/loanUpdateRequest';
import { GenericResponse, Response } from '../_models/response';
import { ResponseDataGeneric } from '../_models/responseData';
import { LoanFactory } from './factory/loan.factory.service';

@Injectable({ providedIn: 'root' })
export class LoanService {
  constructor(private http: HttpClient, private loanFactory: LoanFactory) {}

  getLoans(driverId): Observable<Loan[]> {
    return this.http
      .get<GenericResponse<ResponseDataGeneric<Loan>>>(
        `${environment.apiUrl}/loans/driver/${driverId}`
      )
      .pipe(map((response) => response.data.collection as Loan[]));
  }
  updateLoan(loan: LoanUpdateRequest) {
    return this.http
      .put<Response>(`${environment.apiUrl}/loans`, loan)
      .pipe(map((response) => response.data as Loan))
      .pipe(
        tap((responseData) => {
          this.loanFactory.getLoanTimeZoneDate(responseData);
        })
      );
  }

  addLoan(loan: LoanCreateRequest) {
    return this.http
      .post<Response>(`${environment.apiUrl}/loans`, loan)
      .pipe(map((response) => response.data as Loan))
      .pipe(
        tap((responseData) => {
          this.loanFactory.getLoanTimeZoneDate(responseData);
        })
      );
  }

  deleteLoan(id: number) {
    return this.http.delete<Response>(`${environment.apiUrl}/loans/${id}`);
  }

  async getPdfForDriverId(id: number) {
    let headers = new HttpHeaders();
    headers = headers.append('Accept', 'application/pdf');
    let url = `${environment.apiUrl}/loans/driver/${id}/pdf`;
    const result = await lastValueFrom(
      this.http.post(url, {}, { headers: headers, responseType: 'blob' })
    );
    return result;
  }
}
