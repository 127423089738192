import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { RouteRequest } from '../_models/location/routeRequest';
import { Response } from '../_models/response';
import { ResponseData } from '../_models/responseData';

@Injectable({ providedIn: 'root' })
export class MapService {
  constructor(private http: HttpClient) {}

  searchMap(searchTerm: string): Observable<any> {
    let params = new HttpParams().set('searchTerm', searchTerm);
    return this.http
      .get<Response>(`${environment.apiUrl}/map/search`, { params: params })
      .pipe(map((response) => response.data as ResponseData));
  }

  calculateRoutes(routeRequest: RouteRequest): Observable<any> {
    return this.http
      .post<Response>(`${environment.apiUrl}/map/routes`, routeRequest)
      .pipe(map((response) => response.data as ResponseData));
  }
}
