import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  Output,
  VERSION,
  ViewChild,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import {
  debounceTime,
  distinctUntilChanged,
  first,
  startWith,
  switchMap,
  tap,
} from 'rxjs/operators';

import { Location } from 'src/app/_models/location/location';

import { CodeModel } from 'src/app/_models/code';
import { CustomerModel } from 'src/app/_models/customer';
import { DispatcherModel } from 'src/app/_models/dispatcher';
import { DriverModel } from 'src/app/_models/driver/driver';
import { TrailerModel } from 'src/app/_models/trailer';
import { TruckModel } from 'src/app/_models/truck';
import { Paginator } from '../../_models/common/paginator';
import { LoadModel } from '../../_models/load/load';

import { MatTableDataSource } from '@angular/material/table';
import { CommentModel } from 'src/app/_models/comments';

import { LoadCommentDialogComponent } from './load-comment-dialog.component';

import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Subject } from 'rxjs/internal/Subject';
import { GeoLocation } from 'src/app/_models/location/geoLocation';
import { RouteRequest } from 'src/app/_models/location/routeRequest';
import { Stop } from 'src/app/_models/location/stop';
import {
  CustomerService,
  DispatcherService,
  DriverService,
  LoadService,
  MapService,
  ToastrTranslateService,
  TrailerService,
  TruckService,
} from 'src/app/_services';
import { CodeFactory } from 'src/app/_services/factory/code.factory.service';
import { LoadDocument } from '../../_models/load/loadDocument';
import { ConfirmDeleteLoadDocumentComponent } from './confirm-delete-load-document/confirm-delete-load-document.component';
import { EditDeadheadDialogComponent } from './edit-deadhead-dialog/edit-deadhead-dialog.component';
import { EditMilageDialogComponent } from './edit-milage-dialog/edit-milage-dialog.component';

@Component({
  selector: 'app-load',
  templateUrl: './load.component.html',
  styleUrls: ['./load.component.scss'],
})
export class LoadComponent implements OnInit, AfterViewChecked {
  @Output() commentAdded = new EventEmitter<CommentModel>();
  comments: CommentModel[];
  version = VERSION;
  public updatedFiles: File[] = [];
  public isExist = false;
  loadData: LoadModel = new LoadModel();
  searchStopsAddress = new UntypedFormControl();
  searchDeliveryAddress = new UntypedFormControl();
  searchPickupAddress = new UntypedFormControl();
  searchCustomers = new UntypedFormControl();
  searchResult: Location[];
  searchResultCustomers: CustomerModel[];
  loadNumber: number;
  loadId: number;
  activeButton = '';
  previousPage = '';
  searchTerm = '';
  hasHoldFilter = false;
  driverList: DriverModel[] = [];
  dispatcherList: DispatcherModel[] = [];
  truckList: TruckModel[] = [];
  truckLastDestination: Location;
  undedicatedTruckList: TruckModel[] = [];
  driverTruckList: TruckModel[] = [];
  trailerList: TrailerModel[] = [];
  equipmentTypeList: CodeModel[] = [];
  haulTypeList: CodeModel[] = [];
  statusTypeList: CodeModel[] = [];
  fileNameDialogRef: MatDialogRef<LoadCommentDialogComponent>;
  selectedDocumentId: Subject<number> = new Subject();
  selectedDocumentBytes: Subject<string> = new Subject();
  tonuSelected = false;
  isLoading = false;
  pickupTime;
  deliveryTime;
  showStops = true;
  uploading = false;
  previousStatus = '';
  @ViewChild('inputPickupAddress') inputPickupAddress: ElementRef;
  @ViewChild('inputDeliveryAddress') inputDeliveryAddress: ElementRef;

  showCustomerDiv = false;
  public form: UntypedFormGroup;

  constructor(
    private loadService: LoadService,
    private driversService: DriverService,
    private customerService: CustomerService,
    private dispatcherService: DispatcherService,
    private truckService: TruckService,
    private trailerService: TrailerService,
    private mapService: MapService,
    private codeFactory: CodeFactory,
    private toastrTranslateService: ToastrTranslateService,
    private builder: UntypedFormBuilder,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router,
    private cdref: ChangeDetectorRef
  ) {
    this.form = this.builder.group({
      files: ['', Validators.required],
    });
    this.codeFactory.getStatusList().then((data) => {
      this.statusTypeList = data;
    });
    this.codeFactory.getEquipmentTypeList().then((data) => {
      this.equipmentTypeList = data;
    });
    this.codeFactory.getHaulTypeList().then((data) => {
      this.haulTypeList = data;
    });
  }

  dataSource = new MatTableDataSource<CustomerModel>();

  ngAfterViewChecked() {
    if (!this.loadData.status) {
      this.loadData.status = this.statusTypeList.find(
        (x) => x.name.toUpperCase() == 'OPEN'
      );
      this.cdref.detectChanges();
    }
    if (!this.loadData.haulType) {
      this.loadData.haulType = this.haulTypeList.find(
        (x) => x.name.toUpperCase() == 'LINE_HAUL'
      );
      this.cdref.detectChanges();
    }
  }

  ngOnInit() {
    this.loadId = this.loadData.id;
    this.loadData = new LoadModel();
    this.loadData.driver = new DriverModel();
    this.loadData.customer = new CustomerModel();

    let dropDownPaginator = new Paginator();
    dropDownPaginator.pageSize = 1000;
    dropDownPaginator.sortOrder = 'asc';

    this.route.params.subscribe((params) => {
      this.loadId = params['loadId'];
      this.activeButton = params['activeButton'];
      this.hasHoldFilter = params['hasHold'];
      this.previousPage = params['previousPage'];
      if (params['searchTerm']) {
        this.searchTerm = params['searchTerm'];
      }
      if (this.loadId && this.loadId != 0) {
        this.loadService.getLoadById(this.loadId).subscribe((response) => {
          this.loadData = response;
          this.previousStatus = this.loadData?.status?.name;
          this.getTrucks();
          this.setTimes();
          this.getLastTruckDestination(response.truck.id, false);
          this.searchPickupAddress.setValue(this.loadData.pickupLocation);
          this.searchDeliveryAddress.setValue(this.loadData.deliveryLocation);
          this.searchCustomers.setValue(this.loadData.customer);
          this.reorderStopSequence();
          this.reorderCommentSequence();
          //Show document preview for first in the list
          if (this.loadData.documents && this.loadData.documents.length > 0) {
            this.previewDocument(this.loadData.documents[0].id, null);
          }
          this.calculateTotalLumperCost();
          //Chak TONU
          if (this.loadData?.haulType?.name.toUpperCase() == 'TONU') {
            this.tonuSelected = true;
          }
        });
      } else {
        this.getTrucks();
      }
      this.updateExternalId();
    });

    this.searchCustomers.valueChanges
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        startWith(''),
        tap(() => {
          this.searchResultCustomers = [];
        }),
        switchMap((value) => {
          let paginator = new Paginator();
          paginator.searchTerm = value;
          if (value != '') {
            return this.customerService.getCustomers(paginator, true);
          } else {
            this.loadData.customer = null;
            return (this.searchResultCustomers = []);
          }
        })
      )
      .subscribe((data) => {
        if (data && data.count != 0) {
          this.searchResultCustomers = data.collection;
        } else {
          this.searchResultCustomers = [];
        }
      });

    this.searchDeliveryAddress.valueChanges
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        startWith(''),
        tap(() => {
          this.searchResult = [];
        }),
        switchMap((value) => {
          if (value != '') {
            return this.mapService.searchMap(value);
          } else {
            this.loadData.deliveryLocation = null;
            return (this.searchResult = []);
          }
        })
      )
      .subscribe((data) => {
        if (data && data.count != 0) {
          this.searchResult = data.collection;
        } else {
          this.searchResult = [];
        }
      });

    this.searchStopsAddress.valueChanges
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        startWith(''),
        tap(() => {
          this.searchResult = [];
        }),
        switchMap((value) => {
          if (value != '') {
            return this.mapService.searchMap(value);
          } else {
            return (this.searchResult = []);
          }
        })
      )
      .subscribe((data) => {
        if (data && data.count != 0) {
          this.searchResult = data.collection;
        } else {
          this.searchResult = [];
        }
      });

    this.searchPickupAddress.valueChanges
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        startWith(''),
        tap(() => {
          this.searchResult = [];
        }),
        switchMap((value) => {
          if (value != '') {
            return this.mapService.searchMap(value);
          } else {
            this.loadData.pickupLocation = null;
            return (this.searchResult = []);
          }
        })
      )
      .subscribe((data) => {
        if (data && data.count != 0) {
          this.searchResult = data.collection;
        } else {
          this.searchResult = [];
        }
      });

    this.loadData = new LoadModel();

    dropDownPaginator.sortBy = 'firstname';

    this.driversService
      .getAllDrivers(dropDownPaginator, true)
      .pipe(first())
      .subscribe((response) => {
        this.driverList = response.collection;
      });

    this.dispatcherService
      .getAllDispatchers(dropDownPaginator, true)
      .pipe(first())
      .subscribe((response) => {
        this.dispatcherList = response.collection;
      });

    this.trailerService
      .getTrailers(true)
      .pipe(first())
      .subscribe((response) => {
        this.trailerList = response;
      });
  }

  getLastTruckDestination(truckId: number, calculateDeadhead: boolean) {
    this.truckService.getLastDestination(truckId).subscribe({
      next: (response) => {
        this.truckLastDestination = response;
        if (calculateDeadhead) {
          this.calculateDeadhead();
        }
      },
      error: (response) => {
        this.toastrTranslateService.error(response, 'TRUCKS.FAILED');
      },
    });
  }

  onSelectionHaulTypeChanged({ value }) {
    if (value.name != 'TONU') {
      this.tonuSelected = false;
      this.loadData.toNu = 0;
      this.calculateTotalInvoiced();
    }
  }

  onSelectionTrailerChanged({ value }) {
    if (value.equipmentType) {
      this.loadData.equipmentType = value.equipmentType;
      this.loadData.equipmentTypeId = value.equipmentType.id;
    }
  }

  toNuChange() {
    if (this.loadData.toNu > 0) {
      this.loadData.rate = 0;
      this.loadData.ratePerMile = 0;
      this.loadData.lumperCost = 0;
      this.loadData.detentionCost = 0;
      this.tonuSelected = true;
      this.loadData.status = this.statusTypeList.find(
        (x) => x.name.toUpperCase() == 'COMPLETED'
      );
      this.loadData.stops.forEach((stop) => {
        stop.lumperFee = 0;
      });
      this.calculateTotalInvoiced();
    }
  }

  calculateTotalInvoiced() {
    this.calculateTotalLumperCost();
    this.loadData.totalInvoiced =
      (this.loadData.rate || 0) +
      (this.loadData.totalLumperCost || 0) +
      (this.loadData.detentionCost || 0) +
      (this.loadData.otherCost || 0) +
      (this.loadData.toNu || 0) -
      (this.loadData.advanedPayment || 0);
  }

  calculateTotalLumperCost() {
    this.loadData.totalLumperCost = 0;
    this.loadData.stops.forEach((stop) => {
      this.loadData.totalLumperCost =
        this.loadData.totalLumperCost + stop.lumperFee;
    });
    this.loadData.totalLumperCost =
      this.loadData.totalLumperCost + this.loadData.lumperCost;
  }

  calculateRatePerMile() {
    this.loadData.ratePerMile =
      this.loadData.rate / (this.loadData.deadhead + this.loadData.milage);
  }

  rateChangeCalculation() {
    this.calculateTotalInvoiced();
    this.calculateRatePerMile();
  }

  calculateDeadhead() {
    if (this.truckLastDestination && this.loadData.pickupLocation) {
      let routeRequest = new RouteRequest();
      routeRequest.originPoint.latitude = this.truckLastDestination.lat;
      routeRequest.originPoint.longitude = this.truckLastDestination.lon;
      routeRequest.destinationPoint.latitude = this.loadData.pickupLocation.lat;
      routeRequest.destinationPoint.longitude =
        this.loadData.pickupLocation.lon;

      this.mapService
        .calculateRoutes(routeRequest)
        .pipe(first())
        .subscribe((response) => {
          if (response && response.collection.length > 0) {
            this.loadData.deadhead = response.collection[0].lengthInMiles;
            this.loadData.calculatedDeadhead =
              response.collection[0].lengthInMiles;
          }
        });
    } else {
      this.loadData.calculatedDeadhead = 0;
      this.loadData.deadhead = 0;
    }
  }

  calculateRoutes() {
    if (this.loadData.pickupLocation && this.loadData.deliveryLocation) {
      let routeRequest = new RouteRequest();
      routeRequest.originPoint.latitude = this.loadData.pickupLocation.lat;
      routeRequest.originPoint.longitude = this.loadData.pickupLocation.lon;
      routeRequest.destinationPoint.latitude =
        this.loadData.destinationLocation.lat;
      routeRequest.destinationPoint.longitude =
        this.loadData.destinationLocation.lon;
      if (this.loadData.stops && this.loadData.stops.length > 0) {
        routeRequest.supportingPoints = new Array<GeoLocation>();
        // this.loadData.stops.sort(
        //   (s1, s2) => s2.location.sequence - s1.location.sequence
        // );
        this.loadData.stops.forEach((stopItem) => {
          let stop = new GeoLocation();
          stop.latitude = stopItem.location.lat;
          stop.longitude = stopItem.location.lon;
          routeRequest.supportingPoints.push(stop);
        });
      } else {
        routeRequest.supportingPoints = undefined;
      }

      this.mapService
        .calculateRoutes(routeRequest)
        .pipe(first())
        .subscribe((response) => {
          if (response && response.collection.length > 0) {
            this.loadData.calculatedMilage =
              response.collection[0].lengthInMiles;
            this.loadData.milage = this.loadData.calculatedMilage;
            this.calculateRatePerMile();
          }
        });
    }
  }
  getTrucks() {
    this.truckService
      .getTrucks(true)
      .pipe(first())
      .subscribe((response) => {
        this.truckList = response;
        this.undedicatedTruckList = this.truckList.filter(
          (x) => x.driver == null
        );
        this.truckList = this.truckList.filter((x) => x.driver != null);
        if (this.loadData.driver) {
          this.filterTruck(this.loadData.driver.id);
        }
      });
  }

  openDialogEditMilage() {
    const dialogRef = this.dialog.open(EditMilageDialogComponent, {
      disableClose: true,
      position: { top: '20%', left: '20%' },
      data: this.loadData.milage,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result == null) result = 0;
      if (result) {
        this.loadData.milage = result;
        this.calculateRatePerMile();
      }
    });
  }

  setTimes() {
    const pickupDate = new Date(this.loadData.pickupDate);
    if (pickupDate?.getFullYear() == 1) {
      this.loadData.pickupDate = null;
      this.pickupTime = null;
    } else {
      this.pickupTime = `${
        pickupDate.getHours() < 10
          ? '0' + pickupDate.getHours()
          : pickupDate.getHours()
      }:${
        pickupDate.getMinutes() < 10
          ? '0' + pickupDate.getMinutes()
          : pickupDate.getMinutes()
      }`;
      if (this.pickupTime == '00:00') {
        this.pickupTime = null;
      }
    }

    const deliveryDate = new Date(this.loadData.deliveryDate);
    if (deliveryDate?.getFullYear() == 1) {
      this.loadData.deliveryDate = null;
      this.deliveryTime = null;
    } else {
      this.deliveryTime = `${
        deliveryDate.getHours() < 10
          ? '0' + deliveryDate.getHours()
          : deliveryDate.getHours()
      }:${
        deliveryDate.getMinutes() < 10
          ? '0' + deliveryDate.getMinutes()
          : deliveryDate.getMinutes()
      }`;

      if (this.deliveryTime == '00:00') {
        this.deliveryTime = null;
      }
    }
  }

  pickupDateChange(event): void {
    if (event && this.pickupTime) {
      this.loadData.pickupDate = new Date(
        new Date(this.loadData.pickupDate).toDateString() +
          ' ' +
          this.pickupTime
      );
    }
    this.pickupTime = `${('0' + this.loadData.pickupDate.getHours()).slice(
      -2
    )}:${('0' + this.loadData.pickupDate.getMinutes()).slice(-2)}`;
  }

  deliveryDateChange(event): void {
    if (event && this.deliveryTime) {
      this.loadData.deliveryDate = new Date(
        new Date(this.loadData.deliveryDate).toDateString() +
          ' ' +
          this.deliveryTime
      );
    }
    this.deliveryTime = `${('0' + this.loadData.deliveryDate.getHours()).slice(
      -2
    )}:${('0' + this.loadData.deliveryDate.getMinutes()).slice(-2)}`;
  }

  pickupTimeChange(event): void {
    let pickupTimeString = '00:00';
    event.target.value
      ? (pickupTimeString = this.pickupTime)
      : (this.pickupTime = null);
    this.loadData.pickupDate = new Date(
      new Date(this.loadData.pickupDate).toDateString() + ' ' + pickupTimeString
    );
  }

  deliveryTimeChange(event): void {
    let deliveryTimeString = '00:00';
    event.target.value
      ? (deliveryTimeString = this.deliveryTime)
      : (this.deliveryTime = null);
    this.loadData.deliveryDate = new Date(
      new Date(this.loadData.deliveryDate).toDateString() +
        ' ' +
        deliveryTimeString
    );
  }

  deleteStop(x: number) {
    this.loadData.stops.splice(x, 1);
    for (let i = 0; i < this.loadData.stops.length; i++) {
      this.loadData.stops[i].location.sequence = i + 1;
    }
    this.calculateRoutes();
    this.calculateTotalLumperCost();
    //ReloadStops
    this.showStops = false;
    setTimeout(() => (this.showStops = true));
  }

  dropStop(event: CdkDragDrop<string[]>) {
    moveItemInArray(
      this.loadData.stops,
      event.previousIndex,
      event.currentIndex
    );
    for (let i = 0; i < this.loadData.stops.length; i++) {
      this.loadData.stops[i].location.sequence = i + 1;
    }
    this.calculateRoutes();
  }

  revertMilage() {
    this.loadData.milage = this.loadData.calculatedMilage;
    this.calculateRatePerMile();
  }

  revertDeadhead() {
    this.loadData.deadhead = this.loadData.calculatedDeadhead;
    this.calculateRatePerMile();
  }

  truckSelectionChange(truckId: number) {
    this.getLastTruckDestination(truckId, true);
  }

  openDialogEditDeadhead() {
    const dialogRef = this.dialog.open(EditDeadheadDialogComponent, {
      disableClose: true,
      position: { top: '20%', left: '20%' },
      data: this.loadData.deadhead,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result == null) result = 0;
      if (result >= 0) {
        this.loadData.deadhead = result;
        this.calculateRatePerMile();
      }
    });
  }

  filterTruck(driverId: number) {
    this.driverTruckList = this.truckList.filter(
      (x) => x.driver.id == driverId
    );
    Array.prototype.push.apply(this.driverTruckList, this.undedicatedTruckList);
  }

  deleteComment(x: number) {
    this.loadData.comments.splice(x, 1);
    this.reorderCommentSequence();
  }

  private reorderCommentSequence() {
    this.loadData.comments.forEach((comment, index) => {
      comment.sequence = index;
    });
  }

  private reorderStopSequence() {
    let stopsInOrder = new Array<Stop>();
    stopsInOrder = this.loadData.stops.sort(
      (n1, n2) => n1.location.sequence - n2.location.sequence
    );
    this.loadData.stops = stopsInOrder;
  }

  openAddCommentDialog(loadId: number, comment?: CommentModel) {
    this.fileNameDialogRef = this.dialog.open(LoadCommentDialogComponent, {
      width: '450px',
      data: {
        loadId: loadId,
        comment: comment,
      },
    });

    this.fileNameDialogRef.afterClosed().subscribe((commentItem) => {
      if (commentItem) {
        if (commentItem.sequence == undefined) {
          commentItem.sequence = this.loadData.comments.length;
          this.loadData.comments.push(commentItem);
        }
        this.loadData.comments[commentItem.sequence] = commentItem;
      }
    });
  }

  displayWith(value) {
    return value ? value.name : '' + value.content;
  }

  save(isInvalid) {
    if (
      isInvalid ||
      !this.loadData.deliveryLocation ||
      !this.loadData.pickupLocation ||
      !this.loadData.customer
    ) {
      this.toastrTranslateService.warning('GENERAL.FORM_INVALID');
      return;
    }
    if (
      this.loadData.haulType.name.toUpperCase() == 'TONU' &&
      this.loadData.toNu == 0
    ) {
      this.toastrTranslateService.warning('NEW_LOAD.WRONG_TONU_VALUE_ERROR');
      return;
    }
    this.updateExternalId();
    this.loadData = this.tempUpdatePayroll(this.loadData);
    if (
      (this.previousStatus != 'PAID' && this.loadData.status.name == 'PAID') ||
      (this.previousStatus != 'DELETED' &&
        this.loadData.status.name == 'DELETED') ||
      (this.loadData.status.name != 'PAID' &&
        this.loadData.status.name != 'DELETED')
    ) {
      this.isLoading = true;
      if (this.loadId && this.loadId != 0) {
        this.loadService.updateLoad(this.loadData).subscribe({
          next: (response) => {
            this.isLoading = false;
            this.loadData = response;
            this.toastrTranslateService.success(
              'NEW_LOAD.SAVED',
              this.loadData.loadNumber
            );
            this.back();
          },
          error: (response) => {
            this.toastrTranslateService.error(response, 'NEW_LOAD.NOT_SAVED');
            this.isLoading = false;
          },
        });
      } else {
        this.loadService.addLoad(this.loadData).subscribe({
          next: (response) => {
            this.isLoading = false;
            this.loadData = response;
            this.toastrTranslateService.success(
              'NEW_LOAD.SAVED',
              this.loadData.loadNumber
            );
            this.back();
          },
          error: (response) => {
            this.isLoading = false;
            this.toastrTranslateService.error(response, 'NEW_LOAD.NOT_SAVED');
          },
        });
      }
    } else {
      this.toastrTranslateService.info('NEW_LOAD.WRONG_STATUS_ERROR');
    }
  }

  compareObjects(o1: any, o2: any): boolean {
    if (o1 && o2) {
      return o1.id === o2.id;
    }
    return false;
  }

  updateExternalId() {
    this.loadData.equipmentType
      ? (this.loadData.equipmentTypeId = this.loadData.equipmentType.id)
      : (this.loadData.equipmentTypeId = null);
    this.loadData.haulType
      ? (this.loadData.haulTypeId = this.loadData.haulType.id)
      : (this.loadData.haulTypeId = null);
    this.loadData.status
      ? (this.loadData.statusId = this.loadData.status.id)
      : (this.loadData.statusId = null);
    this.loadData.customer
      ? (this.loadData.customerId = this.loadData.customer.id)
      : (this.loadData.customerId = null);
    this.loadData.trailer
      ? (this.loadData.trailerId = this.loadData.trailer.id)
      : (this.loadData.trailerId = null);
    this.loadData.truck
      ? (this.loadData.truckId = this.loadData.truck.id)
      : (this.loadData.truckId = null);
    this.loadData.dispatcher
      ? (this.loadData.dispatcherId = this.loadData.dispatcher.id)
      : (this.loadData.dispatcherId = null);
    this.loadData.driver
      ? (this.loadData.driverId = this.loadData.driver.id)
      : (this.loadData.driverId = null);
  }

  tempUpdatePayroll(loadData: LoadModel): LoadModel {
    return loadData;
  }

  selectDeliveryLocation(location: Location) {
    this.loadData.destinationLocation = location;
    this.loadData.deliveryLocation = location;
    this.calculateRoutes();
  }

  selectPickupLocation(location: Location) {
    this.loadData.originLocation = location;
    this.loadData.pickupLocation = location;
    this.calculateRoutes();
    this.calculateDeadhead();
  }

  autofillPickupAddress() {
    setTimeout(() => {
      let inputValue =
        this.inputPickupAddress.nativeElement.value !=
        this.loadData.pickupLocation?.address +
          ', ' +
          this.loadData.pickupLocation?.countryCode;
      if (!this.loadData.pickupLocation || inputValue) {
        if (this.searchResult.length > 0) {
          this.loadData.pickupLocation = this.searchResult[0];
          this.searchPickupAddress.setValue(this.loadData.pickupLocation);
        } else if (inputValue) {
          this.loadData.pickupLocation = new Location();
          this.searchPickupAddress.setValue('');
          this.toastrTranslateService.warning('GENERAL.ADDRESS_ISSUE');
        }
      }
      this.loadData.originLocation = this.loadData.pickupLocation;
    }, 1000);
  }

  autofillDeliveryAddress() {
    setTimeout(() => {
      let inputValue =
        this.inputDeliveryAddress.nativeElement.value !=
        this.loadData.deliveryLocation.address +
          ', ' +
          this.loadData.deliveryLocation.countryCode;
      if (!this.loadData.deliveryLocation || inputValue) {
        if (this.searchResult.length > 0) {
          this.loadData.deliveryLocation = this.searchResult[0];
          this.searchDeliveryAddress.setValue(this.loadData.deliveryLocation);
        } else if (inputValue) {
          this.loadData.deliveryLocation = new Location();
          this.searchDeliveryAddress.setValue('');
          this.toastrTranslateService.warning('GENERAL.ADDRESS_ISSUE');
        }
      }
      this.loadData.destinationLocation = this.loadData.deliveryLocation;
    }, 1000);
  }

  selectStopAddress(location: Location) {
    if (!this.loadData.stops) {
      this.loadData.stops = [];
    }
    let newStop = new Stop();
    location.sequence = this.loadData.stops.length + 1;
    newStop.location = location;
    newStop.lumperFee = 0;

    this.loadData.stops.push(newStop);
    this.searchStopsAddress.reset('');
    this.reorderStopSequence();
    this.calculateRoutes();
  }

  displayLocation(location: Location): string {
    return location ? location.address + ', ' + location.countryCode : '';
  }

  selectCustomer(customer: CustomerModel) {
    this.loadData.customer = customer;
  }

  displayCustomer(customer: CustomerModel): string {
    return customer
      ? customer.name ||
          '' + ', ' + customer.mc ||
          '' + ', ' + customer.email ||
          '' + ', ' + customer.phoneNumber ||
          ''
      : '';
  }

  getStopsFromChild(stopList: Stop[]) {
    this.loadData.stops = stopList;
    this.calculateRoutes();
  }

  getCustomersFromChild(customer: CustomerModel) {
    this.showCustomerDiv = false;
    this.loadData.customerId = customer?.id;
    if (customer != null) {
      this.loadData.customer = customer;
      this.searchCustomers.setValue(this.loadData.customer);
    }
  }

  getDocumentsFromChild(documentList: LoadDocument[]) {
    if (!this.loadData.documentCreateRequests) {
      this.loadData.documentCreateRequests = new Array<LoadDocument>();
    }
    for (const document of documentList) {
      if (document.id == null) {
        const documentCreateRequests = new LoadDocument();

        documentCreateRequests.fileNameWithExtension =
          document.fileNameWithExtension;
        documentCreateRequests.idTemp = document.idTemp;

        documentCreateRequests.bytes = document.bytes;
        if (this.loadId != 0) {
          this.addDocument(documentCreateRequests);
        } else {
          const documentForList = new LoadDocument();
          documentForList.fileNameWithExtension =
            document.fileNameWithExtension;
          documentForList.idTemp = document.idTemp;
          this.loadData.documents.push(documentForList);
          this.loadData.documentCreateRequests.push(documentCreateRequests);
        }
      }
      document.bytes = null;
      document.idTemp = null;
    }
  }

  addDocument(document: LoadDocument) {
    this.uploading = true;
    this.loadService
      .addDocument(this.loadId, document.fileNameWithExtension, document.bytes)
      .subscribe({
        next: (response) => {
          if (response) {
            this.toastrTranslateService.success(
              document.fileNameWithExtension,
              'NEW_LOAD.DOCUMENTS_UPLOAD_SUCCESS'
            );
            this.loadData.documents.push(response);
            this.uploading = false;
          } else {
            this.toastrTranslateService.error(
              document.fileNameWithExtension,
              'NEW_LOAD.DOCUMENTS_UPLOAD_FAILED'
            );
            this.uploading = false;
          }
        },
        error: (response) => {
          this.toastrTranslateService.error(
            document.fileNameWithExtension + response,
            'NEW_LOAD.DOCUMENTS_UPLOAD_FAILED'
          );
          this.uploading = false;
        },
      });
  }

  back() {
    if (!this.router.url.toLowerCase().startsWith('/load-advanced')) {
      let backUrl =
        '/' +
        this.previousPage +
        '/' +
        this.activeButton +
        '/' +
        this.hasHoldFilter +
        '/' +
        this.searchTerm;
      this.router.navigateByUrl(backUrl);
    } else {
      this.router.navigateByUrl('/advanced-search');
    }
  }

  previewDocument(documentId: number, idTemp: number) {
    if (documentId) {
      this.selectedDocumentId.next(documentId);
    } else if (idTemp != null && idTemp != 0) {
      const selectedDocument = this.loadData.documentCreateRequests.find(
        (x) => x.idTemp == idTemp
      );
      this.selectedDocumentBytes.next(selectedDocument.bytes);
    } else {
      this.selectedDocumentId.next(null);
    }
  }

  openDialogConfirmDeleteLoadDocument(
    documentName: string,
    documentId: number,
    index: number,
    documentIdTemp: number
  ) {
    const dialogRef = this.dialog.open(ConfirmDeleteLoadDocumentComponent, {
      disableClose: true,
      position: { top: '20px' },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.deleteDocument(documentName, documentId, index, documentIdTemp);
      }
    });
  }

  deleteDocument(
    documentName: string,
    documentId: number,
    index: number,
    documentIdTemp: number
  ) {
    if (documentId) {
      this.loadService.deleteDocument(documentId).subscribe({
        next: (response) => {
          if (response) {
            this.clearDeletedDocument(
              documentName,
              index,
              documentIdTemp,
              documentId
            );
            this.toastrTranslateService.success('CONFIRM_DIALOG.SUCCESS_DELL');
          } else {
            this.toastrTranslateService.error('CONFIRM_DIALOG.FAILED_DELL');
          }
        },
        error: (response) => {
          this.toastrTranslateService.error(
            response,
            'CONFIRM_DIALOG.FAILED_DELL'
          );
        },
      });
    } else {
      this.clearDeletedDocument(documentName, index, documentIdTemp);
      this.toastrTranslateService.success('CONFIRM_DIALOG.SUCCESS_DELL');
    }
  }

  clearDeletedDocument(
    documentName: string,
    index: number,
    documentIdTemp: number,
    deletedDocumentId?: number
  ) {
    this.previewDocument(null, null);
    if (deletedDocumentId) {
      for (let i = 0; i < this.loadData.documents.length; i++) {
        const element = this.loadData.documents[i];
        if (
          element.id == deletedDocumentId &&
          element.fileNameWithExtension == documentName
        ) {
          this.loadData.documents.splice(i, 1);
        }
      }
    } else {
      this.loadData.documents.splice(index, 1);
      for (let i = 0; i < this.loadData.documentCreateRequests.length; i++) {
        const element = this.loadData.documentCreateRequests[i];
        if (element.idTemp == documentIdTemp) {
          this.loadData.documentCreateRequests.splice(i, 1);
        }
      }
    }
  }

  addNewCustomer() {
    this.showCustomerDiv = true;
  }

  toBool(boolVariable: any): boolean {
    if (boolVariable && boolVariable != 'undefined') {
      return Boolean(JSON.parse(boolVariable));
    }
    return false;
  }
  toggleHold(e) {
    e.checked
      ? (this.loadData.hasHold = true)
      : (this.loadData.hasHold = false);
  }
}
