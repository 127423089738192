<div cdkDropList #data>
  <div
    *ngFor="let comment of commentList; let i = index"
    cdkDrag
    class="commentlist"
  >
    <p>{{ comment.type }}</p>
    <b>{{ i + 1 }}.</b>&nbsp;&nbsp;{{ comment.text }}
  </div>
</div>
