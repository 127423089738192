<div class="modal-dialog formInModal">
  <div>
    <h2 mat-dialog-title class="text">
      {{ "CHANGE_PASS.CHANGE_PASS" | translate }}
    </h2>
  </div>
  <hr />
  <form #changePass="ngForm">
    <mat-dialog-content class="box">
      <mat-form-field
        floatLabel="auto"
        hideRequiredMarker
        appearance="outline"
        class="formWidth"
      >
        <mat-label class="global_search"
          >{{ "CHANGE_PASS.NEW_PASS" | translate
          }}<span class="star">*</span></mat-label
        >
        <div class="d-flex">
          <input
            matInput
            [type]="inputType"
            [(ngModel)]="newPassword"
            autocomplete="off"
            matTooltip="{{ 'CHANGE_PASS.COMPLEXITY' | translate }}"
            name="new_password"
            pattern="(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-_]).{8,}"
            required
          />
          <div (click)="showHidePassword()">
            <mat-icon class="icon-visibility">{{
              showPassword ? "visibility_off" : "visibility"
            }}</mat-icon>
          </div>
        </div>
      </mat-form-field>
      <mat-form-field
        floatLabel="auto"
        hideRequiredMarker
        appearance="outline"
        class="formWidth"
      >
        <mat-label class="global_search"
          >{{ "CHANGE_PASS.REPEAT_NEW_PASS" | translate
          }}<span class="star">*</span></mat-label
        >
        <div class="d-flex">
          <input
            matInput
            [type]="inputType"
            [(ngModel)]="repetedNewPassword"
            autocomplete="off"
            matTooltip="{{ 'CHANGE_PASS.COMPLEXITY' | translate }}"
            name="repeat_pass"
            pattern="(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-_]).{8,}"
            required
          />
          <div (click)="showHidePassword()">
            <mat-icon class="icon-visibility">{{
              showPassword ? "visibility_off" : "visibility"
            }}</mat-icon>
          </div>
        </div>
      </mat-form-field>
    </mat-dialog-content>
    <mat-dialog-actions>
      <button mat-button class="btnCan" mat-dialog-close>
        {{ "COMMON.CANCEL" | translate }}
      </button>
      <button
        (click)="save()"
        [disabled]="
          changePass.form.invalid ||
          newPassword !== repetedNewPassword ||
          newPassword == '' ||
          repetedNewPassword == ''
        "
        mat-flat-button
        class="btn btn-green"
      >
        {{ "COMMON.SAVE" | translate }}
      </button>
    </mat-dialog-actions>
  </form>
</div>
