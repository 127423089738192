import { Component, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort, MatSortable } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { first } from 'rxjs/operators';
import { SpanTodayPipe } from 'src/app/_helpers/pipes/span.today.date.pipe';
import { SortData } from 'src/app/_models/common/sortData';
import { TableType } from 'src/app/_models/enums/tableType';
import { TrailerModel } from 'src/app/_models/trailer';
import { TenantService, TrailerService } from 'src/app/_services';
import { LocalStorageSortService } from 'src/app/_services/local.storage.sort.service';
import { UserDataService } from 'src/app/_services/user.data.service';
import { ComfirmDialogTrailerComponent } from './edit-delete-trailer/comfirm-dialog-trailer/comfirm-dialog-trailer.component';
import { EditTrailerDialogComponent } from './edit-delete-trailer/edit-trailer-dialog/edit-trailer-dialog.component';

@Component({
  selector: 'app-trailers',
  templateUrl: './trailers.component.html',
  styleUrls: ['./trailers.component.scss'],
})
export class TrailersComponent {
  trailersData: Array<TrailerModel>;
  searchTerm = '';
  sortData: SortData;
  showInactive = false;
  count: number;

  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private trailerService: TrailerService,
    private tenantService: TenantService,
    private localStorageSortService: LocalStorageSortService,
    public userDataService: UserDataService,
    public dialog: MatDialog
  ) {}

  spanTodayDaysPipe = new SpanTodayPipe(this.tenantService);

  dataSource = new MatTableDataSource<TrailerModel>();

  ngOnInit() {
    this.getTrailers();
    this.dataSource.filterPredicate = (
      data: TrailerModel,
      filtersJson: string
    ) => {
      const matchFilter = [];
      const filters = JSON.parse(filtersJson);

      filters.forEach((filter) => {
        const val = data[filter.id] === null ? '' : data[filter.id];
        matchFilter.push(
          val.toLowerCase().includes(filter.value.toLowerCase())
        );
      });
      return matchFilter.every(Boolean);
    };
    this.sortData = this.localStorageSortService.getSortData(
      TableType.ManageTrailers
    );
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.sort.sort({
        id: this.sortData.activeColumn,
        start: this.sortData.order,
      } as MatSortable);
      this.dataSource.sort = this.sort;
    });
    this.dataSource.sortingDataAccessor = (item, property) => {
      this.localStorageSortService.setSortData(
        TableType.ManageTrailers,
        this.sort
      );
      switch (property) {
        case 'equipmentTypeId':
          return item.equipmentType.name;
        case 'due_date_registration':
          return this.spanTodayDaysPipe.transform(
            item.registrationExpirationDate
          );
        case 'due_date_annual':
          return this.spanTodayDaysPipe.transform(
            item.annualInspectionExpirationDate
          );
        case 'due_date_quarterly':
          return this.spanTodayDaysPipe.transform(item.quarterlyInspectionDue);
        default:
          return item[property];
      }
    };
  }

  applyFilter(filterValue: string) {
    this.searchTerm = filterValue;
    const tableFilters = [];
    tableFilters.push({
      id: 'trailerNumber',
      value: filterValue,
    });

    this.dataSource.filter = JSON.stringify(tableFilters);
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  toBool(boolVariable: any) {
    if (boolVariable && boolVariable != 'undefined') {
      return Boolean(JSON.parse(boolVariable));
    }
    return false;
  }

  openDialogComfirmDeleteTrailer(id: number) {
    const dialogRef = this.dialog.open(ComfirmDialogTrailerComponent, {
      disableClose: true,
      position: { top: '20px' },
      data: id,
    });

    dialogRef.afterClosed().subscribe(() => {
      this.getTrailers();
    });
  }

  openDialogEditTrailer(readOnly: boolean, id?: number) {
    const dialogRef = this.dialog.open(EditTrailerDialogComponent, {
      disableClose: true,
      position: { top: '20px' },
      data: {
        id,
        readOnly,
      },
    });

    dialogRef.afterClosed().subscribe(() => {
      this.getTrailers();
    });
  }

  getTrailers() {
    this.trailerService
      .getTrailers(!this.showInactive)
      .pipe(first())
      .subscribe((response) => {
        this.dataSource.data = response;
        this.trailersData = response;
        this.count = response.length;
      });
  }
  getCountOfElement(value) {
    return this.trailersData ? this.trailersData.length : 0;
  }
  refresh() {
    this.applyFilter('');
    this.getTrailers();
  }
  displayedColumns: string[] = [
    'trailerNumber',
    'owner',
    'licensePlate',
    'equipmentTypeId',
    'registrationExpirationDate',
    'due_date_registration',
    'annualInspectionExpirationDate',
    'due_date_annual',
    'quarterlyInspectionDue',
    'due_date_quarterly',
    'isActive',
    'action',
  ];
}
