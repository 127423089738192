import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { first } from 'rxjs/operators';
import { Paginator } from 'src/app/_models/common/paginator';
import { DispatcherModel } from 'src/app/_models/dispatcher';
import { DriverModel } from 'src/app/_models/driver/driver';
import { LoadModel } from 'src/app/_models/load/load';
import { TruckModel } from 'src/app/_models/truck';
import {
  DispatcherService,
  DriverService,
  ToastrTranslateService,
  TruckService
} from 'src/app/_services';
import { LoadQuickPage } from '../../_models/load/loadQuickPage';
import { LoadQuickRequest } from '../../_models/load/loadQuickRequest';
import { LoadService } from '../../_services/load.service';

@Component({
  selector: 'app-quick-load-dialog',
  templateUrl: './quick-load-dialog.component.html',
  styleUrls: ['./quick-load-dialog.component.scss'],
})
export class QuickLoadDialogComponent implements OnInit {
  truckList: TruckModel[] = [];
  loadData: LoadModel;
  undedicatedTruckList: TruckModel[] = [];
  loadQuickDataPage = new LoadQuickPage();
  driverTruckList: TruckModel[] = [];
  dropDownPaginator = new Paginator();
  driverList: DriverModel[] = [];
  dispatcherList: DispatcherModel[] = [];
  isTruckListEmpty = true;

  constructor(
    private driversService: DriverService,
    private dispatcherService: DispatcherService,
    private truckService: TruckService,
    private loadService: LoadService,
    private toastrTranslateService: ToastrTranslateService,
    private dialogRef: MatDialogRef<QuickLoadDialogComponent>
  ) {}

  ngOnInit(): void {
    this.initHelpers();
    this.getTrucks();
    this.getDispatchers();
    this.getDrivers();
  }

  initHelpers() {
    //Helper just ot get all data
    this.dropDownPaginator.pageSize = 1000;
    this.dropDownPaginator.sortOrder = 'asc';
  }

  getTrucks() {
    this.truckService
      .getTrucks(true)
      .pipe(first())
      .subscribe((response) => {
        this.truckList = response;
        this.undedicatedTruckList = this.truckList.filter(
          (x) => x.driver == null
        );
        this.truckList = this.truckList.filter((x) => x.driver != null);
        if (this.loadQuickDataPage.driver?.id) {
          this.filterTruck(this.loadQuickDataPage.driver.id);
        }
        if (this.driverTruckList.length > 0) {
          this.isTruckListEmpty = false;
        }
      });
  }

  filterTruck(driverId: number) {
    this.driverTruckList = this.truckList.filter(
      (x) => x.driver.id == driverId
    );
    Array.prototype.push.apply(this.driverTruckList, this.undedicatedTruckList);
  }

  getDrivers() {
    this.driversService
      .getAllDrivers(this.dropDownPaginator, true)
      .pipe(first())
      .subscribe((response) => {
        this.driverList = response.collection;
      });
  }

  getDispatchers() {
    this.dispatcherService
      .getAllDispatchers(this.dropDownPaginator, true)
      .pipe(first())
      .subscribe((response) => {
        this.dispatcherList = response.collection;
      });
  }

  getLoadQuickRequest(loadQucikPage: LoadQuickPage): LoadQuickRequest {
    let loadQuickRequest = new LoadQuickRequest();
    loadQuickRequest.truckId = loadQucikPage?.truck?.id;
    loadQuickRequest.driverId = loadQucikPage?.driver?.id;
    loadQuickRequest.dispatcherId = loadQucikPage?.dispatcher?.id;
    loadQuickRequest.rate = loadQucikPage?.rate;
    return loadQuickRequest;
  }

  save(loadQucikPage: LoadQuickPage) {
    let loadQuick = this.getLoadQuickRequest(loadQucikPage);
    this.loadService.addLoadQuick(loadQuick).subscribe({
      next: (response) => {
        if (response) {
          this.loadData = response;
          this.toastrTranslateService.success(
            'NEW_LOAD.SAVED',
             this.loadData.loadNumber
          );
          this.loadService.emitLoadAdded();
          this.dialogRef.close();
        }
      },
      error: (response) => {
        this.toastrTranslateService.error(response, 'NEW_LOAD.NOT_SAVED');
      },
    });
  }
}
