<div class="modal-dialog">
  <h2 mat-dialog-title>
    <mat-icon class="iconwarn">warning</mat-icon>
  </h2>
  <mat-dialog-content class="mat-typography d-flex">
    <p class="text">
      {{ "CONFIRM_DIALOG.QUESTION" | translate }}
    </p>
    <p class="text">
      {{ "CONFIRM_DIALOG.WANT_DELETE_CUSTOMER" | translate }}
    </p>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-button class="btnCan" mat-dialog-close>
      {{ "CUSTOMER.CANCEL" | translate }}
    </button>
    <button
      mat-flat-button
      class="btn"
      color="warn"
      (click)="deleteCustomer()"
      cdkFocusInitial
    >
      {{ "GENERAL.DELETE" | translate }}
    </button>
  </mat-dialog-actions>
</div>
