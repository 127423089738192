import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { saveAs } from 'file-saver-es';
import { PayrollCalculationCreateRequest } from 'src/app/_models/_payroll/payroll-calculation';
import { PrintPayrollRequest } from 'src/app/_models/_payroll/printPayrollRequest';
import { CodeModel } from 'src/app/_models/code';
import { PayrollService, ToastrTranslateService } from 'src/app/_services';
import { CodeFactory } from 'src/app/_services/factory/code.factory.service';
import { LocalStorageService } from '../../../_services/local.storage.service';

@Component({
  selector: 'app-payroll-preview',
  templateUrl: './payroll-preview.component.html',
  styleUrls: ['./payroll-preview.component.scss'],
})
export class PayrollPreviewComponent implements OnInit {
  data: Blob;
  includeEscrow = true;
  includeLoan = true;
  showFilters = false;
  payrollRequest: PrintPayrollRequest;
  currentPayroll: PayrollCalculationCreateRequest;
  disabledSubmit = false;
  payrollTypeList: CodeModel[] = [];
  isLoadSelected = false;
  fileName = '';

  constructor(
    private payrollService: PayrollService,
    private localStorageService: LocalStorageService,
    private toastrTranslateService: ToastrTranslateService,
    private codeFactory: CodeFactory,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.initData();
  }
  async initData() {
    try {
      let payroll = this.payrollService.getCurrentPayroll();
      if (payroll) {
        this.fileName =
          'MYT Payroll statement ' +
          payroll.dateFrom.toLocaleDateString() +
          '-' +
          payroll.dateTo.toLocaleDateString();
        let printPayrollRequest: PrintPayrollRequest = {
          dateFrom: payroll.dateFrom,
          dateTo: payroll.dateTo,
          driverId: payroll.driverId,
          truckId: payroll.truckId,
          payrollTypeId: payroll.payrollTypeId,
          percentage: payroll.percentage,
          perMileLoaded: payroll.perMileLoaded,
          perMileDeadhead: payroll.perMileDeadhead,
          perStop: payroll.perStop,
          loadIds: payroll.loads,
        };
        if (payroll.loads.length > 0) {
          this.isLoadSelected = true;
        }
        this.payrollRequest = printPayrollRequest;
        this.currentPayroll = payroll;

        this.codeFactory.getPayrollTypeList().then((data) => {
          this.payrollTypeList = data;
          this.calculateFilterVisibility(payroll.payrollTypeId);
        });

        this.data = await this.payrollService.getPdf(
          printPayrollRequest,
          this.includeEscrow,
          this.includeLoan
        );
      }
    } catch (error) {
      this.toastrTranslateService.error(error, 'COMMON.ERROR_DATA');
    }
  }

  ngAfterViewChecked() {}
  async downloadPdf(title: string, data: Blob) {
    const blob = new Blob([data], {
      type: 'application/pdf',
    });
    saveAs(blob, `${title}.pdf`);
  }

  async updatePdf() {
    try {
      this.data = await this.payrollService.getPdf(
        this.payrollRequest,
        this.includeEscrow,
        this.includeLoan
      );
    } catch (error) {
      this.toastrTranslateService.error(error, 'COMMON.ERROR_DATA');
    }
  }

  calculateFilterVisibility(payrollTypeId: number) {
    let payrollType = this.payrollTypeList.find((x) => x.id == payrollTypeId);
    if (
      payrollType.name.toUpperCase() == 'PER_PERCENTAGE' ||
      payrollType.name.toUpperCase() == 'PER_MILE'
    ) {
      this.showFilters = false;
    } else {
      this.showFilters = true;
    }
  }

  submitPayrollCalculation() {
    this.disabledSubmit = true;
    this.currentPayroll.includeEscrows = this.includeEscrow;
    this.currentPayroll.includeLoans = this.includeLoan;
    this.payrollService
      .submitPayrollCalculation(this.currentPayroll)
      .subscribe({
        next: (pc) => {
          this.toastrTranslateService.success('NEW_PAYROLL_CALC.SUBMITED');
          // Restart payroll state after submit
          this.localStorageService.removePayrollLocalStorage(
            this.currentPayroll.truckId,
            this.currentPayroll.driverId
          );
          this.payrollService.changeCurrentPayroll(null);
          this.router.navigateByUrl(
            `/payrolls/truck/${this.currentPayroll.truckId}/driver/${this.currentPayroll.driverId}/previous-payrolls?openid=${pc.id}`
          );
        },
        error: (response) => {
          this.disabledSubmit = false;
          this.toastrTranslateService.error(
            response,
            'NEW_PAYROLL_CALC.FAILED'
          );
        },
      });
  }
}
