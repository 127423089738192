<div cdkDropList #data (cdkDropListDropped)="drop($event)">
  <div
    class="stopaddress"
    *ngFor="let address of addressList; let i = index"
    cdkDrag
  >
    <b>{{ i + 1 }}.</b>&nbsp;&nbsp;{{ address.address }} {{ address.poBox }}

    <span class="material-icons" (click)="deleteRow(i)"> close </span>
  </div>
</div>
