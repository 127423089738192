import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CodeModel } from '../_models/code';
import { CustomColumn } from '../_models/common/customColumn';
import { LoadFilter } from '../_models/load/loadFilter';
import { PayrollLocalStorage } from '../_models/payrollLocalStorage';

@Injectable({ providedIn: 'root' })
export class LocalStorageService {
  constructor(private http: HttpClient) {}

  getPayrollLocalStorage(truckId: number, driverId: number) {
    let rawString = localStorage.getItem('payrollData');
    if (!rawString) {
      return null;
    }
    try {
      let payrolls = JSON.parse(rawString) as PayrollLocalStorage[];
      payrolls = payrolls.map(
        (x) =>
          ({
            loads: x.loads,
            dateFrom: new Date(x.dateFrom),
            dateTo: new Date(x.dateTo),
            total: x.total,
            driverId: x.driverId,
            truckId: x.truckId,
            comment: x.comment,
          } as PayrollLocalStorage)
      );
      return payrolls.find(
        (x) => x.driverId == driverId && x.truckId == truckId
      );
    } catch {
      localStorage.removeItem('payrollData');
      return null;
    }
  }

  addPayrollLocalStorage(item: PayrollLocalStorage) {
    let rawString = localStorage.getItem('payrollData');
    let payrollArray = [];
    if (rawString) {
      try {
        payrollArray = JSON.parse(rawString) as PayrollLocalStorage[];
      } catch {
        localStorage.removeItem('payrollData');
        payrollArray = [];
      }
    }
    payrollArray = payrollArray.map(
      (x) =>
        ({
          loads: x.loads,
          dateFrom: new Date(x.dateFrom),
          dateTo: new Date(x.dateTo),
          total: x.total,
          driverId: x.driverId,
          truckId: x.truckId,
          comment: x.comment,
        } as PayrollLocalStorage)
    );
    // remove existign entry if it exists
    payrollArray = payrollArray.filter(
      (x) => x.driverId !== item.driverId || x.truckId !== item.truckId
    );
    payrollArray.push(item);
    localStorage.setItem('payrollData', JSON.stringify(payrollArray));
  }

  removePayrollLocalStorage(truckId: number, driverId: number) {
    let rawString = localStorage.getItem('payrollData');
    if (!rawString) {
      return;
    }
    try {
      let payrolls = JSON.parse(rawString) as PayrollLocalStorage[];
      payrolls = payrolls.filter(
        (x) => x.driverId != driverId || x.truckId != truckId
      );
      localStorage.setItem('payrollData', JSON.stringify(payrolls));
    } catch {
      localStorage.removeItem('payrollData');
    }
  }

  addSelectedColumns(columnList: CustomColumn[], key: string) {
    key = this.getCurrentUser() + '_' + key;
    localStorage.removeItem(key);
    localStorage.setItem(key, JSON.stringify(columnList));
  }

  clearSelectedColumns(key: string) {
    key = this.getCurrentUser() + '_' + key;
    localStorage.removeItem(key);
  }

  getSelectedColumns(key: string): CustomColumn[] {
    let rawString = localStorage.getItem(this.getCurrentUser() + '_' + key);
    if (!rawString) {
      return;
    }
    try {
      let columnList = JSON.parse(rawString) as CustomColumn[];
      return columnList;
    } catch {
      localStorage.removeItem(this.getCurrentUser() + '_' + key);
    }
  }

  setPagginatorPageSize(pageSize: number) {
    localStorage.removeItem('pageSize');
    localStorage.setItem('pageSize', pageSize.toString());
  }

  getPagginatorPageSize(): number {
    const savedPageSize = localStorage.getItem('pageSize');
    if (savedPageSize && savedPageSize.length > 0) {
      return +savedPageSize;
    } else {
      return 10;
    }
  }

  setExistingPayrollDataUse(useSavedPayrollData: boolean) {
    localStorage.setItem('existingPayrollDataUse', String(useSavedPayrollData));
  }

  getExistingPayrollDataUse(): boolean {
    return (
      localStorage.getItem('existingPayrollDataUse').toLowerCase() === 'true'
    );
  }

  setGlobalPayrollPeriod(dateFrom: Date, dateTo: Date) {
    localStorage.setItem(
      'globalPayrollPeriodDateFrom',
      dateFrom?.getTime().toString()
    );
    localStorage.setItem(
      'globalPayrollPeriodDateTo',
      dateTo.getTime().toString()
    );
  }

  getGlobalPayrollPeriod(): any {
    const dateFrom = localStorage.getItem('globalPayrollPeriodDateFrom');
    const dateTo = localStorage.getItem('globalPayrollPeriodDateTo');
    if (dateFrom && dateTo) {
      return {
        dateFrom: new Date(parseInt(dateFrom)),
        dateTo: new Date(parseInt(dateTo)),
      };
    } else {
      const today = new Date();
      return {
        dateFrom: new Date(today.getFullYear(), today.getMonth(), 1),
        dateTo: new Date(today.getFullYear(), today.getMonth(), 20),
      };
    }
  }

  setCurrentUser(user) {
    localStorage.removeItem('user');
    localStorage.setItem('user', user);
  }

  getCurrentUser(): string {
    return localStorage.getItem('user');
  }

  setTenantTimeZone(tenantTimeZone: CodeModel) {
    localStorage.removeItem('tenantTimeZone');
    localStorage.setItem('tenantTimeZone', JSON.stringify(tenantTimeZone));
  }

  getTenantTimeZone() {
    let rawString = localStorage.getItem('tenantTimeZone');
    if (!rawString) {
      return;
    }
    try {
      let savedTimeZone = JSON.parse(rawString) as CodeModel;
      return savedTimeZone;
    } catch {
      localStorage.removeItem('tenantTimeZone');
      return;
    }
  }

  setAdvancedSerachFilter(filter: LoadFilter) {
    localStorage.removeItem(
      this.getCurrentUser() + '_' + 'AdvancedSerachFilter'
    );
    localStorage.setItem(
      this.getCurrentUser() + '_' + 'AdvancedSerachFilter',
      JSON.stringify(filter)
    );
  }

  getAdvancedSerachFilter(): LoadFilter {
    let rawString = localStorage.getItem(
      this.getCurrentUser() + '_' + 'AdvancedSerachFilter'
    );
    try {
      let savedFilter = JSON.parse(rawString) as LoadFilter;
      return savedFilter ? savedFilter : new LoadFilter();
    } catch {
      localStorage.removeItem(
        this.getCurrentUser() + '_' + 'AdvancedSerachFilter'
      );
      return new LoadFilter();
    }
  }
}
