import { Pipe, PipeTransform } from '@angular/core';
import * as luxon from 'luxon';
import { DateTime } from 'luxon';
import { TenantService } from 'src/app/_services';

@Pipe({
  name: 'tenantDate',
})
export class TenantDatePipe implements PipeTransform {
  constructor(private tenantService: TenantService) {}
  transform(value: any, args?: any): any {
    if (value == null) {
      return '';
    }
    const timeZone = this.tenantService.getSetTimeZone();
    const utcDate = luxon.DateTime;
    const tenantUTC = utcDate.fromISO(value).setZone(timeZone.name);
    return tenantUTC.setLocale('en-US').toLocaleString(DateTime.DATE_SHORT);
  }
}
