<div class="wrapperTable" id="loads">
  <button mat-icon-button class="refresh" (click)="refresh()">
    <mat-icon
      matTooltip="{{ 'MANAGE.REFRESH' | translate }}"
      matTooltipPosition="below"
      >refresh</mat-icon
    >
  </button>
  <button
    class="btnDialog"
    id="newLoad"
    mat-raised-button
    routerLink="{{
      '/load/' +
        0 +
        '/' +
        'home/' +
        activeButton +
        '/' +
        hasHoldFilter +
        '/' +
        searchTerm
    }}"
    routerLinkActive="active"
    matTooltip="{{ 'LOADS.ADD_NEW_LOAD' | translate }}"
  >
    {{ "LOADS.NEW_LOAD" | translate }}
  </button>
  <mat-form-field class="filterField">
    <input
      matInput
      (blur)="globalSearch($event.target.value)"
      (keydown.enter)="globalSearch($event.target.value)"
      [(ngModel)]="searchTerm"
      placeholder="{{ 'LOADS.FILTER' | translate }}"
      class="filterInput"
      autocomplete="off"
    />
    <mat-icon matPrefix class="filterIcon">search</mat-icon>
  </mat-form-field>

  <button
    mat-raised-button
    class="mat-raised-button"
    (click)="getLoadFromButtonFilter(true, 'ACTIVE')"
    [ngClass]="{ 'phase-active': activeButton === 'ACTIVE' }"
  >
    {{ "LOADS.ACTIVE" | translate }}
  </button>
  <button
    mat-raised-button
    matBadge="{{ pendingCount }}"
    [matBadgeHidden]="pendingCount == 0"
    class="mat-raised-button"
    (click)="getLoadFromButtonFilter(true, 'PENDING')"
    [ngClass]="{ 'phase-active': activeButton === 'PENDING' }"
  >
    {{ "LOADS.PENDING" | translate }}
  </button>

  <button
    mat-raised-button
    class="mat-raised-button"
    (click)="getLoadFromButtonFilter(true, 'DELIVERED')"
    [ngClass]="{ 'phase-active': activeButton === 'DELIVERED' }"
  >
    {{ "LOADS.DELIVERED" | translate }}
  </button>
  <button
    mat-raised-button
    class="mat-raised-button"
    (click)="getLoadFromButtonFilter(true, 'COMPLETED')"
    [ngClass]="{ 'phase-active': activeButton === 'COMPLETED' }"
  >
    {{ "LOADS.COMPLETED" | translate }}
  </button>
  <button
    mat-raised-button
    class="mat-raised-button"
    (click)="getLoadFromButtonFilter(true, 'INVOICED')"
    [ngClass]="{ 'phase-active': activeButton === 'INVOICED' }"
  >
    {{ "LOADS.INVOICED" | translate }}
  </button>
  <button
    mat-raised-button
    class="mat-raised-button"
    (click)="getLoadFromButtonFilter(true, 'PAID')"
    [ngClass]="{ 'phase-active': activeButton === 'PAID' }"
  >
    {{ "LOADS.PAID" | translate }}
  </button>
  <button
    mat-raised-button
    class="mat-raised-button"
    (click)="getLoadFromButtonFilter(true, 'DELETED')"
    [ngClass]="{ 'phase-active': activeButton === 'DELETED' }"
  >
    {{ "LOADS.DELETED" | translate }}
  </button>
  <button
    mat-raised-button
    class="mat-raised-button"
    (click)="getLoadFromButtonFilter(true, 'ALL')"
    [ngClass]="{ 'phase-active': activeButton === 'ALL' }"
  >
    {{ "LOADS.ALL" | translate }}
  </button>
  <mat-checkbox
    [checked]="hasHoldFilter"
    (change)="getLoadFromCheckedFilter($event, true, activeButton)"
    class="issue-hold"
    >{{ "NEW_LOAD.ISSUE-HOLD" | translate }}</mat-checkbox
  >
  <div class="p-25" *ngIf="loadsTotals.totalCount > 0">
    <div class="inline-table">
      <div class="mat-elevation-z8">
        <table
          mat-table
          matSort
          matSortDisableClear
          [dataSource]="dataSource"
          class="mat-elevation-z8"
        >
          <ng-container matColumnDef="loadNumber">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
              {{ "LOADS.LOAD" | translate }}
            </th>
            <td
              mat-cell
              *matCellDef="let load"
              routerLink="{{
                '/load/' +
                  load.id +
                  '/' +
                  'home/' +
                  activeButton +
                  '/' +
                  hasHoldFilter +
                  '/' +
                  searchTerm
              }}"
              class="link"
            >
              <label
                matTooltip="{{ 'LOADS.EDIT_LOAD' | translate }} {{
                  load.loadNumber
                }}"
                >{{ load.loadNumber }}</label
              >
            </td>
            <td mat-footer-cell *matFooterCellDef>
              {{ "LOADS.COUNT" | translate }} {{ loadsTotals.totalCount }}
            </td>
          </ng-container>

          <ng-container matColumnDef="driver" class="driver">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
              {{ "LOADS.DRIVER" | translate }}
            </th>
            <td
              mat-cell
              *matCellDef="let load"
              routerLink="{{
                '/load/' +
                  load.id +
                  '/' +
                  'home/' +
                  activeButton +
                  '/' +
                  hasHoldFilter +
                  '/' +
                  searchTerm
              }}"
              class="text-truncate"
              id="driverName"
            >
              <label
                *ngIf="load.driver"
                class="link"
                matTooltip="{{
                  load.driver.firstname + ' ' + load.driver.lastname
                }}"
                >{{ load.driver.firstname + " " + load.driver.lastname }}</label
              >
            </td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>

          <ng-container matColumnDef="dispatcher">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
              {{ "LOADS.DISPATCHER" | translate }}
            </th>
            <td
              mat-cell
              *matCellDef="let load"
              routerLink="{{
                '/load/' +
                  load.id +
                  '/' +
                  'home/' +
                  activeButton +
                  '/' +
                  hasHoldFilter +
                  '/' +
                  searchTerm
              }}"
              class="dispatcherName"
            >
              <label
                *ngIf="load.dispatcher"
                class="link"
                matTooltip="{{
                  load.dispatcher.firstname + ' ' + load.dispatcher.lastname
                }}"
                >{{
                  load.dispatcher.firstname + " " + load.dispatcher.lastname
                }}</label
              >
            </td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>

          <ng-container matColumnDef="truck">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
              {{ "LOADS.TRUCK" | translate }}
            </th>
            <td
              mat-cell
              *matCellDef="let load"
              routerLink="{{
                '/load/' +
                  load.id +
                  '/' +
                  'home/' +
                  activeButton +
                  '/' +
                  hasHoldFilter +
                  '/' +
                  searchTerm
              }}"
              class="middle-truncate"
            >
              <label
                *ngIf="load.truck"
                class="link"
                matTooltip="{{ load.truck.truckNumber }}"
                >{{ load.truck.truckNumber }}
              </label>
            </td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>

          <ng-container matColumnDef="trailer">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
              {{ "LOADS.TRAILER" | translate }}
            </th>
            <td
              mat-cell
              *matCellDef="let load"
              routerLink="{{
                '/load/' +
                  load.id +
                  '/' +
                  'home/' +
                  activeButton +
                  '/' +
                  hasHoldFilter +
                  '/' +
                  searchTerm
              }}"
            >
              <label
                *ngIf="load.trailer"
                class="link"
                matTooltip="{{ load.trailer.trailerNumber }}"
                >{{ load.trailer.trailerNumber }}
              </label>
            </td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>

          <ng-container matColumnDef="eq_type">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
              <label matTooltip="{{ 'LOADS.EQ_TYPE' | translate }}">{{
                "LOADS.EQT" | translate
              }}</label>
            </th>
            <td
              mat-cell
              *matCellDef="let load"
              routerLink="{{
                '/load/' +
                  load.id +
                  '/' +
                  'home/' +
                  activeButton +
                  '/' +
                  hasHoldFilter +
                  '/' +
                  searchTerm
              }}"
              class="text-truncate"
            >
              <label
                *ngIf="load.equipmentType"
                class="link"
                matTooltip="{{ load.equipmentType.name }}"
              >
                {{ "EQ_TYPE_SHORT." + load.equipmentType.name | translate }}
              </label>
            </td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>

          <ng-container matColumnDef="customer">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
              {{ "LOADS.CUSTOMER" | translate }}
            </th>
            <td
              mat-cell
              *matCellDef="let load"
              routerLink="{{
                '/load/' +
                  load.id +
                  '/' +
                  'home/' +
                  activeButton +
                  '/' +
                  hasHoldFilter +
                  '/' +
                  searchTerm
              }}"
              class="middle-truncate"
            >
              <label
                *ngIf="load.customer"
                class="link"
                matTooltip="{{
                  load.customer.name ||
                    '' + ', ' + load.customer.email ||
                    '' + ', ' + load.customer.phoneNumber ||
                    ''
                }}"
                >{{ load.customer.name }}
              </label>
            </td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>

          <ng-container matColumnDef="origin">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
              {{ "LOADS.ORIGIN" | translate }}
            </th>
            <td
              mat-cell
              *matCellDef="let load"
              routerLink="{{
                '/load/' +
                  load.id +
                  '/' +
                  'home/' +
                  activeButton +
                  '/' +
                  hasHoldFilter +
                  '/' +
                  searchTerm
              }}"
              class="address-truncate"
            >
              <label
                *ngIf="load.originLocation"
                class="link"
                matTooltip="{{
                  load.originLocation.address +
                    ', ' +
                    load.originLocation.countryCode
                }}"
              >
                <label *ngIf="load.originLocation.city">
                  {{
                    load.originLocation.city + ", " + load.originLocation.state
                  }}
                </label>
                <label *ngIf="!load.originLocation.city">
                  {{ load.originLocation.address }}
                </label>
              </label>
            </td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>

          <ng-container matColumnDef="destination">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
              {{ "LOADS.DESTINATION" | translate }}
            </th>
            <td
              mat-cell
              *matCellDef="let load"
              routerLink="{{
                '/load/' +
                  load.id +
                  '/' +
                  'home/' +
                  activeButton +
                  '/' +
                  hasHoldFilter +
                  '/' +
                  searchTerm
              }}"
              class="address-truncate"
            >
              <label
                *ngIf="load.destinationLocation"
                class="link"
                matTooltip="{{
                  load.destinationLocation.address +
                    ', ' +
                    load.destinationLocation.countryCode
                }}"
              >
                <label *ngIf="load.destinationLocation.city">
                  {{
                    load.destinationLocation.city +
                      ", " +
                      load.destinationLocation.state
                  }}
                </label>
                <label *ngIf="!load.destinationLocation.city">
                  {{ load.destinationLocation.address }}
                </label>
              </label>
            </td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>

          <ng-container matColumnDef="pickup_date">
            <th mat-header-cell mat-sort-header="pickupDate" *matHeaderCellDef>
              {{ "LOADS.PICKUP_DATE" | translate }}
            </th>
            <td
              mat-cell
              *matCellDef="let load"
              routerLink="{{
                '/load/' +
                  load.id +
                  '/' +
                  'home/' +
                  activeButton +
                  '/' +
                  hasHoldFilter +
                  '/' +
                  searchTerm
              }}"
              class="textRight"
            >
              {{ load.pickupDate | tenantDate : "MM/dd/y " }}
            </td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>

          <ng-container matColumnDef="delivery_date">
            <th
              mat-header-cell
              mat-sort-header="deliveryDate"
              *matHeaderCellDef
            >
              {{ "LOADS.DELIVERY_DATE" | translate }}
            </th>
            <td
              mat-cell
              *matCellDef="let load"
              routerLink="{{
                '/load/' +
                  load.id +
                  '/' +
                  'home/' +
                  activeButton +
                  '/' +
                  hasHoldFilter +
                  '/' +
                  searchTerm
              }}"
              class="textRight"
            >
              {{ load.deliveryDate | tenantDate }}
            </td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>

          <ng-container matColumnDef="milage">
            <th
              mat-header-cell
              mat-sort-header="milage"
              *matHeaderCellDef
              class="textRight padingHeaderMilage"
            >
              {{ "LOADS.MILAGE" | translate }}
            </th>
            <td
              mat-cell
              *matCellDef="let load"
              routerLink="{{
                '/load/' +
                  load.id +
                  '/' +
                  'home/' +
                  activeButton +
                  '/' +
                  hasHoldFilter +
                  '/' +
                  searchTerm
              }}"
              class="link padingLeft"
              type="number"
            >
              {{ load.milage | number : "1.2-2" }}
            </td>
            <td mat-footer-cell *matFooterCellDef>
              {{ loadsTotals.totalMilage | number : "1.2-2" }}
            </td>
          </ng-container>

          <ng-container matColumnDef="dead_head">
            <th
              mat-header-cell
              mat-sort-header="deadhead"
              class="textRight padingHeaderDh"
              *matHeaderCellDef
            >
              <label matTooltip="{{ 'LOADS.DEAD_HEAD' | translate }}">{{
                "LOADS.DEAD_HEAD_SHORT" | translate
              }}</label>
            </th>
            <td
              mat-cell
              *matCellDef="let load"
              routerLink="{{
                '/load/' +
                  load.id +
                  '/' +
                  'home/' +
                  activeButton +
                  '/' +
                  hasHoldFilter +
                  '/' +
                  searchTerm
              }}"
              class="link padingLeft"
              type="number"
            >
              {{ load.deadhead | number : "1.2-2" }}
            </td>
            <td mat-footer-cell *matFooterCellDef>
              {{ loadsTotals.totalDeadHead | number : "1.2-2" }}
            </td>
          </ng-container>

          <ng-container matColumnDef="rate">
            <th
              mat-header-cell
              mat-sort-header="rate"
              class="padingHeaderRate"
              *matHeaderCellDef
            >
              {{ "LOADS.RATE" | translate }}
            </th>
            <td
              mat-cell
              *matCellDef="let load"
              routerLink="{{
                '/load/' +
                  load.id +
                  '/' +
                  'home/' +
                  activeButton +
                  '/' +
                  hasHoldFilter +
                  '/' +
                  searchTerm
              }}"
              class="link padingLeft"
              type="number"
            >
              {{ load.rate | currency }}
            </td>
            <td mat-footer-cell *matFooterCellDef>
              {{ loadsTotals.totalRate | currency }}
            </td>
          </ng-container>

          <ng-container matColumnDef="lumper_fee">
            <th
              mat-header-cell
              mat-sort-header="lumperCost"
              class="textRight padingHeader"
              *matHeaderCellDef
            >
              {{ "LOADS.LUMPER_FEE" | translate }}
            </th>
            <td
              mat-cell
              *matCellDef="let load"
              routerLink="{{
                '/load/' +
                  load.id +
                  '/' +
                  'home/' +
                  activeButton +
                  '/' +
                  hasHoldFilter +
                  '/' +
                  searchTerm
              }}"
              class="textRight padingLeft"
              type="number"
            >
              <label
                matTooltip="Loading and unloading fee - Coast that is payed for person who performed loading/unloading of the truck"
                >{{ load.lumperCost | currency }}</label
              >
            </td>
            <td class="textRight" mat-footer-cell *matFooterCellDef>
              {{ loadsTotals.totalLumperCost | currency }}
            </td>
          </ng-container>

          <ng-container matColumnDef="detention_fee">
            <th
              mat-header-cell
              mat-sort-header="detentionCost"
              class="textRight padingHeader"
              *matHeaderCellDef
            >
              {{ "LOADS.DETENTION_FEE" | translate }}
            </th>
            <td
              mat-cell
              *matCellDef="let load"
              routerLink="{{
                '/load/' +
                  load.id +
                  '/' +
                  'home/' +
                  activeButton +
                  '/' +
                  hasHoldFilter +
                  '/' +
                  searchTerm
              }}"
              class="textRight padingLeft"
              type="number"
            >
              <label
                matTooltip="Coast that is payed if driver had waiting time to load or unload"
                >{{ load.detentionCost | currency }}</label
              >
            </td>
            <td class="textRight" mat-footer-cell *matFooterCellDef>
              {{ loadsTotals.totalDetention | currency }}
            </td>
          </ng-container>

          <ng-container matColumnDef="other">
            <th
              mat-header-cell
              mat-sort-header="otherCost"
              class="textRight padingHeader"
              *matHeaderCellDef
            >
              {{ "LOADS.OTHER_COSTS" | translate }}
            </th>
            <td
              mat-cell
              *matCellDef="let load"
              routerLink="{{
                '/load/' +
                  load.id +
                  '/' +
                  'home/' +
                  activeButton +
                  '/' +
                  hasHoldFilter +
                  '/' +
                  searchTerm
              }}"
              class="textRight padingLeft"
              type="number"
            >
              <label
                matTooltip="Other coast; If there are other coast (that is not lumper and detention fee) here will be entered"
                >{{ load.otherCost | currency }}</label
              >
            </td>
            <td class="textRight" mat-footer-cell *matFooterCellDef>
              {{ loadsTotals.totalOtherCost | currency }}
            </td>
          </ng-container>

          <ng-container matColumnDef="total_invoice">
            <th
              mat-header-cell
              mat-sort-header="totalInvoiced"
              class="textRight padingHeader"
              *matHeaderCellDef
            >
              {{ "LOADS.TOTAL_INVOICE" | translate }}
            </th>
            <td
              mat-cell
              *matCellDef="let load"
              routerLink="{{
                '/load/' +
                  load.id +
                  '/' +
                  'home/' +
                  activeButton +
                  '/' +
                  hasHoldFilter +
                  '/' +
                  searchTerm
              }}"
              class="textRight padingLeft"
              type="number"
            >
              <label
                matTooltip="Rate + Lumper fee + Detention fee + Other - Advance"
                >{{ load.totalInvoiced | currency }}</label
              >
            </td>
            <td class="textRight" mat-footer-cell *matFooterCellDef>
              {{ loadsTotals.totalTotalInvoiced | currency }}
            </td>
          </ng-container>

          <ng-container matColumnDef="advance">
            <th
              mat-header-cell
              mat-sort-header="advanedPayment"
              class="textRight padingHeader"
              *matHeaderCellDef
            >
              {{ "LOADS.ADVANCE" | translate }}
            </th>
            <td
              mat-cell
              *matCellDef="let load"
              routerLink="{{
                '/load/' +
                  load.id +
                  '/' +
                  'home/' +
                  activeButton +
                  '/' +
                  hasHoldFilter +
                  '/' +
                  searchTerm
              }}"
              class="textRight padingLeft"
              type="number"
            >
              <label matTooltip="Advance payment from shipper">{{
                load.advanedPayment | currency
              }}</label>
            </td>
            <td class="textRight" mat-footer-cell *matFooterCellDef>
              {{ loadsTotals.totalAdvancedPayment | currency }}
            </td>
          </ng-container>

          <ng-container matColumnDef="rpm">
            <th
              mat-header-cell
              mat-sort-header="ratePerMile"
              class="textRight padingHeader"
              *matHeaderCellDef
            >
              <label matTooltip="{{ 'LOADS.RATE_PER_MILE' | translate }}">
                {{ "LOADS.RPM" | translate }}
              </label>
            </th>
            <td
              mat-cell
              *matCellDef="let load"
              routerLink="{{
                '/load/' +
                  load.id +
                  '/' +
                  'home/' +
                  activeButton +
                  '/' +
                  hasHoldFilter +
                  '/' +
                  searchTerm
              }}"
              class="link padingLeft"
              type="number"
            >
              <label matTooltip="{{ 'LOADS.RPM_CALCULATE' | translate }}">{{
                load.ratePerMile | currency
              }}</label>
            </td>
            <td mat-footer-cell *matFooterCellDef>
              <label
                matTooltip="{{ 'LOADS.RPM_AVERAGE' | translate }}"
                matTooltipPosition="after"
              >
                {{ loadsTotals.avgRpm | currency }}
              </label>
            </td>
          </ng-container>

          <ng-container matColumnDef="tonu">
            <th
              mat-header-cell
              mat-sort-header="toNu"
              class="textRight padingHeader"
              *matHeaderCellDef
            >
              {{ "LOADS.TONU" | translate }}
            </th>
            <td
              mat-cell
              *matCellDef="let load"
              routerLink="{{
                '/load/' +
                  load.id +
                  '/' +
                  'home/' +
                  activeButton +
                  '/' +
                  hasHoldFilter +
                  '/' +
                  searchTerm
              }}"
              class="link padingLeft"
              type="number"
            >
              {{ load.toNu | currency }}
            </td>
            <td mat-footer-cell *matFooterCellDef>
              {{ loadsTotals.totalToNu | currency }}
            </td>
          </ng-container>

          <ng-container matColumnDef="status">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
              {{ "LOADS.STATUS" | translate }}
            </th>
            <td mat-cell *matCellDef="let load">
              <mat-form-field
                floatLabel="auto"
                hideRequiredMarker
                class="matFormStatus"
              >
                <mat-select
                  class="global_search {{ load.status.name }}"
                  name="loadStatus"
                  [compareWith]="compareObjects"
                  [(ngModel)]="load.status"
                  (selectionChange)="
                    openDialogConfirmStatus(
                      load.id,
                      load.status.id,
                      load.loadNumber
                    )
                  "
                >
                  <ng-container *ngIf="statusTypeList.length > 0">
                    <mat-option
                      *ngFor="let statusType of statusTypeList"
                      [value]="statusType"
                      class="statusOption"
                    >
                      {{ "LOAD_STATUS." + statusType.name | translate }}
                    </mat-option>
                  </ng-container>
                </mat-select>
              </mat-form-field>
            </td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>

          <ng-container matColumnDef="rc">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let load" class="link" id="downloadRC">
              <span
                (click)="
                  openDownloadRC(
                    load.id,
                    load.loadNumber,
                    load.documents,
                    load.status.name
                  )
                "
                class="material-icons"
                matTooltip="{{ 'LOADS.DOWNLOAD_DOCUMENTS' | translate }}"
              >
                <mat-icon class="download"> download</mat-icon>
              </span>
            </td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>
          <ng-container matColumnDef="sendMail">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let load" class="link" id="sendMail">
              <span
                (click)="
                  sendMailToCustomer(
                    load.id,
                    load.loadNumber,
                    load.customer,
                    load.documents
                  )
                "
                class="material-icons"
                matTooltip="{{ 'LOADS.SEND_MAIL' | translate }}"
              >
                <mat-icon class="mail"> mail</mat-icon>
              </span>
            </td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>

          <ng-container matColumnDef="print_ivoice">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let load">
              <button
                class="btnDialog invoice"
                (click)="openPrintInoviceDialog(load.id, load.status.name)"
              >
                <span>
                  <mat-icon class="printIcon"> local_printshop</mat-icon>
                </span>
                <span
                  class="spanDisplay"
                  matTooltip="{{ 'LOADS.PRINT_INVOICE' | translate }}"
                >
                  {{ "LOADS.INVOICE" | translate }}
                </span>
              </button>
            </td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>

          <ng-container matColumnDef="order">
            <th mat-header-cell mat-sort-header="orderNumber" *matHeaderCellDef>
              {{ "LOADS.ORDER" | translate }}
            </th>
            <td
              mat-cell
              *matCellDef="let load"
              routerLink="{{
                '/load/' +
                  load.id +
                  '/' +
                  'home/' +
                  activeButton +
                  '/' +
                  hasHoldFilter +
                  '/' +
                  searchTerm
              }}"
              class="link"
            >
              {{ load.orderNumber }}
            </td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>

          <ng-container matColumnDef="comment">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
              {{ "LOADS.COMMENT" | translate }}
            </th>
            <td mat-cell *matCellDef="let load">
              <button
                [disabled]="!load.comments || load.comments.length == 0"
                (click)="openCommentsDialog(load.comments, load.loadNumber)"
                class="button-comment"
              >
                <mat-icon
                  class="material-icons"
                  [matBadge]="load.comments.length"
                  [matBadgeHidden]="!load.comments || load.comments.length == 0"
                  matBadgeColor="primary"
                  matTooltip="{{ 'LOADS.VIEW_COMMENT' | translate }}"
                  >comment</mat-icon
                >
              </button>
            </td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>
          <ng-container matColumnDef="note">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
              {{ "LOADS.NOTE" | translate }}
            </th>
            <td mat-cell *matCellDef="let load">
              <button
                [disabled]="!load.comments || load.comments.length == 0"
                (click)="openCommentsDialog(load.comments, load.loadNumber)"
                class="button-comment"
              >
                <mat-icon
                  class="material-icons"
                  [matBadge]="load.comments.length"
                  [matBadgeHidden]="!load.comments || load.comments.length == 0"
                  matBadgeColor="primary"
                  matTooltip="{{ 'LOADS.VIEW_COMMENT' | translate }}"
                  >comment</mat-icon
                >
              </button>
            </td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>

          <ng-container matColumnDef="haul_type">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
              <label matTooltip="{{ 'LOADS.HAUL_TYPE' | translate }}">{{
                "LOADS.HAUL_TYPE_SHORT" | translate
              }}</label>
            </th>
            <td
              mat-cell
              *matCellDef="let load"
              routerLink="{{
                '/load/' +
                  load.id +
                  '/' +
                  'home/' +
                  activeButton +
                  '/' +
                  hasHoldFilter +
                  '/' +
                  searchTerm
              }}"
              class="number-truncate"
            >
              <label
                *ngIf="load.haulType"
                class="link"
                matTooltip="{{ 'HAUL_TYPE.' + load.haulType.name | translate }}"
                >{{
                  "HAUL_TYPE_SHORT." + load.haulType.name | translate
                }}</label
              >
            </td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>

          <ng-container matColumnDef="ageing">
            <th mat-header-cell *matHeaderCellDef>
              {{ "LOADS.AGEING" | translate }}
            </th>
            <td
              mat-cell
              *matCellDef="let load"
              routerLink="{{
                '/load/' +
                  load.id +
                  '/' +
                  'home/' +
                  activeButton +
                  '/' +
                  hasHoldFilter +
                  '/' +
                  searchTerm
              }}"
              class="link"
            >
              <label matTooltip="{{ 'LOADS.AGEING_DEF' | translate }}">{{
                load.ageing
              }}</label>
            </td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>

          <ng-container matColumnDef="cs">
            <th mat-header-cell *matHeaderCellDef>
              {{ "LOADS.C/S" | translate }}
            </th>
            <td
              mat-cell
              *matCellDef="let load"
              routerLink="{{
                '/load/' +
                  load.id +
                  '/' +
                  'home/' +
                  activeButton +
                  '/' +
                  hasHoldFilter +
                  '/' +
                  searchTerm
              }}"
              class="link"
            >
              'c/s'
            </td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>

          <ng-container matColumnDef="pod_scans">
            <th mat-header-cell *matHeaderCellDef>
              {{ "LOADS.POD_SCANS" | translate }}
            </th>
            <td mat-cell *matCellDef="let load" class="link"></td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>

          <ng-container matColumnDef="invoiced">
            <th mat-header-cell *matHeaderCellDef>
              {{ "LOADS.INVOICED" | translate }}
            </th>
            <td mat-cell *matCellDef="let load" class="link">'Invoiced'</td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>

          <ng-container matColumnDef="BOL">
            <th mat-header-cell *matHeaderCellDef>
              {{ "LOADS.BOL" | translate }}
            </th>
            <td mat-cell *matCellDef="let load" class="link">
              <label
                matTooltip="Open PDF/JPEG of BOL type of
        uploaded document (POD)"
              >
              </label>
            </td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>

          <ng-container matColumnDef="columns">
            <th
              mat-header-cell
              *matHeaderCellDef
              style="padding: 10px"
              matTooltip="{{ 'ADVANCED.CONFIGURE_COLUMNS' | translate }}"
              matTooltipClass="columns-tooltip"
              matTooltipPosition="below"
            >
              <div type="button" class="select-column-div">
                <mat-select
                  #columnsSelector
                  name="columnsSelector"
                  class="global_search"
                  style="padding-top: 8px"
                  [(ngModel)]="selectedColumnList"
                  (selectionChange)="toggleColumn()"
                  multiple
                  [compareWith]="compareObjects"
                >
                  <mat-select-trigger style="margin: auto">
                    <mat-icon>view_column</mat-icon>
                  </mat-select-trigger>
                  <ng-container *ngIf="allColumnList.length > 0">
                    <span *ngFor="let column of allColumnList">
                      <mat-option
                        [value]="column"
                        [ngClass]="{ hidden: column.name == 'columns' }"
                      >
                        {{ "COLUMN." + column.name | translate }}
                      </mat-option>
                    </span>
                    <div class="p-10">
                      <a
                        (click)="
                          clearSelectedColumns(); columnsSelector.close()
                        "
                        >{{ "COMMON.RESET_SELECTION" | translate }}</a
                      >
                    </div>
                  </ng-container>
                </mat-select>
              </div>
            </th>
            <td mat-cell *matCellDef="let load" class="link"></td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>

          <tr
            mat-header-row
            *matHeaderRowDef="displayedColumns; sticky: true"
          ></tr>
          <tr
            mat-row
            *matRowDef="let load; columns: displayedColumns"
            class="link"
          ></tr>
          <tr
            mat-footer-row
            *matFooterRowDef="displayedColumns"
            class="table-footer"
          ></tr>
        </table>
      </div>

      <mat-paginator
        (page)="onChangePage($event)"
        class="paginator"
        aria-label="Select page"
        [length]="totalSize"
        [showFirstLastButtons]="true"
        [pageIndex]="currentPage"
        [pageSize]="pageSize"
        [pageSizeOptions]="pageSizeOptions"
      >
      </mat-paginator>
    </div>
  </div>
  <app-info-nodata
    *ngIf="count == 0"
    [infoKey]="'LOADS.NO_LOADS'"
  ></app-info-nodata>
</div>
