import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import {
  PayrollAmountImpact,
  PayrollAmountImpactTypeEnum,
} from 'src/app/_models/_payroll/payroll-amount-impact';
import { UserTruckPayrollAmountImpact } from 'src/app/_models/_payroll/user-truck-payroll-amount-impact';
import { PayrollService } from 'src/app/_services';
import { AddAdditionalCostDialogComponent } from './../add-additional-cost-dialog/add-additional-cost-dialog.component';

export interface AmountItemData {
  type: PayrollAmountImpactTypeEnum;
  suggestions: PayrollAmountImpact[];
  amountImpacts: UserTruckPayrollAmountImpact[];
  driverId: number;
  truckId: number;
}

@Component({
  selector: 'app-amount-item',
  templateUrl: './amount-item.component.html',
  styleUrls: ['./amount-item.component.scss'],
})
export class AmountItemComponent implements OnInit {
  @Input('data') data: AmountItemData;

  @Output() onAdd: EventEmitter<any> = new EventEmitter();
  @Output() onRemove: EventEmitter<any> = new EventEmitter();

  values = [];
  values1 = [];

  filteredOptions: PayrollAmountImpact[];
  additionalCosts: string[] = [];

  constructor(
    private fb: UntypedFormBuilder,
    private dialog: MatDialog,
    private payrollService: PayrollService
  ) {}

  ngOnInit() {
    this.filteredOptions = [...this.data.suggestions];
    this.addvalue();
  }

  removevalue(i) {
    let deletedItem = this.data.amountImpacts[i];
    if (deletedItem.id <= 0) {
      this.data.amountImpacts.splice(i, 1);
    } else {
      this.payrollService
        .deletePayrollAmountImpact(deletedItem.id)
        .subscribe((res) => {
          this.data.amountImpacts.splice(i, 1);
          this.onRemove.emit();
        });
    }
  }

  numberOfAppearances(array: any[], item) {
    let count = 0;
    for (let i = 0; i < array.length; i++) {
      if (array[i].value == item) {
        count++;
      }
    }
    return count;
  }

  onAmountInputFocusOut(index: number) {
    let impact = this.data.amountImpacts[index];
    if (impact.amount == 0 || !impact.payrollAmountImpact?.description) return;
    // ---- CREATE ----
    if (impact.id == 0) {
      let suggestion = this.data.suggestions.find(
        (s) => s.description == impact.payrollAmountImpact.description
      );
      if (suggestion == undefined) {
        // new impact type (popup and create type first)
        const dialogRef = this.dialog.open(AddAdditionalCostDialogComponent, {
          disableClose: true,
          position: { top: '20vh' },
        });
        dialogRef.afterClosed().subscribe((response) => {
          if (response) {
            let newPayrollAmountImpactReq: PayrollAmountImpact = {
              id: 0,
              description: impact.payrollAmountImpact.description,
              type: this.data.type,
            };
            this.payrollService
              .createNewPayrollAmountImpact(newPayrollAmountImpactReq)
              .subscribe((res) => {
                this.data.suggestions.push(res);
                impact.payrollAmountImpact = res;
                impact.payrollAmountImpactId = res.id;
                this.payrollService
                  .createNewUserTruckPayrollAmountImpact(impact)
                  .subscribe((resImpact) => {
                    this.data.amountImpacts[index] = resImpact;
                    this.onAdd.emit();
                  });
              });
          }
        });
      } else {
        // old impact type
        impact.payrollAmountImpact.id = suggestion.id;
        impact.payrollAmountImpactId = suggestion.id;
        this.payrollService
          .createNewUserTruckPayrollAmountImpact(impact)
          .subscribe((resImpact) => {
            this.data.amountImpacts[index] = resImpact;
            this.onAdd.emit();
          });
      }
    }
    // --- EDIT ----
    else {
      let suggestion = this.data.suggestions.find(
        (s) => s.description == impact.payrollAmountImpact.description
      );
      if (suggestion == undefined) {
        // new impact type (popup and create type first)
        const dialogRef = this.dialog.open(AddAdditionalCostDialogComponent, {
          disableClose: true,
          position: { top: '20vh' },
        });
        dialogRef.afterClosed().subscribe((response) => {
          if (response) {
            let newPayrollAmountImpactReq: PayrollAmountImpact = {
              id: 0,
              description: impact.payrollAmountImpact.description,
              type: this.data.type,
            };
            this.payrollService
              .createNewPayrollAmountImpact(newPayrollAmountImpactReq)
              .subscribe((res) => {
                this.data.suggestions.push(res);
                impact.payrollAmountImpact = res;
                impact.payrollAmountImpactId = res.id;
                this.payrollService
                  .updateUserTruckPayrollAmountImpact(impact)
                  .subscribe((resImpact) => {
                    this.data.amountImpacts[index] = resImpact;
                    this.onAdd.emit();
                  });
              });
          }
        });
      } else {
        // old impact type
        impact.payrollAmountImpact.id = suggestion.id;
        impact.payrollAmountImpactId = suggestion.id;
        this.payrollService
          .updateUserTruckPayrollAmountImpact(impact)
          .subscribe((resImpact) => {
            this.data.amountImpacts[index] = resImpact;
            this.onAdd.emit();
          });
      }
    }

    // let currentValue = this.values[this.values.length - 1];
    // let valueAlreadyExists =
    //   (this.additionalCosts.find(c => c == currentValue?.value) || this.numberOfAppearances(this.values, currentValue?.value) > 1);
    // if (!valueAlreadyExists) {
    //   const dialogRef = this.dialog.open(AddAdditionalCostDialogComponent, {
    //     disableClose: true,
    //     position: { top: '20px' }
    //   });
    //   dialogRef.afterClosed().subscribe((response) => {
    //     if (response) {
    //       this.values.push({ value: '' });
    //       this.values1.push({ value: '' });
    //       this.onAdd.emit();
    //     }
    //     else {
    //       this.values[this.values.length - 1].value = "";
    //     }
    //   });
    // }
    // else {
    //   this.onAdd.emit();
    // }
  }

  addvalue() {
    this.data.amountImpacts.push({
      id: 0,
      driverId: this.data.driverId,
      truckId: this.data.truckId,
      payrollAmountImpactId: 0,
      amount: 0,
      payrollAmountImpact: {
        id: 0,
        description: '',
        type: this.data.type,
      },
    });
  }

  suggestionsKeyUp(event, value: string) {
    if (!value || value?.trim() == '') {
      this.filteredOptions = [...this.data.suggestions];
    }
    this.filteredOptions = this.data.suggestions.filter((s) =>
      s.description.toLowerCase().startsWith(value.toLowerCase())
    );
  }
}
