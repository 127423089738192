import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { Paginator } from '../_models/common/paginator';
import { Response } from '../_models/response';
import { ResponseData } from '../_models/responseData';

@Injectable({ providedIn: 'root' })
export class DriverApplicationsService {
  constructor(private http: HttpClient) {}

  getDriverApplications(paginator?: Paginator): Observable<ResponseData> {
    const params = new HttpParams({
      fromObject: {
        PageSize: '' + paginator.pageSize,
        PageNumber: '' + paginator.pageNumber,
        SortBy: '' + paginator.sortBy,
        SortOrder: '' + paginator.sortOrder,
        GlobalSearch: '' + paginator.searchTerm,
      },
    });

    return this.http
      .get<Response>(`${environment.apiUrl}/driver-applications`, {
        params: params,
      })
      .pipe(map((response) => response.data as ResponseData));
  }
}
