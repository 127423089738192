import { PayrollSettings } from '../_payroll/payroll';
import { CodeModel } from '../code';
import { CommentModel } from '../comments';
import { CustomerModel } from '../customer';
import { DispatcherModel } from '../dispatcher';
import { DriverModel } from '../driver/driver';
import { Location } from '../location/location';
import { Stop } from '../location/stop';
import { TrailerModel } from '../trailer';
import { TruckModel } from '../truck';
import { LoadDocument } from './loadDocument';

export class LoadModel {
  statusId: number;
  status: CodeModel;
  loadNumber: string;
  orderNumber: string;
  hasHold: boolean;
  originLocation: Location;
  destinationLocation: Location;
  pickupLocation: Location;
  deliveryLocation: Location;
  stops = new Array<Stop>();
  pickupDate: Date;
  deliveryDate: Date;
  haulTypeId: number;
  haulType: CodeModel;
  truck: TruckModel;
  truckId: number;
  driver: DriverModel;
  driverId: number;
  dispatcher: DispatcherModel;
  dispatcherId: number;
  trailer: TrailerModel;
  trailerId: number;
  equipmentTypeId: number;
  equipmentType: CodeModel;
  customer: CustomerModel;
  customerId: number;
  contactPerson: string;
  milage: number;
  calculatedMilage: number;
  deadhead: number;
  calculatedDeadhead: number;
  advanedPayment: number;
  lumperCost: number = 0;
  totalLumperCost: number = 0;
  detentionCost: number;
  otherCost: number;
  otherCostComment: string;
  totalInvoiced: number;
  rate: number;
  ratePerMile: number;
  rcPath: string;
  note: string;
  comments: CommentModel[] = new Array();
  toNu: number = 0;
  payroll: PayrollSettings;
  id: number;
  pendingLoadsCount: number;
  documents = new Array<LoadDocument>();
  documentCreateRequests: LoadDocument[];
  invoiceDate: Date;
  ageing;
}
