import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-upload-logo',
  templateUrl: './upload-logo.component.html',
  styleUrls: ['./upload-logo.component.scss'],
})
export class UploadLogoComponent implements OnInit {
  @Input() fileToShow: any;
  @Input() readOnly: any;
  @Output() imageUpdated = new EventEmitter<File>();

  imageUrl: any;
  constructor() {}

  ngOnInit(): void {
    if (this.fileToShow) {
      this.imageUrl = this.fileToShow;
    }
  }

  handleFileInput(file: FileList) {
    if (!this.readOnly) {
      this.fileToShow = file.item(0);

      let reader = new FileReader();
      reader.onload = (event: any) => {
        this.imageUrl = event.target.result;
      };
      reader.readAsDataURL(this.fileToShow);
      this.imageUpdated.emit(this.fileToShow);
    }
  }
}
