import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LoadDocument } from 'src/app/_models/load/loadDocument';
import { LoadSendEmailRequest } from 'src/app/_models/load/loadSendEmailRequest';
import { LoadService, ToastrTranslateService } from 'src/app/_services';
import { CustomerModel } from '../../_models/customer';

@Component({
  selector: 'email-documents',
  templateUrl: './email-documents.component.html',
  styleUrls: ['./email-documents.component.scss'],
})
export class EmailDocumentsComponent implements OnInit {
  loadNumber: string;
  loadId: number;
  customer: CustomerModel;
  documentList: Array<LoadDocument>;
  toAddress = '';
  ccAddress = '';
  selectedTo = false;
  selectedCc = false;
  isLoading = false;

  constructor(
    private dialogRef: MatDialogRef<EmailDocumentsComponent>,
    private toastrTranslateService: ToastrTranslateService,
    private loadService: LoadService,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {}

  ngOnInit(): void {
    this.loadNumber = this.data.loadNumber;
    this.loadId = this.data.loadId;
    this.customer = this.data.customer;
    this.documentList = this.data.documentList;
    this.getCustomerAddress(this.customer);
  }

  getCustomerAddress(customer: CustomerModel) {
    let toAddress = '';
    if (customer?.billingEmail) {
      toAddress = customer.billingEmail;
    } else {
      toAddress = customer?.email;
    }
    this.setDefaultSelection(toAddress);
    this.toAddress = toAddress;
  }

  setDefaultSelection(toAddress) {
    toAddress ? (this.selectedTo = true) : (this.selectedTo = false);
  }

  selectCc(event) {
    event.checked ? (this.selectedCc = true) : (this.selectedCc = false);
  }

  selectTo(event) {
    event.checked ? (this.selectedTo = true) : (this.selectedTo = false);
  }

  ccCheckBoxChange() {
    this.ccAddress ? (this.selectedCc = true) : (this.selectedCc = false);
  }

  sendEmail(isInvalid) {
    if (isInvalid || (!this.selectedCc && !this.selectedTo)) {
      this.toastrTranslateService.warning('GENERAL.FORM_INVALID');
      return;
    }

    this.isLoading = true;
    let loadSendEmailRequest = new LoadSendEmailRequest();
    if (this.selectedTo) {
      loadSendEmailRequest.emailTo = this.toAddress;
    }
    if (this.selectedCc) {
      loadSendEmailRequest.emailCc = this.ccAddress;
    }
    loadSendEmailRequest.loadIds.push(this.loadId);

    this.loadService.sendEmail(loadSendEmailRequest).subscribe({
      next: (response) => {
        this.isLoading = false;
        if (response && response.success) {
          this.toastrTranslateService.success(
            'CONFIRM_DIALOG.SUCCESS_SEND_DOCUMENTS'
          );
          this.dialogRef.close({ success: response });
        } else {
          this.toastrTranslateService.error(
            'CONFIRM_DIALOG.FAILED_SEND_DOCUMENTS'
          );
        }
      },
      error: (response) => {
        this.isLoading = false;
        this.toastrTranslateService.error(
          response?.message,
          'CONFIRM_DIALOG.FAILED_SEND_DOCUMENTS'
        );
      },
    });
  }
}
