import { Injectable } from '@angular/core';
import { LoadsTotals } from 'src/app/_models/load/loadsTotals';
import { LoadModel } from '../../_models/load/load';

@Injectable({
  providedIn: 'root',
})
export default class LoadsHelper {
  public getTotals(loads: LoadModel[]): LoadsTotals {
    let loadsTotals = new LoadsTotals();
    loadsTotals.totalCount = loads ? loads.length : 0;
    loadsTotals.totalMilage = this.getTotalMilage(loads);
    loadsTotals.totalDeadHead = this.getTotalDeadHead(loads);
    loadsTotals.totalRate = this.getTotalRate(loads);
    loadsTotals.totalLumperCost = this.getTotalLumperCost(loads);
    loadsTotals.totalDetention = this.getTotalDetentionCost(loads);
    loadsTotals.totalOtherCost = this.getTotalOtherCost(loads);
    loadsTotals.totalTotalInvoiced = this.getTotalTotalInvoiced(loads);
    loadsTotals.totalAdvancedPayment = this.getTotalAdvancedPayment(loads);
    loadsTotals.avgRpm = this.getAvgRpm(loads);
    loadsTotals.totalToNu = this.getToNu(loads);
    return loadsTotals;
  }

  private getTotalMilage(loads: LoadModel[]): number {
    return loads.map((t) => t.milage).reduce((acc, value) => acc + value, 0);
  }

  private getTotalDeadHead(loads: LoadModel[]): number {
    return loads.map((t) => t.deadhead).reduce((acc, value) => acc + value, 0);
  }

  private getTotalRate(loads: LoadModel[]): number {
    return loads.map((t) => t.rate).reduce((acc, value) => acc + value, 0);
  }

  private getTotalLumperCost(loads: LoadModel[]): number {
    return loads
      .map((t) => t.lumperCost)
      .reduce((acc, value) => acc + value, 0);
  }

  private getTotalDetentionCost(loads: LoadModel[]): number {
    return loads
      .map((t) => t.detentionCost)
      .reduce((acc, value) => acc + value, 0);
  }

  private getTotalOtherCost(loads: LoadModel[]): number {
    return loads.map((t) => t.otherCost).reduce((acc, value) => acc + value, 0);
  }

  private getTotalTotalInvoiced(loads: LoadModel[]): number {
    return loads
      .map((t) => t.totalInvoiced)
      .reduce((acc, value) => acc + value, 0);
  }

  private getTotalAdvancedPayment(loads: LoadModel[]): number {
    return loads
      .map((t) => t.advanedPayment)
      .reduce((acc, value) => acc + value, 0);
  }

  private getAvgRpm(loads: LoadModel[]): number {
    return loads
      .map((t) => t.ratePerMile / loads.length)
      .reduce((acc, value) => acc + value, 0);
  }

  private getToNu(loads: LoadModel[]): number {
    return loads.map((t) => t.toNu).reduce((acc, value) => acc + value, 0);
  }
}
