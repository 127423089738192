<mat-card class="info-section">
  <p>
    {{ "PAYROLL.DRIVER_NAME" | translate }} {{ truck?.driver?.firstname }}
    {{ truck?.driver?.lastname }}
  </p>
  <p>{{ "PAYROLL.TRUCK_NUMBER" | translate }} {{ truck?.truckNumber }}</p>

  <p>
    {{ "PAYROLL.PAYROLL_TYPE" | translate }}:
    {{
      "PAYROLL_TYPE_NAME." + truck?.driver?.payrollSettings?.type?.name
        | translate
    }}
  </p>

  <p *ngIf="truck?.driver?.payrollSettings?.type?.name != 'Per_Mile'">
    {{ "DRIVERS.PERCENTAGE" | translate }}:
    {{ truck?.driver?.payrollSettings?.percentage }}%
  </p>

  <p *ngIf="truck?.driver?.payrollSettings?.type?.name == 'Per_Mile'">
    {{ "DRIVERS.PER_MILE_L" | translate }}:
    {{ truck?.driver?.payrollSettings?.perMileLoaded }}
  </p>

  <p *ngIf="truck?.driver?.payrollSettings?.type?.name == 'Per_Mile'">
    {{ "DRIVERS.PER_MILE_D" | translate }}:
    {{ truck?.driver?.payrollSettings?.perMileDeadhead }}
  </p>

  <p *ngIf="truck?.driver?.payrollSettings?.type?.name == 'Per_Mile'">
    {{ "DRIVERS.PER_STOP" | translate }}:
    {{ truck?.driver?.payrollSettings?.perStop }}
  </p>

  <p *ngIf="truck?.driver?.payrollSettings?.type?.name == 'Per_Mile'">
    {{ "DRIVERS.OTHER" | translate }}:
    {{ truck?.driver?.payrollSettings?.other }}
  </p>

  <p>{{ "PAYROLL.ESCROW_BALANCE" | translate }} {{ escrow | currency }}</p>
  <p>{{ "PAYROLL.LOAN_BALANCE" | translate }} {{ loan | currency }}</p>
</mat-card>
<div class="datepicker-pay">
  <mat-form-field class="datepay-form-field" appearance="outline">
    <mat-label>{{ "PAYROLL.PERIOD_FOR_PAY" | translate }}</mat-label>
    <mat-date-range-input
      [formGroup]="periodForm"
      [rangePicker]="campaignOnePicker"
      [comparisonStart]="periodForm.value.start"
      [comparisonEnd]="periodForm.value.end"
    >
      <input
        (dateInput)="onDateChange()"
        matStartDate
        placeholder="Start date"
        formControlName="start"
      />
      <input
        (dateInput)="onDateChange()"
        matEndDate
        placeholder="End date"
        formControlName="end"
      />
    </mat-date-range-input>
    <mat-datepicker-toggle
      color="primary"
      matSuffix
      [for]="campaignOnePicker"
    ></mat-datepicker-toggle>
    <mat-date-range-picker #campaignOnePicker></mat-date-range-picker>
  </mat-form-field>
</div>

<div class="expensesTitle">
  <h4 class="labelnameExpense">
    <span *ngIf="this.payrollSettingsType.toLowerCase() == 'owner_operator'">{{
      "PAYROLL.EXPENSES" | translate
    }}</span>
    <span *ngIf="this.payrollSettingsType.toLowerCase() != 'owner_operator'">{{
      "PAYROLL.DEDUCTION" | translate
    }}</span>
  </h4>

  <h4 class="labelnameReimbursement">
    <span *ngIf="this.payrollSettingsType.toLowerCase() == 'owner_operator'">{{
      "PAYROLL.REIMBURSEMENTS" | translate
    }}</span>
    <span *ngIf="this.payrollSettingsType.toLowerCase() != 'owner_operator'">{{
      "PAYROLL.ADDITIONAL_PAY" | translate
    }}</span>
  </h4>
</div>

<div class="expensesHeightDiv">
  <div class="expenses" *ngIf="expenseAmountItemData">
    <app-amount-item
      #expensesComponent
      (onAdd)="calcTotal()"
      (onRemove)="calcTotal()"
      [data]="expenseAmountItemData"
    ></app-amount-item>
  </div>

  <div class="reimbursements" *ngIf="reimbursementAmountItemData">
    <h4 class="labelname">
      <span
        *ngIf="this.payrollSettingsType.toLowerCase() == 'owner_operator'"
        >{{ "PAYROLL.REIMBURSEMENTS" | translate }}</span
      >
      <span
        *ngIf="this.payrollSettingsType.toLowerCase() != 'owner_operator'"
        >{{ "PAYROLL.ADDITIONAL_PAY" | translate }}</span
      >
    </h4>
    <app-amount-item
      #reimbursementsComponent
      (onAdd)="calcTotal()"
      (onRemove)="calcTotal()"
      [data]="reimbursementAmountItemData"
    ></app-amount-item>
  </div>
</div>

<div class="expensesTitle" style="display: block">
  <button
    mat-flat-button
    class="buttonNewItemExpense"
    (click)="addExpense()"
    matTooltip="{{ 'ACCOUNT_ITEM.NEW_EXPENSE' | translate }}"
  >
    <mat-icon>add</mat-icon>{{ "ACCOUNT_ITEM.NEW_ITEM" | translate }}
  </button>
  <button
    mat-flat-button
    class="buttonNewItemReimbursement"
    (click)="addReimbursement()"
    matTooltip="{{ 'ACCOUNT_ITEM.NEW_REIMBURSEMENT' | translate }}"
  >
    <mat-icon>add</mat-icon>{{ "ACCOUNT_ITEM.NEW_ITEM" | translate }}
  </button>
</div>

<form class="payroll-comment">
  <mat-form-field class="payroll-full-width" appearance="outline">
    <mat-label>{{ "PAYROLL.COMMENT" | translate }}</mat-label>
    <textarea
      [(ngModel)]="comment"
      matInput
      (blur)="calcTotal()"
      [ngModelOptions]="{ standalone: true }"
    ></textarea>
  </mat-form-field>
</form>

<!-- TABLE OF LOADS IN RANGE -->

<mat-card
  class="cardNoLoads"
  *ngIf="payrollDataGetCompleted && dataSourceInRange.data.length == 0"
  ><p class="noLoadsText">
    <span class="star">*</span>{{ "PAYROLL.NO_LOADS_DATE_RANGE" | translate }}
  </p></mat-card
>
<table
  #tableIn="matSort"
  mat-table
  matSort
  matSortActive="loadNumber"
  matSortDisableClear
  matSortDirection="desc"
  matSortDisabled
  [dataSource]="dataSourceInRange"
  class="mat-elevation-z0"
  id="firstTable"
  *ngIf="payrollDataGetCompleted && dataSourceInRange.data.length > 0"
>
  <ng-container matColumnDef="loadNumber">
    <th mat-header-cell mat-sort-header *matHeaderCellDef>
      {{ "PAYROLL.LOAD_NUMBER" | translate }}
    </th>
    <td mat-cell *matCellDef="let load">
      {{ load.loadNumber }}
    </td>
    <td mat-footer-cell *matFooterCellDef></td>
  </ng-container>
  <ng-container matColumnDef="dispatcherName">
    <th mat-header-cell *matHeaderCellDef>
      {{ "PAYROLL.DISPATCHER_NAME" | translate }}
    </th>
    <td mat-cell *matCellDef="let load" class="dispatcherName">
      <label
        *ngIf="load.dispatcher"
        matTooltip="{{
          load.dispatcher.firstname + ' ' + load.dispatcher.lastname
        }}"
        >{{ load.dispatcher.firstname + " " + load.dispatcher.lastname }}</label
      >
    </td>
    <td mat-footer-cell *matFooterCellDef></td>
  </ng-container>
  <ng-container matColumnDef="pickupAddress">
    <th mat-header-cell *matHeaderCellDef>
      {{ "PAYROLL.PICKUP_ADDRESS" | translate }}
    </th>
    <td mat-cell *matCellDef="let load" class="text-truncate">
      <label
        *ngIf="load.originLocation"
        matTooltip="{{
          load.originLocation.address + ', ' + load.originLocation.countryCode
        }}"
        ><span *ngIf="load.originLocation.city">{{
          load.originLocation.city + ", " + load.originLocation.state
        }}</span>
        <span *ngIf="!load.originLocation.city">{{
          load.originLocation.state
        }}</span>
      </label>
    </td>
  </ng-container>
  <ng-container matColumnDef="destinationAddress">
    <th mat-header-cell *matHeaderCellDef>
      {{ "PAYROLL.DESTINATION_ADDRESS" | translate }}
    </th>
    <td mat-cell *matCellDef="let load" class="text-truncate">
      <label
        *ngIf="load.destinationLocation"
        matTooltip="{{
          load.destinationLocation.address +
            ', ' +
            load.destinationLocation.countryCode
        }}"
        ><span *ngIf="load.destinationLocation.city">{{
          load.destinationLocation.city + ", " + load.destinationLocation.state
        }}</span>
        <span *ngIf="!load.destinationLocation.city">{{
          load.destinationLocation.state
        }}</span>
      </label>
    </td>
  </ng-container>
  <ng-container matColumnDef="pickupDate">
    <th mat-header-cell *matHeaderCellDef>
      {{ "PAYROLL.PICKUP_DATE" | translate }}
    </th>
    <td mat-cell *matCellDef="let load" class="link">
      {{ load.pickupDate | tenantDate | date : "MM/dd/y " }}
    </td>
    <td mat-footer-cell *matFooterCellDef></td>
  </ng-container>

  <ng-container matColumnDef="deliveryDate">
    <th mat-header-cell *matHeaderCellDef>
      {{ "PAYROLL.DELIVERY_DATE" | translate }}
    </th>
    <td mat-cell *matCellDef="let load" class="link">
      {{ load.deliveryDate | tenantDate | date : "MM/dd/y " }}
    </td>
    <td mat-footer-cell *matFooterCellDef></td>
  </ng-container>
  <ng-container matColumnDef="totalInvoiced">
    <th mat-header-cell *matHeaderCellDef class="payrollTotal">
      {{ "PAYROLL.TOTAL" | translate }}
    </th>
    <td
      mat-cell
      *matCellDef="let load"
      class="link"
      id="payrollTotal"
      matTooltip="Rate + Lumper fee + Detention fee + Other - Advance"
      type="number"
    >
      {{ load.totalInvoiced | currency }}
    </td>
    <td mat-footer-cell *matFooterCellDef>totalInvoiced</td>
  </ng-container>

  <ng-container matColumnDef="check">
    <th
      mat-header-cell
      *matHeaderCellDef
      class="alignCenter"
      matTooltip="Check all"
    >
      <mat-checkbox
        (change)="
          $event ? masterToggleInRange() : null; tableInRangeSelectionChanged()
        "
        [checked]="selectionInRange.hasValue() && isAllInRangeSelected()"
        [indeterminate]="selectionInRange.hasValue() && !isAllInRangeSelected()"
      >
      </mat-checkbox>
    </th>
    <td mat-cell *matCellDef="let row" class="alignCenter">
      <mat-checkbox
        (click)="$event.stopPropagation()"
        (change)="
          $event ? selectionInRange.toggle(row) : null;
          tableInRangeSelectionChanged()
        "
        [checked]="selectionInRange.isSelected(row)"
      >
      </mat-checkbox>
    </td>
    <td mat-footer-cell *matFooterCellDef></td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr
    mat-row
    *matRowDef="let load; columns: displayedColumns"
    class="link"
  ></tr>
</table>

<!-- END OF: TABLE OF LOADS IN RANGE-->

<mat-card
  class="cardNoLoads"
  id="cardNoLoads"
  *ngIf="payrollDataGetCompleted && dataSourceOutOfRange.data.length == 0"
  ><p class="noLoadsText">
    <span class="star">*</span
    >{{ "PAYROLL.NO_LOADS_OUT_DATE_RANGE" | translate }}
  </p></mat-card
>

<div
  *ngIf="payrollDataGetCompleted && dataSourceOutOfRange.data.length > 0"
  id="unpaidTable"
>
  <h2>
    {{ "PAYROLL.UNPAID_LOAD" | translate }}
  </h2>

  <table
    #tableOut="matSort"
    mat-table
    matSort
    matSortActive="loadNumber"
    matSortDisableClear
    matSortDisabled
    matSortDirection="desc"
    [dataSource]="dataSourceOutOfRange"
    class="mat-elevation-z0"
    id="unpaidTable"
  >
    <ng-container matColumnDef="loadNumber">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>
        {{ "PAYROLL.LOAD_NUMBER" | translate }}
      </th>
      <td mat-cell *matCellDef="let load">
        {{ load.loadNumber }}
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>
    <ng-container matColumnDef="dispatcherName">
      <th mat-header-cell *matHeaderCellDef>
        {{ "PAYROLL.DISPATCHER_NAME" | translate }}
      </th>
      <td mat-cell *matCellDef="let load" class="dispatcherName">
        <label
          *ngIf="load.dispatcher"
          matTooltip="{{
            load.dispatcher.firstname + ' ' + load.dispatcher.lastname
          }}"
          >{{
            load.dispatcher.firstname + " " + load.dispatcher.lastname
          }}</label
        >
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>
    <ng-container matColumnDef="pickupAddress">
      <th mat-header-cell *matHeaderCellDef>
        {{ "PAYROLL.PICKUP_ADDRESS" | translate }}
      </th>
      <td mat-cell *matCellDef="let load" class="text-truncate">
        <label
          *ngIf="load.pickupLocation"
          matTooltip="{{
            load.pickupLocation.address + ', ' + load.pickupLocation.countryCode
          }}"
        >
          {{ load.pickupLocation.city + ", " + load.pickupLocation.state }}
        </label>
      </td>
    </ng-container>
    <ng-container matColumnDef="destinationAddress">
      <th mat-header-cell *matHeaderCellDef>
        {{ "PAYROLL.DESTINATION_ADDRESS" | translate }}
      </th>
      <td mat-cell *matCellDef="let load" class="text-truncate">
        <label
          *ngIf="load.deliveryLocation"
          matTooltip="{{
            load.deliveryLocation.address +
              ', ' +
              load.deliveryLocation.countryCode
          }}"
        >
          {{ load.deliveryLocation.city + ", " + load.deliveryLocation.state }}
        </label>
      </td>
    </ng-container>
    <ng-container matColumnDef="pickupDate">
      <th mat-header-cell *matHeaderCellDef>
        {{ "PAYROLL.PICKUP_DATE" | translate }}
      </th>
      <td mat-cell *matCellDef="let load" class="link">
        {{ load.pickupDate | tenantDate | date : "MM/dd/yyyy " }}
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="deliveryDate">
      <th mat-header-cell *matHeaderCellDef>
        {{ "PAYROLL.DELIVERY_DATE" | translate }}
      </th>
      <td mat-cell *matCellDef="let load" class="link">
        {{ load.deliveryDate | tenantDate | date : "MM/dd/y " }}
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>
    <ng-container matColumnDef="totalInvoiced">
      <th mat-header-cell class="payrollTotal" *matHeaderCellDef>
        {{ "PAYROLL.TOTAL" | translate }}
      </th>
      <td
        mat-cell
        *matCellDef="let load"
        class="link"
        id="payrollTotal"
        matTooltip="Rate + Lumper fee + Detention fee + Other - Advance"
        type="number"
      >
        {{ load.totalInvoiced | currency }}
      </td>
      <td mat-footer-cell *matFooterCellDef>totalInvoiced</td>
    </ng-container>

    <ng-container matColumnDef="check">
      <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox
          (change)="
            $event ? masterToggleOutOfRange() : null;
            tableOutOfRangeSelectionChanged()
          "
          [checked]="
            selectionOutOfRange.hasValue() && isAllOutOfRangeSelected()
          "
          [indeterminate]="
            selectionOutOfRange.hasValue() && !isAllOutOfRangeSelected()
          "
        >
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row" class="alignCenter">
        <mat-checkbox
          (click)="$event.stopPropagation()"
          (change)="
            $event ? selectionOutOfRange.toggle(row) : null;
            tableOutOfRangeSelectionChanged()
          "
          [checked]="selectionOutOfRange.isSelected(row)"
        >
        </mat-checkbox>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr
      mat-row
      *matRowDef="let load; columns: displayedColumns"
      class="link"
    ></tr>
  </table>
</div>

<div class="footer">
  <div class="footer-button-div">
    <h3>{{ "PAYROLL.TOTALPAY" | translate }} {{ total | currency }}</h3>
    <button
      mat-button
      class="footer-btn"
      routerLink="{{
        '/payrolls/truck/' + truckId + '/driver/' + driverId + '/loan'
      }}"
    >
      {{ "PAYROLL.NEXT" | translate }}
    </button>
  </div>
 
</div>
