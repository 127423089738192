import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  startWith,
  switchMap,
  tap,
} from 'rxjs/operators';
import { CustomerModel } from 'src/app/_models/customer';

import { Location } from 'src/app/_models/location/location';
import { PrimaryContact } from 'src/app/_models/primaryContact';
import {
  CustomerService,
  MapService,
  ToastrTranslateService,
} from 'src/app/_services';

@Component({
  selector: 'app-edit-customer-dialog',
  templateUrl: './edit-customer-dialog.component.html',
  styleUrls: ['./edit-customer-dialog.component.scss'],
})
export class EditCustomerDialogComponent implements OnInit {
  searchAddress = new UntypedFormControl();
  searchBillingAddress = new UntypedFormControl();
  searchAddressResult: Location[];
  searchBillingAddressResult: Location[];
  locationsData: Location[] = [];
  myControl = new UntypedFormControl();
  filteredOptions: Observable<string[]>;
  customer: CustomerModel;
  searchTerm = new UntypedFormControl();
  showAddAddressFlag = false;
  customerId: number;
  isAddressPoBox: boolean = false;
  isBillingAddressPoBox: boolean = false;
  readOnly: boolean;
  dataLoading = true;
  requiredAddress = true;
  addresses;
  poBoxValue = '';
  poBoxBillingValue = '';
  @ViewChild('inputCustomerAddress') inputCustomerAddress: ElementRef;
  @ViewChild('inputBillingAddress') inputBillingAddress: ElementRef;

  constructor(
    private customerService: CustomerService,
    private mapService: MapService,
    private dialogRef: MatDialogRef<EditCustomerDialogComponent>,
    private toastrTranslateService: ToastrTranslateService,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {}

  ngOnInit(): void {
    this.customerId = this.data.id;
    this.readOnly = this.data.readOnly;
    this.customer = new CustomerModel();
    if (this.customerId) {
      this.customerService.getCustomer(this.customerId).subscribe({
        next: (response) => {
          this.customer = response;
          if (this.customer.addresses) {
            this.customer.addresses.sort((a, b) => a.sequence - b.sequence);
          }
          this.searchBillingAddress.setValue(this.customer.billingAddress);
          this.recalculateRequredAddress();
          if (this.customer.billingAddress.poBox) {
            this.isBillingAddressPoBox = true;
            this.poBoxBillingValue = this.customer.billingAddress.poBox;
          }
          this.dataLoading = false;
        },
        error: (response) => {
          this.toastrTranslateService.error(response, 'CUSTOMER.FAILED');
        },
      });
    } else {
      this.customer.isActive = true;
      this.customer.addresses = [];
      this.customer.primaryContact = new PrimaryContact();
      this.recalculateRequredAddress();
      this.dataLoading = false;
    }

    this.searchAddress.valueChanges
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        startWith(''),
        tap(() => {
          this.searchAddressResult = [];
        }),
        switchMap((value) => {
          if (value != '') {
            return this.mapService.searchMap(value);
          } else {
            this.customer.addresses = null;
            return (this.searchAddressResult = []);
          }
        })
      )
      .subscribe((data) => {
        data && data.count != 0
          ? (this.searchAddressResult = data.collection)
          : (this.searchAddressResult = []);
      });

    this.searchBillingAddress.valueChanges
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        startWith(''),
        tap(() => {
          this.searchBillingAddressResult = [];
        }),
        switchMap((value) => {
          if (value != '') {
            return this.mapService.searchMap(value);
          } else {
            this.customer.billingAddress = null;
            return (this.searchBillingAddressResult = []);
          }
        })
      )
      .subscribe((data) => {
        if (data && data.count != 0) {
          this.searchBillingAddressResult = data.collection;
        } else {
          this.searchBillingAddressResult = [];
        }
      });
  }

  recalculateRequredAddress() {
    this.requiredAddress = this.customer.addresses.length == 0;
  }

  selectBillingAddress(location: Location) {
    if ((this.customer.billingAddress = location)) {
      this.customer.isBillingSameAsAddress = false;
    }
  }

  selectAddress(location: Location) {
    //TODO Primvremeno je stavljeno da se unosi samo jedna adresa
    if (!this.customer.addresses) {
      this.customer.addresses = [];
    }
    location.sequence = this.customer.addresses.length - 1;
    this.customer.addresses.push(location);
    this.requiredAddress = false;
    if (this.customer.isBillingSameAsAddress) {
      this.customer.billingAddress = this.customer.addresses[0];
    }
    this.showAddAddressFlag = false;
    this.searchAddress.setValue(null);
    this.poBoxValue = '';
  }

  displayFn(location: Location): string {
    return location ? location.address + ', ' + location.countryCode : '';
  }

  setBlackListValue(e) {
    e.checked
      ? (this.customer.isBlacklisted = true)
      : (this.customer.isBlacklisted = false);
  }

  checkBrokerFromMc(mc) {
    mc ? (this.customer.isBroker = true) : (this.customer.isBroker = false);
  }

  setBrokerValue(e) {
    e.checked
      ? (this.customer.isBroker = true)
      : (this.customer.isBroker = false);
  }

  setActiveValue(e) {
    e.checked
      ? (this.customer.isActive = true)
      : (this.customer.isActive = false);
  }

  setCustomerBillingAddress(isBillingSameAsAddress: boolean) {
    if (isBillingSameAsAddress) {
      this.customer.billingAddress = this.customer.addresses[0];
      this.customer.billingAddressId = this.customer.billingAddress.id;
      this.customer.isBillingSameAsAddress = true;
      if (this.customer.billingAddress.poBox) {
        this.isBillingAddressPoBox = true;
        this.poBoxBillingValue = this.customer.billingAddress.poBox;
      }
    } else {
      this.customer.isBillingSameAsAddress = false;
    }
  }

  customerBillingAddressChange(e) {
    this.setCustomerBillingAddress(e.checked);
  }

  eventFromAddresses(data) {
    this.customer.addresses = data;
    this.setCustomerBillingAddress(this.customer.isBillingSameAsAddress);
    this.recalculateRequredAddress();
  }

  showAddAddress() {
    this.showAddAddressFlag = true;
  }

  clearBillingAddress() {
    this.customer.billingAddress = new Location();
    this.customer.billingAddress.poBox = '';
    this.searchBillingAddress.setValue('');
  }

  autofillCustomerAddress() {
    setTimeout(() => {
      if (
        this.inputCustomerAddress?.nativeElement.value &&
        this.searchAddressResult.length > 0
      ) {
        this.selectAddress(this.searchAddressResult[0]);
      } else if (this.inputCustomerAddress?.nativeElement.value) {
        this.inputCustomerAddress.nativeElement.value = '';
        this.toastrTranslateService.warning('GENERAL.ADDRESS_ISSUE');
      }
    }, 1000);
  }

  addAddressManualy() {
    if (this.isAddressPoBox) {
      const location = new Location();
      location.poBox = this.poBoxValue;
      this.selectAddress(location);
      this.isAddressPoBox = false;
      this.recalculateRequredAddress();
    }
  }

  addBillingAddressManualy() {
    if (this.isBillingAddressPoBox) {
      this.clearBillingAddress();
      this.customer.billingAddress.poBox = this.poBoxBillingValue;
      this.customer.isBillingSameAsAddress = false;
    }
  }

  billingAddressIsPoBox(e) {
    this.customer.isBillingSameAsAddress = false;
  }

  autofillBillingAddress() {
    setTimeout(() => {
      let inputValue =
        this.inputBillingAddress.nativeElement.value !=
        this.customer.billingAddress.address +
          ', ' +
          this.customer.billingAddress.countryCode;
      if (!this.customer.billingAddress || inputValue) {
        if (this.searchBillingAddressResult.length > 0) {
          this.customer.billingAddress = this.searchBillingAddressResult[0];
          this.searchBillingAddress.setValue(this.customer.billingAddress);
          this.customer.isBillingSameAsAddress = false;
        } else if (inputValue && this.inputBillingAddress.nativeElement.value) {
          this.clearBillingAddress();
          this.toastrTranslateService.warning('GENERAL.ADDRESS_ISSUE');
        }
      }
    }, 1000);
  }

  toBool(boolVariable: any): Boolean {
    if (boolVariable && boolVariable != 'undefined') {
      return Boolean(JSON.parse(boolVariable));
    }
    return false;
  }

  save(isInvalid) {
    if (isInvalid || this.requiredAddress || !this.customer.billingAddress) {
      this.toastrTranslateService.warning('GENERAL.FORM_INVALID');
      return;
    }
    if (this.customerId) {
      this.customerService.updateCustomer(this.customer).subscribe({
        next: (response) => {
          this.customer = response;
          this.toastrTranslateService.success(
            'CUSTOMER.SAVED',
            this.customer.name
          );
          this.dialogRef.close();
        },
        error: (response) => {
          this.toastrTranslateService.error(response, 'CUSTOMER.NOT_SAVED');
        },
      });
    } else {
      this.customerService.addCustomers(this.customer).subscribe({
        next: (response) => {
          this.customer = response;
          this.toastrTranslateService.success(
            'CUSTOMER.SAVED',
            this.customer.name
          );
          this.dialogRef.close();
        },
        error: (response) => {
          this.toastrTranslateService.error(response, 'CUSTOMER.NOT_SAVED');
        },
      });
    }
  }
}
