<div class="modal-dialog formInModal">
  <div *ngIf="!dataLoading">
    <h2 *ngIf="dispatcher && !dispatcher.id" mat-dialog-title class="text f-16">
      {{ "DISPATCHERS.ADD_DISPATCHER" | translate }}
    </h2>
    <h2 mat-dialog-title *ngIf="dispatcher.id" class="mb-0">
      <h2 class="text f-16" [hidden]="readOnly">
        <div class="text f-16">
          {{ "DISPATCHERS.EDIT_DISPATCHER" | translate }}
        </div>
        <div>
          {{ dispatcher.firstname + " " + dispatcher.lastname }}
        </div>
      </h2>
      <h2 class="text f-16" [hidden]="!readOnly">
        <div class="text f-16">
          {{ "DISPATCHERS.PREVIEW_DISPATCHER" | translate }}
        </div>
        <div>
          {{ dispatcher.firstname + " " + dispatcher.lastname }}
        </div>
      </h2>
    </h2>
  </div>
  <hr />
  <form #editDispatcher="ngForm">
    <mat-dialog-content class="box">
      <mat-form-field
        floatLabel="auto"
        hideRequiredMarker
        appearance="outline"
        class="displayNone"
      >
        <mat-label class="global_search">{{
          "DISPATCHERS.DISPATCHER_ID" | translate
        }}</mat-label>
        <input
          matInput
          name="dispatcherId"
          [(ngModel)]="dispatcher.id"
          autocomplete="off"
        />
      </mat-form-field>
      <mat-form-field
        floatLabel="auto"
        hideRequiredMarker
        appearance="outline"
        class="formWidth"
      >
        <mat-label class="global_search"
          >{{ "DISPATCHERS.FIRST_NAME" | translate
          }}<span class="star">*</span></mat-label
        >
        <input
          matInput
          [(ngModel)]="dispatcher.firstname"
          autocomplete="off"
          required
          [readonly]="readOnly"
          name="firstName"
        />
      </mat-form-field>

      <mat-form-field
        floatLabel="auto"
        hideRequiredMarker
        appearance="outline"
        class="formWidth"
      >
        <mat-label class="global_search">{{
          "DISPATCHERS.MIDDLE_NAME" | translate
        }}</mat-label>
        <input
          matInput
          [(ngModel)]="dispatcher.middlename"
          [readonly]="readOnly"
          autocomplete="off"
          name="middleName"
        />
      </mat-form-field>

      <mat-form-field
        floatLabel="auto"
        hideRequiredMarker
        appearance="outline"
        class="formWidth"
      >
        <mat-label class="global_search"
          >{{ "DISPATCHERS.LAST_NAME" | translate
          }}<span class="star">*</span></mat-label
        >
        <input
          matInput
          [(ngModel)]="dispatcher.lastname"
          autocomplete="off"
          required
          [readonly]="readOnly"
          name="lastName"
        />
      </mat-form-field>
      <br />

      <mat-slide-toggle
        color="primary"
        class="poBoxToggle"
        name="isAddressPoBoxName"
        [(ngModel)]="isAddressPoBox"
        (change)="clearAddress()"
        >{{ "DISPATCHERS.ADD_ADDRESS_MANUALLY" | translate }}</mat-slide-toggle
      >

      <mat-form-field
        floatLabel="auto"
        hideRequiredMarker
        appearance="outline"
        class="address"
        *ngIf="!isAddressPoBox"
      >
        <mat-label class="global_search">{{
          "DISPATCHERS.SEARCH_ADDRESS" | translate
        }}</mat-label>
        <input
          matInput
          #inputDispatcherAddress
          [matAutocomplete]="dispatcher_address"
          [formControl]="searchAddress"
          (blur)="autofillDispatcherAddress()"
          class="address"
          [readonly]="readOnly"
          autocomplete="off"
          name="address"
        />
        <mat-icon matSuffix class="icon_search">search</mat-icon>
        <mat-autocomplete
          #dispatcher_address="matAutocomplete"
          [displayWith]="displayFn"
          (optionSelected)="selectDispatcherLocation($event.option.value)"
        >
          <ng-container *ngIf="searchResult.length > 0">
            <mat-option
              *ngFor="let location of searchResult"
              [value]="location"
            >
              <span>{{
                location.address +
                  ", " +
                  location.state +
                  ", " +
                  location.countryCode
              }}</span>
            </mat-option>
          </ng-container>
        </mat-autocomplete>
      </mat-form-field>

      <mat-form-field
        floatLabel="auto"
        appearance="outline"
        class="inputPObox"
        hideRequiredMarker
        id="poBox"
        *ngIf="isAddressPoBox"
      >
        <mat-label>{{ "DISPATCHERS.ADDRESS" | translate }}</mat-label>
        <input
          matInput
          name="poBox"
          [(ngModel)]="dispatcher.location.poBox"
          autocomplete="off"
        />
      </mat-form-field>

      <br />

      <mat-form-field
        floatLabel="auto"
        hideRequiredMarker
        appearance="outline"
        class="address"
      >
        <mat-label class="global_search"
          >{{ "DISPATCHERS.EMAIL" | translate
          }}<span class="star">*</span></mat-label
        >
        <input
          matInput
          email
          pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}?"
          type="email"
          [(ngModel)]="dispatcher.email"
          autocomplete="off"
          required
          [readonly]="readOnly || dispatcher.id"
          matTooltip="{{ 'APPLICATION.CAN_NOT_CHANGE_EMAIL' | translate }}"
          [matTooltipDisabled]="readOnly || !dispatcher.id"
          name="email"
          class="address"
        />
      </mat-form-field>

      <mat-form-field
        floatLabel="auto"
        hideRequiredMarker
        appearance="outline"
        class="formWidth"
      >
        <mat-label class="global_search">{{
          "DISPATCHERS.PHONE_NUMBER" | translate
        }}</mat-label>
        <input
          matInput
          [(ngModel)]="dispatcher.phoneNumber"
          autocomplete="off"
          [readonly]="readOnly"
          pattern="[- +()0-9]+"
          maxlength="18"
          name="phone"
        />
      </mat-form-field>
      <div>
        <mat-slide-toggle
          color="primary"
          [checked]="toBool(dispatcher.isAdmin)"
          (change)="setAdminValue($event)"
          [disabled]="readOnly"
          >{{ "DISPATCHERS.IS_ADMIN" | translate }}</mat-slide-toggle
        >

        <mat-slide-toggle
          color="primary"
          [checked]="toBool(dispatcher.isActive)"
          (change)="setActiveValue($event)"
          [disabled]="readOnly"
          >{{ "DISPATCHERS.ACTIVE" | translate }}</mat-slide-toggle
        >
      </div>
    </mat-dialog-content>

    <div
      *ngIf="dispatcherId && !dispatcher.accountActivated"
      class="divNoComplete"
    >
      <label class="labelNoComplete">{{
        "APPLICATION.PROFILE_NOT_COMPLETED" | translate
      }}</label>
    </div>
    <div
      *ngIf="dispatcherId && !dispatcher.accountActivated && !readOnly"
      class="divResend"
    >
      <button class="buttonResend" (click)="resendEmail()" mat-raised-button>
        {{ "APPLICATION.RESEND_ACTIVATION_EMAIL" | translate }}
      </button>
    </div>
    <mat-dialog-actions>
      <button mat-button class="btnCan" mat-dialog-close>
        {{ "DISPATCHERS.CANCEL" | translate }}
      </button>
      <button
        mat-button
        *ngIf="!readOnly"
        class="btn btn-green"
        (click)="save(editDispatcher.form.invalid)"
      >
        {{ "DISPATCHERS.SAVE" | translate }}
      </button>
    </mat-dialog-actions>
  </form>
</div>
