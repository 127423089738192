<div class="container">
  <div class="central">
    <div>
      <p class="left-aling">{{ "TRANSACTIONS.TITLE" | translate }}</p>
      <div class="d-flex">
        <div class="refreshDiv" *ngIf="count > 0">
          <button mat-icon-button class="refresh" (click)="refresh()">
            <mat-icon
              matTooltip="{{ 'MANAGE.REFRESH' | translate }}"
              matTooltipPosition="below"
              >refresh</mat-icon
            >
          </button>
        </div>
        <button
          mat-raised-button
          (click)="managePayment(count == 0)"
          class="mat-raised-button manage"
        >
          <mat-icon>open_in_new</mat-icon>
          {{ "TRANSACTIONS.MANAGE_PAYMENT" | translate }}
          <img
            class="stripe-logo"
            src="assets/icons/stripe_logo.svg"
            alt="stripe-logo"
          />
        </button>
      </div>

      <table
        mat-table
        matSort
        matSortDisableClear
        [dataSource]="dataSource"
        class="mat-elevation-z8 table"
        [hidden]="count == null || count == 0"
      >
        <ng-container matColumnDef="created">
          <th
            mat-header-cell
            mat-sort-header
            *matHeaderCellDef
            class="header-right"
          >
            {{ "TRANSACTIONS_AND_BILING.CREATED" | translate }}
          </th>
          <td mat-cell *matCellDef="let transactionData">
            {{ transactionData.created | tenantDate | date : "MM/dd/y " }}
          </td>
          <td mat-footer-cell *matFooterCellDef class="rightAlign">
            {{ "TRANSACTIONS_AND_BILING.COUNT" | translate }} {{ count }}
          </td>
        </ng-container>

        <ng-container matColumnDef="description">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>
            {{ "TRANSACTIONS_AND_BILING.DESCRIPTION" | translate }}
          </th>
          <td mat-cell *matCellDef="let transactionData">
            {{ transactionData.description }}
          </td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <ng-container matColumnDef="amount">
          <th
            mat-header-cell
            mat-sort-header
            *matHeaderCellDef
            class="header-right"
          >
            {{ "TRANSACTIONS_AND_BILING.AMOUNT" | translate }}
          </th>
          <td mat-cell *matCellDef="let transactionData" class="rightAlign">
            {{ transactionData.amount | currency }}
          </td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <ng-container matColumnDef="status">
          <th
            mat-header-cell
            mat-sort-header
            *matHeaderCellDef
            class="header-right"
          >
            {{ "TRANSACTIONS_AND_BILING.STATUS" | translate }}
          </th>

          <td
            mat-cell
            *matCellDef="let transactionData"
            class="rightAlign capitalize"
          >
            {{ transactionData.status }}
          </td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        <tr
          mat-footer-row
          *matFooterRowDef="displayedColumns"
          class="table-footer"
        ></tr>
      </table>
    </div>

    <app-info-nodata
      *ngIf="count == 0"
      [infoKey]="'TRANSACTIONS.NO_TRANSACTIONS'"
    ></app-info-nodata>
  </div>
</div>
