<div class="verify-error" *ngIf="error">
  {{ error }}
</div>
<div class="verify-success" *ngIf="activationSuccess === true">
  {{ "CHANGE_PASS.COMPLETED" | translate }}
  <a href="/"> {{ "CHANGE_PASS.CLICK" | translate }} </a>
</div>
<div class="verify-page" *ngIf="user && activationSuccess !== true">
  <p class="verify-greet-title">
    {{ "CHANGE_PASS.HELLO" | translate }}
    <span class="verify-greet-name">{{
      user.firstname + " " + user.lastname
    }}</span>
  </p>
  <p>{{ "CHANGE_PASS.VERIFY" | translate }}</p>
  <form *ngIf="verifyForm" [formGroup]="verifyForm" class="pwd-form">
    <mat-form-field class="pwd-full-width" appearance="fill">
      <mat-label>{{ "CHANGE_PASS.NEW_PASS_ENTER" | translate }}</mat-label>
      <input
        (keyup)="checkRepeatedPassword()"
        autocomplete="off"
        formControlName="newPassword"
        matInput
        [type]="hide ? 'password' : 'text'"
      />
      <button
        mat-icon-button
        matSuffix
        tabindex="-1"
        (click)="hide = !hide"
        [attr.aria-label]="'Hide password'"
        [attr.aria-pressed]="hide"
      >
        <mat-icon>{{ hide ? "visibility_off" : "visibility" }}</mat-icon>
      </button>
      <mat-error>
        <span class="error" *ngIf="newPassword?.errors?.required">{{
          "CHANGE_PASS.REQUIRED" | translate
        }}</span>
        <span class="error" *ngIf="!newPassword?.errors?.required">
          {{ "CHANGE_PASS.COMPLEXITY" | translate }}
        </span>
      </mat-error>
    </mat-form-field>

    <mat-form-field class="pwd-full-width" appearance="fill">
      <mat-label>{{ "CHANGE_PASS.REPEAT_NEW_PASS" | translate }}</mat-label>
      <input
        (keyup)="checkRepeatedPassword()"
        autocomplete="off"
        formControlName="newPasswordRepeat"
        matInput
        [type]="hideRepeat ? 'password' : 'text'"
      />
      <button
        mat-icon-button
        matSuffix
        tabindex="-1"
        (click)="hideRepeat = !hideRepeat"
        [attr.aria-label]="'Hide password'"
        [attr.aria-pressed]="hideRepeat"
      >
        <mat-icon>{{ hideRepeat ? "visibility_off" : "visibility" }}</mat-icon>
      </button>
      <mat-error>
        <span class="error" *ngIf="newPasswordRepeat?.errors?.required">{{
          "CHANGE_PASS.REPEAT_NEW_PASS" | translate
        }}</span>
        <span class="error" *ngIf="!newPasswordRepeat?.errors?.required">{{
          "CHANGE_PASS.NOT_MATCH" | translate
        }}</span>
      </mat-error>
    </mat-form-field>

    <button
      [disabled]="verifyDisabled"
      (click)="verify()"
      class="pwd-full-width"
      mat-raised-button
      color="primary"
    >
      <mat-icon>check</mat-icon>&nbsp; Verify
    </button>
  </form>
</div>
