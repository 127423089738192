import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { TenantService } from 'src/app/_services';

@Component({
  selector: 'app-confirm-change-timezone',
  templateUrl: './confirm-change-timezone.component.html',
  styleUrls: ['./confirm-change-timezone.component.scss'],
})
export class ConfirmChangeTimezoneComponent {
  //tenant: TenantModel = new TenantModel();

  constructor(
    private tenantService: TenantService,
    private dialogRef: MatDialogRef<ConfirmChangeTimezoneComponent>
  ) {}

  confirm() {
    this.dialogRef.close(true);
  }
  cancel() {
    this.dialogRef.close(false);
  }
}
