<mat-card class="loancard">
  <form #positivAdjust="ngForm">
    <mat-card-tittle
      >{{ "PAYROLL.POSITIVE_ADJUSTMENT" | translate }}
      <mat-icon
        class="infotooltip"
        matTooltip="{{ 'PAYROLL.INFO_POSITIVE_LOAN' | translate }}"
        matTooltipPosition="after"
        >info</mat-icon
      ></mat-card-tittle
    >
    <mat-card-content>
      <mat-form-field class="full-width" appearance="fill">
        <input
          matInput
          required
          [(ngModel)]="positiveAdjustmentReason"
          placeholder="{{ 'PAYROLL.REASON_FOR_PAY' | translate }}"
          autocomplete="off"
          name="positiveReason"
        />
      </mat-form-field>
      <mat-form-field
        id="positiveAmount"
        class="small-width"
        appearance="fill"
        floatLabel="always"
      >
        <mat-label>{{ "PAYROLL.AMOUNT" | translate }}</mat-label>
        <input
          matInput
          required
          type="number"
          [(ngModel)]="positiveAmount"
          onfocus="this.select()"
          placeholder="0"
          class="amountInput"
          autocomplete="off"
          name="positiveAmount"
        /><span matTextPrefix>$&nbsp;</span>
      </mat-form-field>
      <div class="buttonAdjustDiv">
        <button
          mat-flat-button
          class="btnAdjust"
          (click)="addPositiveAdjustment(positivAdjust.form.invalid)"
        >
          <span>{{ "PAYROLL.ADJUST" | translate }}</span>
        </button>
      </div>
    </mat-card-content>
  </form>
</mat-card>
<mat-card class="loancard1">
  <form #negativAdjust="ngForm">
    <mat-card-tittle
      >{{ "PAYROLL.NEGATIVE_ADJUSTMENT" | translate }}
      <mat-icon
        class="infotooltip"
        matTooltip="{{ 'PAYROLL.INFO_NEGATIVE_LOAN' | translate }}"
        matTooltipPosition="after"
        >info</mat-icon
      ></mat-card-tittle
    >
    <mat-card-content>
      <mat-form-field class="full-width" appearance="fill" floatLabel="always">
        <input
          required
          [(ngModel)]="negativeAdjustmentReason"
          matInput
          name="negativeReason"
          placeholder="{{ 'PAYROLL.REASON_FOR_PAY' | translate }}"
          autocomplete="off"
        />
      </mat-form-field>
      <mat-form-field class="small-width" appearance="fill" floatLabel="always">
        <mat-label>{{ "PAYROLL.AMOUNT" | translate }}</mat-label>
        <input
          required
          [(ngModel)]="negativeAmount"
          matInput
          type="number"
          name="negativeAmount"
          onfocus="this.select()"
          placeholder="0"
          class="amountInput"
          autocomplete="off"
        />
        <span matTextPrefix>$&nbsp;</span>
      </mat-form-field>

      <div class="buttonAdjustDiv">
        <button
          mat-flat-button
          class="btnAdjust"
          (click)="addNegativeAdjustment(negativAdjust.form.invalid)"
        >
          <span>{{ "PAYROLL.ADJUST" | translate }}</span>
        </button>
      </div>
    </mat-card-content>
  </form>
</mat-card>
<mat-card class="info-section">
  <p>
    {{ "PAYROLL.DRIVER_NAME" | translate }} {{ truck?.driver?.firstname }}
    {{ truck?.driver?.lastname }}
  </p>
  <p>{{ "PAYROLL.TRUCK_NUMBER" | translate }} {{ truck?.truckNumber }}</p>
  <p>
    {{ "PAYROLL.PAYROLL_TYPE" | translate }} :
    {{
      "PAYROLL_TYPE_NAME." + truck?.driver?.payrollSettings?.type?.name
        | translate
    }}
  </p>
  <p *ngIf="truck?.driver?.payrollSettings?.type?.name != 'Per_Mile'">
    {{ "DRIVERS.PERCENTAGE" | translate }}:
    {{ truck?.driver?.payrollSettings?.percentage }}%
  </p>
  <p *ngIf="truck?.driver?.payrollSettings?.type?.name == 'Per_Mile'">
    {{ "DRIVERS.PER_MILE_L" | translate }}:
    {{ truck?.driver?.payrollSettings?.perMileLoaded }}
  </p>

  <p *ngIf="truck?.driver?.payrollSettings?.type?.name == 'Per_Mile'">
    {{ "DRIVERS.PER_MILE_D" | translate }}:
    {{ truck?.driver?.payrollSettings?.perMileDeadhead }}
  </p>

  <p *ngIf="truck?.driver?.payrollSettings?.type?.name == 'Per_Mile'">
    {{ "DRIVERS.PER_STOP" | translate }}:
    {{ truck?.driver?.payrollSettings?.perStop }}
  </p>

  <p *ngIf="truck?.driver?.payrollSettings?.type?.name == 'Per_Mile'">
    {{ "DRIVERS.OTHER" | translate }}:
    {{ truck?.driver?.payrollSettings?.other }}
  </p>
  <p>{{ "PAYROLL.ESCROW_BALANCE" | translate }} {{ escrow | currency }}</p>
  <p>{{ "PAYROLL.LOAN_BALANCE" | translate }} {{ totalLoan | currency }}</p>
</mat-card>

<div class="wrapperTable">
  <div class="loanTable">
    <h2 class="payroll">
      {{ "PAYROLL.LOAN_PERAYMENT_ADJUSTMENTS" | translate }}
    </h2>
  </div>
  <button mat-icon-button class="refresh">
    <mat-icon
      matTooltip="{{ 'MANAGE.REFRESH' | translate }}"
      matTooltipPosition="below"
      (click)="refresh()"
      >refresh
    </mat-icon>
  </button>
  <button mat-flat-button class="btnDialog" (click)="downloadPdf()">
    {{ "PAYROLL.DOWNLOAD_PDF" | translate }}
  </button>
  <div class="scrollDiv">
    <table
      mat-table
      matSort
      matSortDisableClear
      [dataSource]="dataSource"
      class="mat-elevation-z8"
      id="mat-table"
    >
      <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "PAYROLL.DATE" | translate }}
        </th>
        <td mat-cell *matCellDef="let loan">
          {{ loan.date | tenantDate | date : "MM/dd/y " }}
        </td>
        <td mat-footer-cell *matFooterCellDef>
          {{ "PAYROLL.SUM" | translate }}
        </td>
      </ng-container>
      <ng-container matColumnDef="userName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "PAYROLL.CHANGE_BY" | translate }}
        </th>
        <td mat-cell *matCellDef="let loan">{{ loan.userName }}</td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>
      <ng-container matColumnDef="reason">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "PAYROLL.REASON" | translate }}
        </th>
        <td mat-cell *matCellDef="let loan">{{ loan.reason }}</td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>
      <ng-container matColumnDef="adjustment">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="alignRight"
        >
          {{ "PAYROLL.ADJUSTMENT" | translate }}
        </th>
        <td mat-cell *matCellDef="let loan" class="alignRight pr-100">
          {{ loan.adjustment | currency }}
        </td>
        <td mat-footer-cell *matFooterCellDef class="alignRight pr-100">
          {{ totalAdjustment | currency }}
        </td>
      </ng-container>
      <ng-container matColumnDef="delete">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let loan" class="alignCenter">
          <button
            mat-flat-button
            class="btnDelete"
            color="warn"
            (click)="deleteLoan(loan)"
          >
            <span>{{ "PAYROLL.DELETED" | translate }}</span>
          </button>
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>

      <ng-container matColumnDef="star" class="more_vert">
        <th mat-header-cell *matHeaderCellDef class="more_vert"></th>
        <td mat-cell *matCellDef="let loan" class="more_vert">
          <button mat-icon-button [matMenuTriggerFor]="menu" class="more_vert">
            <mat-icon class="more_vert"
              >more_vert
              <mat-menu #menu="matMenu">
                <button mat-menu-item (click)="deleteLoan(loan)">
                  <mat-icon color="warn">delete</mat-icon>
                  <span class="delete">{{
                    "PAYROLL.DELETED" | translate
                  }}</span>
                </button>
              </mat-menu>
            </mat-icon>
          </button>
        </td>
        <td mat-footer-cell *matFooterCellDef class="more_vert"></td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      <tr
        mat-footer-row
        *matFooterRowDef="displayedColumns"
        class="table-footer"
      ></tr>
    </table>
  </div>
</div>
<div class="footer">
  <div class="footer-button-div">
  <h3>{{ "PAYROLL.TOTALPAY" | translate }} {{ total | currency }}</h3>
  <button
    mat-button
    class="footer-btn"
    routerLink="{{
      '/payrolls/truck/' + truckId + '/driver/' + driverId + '/escrow'
    }}"
  >
    {{ "PAYROLL.NEXT" | translate }}
  </button>
</div>
</div>
