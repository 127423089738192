import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-add-dialog',
  templateUrl: './confirm-add-dialog.component.html',
  styleUrls: ['./confirm-add-dialog.component.scss'],
})
export class ConfirmAddDialogComponent implements OnInit {
  key: string;
  value: string;
  constructor(
    private dialogRef: MatDialogRef<ConfirmAddDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {}
  ngOnInit(): void {
    this.key = this.data?.key;
    this.value = this.data?.value;
  }

  confirmAddition() {
    this.dialogRef.close(true);
  }
}
