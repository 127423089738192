import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, firstValueFrom, lastValueFrom } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CodeModel } from '../_models/code';
import { Response } from '../_models/response';

@Injectable({
  providedIn: 'root',
})
export class CodeService {
  constructor(private http: HttpClient) {}

  getStatus(): Promise<CodeModel[]> {
    return lastValueFrom(
      this.http
        .get<Response>(`${environment.apiUrl}/statuses`)
        .pipe(map((response) => response.data as CodeModel[]))
    );
  }

  getEquipmentType(): Promise<CodeModel[]> {
    return lastValueFrom(
      this.http
        .get<Response>(`${environment.apiUrl}/equipment-types`)
        .pipe(map((response) => response.data as CodeModel[]))
    );
  }

  getPayrollType(): Promise<CodeModel[]> {
    return lastValueFrom(
      this.http
        .get<Response>(`${environment.apiUrl}/payrolls/types`)
        .pipe(map((response) => response.data as CodeModel[]))
    );
  }

  getHaulType(): Promise<CodeModel[]> {
    return lastValueFrom(
      this.http
        .get<Response>(`${environment.apiUrl}/haul-types`)
        .pipe(map((response) => response.data as CodeModel[]))
    );
  }

  async getAccountingTypes(): Promise<CodeModel[]> {
    const accountingTypes = this.http
      .get<Response>(`${environment.apiUrl}/accountings/types`)
      .pipe(map((response) => response.data as CodeModel[]));
    return await firstValueFrom(accountingTypes);
  }

  addAccountingType(type: CodeModel) {
    return this.http
      .post<Response>(`${environment.apiUrl}/accountings/types`, type)
      .pipe(map((response) => response.data as CodeModel));
  }

  async getAccountingCategories(): Promise<CodeModel[]> {
    const accountingCategories = this.http
      .get<Response>(`${environment.apiUrl}/accountings/categories`)
      .pipe(map((response) => response.data as CodeModel[]));
    return await firstValueFrom(accountingCategories);
  }

  addAccountingCategory(category: CodeModel) {
    return this.http
      .post<Response>(`${environment.apiUrl}/accountings/categories`, category)
      .pipe(map((response) => response.data as CodeModel));
  }

  async getAccountingStatuses(): Promise<CodeModel[]> {
    const accountingStatuses = this.http
      .get<Response>(`${environment.apiUrl}/accountings/statuses`)
      .pipe(map((response) => response.data as CodeModel[]));
    return await firstValueFrom(accountingStatuses);
  }

  addAccountingStatus(status: CodeModel) {
    return this.http
      .post<Response>(`${environment.apiUrl}/accountings/statuses`, status)
      .pipe(map((response) => response.data as CodeModel));
  }

  getTimezones(): Observable<CodeModel[]> {
    return this.http
      .get<Response>(`${environment.apiUrl}/tenant/timezones`)
      .pipe(map((response) => response.data as CodeModel[]));
  }
}
