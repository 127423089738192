import { Injectable } from '@angular/core';
import { UserRole } from '../../_models/userRole';
import { UserRoleService } from '../userRole.service';

@Injectable()
export class RoleFactory {
  private userRoleList: UserRole[];

  constructor(private userRoleService: UserRoleService) {}

  public async getEquipmentTypeList(): Promise<UserRole[]> {
    if (!this.userRoleList) {
      this.userRoleList = await this.userRoleService.getUserRoles();
    }
    return this.userRoleList;
  }

  public clearRoles() {
    this.userRoleList = null;
  }
}
