import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, MatSortable } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { first } from 'rxjs';
import { Paginator } from 'src/app/_models/common/paginator';
import { SortData } from 'src/app/_models/common/sortData';
import { DriverModel } from 'src/app/_models/driver/driver';
import { TableType } from 'src/app/_models/enums/tableType';
import { LocalStorageService } from 'src/app/_services/local.storage.service';
import { LocalStorageSortService } from 'src/app/_services/local.storage.sort.service';

import { DriverApplicationsService } from '../_services/driver-applications.service';

@Component({
  selector: 'app-driver-app-list',
  templateUrl: './driver-app-list.component.html',
  styleUrls: ['./driver-app-list.component.scss'],
})
export class DriverAppListComponent implements OnInit, AfterViewInit {
  totalSize = 0;
  public count;
  pageSizeOptions: number[];
  pageSize;
  currentPage;
  searchTerm = '';
  sortData: SortData;
  showInactive = false;
  driversData: Array<DriverModel>;
  public infoKey: any;

  @ViewChild(MatPaginator) matPaginator: MatPaginator;
  @ViewChild(MatSort) matSort: MatSort;

  constructor(
    private driverAppService: DriverApplicationsService,
    private localStorageService: LocalStorageService,
    private localStorageSortService: LocalStorageSortService,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.dataSource.paginator = this.matPaginator;
    this.initPaginatorOptions();
    this.sortData = this.localStorageSortService.getSortData(
      TableType.DriverApplications
    );
    this.getDriverApplications();
  }

  dataSource = new MatTableDataSource<DriverModel>();

  ngAfterViewInit() {
    setTimeout(() => {
      this.matSort?.sort({
        id: this.sortData.activeColumn,
        start: this.sortData.order,
      } as MatSortable);
      this.dataSource.sort = this.matSort;
    });

    this.dataSource.sortingDataAccessor = (item, property) => {
      this.localStorageSortService.setSortData(
        TableType.DriverApplications,
        this.matSort
      );
      return item[property];
    };
    this.matSort?.sortChange.subscribe(() => {
      this.refresh();
      this.currentPage = 0;
    });
  }

  getDriverApplications() {
    this.driverAppService
      .getDriverApplications(this.getPaginator())
      .pipe(first())
      .subscribe((response) => {
        this.dataSource.data = response.collection;
        this.driversData = response.collection;
        this.count = response.count;
        setTimeout(() => {
          this.matPaginator.length = response.count;
          this.matPaginator.pageIndex = this.currentPage;
        });
      });
  }

  private getPaginator(): Paginator {
    const paginator = new Paginator();
    paginator.pageSize = this.pageSize;
    paginator.pageNumber = this.currentPage + 1;
    paginator.searchTerm = this.searchTerm;
    paginator.sortBy = this.matSort?.active;
    paginator.sortOrder = this.matSort?.direction;
    this.localStorageSortService.setSortData(
      TableType.DriverApplications,
      this.matSort
    );
    return paginator;
  }

  refresh() {
    this.searchTerm = '';
    this.getDriverApplications();
  }

  initPaginatorOptions() {
    this.pageSize = this.localStorageService.getPagginatorPageSize();
    this.pageSizeOptions = [10, 25, 50, 100];
    this.currentPage = 0;
  }

  onChangePage(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    this.localStorageService.setPagginatorPageSize(event.pageSize);
    this.getDriverApplications();
  }

  displayedColumns: string[] = [
    'firstName',
    'lastName',
    'address',
    'phoneNumber',
    'email',
  ];
}
