<div class="container">
  <label class="custom_file_upload" *ngIf="!imageUrl">
    <input type="file" (change)="handleFileInput($event.target.files)" />
    <em class="fas fa-cloud-upload-alt center_upload1"></em>
    <mat-icon
      class="center_upload1"
      aria-hidden="false"
      aria-label="Example upload icon"
      >upload</mat-icon
    >
    <strong class="center_upload2">{{
      "UPLOAD_LOGO.UPLOAD_LOGO" | translate
    }}</strong>
  </label>

  <div class="myDIV">
    <img *ngIf="imageUrl" [src]="imageUrl" class="image" />
  </div>

  <label *ngIf="imageUrl" class="center_upload_Hide">
    <input type="file" (change)="handleFileInput($event.target.files)" />

    <mat-icon
      class="centered"
      aria-hidden="false"
      aria-label="Example upload icon"
      >monochrome_photos</mat-icon
    >
  </label>
</div>
