import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, HostListener } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map, shareReplay, take } from 'rxjs/operators';
import {
  TenantService,
  ToastrTranslateService,
  UserService,
} from 'src/app/_services';
import { LocalStorageService } from 'src/app/_services/local.storage.service';
import { UserDataService } from 'src/app/_services/user.data.service';
import { QuickLoadDialogComponent } from 'src/app/loads/quick-load-dialog/quick-load-dialog.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-main-nav',
  templateUrl: './main-nav.component.html',
  styleUrls: ['./main-nav.component.scss'],
})
export class MainNavComponent {
  title = 'MyTruckBoard';
  isMenuOpen = true;
  isButtonVisible = false;
  contentFit: number;
  scrWidth: any;
  smallWidth = 960;
  contentFitMenuOpen = 180;
  contentFitMenuClosed = 60;
  mainLogo: any;
  searchTerm = '';
  appVersion = '';

  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(
      map((result) => result.matches),
      shareReplay()
    );
  constructor(
    private breakpointObserver: BreakpointObserver,
    private userService: UserService,
    private userDataService: UserDataService,
    public auth: AuthService,
    public translate: TranslateService,
    private tenantService: TenantService,
    private toastrTranslateService: ToastrTranslateService,
    private sanitizer: DomSanitizer,
    private localStorageService: LocalStorageService,
    public dialog: MatDialog,
    public router: Router
  ) {
    translate.addLangs(['en', 'fr', 'es']);
    translate.setDefaultLang(localStorage.getItem('lang') || 'en');
    translate.use(localStorage.getItem('lang') || 'en');

    this.getScreenSize();
    this.isMenuOpen = JSON.parse(localStorage.getItem('nav') || 'true');
    if (this.isMenuOpen === true) {
      this.contentFit = this.contentFitMenuOpen;
    } else {
      this.contentFit = this.contentFitMenuClosed;
    }
    auth.isAuthenticated$.subscribe((isAuthenticated) => {
      if (isAuthenticated) {
        this.getMainLogo();
        this.setTimeZone();
        this.setCurentUser();
      }
    });
  }

  ngOnInit(): void {
    this.setAppVersion();
  }

  setAppVersion() {
    this.appVersion = 'v ' + environment.appVersion;
  }

  getMainLogo() {
    this.tenantService.getMainLogo().subscribe({
      next: (response) => {
        let objectURL = URL.createObjectURL(response);
        this.mainLogo = this.sanitizer.bypassSecurityTrustUrl(objectURL);
      },
      error: (response) => {
        this.toastrTranslateService.error(response, 'TENANT.FAILED');
      },
    });
  }

  setCurentUser() {
    this.auth.user$.pipe(take(1)).subscribe((user) => {
      this.localStorageService.setCurrentUser(user?.email);
      this.getCurentUser(user.email);
    });
  }

  getCurentUser(email: string) {
    this.userService.getByEmail(email).subscribe({
      next: (response) => {
        if (response?.collection?.length > 0)
          this.userDataService.setCurentUser(response.collection[0]);
      },
      error: (response) => {
        this.toastrTranslateService.error(response, 'ADD_USER.FAILED');
        this.logout();
      },
    });
  }

  setTimeZone() {
    this.tenantService.getSetTimeZone(true);
  }

  changeLanguage(lang: string): void {
    localStorage.setItem('lang', lang);
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(): void {
    this.scrWidth = window.innerWidth;
    if (this.scrWidth < this.smallWidth) {
      this.isMenuOpen = false;
      this.contentFit = this.contentFitMenuClosed;
      this.isButtonVisible = false;
      localStorage.setItem('nav', this.isMenuOpen.toString());
    } else {
      this.isButtonVisible = true;
    }
  }
  onMenuToggle(): void {
    this.isMenuOpen = !this.isMenuOpen;
    localStorage.setItem('nav', this.isMenuOpen.toString());
    if (this.isMenuOpen) {
      this.contentFit = this.contentFitMenuOpen;
    } else {
      this.contentFit = this.contentFitMenuClosed;
    }
  }

  loadSearch(searchTerm: string) {
    this.searchTerm = '';
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigateByUrl('/home/ALL/false/' + searchTerm);
    });
  }

  openDialogQuickLoad() {
    const dialogRef = this.dialog.open(QuickLoadDialogComponent, {
      disableClose: true,
      position: { top: '50px' },
    });

    dialogRef.afterClosed().subscribe(() => {});
  }

  logout(): void {
    this.userService.logout();
    this.auth.logout({
      logoutParams: {
        returnTo: `${environment.auth0.redirectUri}`,
      },
    });
  }
}
