import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-delete-escrow-dialog',
  templateUrl: './confirm-delete-escrow-dialog.component.html',
  styleUrls: ['./confirm-delete-escrow-dialog.component.scss'],
})
export class ConfirmDeleteEscrowDialogComponent implements OnInit {
  constructor(
    private dialogRef: MatDialogRef<ConfirmDeleteEscrowDialogComponent>
  ) {}

  ngOnInit(): void {}
  confirm() {
    this.dialogRef.close(true);
  }
}
