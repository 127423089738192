<div>
  <nav mat-tab-nav-bar class="navBarManage">
    <a mat-tab-link routerLink="analytics-truck" routerLinkActive="active">
      {{ "ANALYTICS.TRUCK_ANALYTICS" | translate }}
      <span class="margin-left"></span>
    </a>

    <a mat-tab-link routerLink="analytics-customer" routerLinkActive="active">
      {{ "ANALYTICS.CUSTOMER_ANALYTICS" | translate }}
      <span class="margin-left"></span>
    </a>
  </nav>
  <router-outlet></router-outlet>
</div>
