import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ComfirmDialogComponent } from 'src/app/manage/trucks/edit-delete-truck/comfirm-dialog/comfirm-dialog.component';
import { LoadModel } from 'src/app/_models/load/load';

@Component({
  selector: 'app-add-additional-cost-dialog',
  templateUrl: './add-additional-cost-dialog.component.html',
  styleUrls: ['./add-additional-cost-dialog.component.scss'],
})
export class AddAdditionalCostDialogComponent implements OnInit {
  loadData: LoadModel = new LoadModel();

  constructor(private dialogRef: MatDialogRef<ComfirmDialogComponent>) {}

  ngOnInit(): void {}

  confirmAddition() {
    this.dialogRef.close(true);
  }
}
