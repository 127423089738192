<div class="upload">
  <label class="custom_file_upload">
    <input type="file" (change)="changeListener($event)" />
    <i class="fas fa-cloud-upload-alt center_upload1"></i>
    <mat-icon
      class="center_upload1"
      aria-hidden="false"
      aria-label="Example upload icon"
      >upload</mat-icon
    >
    <b class="center_upload2">{{ "NEW_LOAD.UPLOAD_FILE" | translate }}</b>
  </label>
  <ngx-file-drop multiple (onFileDrop)="dropped($event)" class="drag">
    <ng-template
      ngx-file-drop-content-tmp
      let-openFileSelector="openFileSelector"
    >
      <span *ngIf="!uploading">
        {{ "NEW_LOAD.UPLOAD_MANUALLY" | translate }}:
        {{ "NEW_LOAD.DROP_FILE" | translate }}
      </span>
      <span *ngIf="uploading"> {{ "NEW_LOAD.UPLOADING" | translate }} </span>
    </ng-template>
  </ngx-file-drop>
</div>
<div *ngIf="isExist" class="bigCard">
  <ngx-extended-pdf-viewer
    backgroundColor="#f4f7fa"
    useBrowserLocale="true"
    width="650px"
    height="90vh"
    [base64Src]="base64"
    [handTool]="false"
  >
  </ngx-extended-pdf-viewer>
</div>
