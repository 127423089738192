import { Location } from './location/location';
import { PrimaryContact } from './primaryContact';
import { UserModel } from './user';
export class CustomerModel extends UserModel {
  name: string;
  billingAddress: Location;
  billingAddressId: number;
  isBillingSameAsAddress: boolean;
  primaryContact = new PrimaryContact();
  ext: string;
  billingEmail: string;
  mc: string;
  ff: string;
  otherPhoneExt: string;
  otherNumber: string;
  isBlacklisted: boolean;
  isBroker: boolean;
  creditLimit: number;
  federalId: string;
  notes: string;
  addresses: Location[];
}
