<div class="wrapperTable" id="wrapperManageTables">
  <div class="datePickersDiv">
    <div class="refreshDiv">
      <button mat-icon-button class="refresh" (click)="refresh()">
        <mat-icon
          matTooltip="{{ 'MANAGE.REFRESH' | translate }}"
          matTooltipPosition="below"
          >refresh</mat-icon
        >
      </button>
    </div>
    <mat-form-field class="filterField">
      <input
        matInput
        (blur)="searchCustomers($event.target.value)"
        (keydown.enter)="searchCustomers($event.target.value)"
        autocomplete="off"
        [value]="searchTerm"
        placeholder="{{ 'CUSTOMER.FILTER' | translate }}"
        class="filterInput"
      />
      <mat-icon matPrefix class="filterIcon">search</mat-icon>
    </mat-form-field>
    <mat-form-field class="matDatepickerStart" appearance="outline">
      <input
        matInput
        onfocus="this.select()"
        [matDatepicker]="startDatePicker"
        (dateInput)="refresh()"
        placeholder="Start Date"
        [(ngModel)]="startDate"
      />
      <mat-label>{{ "ANALYTICS.START_DATE" | translate }}</mat-label>

      <mat-datepicker-toggle
        matSuffix
        [for]="startDatePicker"
      ></mat-datepicker-toggle>
      <mat-datepicker #startDatePicker></mat-datepicker>
    </mat-form-field>

    <mat-form-field class="matDatepickerEnd" appearance="outline">
      <input
        matInput
        onfocus="this.select()"
        (dateInput)="refresh()"
        [matDatepicker]="endDatePicker"
        placeholder="End Date"
        [min]="startDate"
        [(ngModel)]="endDate"
      /><mat-label>{{ "ANALYTICS.END_DATE" | translate }}</mat-label>
      <mat-datepicker-toggle
        matSuffix
        [for]="endDatePicker"
      ></mat-datepicker-toggle>
      <mat-datepicker #endDatePicker></mat-datepicker>
    </mat-form-field>
  </div>

  <table
    mat-table
    matSort
    matSortDisableClear
    [dataSource]="dataSource"
    class="mat-elevation-z8"
    [hidden]="count == 0"
  >
    <ng-container matColumnDef="customerName">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>
        {{ "ANALYTICS.CUSTOMER" | translate }}
      </th>
      <td mat-cell *matCellDef="let customerData">
        {{ customerData.customerName }}
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="totalSales">
      <th
        mat-header-cell
        mat-sort-header
        *matHeaderCellDef
        class="header-right"
        arrowPosition="before"
      >
        {{ "ANALYTICS.TOTAL_SALES" | translate }}
      </th>
      <td mat-cell *matCellDef="let customerData" class="rightAlign">
        {{ customerData.totalSales | currency }}
      </td>
      <td mat-footer-cell *matFooterCellDef class="rightAlign">
        {{ sumTotalSales | currency }}
      </td>
    </ng-container>

    <ng-container matColumnDef="avgInvoiceAge">
      <th
        mat-header-cell
        mat-sort-header
        *matHeaderCellDef
        class="header-right"
        arrowPosition="before"
      >
        {{ "ANALYTICS.AVG_INV_AGE" | translate }}
      </th>
      <td mat-cell *matCellDef="let customerData" class="rightAlign">
        {{ customerData.avgInvoiceAge | number : "1.0-0" }}
      </td>
      <td mat-footer-cell *matFooterCellDef class="rightAlign"></td>
    </ng-container>

    <ng-container matColumnDef="openInvoices">
      <th
        mat-header-cell
        mat-sort-header
        *matHeaderCellDef
        class="header-right"
        arrowPosition="before"
      >
        {{ "ANALYTICS.OPEN_INVOICES" | translate }}
      </th>
      <td mat-cell *matCellDef="let customerData" class="rightAlign">
        {{ customerData.openInvoices }}
      </td>
      <td mat-footer-cell *matFooterCellDef class="rightAlign">
        {{ sumOpenInvoices | number }}
      </td>
    </ng-container>

    <ng-container matColumnDef="paidInvoices">
      <th
        mat-header-cell
        mat-sort-header
        *matHeaderCellDef
        class="header-right"
        arrowPosition="before"
      >
        Paid Invoices
      </th>
      <td mat-cell *matCellDef="let customerData" class="rightAlign">
        {{ customerData.paidInvoices }}
      </td>
      <td mat-footer-cell *matFooterCellDef class="rightAlign">
        {{ sumPaidInvoices | number }}
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    <tr
      mat-footer-row
      *matFooterRowDef="displayedColumns"
      class="table-footer"
    ></tr>
  </table>
  <div class="divPaginator" [hidden]="count == 0">
    <mat-paginator
      (page)="onChangePage($event)"
      class="paging"
      aria-label="Select page"
      [length]="totalSize"
      [showFirstLastButtons]="true"
      [pageIndex]="currentPage"
      [pageSize]="pageSize"
      [pageSizeOptions]="pageSizeOptions"
    >
    </mat-paginator>
  </div>
  <app-info-nodata
    *ngIf="count == 0"
    [infoKey]="'ANALYTICS.NO_CUSTOMER_ANALYTICS'"
    class="no-data"
  ></app-info-nodata>
</div>
