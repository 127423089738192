import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { saveAs } from 'file-saver-es';
import { CodeModel } from 'src/app/_models/code';
import { LoadModel } from 'src/app/_models/load/load';
import { LoadDocument } from 'src/app/_models/load/loadDocument';
import { LoadService, ToastrTranslateService } from 'src/app/_services';
import { CodeFactory } from 'src/app/_services/factory/code.factory.service';

@Component({
  selector: 'app-download-rc',
  templateUrl: './download-rc.component.html',
  styleUrls: ['./download-rc.component.scss'],
})
export class DownloadRcComponent implements OnInit {
  loadId: number;
  loadNumber: string;
  documentList: Array<LoadDocument>;
  loading = false;
  isStatusChanged = true;
  isStatusChangeReady = false;
  loadData: LoadModel = new LoadModel();
  statusTypeList: CodeModel[] = [];

  constructor(
    private loadService: LoadService,
    private toastrTranslateService: ToastrTranslateService,
    private codeFactory: CodeFactory,
    private dialogRef: MatDialogRef<DownloadRcComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {
    this.codeFactory.getStatusList().then((data) => {
      this.statusTypeList = data;
    });
  }

  ngOnInit(): void {
    this.loadId = this.data.loadId;
    this.documentList = this.data.documentList;
    this.loadNumber = this.data.loadNumber;
    this.checkInvoiceStatusChangeState();
  }

  checkInvoiceStatusChangeState() {
    if (
      (this.data.statusName.toUpperCase() == 'DELIVERED' ||
        this.data.statusName.toUpperCase() == 'COMPLETED') &&
      this.documentList.length > 0
    ) {
      this.isStatusChangeReady = true;
      this.isStatusChanged = true;
    } else {
      this.isStatusChangeReady = false;
      this.isStatusChanged = false;
    }
  }

  async downloadPdf(documentId, index) {
    try {
      this.loading = true;
      const data = await this.loadService.getDocument(documentId);
      const blob = new Blob([data], {
        type: 'application/pdf',
      });

      const fileName = this.documentList[index].fileNameWithExtension;
      saveAs(blob, fileName);
      this.loading = false;
    } catch (error) {
      this.loading = false;
      this.toastrTranslateService.error(error, 'COMMON.ERROR_DATA');
    }
  }

  toBool(boolVariable: any) {
    if (boolVariable && boolVariable != 'undefined') {
      return Boolean(JSON.parse(boolVariable));
    }
    return false;
  }

  updateLoad() {
    this.loadService.updateLoad(this.loadData).subscribe({
      next: (response) => {
        if (response) {
          this.toastrTranslateService.success(
            'CONFIRM_DIALOG.SUCCESS_CHANGE_STATUS',
            this.loadData.loadNumber
          );
          this.dialogRef.close({ success: response });
        } else {
          this.toastrTranslateService.error(
            'CONFIRM_DIALOG.FAILED_CHANGE_STATUS'
          );
        }
      },
      error: (response) => {
        this.toastrTranslateService.error(
          response,
          'CONFIRM_DIALOG.FAILED_CHANGE_STATUS'
        );
      },
    });
  }

  async downloadAll() {
    try {
      this.loading = true;
      const loadIdList = Array<number>();
      loadIdList.push(this.loadId);
      const data = await this.loadService.getAllDocuments(loadIdList);
      const blob = new Blob([data], {
        type: 'application/pdf',
      });
      const date = new Date();
      const fileName =
        this.loadNumber +
        '_' +
        date.getFullYear() +
        '_' +
        ('0' + (date.getMonth() + 1)).slice(-2) +
        '_' +
        ('0' + date.getDate()).slice(-2) +
        '.pdf';
      saveAs(blob, fileName);
      if (this.isStatusChanged && this.isStatusChangeReady) {
        this.loadService.getLoadById(this.data.loadId).subscribe((response) => {
          this.loadData = response;
          this.loadData.status = this.statusTypeList.find(
            (x) => x.name.toUpperCase() == 'INVOICED'
          );
          this.loadData.statusId = this.loadData.status.id;
          this.updateLoad();
        });
      } else {
        this.dialogRef.close({ success: true });
      }
      this.loading = false;
    } catch (error) {
      this.loading = false;
      this.toastrTranslateService.error(error, 'COMMON.ERROR_DATA');
    }
  }
}
