import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastrTranslateService } from 'src/app/_services';
import { TrailerService } from '../../../../../app/_services/trailer.service';

@Component({
  selector: 'app-comfirm-dialog-trailer',
  templateUrl: './comfirm-dialog-trailer.component.html',
  styleUrls: ['./comfirm-dialog-trailer.component.scss'],
})
export class ComfirmDialogTrailerComponent implements OnInit {
  constructor(
    private trailerService: TrailerService,
    private dialogRef: MatDialogRef<ComfirmDialogTrailerComponent>,
    private toastrTranslateService: ToastrTranslateService,
    @Inject(MAT_DIALOG_DATA) private trailerId: number
  ) {}

  ngOnInit(): void {}

  deleteTrailer() {
    this.trailerService.deleteTrailer(this.trailerId).subscribe({
      next: (response) => {
        if (response.success) {
          this.toastrTranslateService.success('CONFIRM_DIALOG.SUCCESS_DELL');
          this.dialogRef.close({ success: response.success });
        } else {
          this.toastrTranslateService.error(
            response.message,
            'CONFIRM_DIALOG.FAILED_DELL'
          );
        }
      },
      error: (response) => {
        this.toastrTranslateService.error(
          response,
          'CONFIRM_DIALOG.FAILED_DELL'
        );
      },
    });
  }
}
