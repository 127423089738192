import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastrTranslateService } from 'src/app/_services';
import { AccountingService } from 'src/app/_services/accounting.service';

@Component({
  selector: 'app-comfirm-delete-dialog',
  templateUrl: './comfirm-delete-dialog.component.html',
  styleUrls: ['./comfirm-delete-dialog.component.scss'],
})
export class ComfirmDeleteDialogComponent {
  constructor(
    private accountingService: AccountingService,
    private dialogRef: MatDialogRef<ComfirmDeleteDialogComponent>,
    private toastrTranslateService: ToastrTranslateService,
    @Inject(MAT_DIALOG_DATA) private accountingId: number
  ) {}

  deleteAccounting() {
    this.accountingService.deleteAccounting(this.accountingId).subscribe({
      next: (response) => {
        if (response.success) {
          this.toastrTranslateService.success('CONFIRM_DIALOG.SUCCESS_DELL');
          this.dialogRef.close({ success: response.success });
        } else {
          this.toastrTranslateService.error(
            response.message,
            'CONFIRM_DIALOG.FAILED_DELL'
          );
        }
      },
      error: (response) => {
        this.toastrTranslateService.error(
          response,
          'CONFIRM_DIALOG.FAILED_DELL'
        );
      },
    });
  }
}
