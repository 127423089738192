<!--TODO: style-->
<h3 *ngIf="!isLoadSelected" class="header">
  {{ "PAYROLL.NO_SELECTED_LOAD" | translate }}
</h3>
<span *ngIf="isLoadSelected">
  <span *ngIf="showFilters">
    <br />
    <mat-slide-toggle
      class="slideToggle"
      color="primary"
      [(ngModel)]="includeEscrow"
      (change)="updatePdf()"
      >{{ "PAYROLL.INCLUDE_ESCROW" | translate }}</mat-slide-toggle
    >

    <mat-slide-toggle
      class="slideToggle"
      color="primary"
      [(ngModel)]="includeLoan"
      (change)="updatePdf()"
      >{{ "PAYROLL.INCLUDE_LOAN" | translate }}</mat-slide-toggle
    >
    <br />
  </span>

  <div class="pdfViewer">
    <ngx-extended-pdf-viewer
      backgroundColor="#f4f7fa"
      useBrowserLocale="true"
      width="650px"
      height="90vh"
      [filenameForDownload]="fileName"
      [src]="data"
    >
    </ngx-extended-pdf-viewer>
  </div>
  <div class="footer">
    <div class="footer-button-div">
      <button
      mat-button
      class="footer-btn"
      (click)="submitPayrollCalculation()"
      [disabled]="disabledSubmit"
    >
      {{ "PAYROLL.SUBMIT" | translate }}
    </button>
    </div>
    
  </div>
</span>
