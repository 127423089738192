import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom, lastValueFrom, of, tap } from 'rxjs';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/internal/operators/map';
import { environment } from 'src/environments/environment';
import Common from '../_helpers/common';
import { PayrollAmountImpact } from '../_models/_payroll/payroll-amount-impact';
import {
  CalcTotalResponse,
  PayrollCalculation,
  PayrollCalculationCreateRequest,
} from '../_models/_payroll/payroll-calculation';
import { PayrollTabData } from '../_models/_payroll/payroll-tab-data';
import { PrintPayrollRequest } from '../_models/_payroll/printPayrollRequest';
import { UserTruckPayrollAmountImpact } from '../_models/_payroll/user-truck-payroll-amount-impact';
import { Paginator } from '../_models/common/paginator';
import { Response } from '../_models/response';
import { ResponseData } from '../_models/responseData';
import { PayrollFactory } from './factory/payroll.factory.service';
import { TenantService } from './tenant.service';

@Injectable({ providedIn: 'root' })
export class PayrollService {
  constructor(
    private http: HttpClient,
    private tenantService: TenantService,
    private payrollFactory: PayrollFactory
  ) {}

  getPayrollTabData(
    driverId: number,
    truckId: number,
    dateFrom: Date,
    dateTo: Date
  ): Observable<PayrollTabData> {
    const params = new HttpParams({
      fromObject: {
        driverId: driverId.toString(),
        truckId: truckId.toString(),
        dateFrom: this.tenantService.getTenetUtcDateString(dateFrom),
        dateTo: this.tenantService.getTenetUtcDateString(
          Common.getDateEndOfDay(new Date(dateTo))
        ),
      },
    });
    return this.http
      .get<Response>(`${environment.apiUrl}/payrolls/calculation/tab-data`, {
        params: params,
      })
      .pipe(map((response) => response.data as PayrollTabData));
  }

  getPayrollCalculationTotalSum(
    req: PayrollCalculationCreateRequest
  ): Observable<CalcTotalResponse> {
    req = this.payrollFactory.getPayrollCalculationCreateRequestUtcDate(req);
    return this.http
      .post<Response>(`${environment.apiUrl}/payrolls/calc-total`, req)
      .pipe(map((response) => response.data as CalcTotalResponse));
  }

  getPrevousPayrollsForDriverAndTruck(
    driverId: number,
    truckId: number,
    start: Date = null,
    end: Date = null
  ): Observable<PayrollCalculation[]> {
    const startString = this.tenantService.getTenetUtcDateString(start);
    const endString = this.tenantService.getTenetUtcDateString(
      Common.getDateEndOfDay(new Date(end))
    );
    let endpoint = `${environment.apiUrl}/payrolls/calculation/driver/${driverId}/truck/${truckId}`;
    if (start && end) {
      endpoint = `${endpoint}?startDate=${startString}&endDate=${endString}`;
    }
    return this.http
      .get<Response>(endpoint)
      .pipe(map((response) => response.data as PayrollCalculation[]));
  }

  submitPayrollCalculation(
    req: PayrollCalculationCreateRequest
  ): Observable<PayrollCalculation> {
    // Keep until its verified
    //req = this.payrollFactory.getPayrollCalculationCreateRequestUtcDate(req);
    return this.http
      .post<Response>(`${environment.apiUrl}/payrolls/calculation`, req)
      .pipe(map((response) => response.data as PayrollCalculation))
      .pipe(
        tap((responseData) => {
          this.payrollFactory.getPayrollCalculationTimeZoneDate(responseData);
        })
      );
  }

  payrollSwitchPaid(
    id: number,
    isPaid: boolean
  ): Observable<PayrollCalculation> {
    let endpoint = 'paid';
    if (!isPaid) {
      endpoint = 'unpaid';
    }
    return this.http
      .post<Response>(
        `${environment.apiUrl}/payrolls/calculation/${id}/` + endpoint,
        {}
      )
      .pipe(map((response) => response.data as PayrollCalculation))
      .pipe(
        tap((responseData) => {
          this.payrollFactory.getPayrollCalculationTimeZoneDate(responseData);
        })
      );
  }

  setPayrollCalculationComment(
    id: number,
    comment: string
  ): Observable<PayrollCalculation> {
    return this.http
      .patch<Response>(
        `${environment.apiUrl}/payrolls/calculation/${id}/comment`,
        { comment: comment }
      )
      .pipe(map((response) => response.data as PayrollCalculation))
      .pipe(
        tap((responseData) => {
          this.payrollFactory.getPayrollCalculationTimeZoneDate(responseData);
        })
      );
  }

  deletePayrollCalculation(id: number): Observable<PayrollCalculation> {
    return this.http
      .delete<Response>(`${environment.apiUrl}/payrolls/calculation/${id}`, {})
      .pipe(map((response) => response.data as PayrollCalculation))
      .pipe(
        tap((responseData) => {
          this.payrollFactory.getPayrollCalculationTimeZoneDate(responseData);
        })
      );
  }

  createNewPayrollAmountImpact(
    req: PayrollAmountImpact
  ): Observable<PayrollAmountImpact> {
    return this.http
      .post<Response>(`${environment.apiUrl}/payroll/amount-impacts`, req)
      .pipe(map((response) => response.data as PayrollAmountImpact));
  }

  deletePayrollAmountImpact(id: number): Observable<any> {
    return this.http
      .delete<Response>(`${environment.apiUrl}/payroll/amount-impacts/${id}`)
      .pipe(map((response) => response.data as any));
  }

  createNewUserTruckPayrollAmountImpact(
    req: UserTruckPayrollAmountImpact
  ): Observable<UserTruckPayrollAmountImpact> {
    return this.http
      .post<Response>(
        `${environment.apiUrl}/payroll/amount-impacts/${req.driverId}/${req.truckId}`,
        req
      )
      .pipe(map((response) => response.data as UserTruckPayrollAmountImpact));
  }

  updateUserTruckPayrollAmountImpact(
    req: UserTruckPayrollAmountImpact
  ): Observable<UserTruckPayrollAmountImpact> {
    return this.http
      .put<Response>(
        `${environment.apiUrl}/payroll/amount-impacts/${req.id}`,
        req
      )
      .pipe(map((response) => response.data as UserTruckPayrollAmountImpact));
  }

  async getPdf(
    request: PrintPayrollRequest,
    includeEsrow = true,
    includeLoan = true
  ) {
    let headers = new HttpHeaders();
    headers = headers.append('Accept', 'application/pdf');
    let url = `${environment.apiUrl}/payrolls/pdf?includeLoan=${includeLoan}&includeEscrow=${includeEsrow}`;
    const result = await lastValueFrom(
      this.http.post(url, request, {
        headers: headers,
        responseType: 'blob',
      })
    );
    return result;
  }

  async getPdfForPayrollId(
    id: number,
    includeEsrow = true,
    includeLoan = true
  ) {
    let headers = new HttpHeaders();
    headers = headers.append('Accept', 'application/pdf');
    let url = `${environment.apiUrl}/payrolls/${id}/pdf?includeLoan=${includeLoan}&includeEscrow=${includeEsrow}`;
    const result = await lastValueFrom(
      this.http.post(url, {}, { headers: headers, responseType: 'blob' })
    );
    return result;
  }

  private currentPayrollSource =
    new BehaviorSubject<PayrollCalculationCreateRequest>(null);

  getCurrentPayroll(): PayrollCalculationCreateRequest {
    return this.currentPayrollSource.value;
  }

  changeCurrentPayroll(payroll: PayrollCalculationCreateRequest) {
    this.currentPayrollSource.next(payroll);
  }

  private currentTotalSource = new BehaviorSubject<number>(
    localStorage.getItem('total') ? +localStorage.getItem('total') : 0
  );
  curentTotal = this.currentTotalSource.asObservable();

  changeCurrentTotal(total: number) {
    localStorage.setItem('total', total.toString());
    this.currentTotalSource.next(total);
  }

  getRecentPayrollList(
    dateFrom: Date,
    dateTo: Date,
    paginator: Paginator
  ): Observable<ResponseData> {
    const dateFromString = this.tenantService.getTenetUtcDateString(dateFrom);
    const dateToString = this.tenantService.getTenetUtcDateString(
      Common.getDateEndOfDay(new Date(dateTo))
    );
    if (!dateFromString || !dateToString) {
      return of(undefined);
    }
    const params = new HttpParams({
      fromObject: {
        PageSize: '' + paginator.pageSize,
        PageNumber: '' + paginator.pageNumber,
        SortBy: '' + paginator.sortBy,
        SortOrder: '' + paginator.sortOrder,
        DateFrom: dateFromString,
        DateTo: dateToString,
        IsPaid: true,
      },
    });
    return this.http
      .get<Response>(`${environment.apiUrl}/payrolls/recent`, {
        params: params,
      })
      .pipe(map((response) => response.data as ResponseData));
  }
  async getCsv(dateFrom: Date, dateTo: Date, paginator: Paginator) {
    //Export will always return document without paging
    const params = new HttpParams({
      fromObject: {
        PageSize: 100000,
        PageNumber: 1,
        SortBy: '' + paginator.sortBy,
        SortOrder: '' + paginator.sortOrder,
        DateFrom: this.tenantService.getTenetUtcDateString(dateFrom),
        DateTo: this.tenantService.getTenetUtcDateString(
          Common.getDateEndOfDay(new Date(dateTo))
        ),
        IsPaid: true,
      },
    });
    let headers = new HttpHeaders();
    headers = headers.append('Accept', 'application/vnd.ms-excel');
    return await firstValueFrom(
      this.http.get(`${environment.apiUrl}/payrolls/recent/csv`, {
        headers: headers,
        params: params,
        responseType: 'blob',
      })
    );
  }
}
