import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import {
  debounceTime,
  distinctUntilChanged,
  first,
  startWith,
  switchMap,
  tap,
} from 'rxjs/operators';
import { CodeModel } from 'src/app/_models/code';
import { Location } from 'src/app/_models/location/location';
import { TenantModel } from 'src/app/_models/tenant';
import {
  MapService,
  TenantService,
  ToastrTranslateService,
} from 'src/app/_services';
import { UserDataService } from 'src/app/_services/user.data.service';
import { CodeService } from '../../_services/code.service';
import { ConfirmChangeTimezoneComponent } from './confirm-change-timezone/confirm-change-timezone.component';

@Component({
  selector: 'app-tenant',
  templateUrl: './tenant.component.html',
  styleUrls: ['./tenant.component.scss'],
})
export class TenantComponent implements OnInit {
  searchResult: Location[];
  searchAddress = new UntypedFormControl();
  clientId: number;
  tenant: TenantModel = new TenantModel();
  mainLogo: any;
  isLogoChanged = false;
  invoiceLogo: File;
  locationId: number;
  timezoneList: CodeModel[] = [];
  previousTimeZone: CodeModel;

  isAddressPoBox: boolean = false;
  @ViewChild('inputTenantAddress') inputTenantAddress: ElementRef;

  constructor(
    private mapService: MapService,
    private tenantService: TenantService,
    private toastrTranslateService: ToastrTranslateService,
    private codeService: CodeService,
    private sanitizer: DomSanitizer,
    public userDataService: UserDataService,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.getTimeZoneListAndTenantData();

    this.searchAddress.valueChanges
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        startWith(''),
        tap(() => {
          this.searchResult = [];
        }),
        switchMap((value) => {
          if (value != '') {
            return this.mapService.searchMap(value);
          } else {
            return (this.searchResult = []);
          }
        })
      )
      .subscribe((data) => {
        if (data && data.count != 0) {
          this.searchResult = data.collection;
        } else {
          this.searchResult = [];
        }
      });
  }

  getTimeZoneListAndTenantData() {
    this.codeService
      .getTimezones()
      .pipe(first())
      .subscribe((response) => {
        this.timezoneList = response;
        this.getTenantData();
      });
  }

  getTenantData() {
    this.tenantService.getTenant().subscribe({
      next: (response) => {
        this.tenant = response;
        this.previousTimeZone = response.timeZone;
        this.searchAddress.setValue(response.addresses[0]);
        if (
          this.tenant.addresses.length > 0 &&
          this.tenant.addresses[0].poBox
        ) {
          this.isAddressPoBox = true;
        } else {
          this.isAddressPoBox = false;
        }
      },
      error: (response) => {
        this.toastrTranslateService.error(response, 'TENANT.FAILED');
      },
    });
    this.getMainLogo();
  }

  getMainLogo() {
    this.tenantService.getMainLogo().subscribe({
      next: (response) => {
        let objectURL = URL.createObjectURL(response);
        this.mainLogo = this.sanitizer.bypassSecurityTrustUrl(objectURL);
      },
      error: (response) => {
        this.toastrTranslateService.error(response, 'TENANT.FAILED');
      },
    });
  }

  selectCompanyLocation(location: Location) {
    location.id = this.locationId;
    this.tenant.addresses[0] = location;
  }

  autofillTenantAddress() {
    setTimeout(() => {
      let inputValue =
        this.inputTenantAddress.nativeElement.value !=
        this.tenant.addresses[0].address +
          ', ' +
          this.tenant.addresses[0].countryCode;
      if (!this.tenant.addresses[0] || inputValue) {
        if (this.searchResult.length > 0) {
          this.tenant.addresses[0] = this.searchResult[0];
          this.searchAddress.setValue(this.tenant.addresses[0]);
        } else if (inputValue) {
          this.clearAddress();
          this.toastrTranslateService.warning('GENERAL.ADDRESS_ISSUE');
        }
      }
    }, 1000);
  }

  onAddressChange(changedValue: string) {
    if (changedValue == '') {
      this.tenant.addresses.length = 0;
      this.tenant.addresses.push(new Location());
    }
  }

  clearAddress() {
    this.tenant.addresses = new Array<Location>();
    this.tenant.addresses[0] = new Location();
    this.searchAddress.setValue('');
  }

  displayLoc(location: Location): string {
    return location ? location.address + ', ' + location.countryCode : '';
  }

  compareObjects(o1: any, o2: any): boolean {
    if (o1 && o2 && Object.keys(o1).length > 0 && Object.keys(o2).length > 0) {
      return o1.id === o2.id;
    }
    return false;
  }

  mainLogoUpdated(image: File) {
    this.mainLogo = image;
    this.isLogoChanged = true;
  }

  openDialogChangeTimezone() {
    const dialogRef = this.dialog.open(ConfirmChangeTimezoneComponent, {
      disableClose: true,
      position: { top: '100px' },
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (!res) {
        this.tenant.timeZone = this.previousTimeZone;
      }
    });
  }

  save() {
    this.saveTenanatData();
    if (this.isLogoChanged) {
      this.saveMainLogo();
      this.saveInvoiceLogo();
    }
  }

  saveMainLogo() {
    this.tenantService.uploadMainLogo(this.mainLogo).subscribe({
      error: (response) => {
        this.toastrTranslateService.error(response, 'saveMainLogo');
      },
    });
  }

  saveInvoiceLogo() {
    this.tenantService.uploadInvoiceLogo(this.mainLogo).subscribe({
      error: (response) => {
        this.toastrTranslateService.error(response, 'saveMainLogo');
      },
    });
  }

  saveTenanatData() {
    this.tenant.timeZoneId = this.tenant.timeZone.id;
    this.tenantService.updateTenant(this.tenant).subscribe({
      next: (response) => {
        this.tenant = response;
        this.tenantService.getSetTimeZone(true);
        this.toastrTranslateService.success('COMPANY.SAVED');
      },
      error: (response) => {
        this.toastrTranslateService.error(response, 'COMPANY.NOT_SAVED');
      },
    });
  }
}
