export class AccountingRequest {
  id: number;
  accountingTypeId: number;
  accountingStatusId: number;
  accountingCategoryId: number;
  driverId: number;
  truckId: number;
  date: Date;
  vendorId: number;
  description: string;
  amount: number;
  comment: string;
}
