import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ActiveToast, IndividualConfig, ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root',
})
export class ToastrTranslateService {
  constructor(
    private toastrService: ToastrService,
    private translateService: TranslateService
  ) {}

  show(
    message?: string,
    title?: string,
    override?: Partial<IndividualConfig>,
    type?: string
  ): ActiveToast<any> {
    return this.toastrService.show(
      this.translate(message),
      this.translate(title),
      override,
      type
    );
  }

  success(
    message?: string,
    title?: string,
    override?: Partial<IndividualConfig>
  ): ActiveToast<any> {
    return this.toastrService.success(
      this.translate(message),
      this.translate(title),
      override
    );
  }

  error(
    message?: string,
    title?: string,
    override?: Partial<IndividualConfig>
  ): ActiveToast<any> {
    return this.toastrService.error(
      this.translate(message),
      this.translate(title),
      override
    );
  }

  info(
    message?: string,
    title?: string,
    override?: Partial<IndividualConfig>
  ): ActiveToast<any> {
    return this.toastrService.info(
      this.translate(message),
      this.translate(title),
      override
    );
  }

  warning(
    message?: string,
    title?: string,
    override?: Partial<IndividualConfig>
  ): ActiveToast<any> {
    return this.toastrService.warning(
      this.translate(message),
      this.translate(title),
      override
    );
  }

  clear(toastId?: number): void {
    this.toastrService.clear(toastId);
  }

  remove(toastId: number): boolean {
    return this.toastrService.remove(toastId);
  }

  private translate(txt: string): string {
    let translatedTxt;
    txt
      ? (translatedTxt = this.translateService.instant(txt))
      : (translatedTxt = '');
    return translatedTxt;
  }
}
