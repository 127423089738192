import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import Common from '../_helpers/common';
import { Paginator } from '../_models/common/paginator';
import { CustomerModel } from '../_models/customer';
import { Response } from '../_models/response';
import { ResponseData } from '../_models/responseData';

@Injectable({ providedIn: 'root' })
export class CustomerService {
  constructor(private http: HttpClient) {}

  getCustomers(
    paginator?: Paginator,
    isActive?: boolean
  ): Observable<ResponseData> {
    const params = new HttpParams({
      fromObject: {
        PageSize: '' + paginator.pageSize,
        PageNumber: '' + paginator.pageNumber,
        SortBy: '' + paginator.sortBy,
        SortOrder: '' + paginator.sortOrder,
        GlobalSearch: '' + paginator.searchTerm,
        isActive: Common.getSafeBooleanString(isActive),
      },
    });

    return this.http
      .get<Response>(`${environment.apiUrl}/customers`, { params: params })
      .pipe(map((response) => response.data as ResponseData));
  }

  getCustomer(id: number): Observable<CustomerModel> {
    return this.http
      .get<Response>(`${environment.apiUrl}/customers/${id}`)
      .pipe(map((response) => response.data as CustomerModel));
  }
  addCustomers(customers: CustomerModel) {
    return this.http
      .post<Response>(`${environment.apiUrl}/customers`, customers)
      .pipe(map((response) => response.data as CustomerModel));
  }
  updateCustomer(customers: CustomerModel) {
    return this.http
      .put<Response>(`${environment.apiUrl}/customers`, customers)
      .pipe(map((response) => response.data as CustomerModel));
  }
  deleteCustomer(id: number) {
    return this.http.delete<Response>(`${environment.apiUrl}/customers/${id}`);
  }
}
