<div class="modal-dialog formInModal">
  <div>
    <h2 mat-dialog-title class="manageComment">
      {{ "COMMENT_DIALOG.MANAGE_COMMENT" | translate }}
    </h2>
  </div>
  <hr />
  <form #editComment="ngForm">
    <mat-dialog-content class="box">
      <mat-form-field
        floatLabel="auto"
        hideRequiredMarker
        appearance="outline"
        class="formWidth"
      >
        <mat-label class="global_search">{{
          "COMMENT_DIALOG.SELECT_TYPE" | translate
        }}</mat-label>
        <mat-select
          name="commentType"
          [(ngModel)]="commentItem.type"
          class="global_search"
          placeholder="Select comment type"
        >
          <mat-option *ngFor="let comment of commentTypes" [value]="comment">
            {{ comment }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <br />
      <mat-form-field floatLabel="auto" hideRequiredMarker appearance="outline">
        <mat-label class="global_search"
          >{{ "COMMENT_DIALOG.ENTER_TEXT" | translate
          }}<span class="star">*</span></mat-label
        >

        <textarea
          matInput
          cdkAutosizeMinRows="1"
          cdkAutosizeMaxRows="10"
          cdkTextareaAutosize
          #autosize="cdkTextareaAutosize"
          [(ngModel)]="commentItem.text"
          required
          autocomplete="off"
          name="commentName"
        ></textarea>
      </mat-form-field>
    </mat-dialog-content>

    <mat-dialog-actions>
      <button mat-button class="btnCan" (click)="cancel()" mat-dialog-close>
        {{ "COMMENT_DIALOG.CANCEL" | translate }}
      </button>
      <button
        mat-flat-button
        class="btnDel"
        (click)="submit(editComment.form.invalid)"
      >
        {{ "COMMENT_DIALOG.SAVE" | translate }}
      </button>
    </mat-dialog-actions>
  </form>
</div>
