import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Response } from '../_models/response';
import { ResponseData } from '../_models/responseData';
import { TenantService } from './tenant.service';

@Injectable({ providedIn: 'root' })
export class IntegrationService {
  constructor(private http: HttpClient, private tenantService: TenantService) {}

  getStripeTransations(): Observable<ResponseData> {
    return this.http
      .get<Response>(
        `${environment.apiUrl}/integrations/stripe-customer-balance-transactions`
      )
      .pipe(map((response) => response.data as ResponseData));
  }

  getStripePortalLink(): Observable<string> {
    return this.http
      .get<Response>(`${environment.apiUrl}/integrations/stripe-portal-link`)
      .pipe(map((response) => response.data as string));
  }
}
