import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-delete-load-document',
  templateUrl: './confirm-delete-load-document.component.html',
  styleUrls: ['./confirm-delete-load-document.component.scss'],
})
export class ConfirmDeleteLoadDocumentComponent {
  constructor(
    private dialogRef: MatDialogRef<ConfirmDeleteLoadDocumentComponent>
  ) {}

  confirmDelete() {
    this.dialogRef.close(true);
  }
}
