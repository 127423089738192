<div class="modal-dialog formInModal">
  <div *ngIf="!dataLoading">
    <h2 *ngIf="accounting && !accounting.id" mat-dialog-title class="text">
      {{ "ACCOUNTING.ADD_NEW_ACCOUNTING" | translate }}
    </h2>
    <h2 *ngIf="accounting.id" mat-dialog-title>
      <h2 class="text" [hidden]="readOnly">
        {{ "ACCOUNTING.EDIT_ACCOUNTING" | translate }}
      </h2>
      <h2 class="text" [hidden]="!readOnly">
        {{ "ACCOUNTING.PREVIEW_ACCOUNTING" | translate }}
      </h2>
    </h2>
  </div>
  <hr />
  <form #editAccounting="ngForm">
    <mat-dialog-content class="mat-typography">
      <mat-form-field
        floatLabel="auto"
        hideRequiredMarker
        appearance="outline"
        class="formWidth"
      >
        <mat-label class="global_search">
          {{ "ACCOUNTING.TYPE" | translate }}
          <span class="star">*</span></mat-label
        >
        <input
          matInput
          onfocus="this.select()"
          #accoutingType
          type="text"
          [matAutocomplete]="accounting_type"
          placeholder="{{ 'ACCOUNTING.TYPE' | translate }}"
          [(ngModel)]="accounting.accountingType.name"
          name="type"
          required
          [readonly]="readOnly"
          (blur)="onTypeBlur()"
          (keyup)="typeKeyFilter(accounting.accountingType.name)"
        />
        <mat-autocomplete #accounting_type="matAutocomplete">
          <ng-container *ngIf="typeList.length > 0">
            <mat-option
              *ngFor="let typeItem of typeListFiltered"
              [value]="typeItem.name"
            >
              {{ typeItem.name }}
            </mat-option>
          </ng-container>
        </mat-autocomplete>
      </mat-form-field>

      <mat-form-field
        floatLabel="auto"
        hideRequiredMarker
        appearance="outline"
        class="formWidth"
      >
        <mat-label class="global_search"
          >{{ "ACCOUNTING.DATE" | translate }} <span class="star">*</span>
        </mat-label>
        <input
          matInput
          required
          [matDatepicker]="datePicker"
          [(ngModel)]="accounting.date"
          autocomplete="off"
          name="accountingDate"
          [readonly]="readOnly"
        />
        <mat-datepicker-toggle
          [readonly]="readOnly"
          [disabled]="readOnly"
          matSuffix
          [for]="datePicker"
        ></mat-datepicker-toggle>
        <mat-datepicker #datePicker></mat-datepicker>
      </mat-form-field>
      <br />
      <mat-form-field
        floatLabel="auto"
        hideRequiredMarker
        appearance="outline"
        class="formWidth"
        [readonly]="readOnly"
      >
        <mat-label class="global_search">
          {{ "ACCOUNTING.VENDOR" | translate }}
        </mat-label>
        <input
          matInput
          onfocus="this.select()"
          #vendorName
          type="text"
          [matAutocomplete]="vendor_name"
          placeholder="{{ 'ACCOUNTING.VENDOR' | translate }}"
          [(ngModel)]="accounting.vendor.name"
          name="vendor"
          (keyup)="searchVendor()"
          (blur)="onVendorBlur()"
          [readonly]="readOnly"
        />
        <mat-autocomplete #vendor_name="matAutocomplete">
          <ng-container *ngIf="vendorList?.length > 0">
            <mat-option
              [readonly]="readOnly"
              *ngFor="let vendorItem of vendorList"
              [value]="vendorItem.name"
            >
              {{ vendorItem.name }}
            </mat-option>
          </ng-container>
        </mat-autocomplete>
      </mat-form-field>

      <mat-form-field
        floatLabel="auto"
        hideRequiredMarker
        appearance="outline"
        class="formWidth"
      >
        <mat-label class="global_search"
          >{{ "ACCOUNTING.AMOUNT" | translate }} <span class="star">*</span>
        </mat-label>
        <input
          type="text"
          placeholder="$0"
          matInput
          [ngModel]="accounting.amount"
          [ngModelOptions]="{ updateOn: 'blur' }"
          (ngModelChange)="updateModel($event)"
          required
          name="amount"
          autocomplete="off"
          type="number"
          [readonly]="readOnly"
        />
      </mat-form-field>
      <br />
      <mat-form-field
        floatLabel="auto"
        hideRequiredMarker
        appearance="outline"
        class="textWidth"
      >
        <mat-label class="global_search">{{
          "ACCOUNTING.DESCRIPTION" | translate
        }}</mat-label>

        <textarea
          matInput
          maxlength="30"
          [(ngModel)]="accounting.description"
          autocomplete="off"
          name="description"
          [readonly]="readOnly"
        ></textarea>
      </mat-form-field>
      <br />
      <mat-form-field
        floatLabel="auto"
        hideRequiredMarker
        appearance="outline"
        class="textWidth"
      >
        <mat-label class="global_search">{{
          "ACCOUNTING.COMMENT" | translate
        }}</mat-label>

        <textarea
          matInput
          autocomplete="off"
          [(ngModel)]="accounting.comment"
          name="comment"
          maxlength="30"
          [readonly]="readOnly"
        ></textarea>
      </mat-form-field>
      <br />
      <mat-form-field
        floatLabel="auto"
        hideRequiredMarker
        appearance="outline"
        class="formWidth"
        required
      >
        <mat-label class="global_search"
          >{{ "ACCOUNTING.TRUCK" | translate }}
        </mat-label>
        <mat-select
          [(ngModel)]="accounting.truck"
          class="global_search"
          [compareWith]="compareObjects"
          placeholder="{{ 'ACCOUNTING.TRUCK' | translate }}"
          name="truck"
          [readonly]="readOnly"
          [disabled]="readOnly"
        >
          <ng-container *ngIf="truckList.length > 0">
            <mat-option *ngFor="let truck of truckList" [value]="truck">
              {{ truck.truckNumber }}
            </mat-option>
          </ng-container>
        </mat-select>
      </mat-form-field>
      <mat-form-field
        floatLabel="auto"
        hideRequiredMarker
        appearance="outline"
        class="formWidth"
        required
      >
        <mat-label class="global_search"
          >{{ "ACCOUNTING.DRIVER" | translate }}
        </mat-label>

        <mat-select
          [(ngModel)]="accounting.driver"
          class="global_search"
          placeholder="{{ 'ACCOUNTING.DRIVER' | translate }}"
          [compareWith]="compareObjects"
          name="driver"
          [readonly]="readOnly"
          [disabled]="readOnly"
        >
          <ng-container *ngIf="driverList.length > 0">
            <mat-option *ngFor="let driver of driverList" [value]="driver">
              {{ driver.firstname + " " + driver.lastname }}
            </mat-option>
          </ng-container>
        </mat-select>
      </mat-form-field>
      <br />
      <mat-form-field
        floatLabel="auto"
        hideRequiredMarker
        appearance="outline"
        class="formWidth"
      >
        <mat-label class="global_search">
          {{ "ACCOUNTING.CATEGORY" | translate }}
          <span class="star">*</span></mat-label
        >
        <input
          matInput
          onfocus="this.select()"
          #accoutingCategory
          category="text"
          [matAutocomplete]="accounting_category"
          placeholder="{{ 'ACCOUNTING.CATEGORY' | translate }}"
          [(ngModel)]="accounting.accountingCategory.name"
          name="category"
          required
          (blur)="onCategoryBlur()"
          (keyup)="categoryKeyFilter(accounting.accountingCategory.name)"
          [readonly]="readOnly"
        />
        <mat-autocomplete #accounting_category="matAutocomplete">
          <ng-container *ngIf="categoryList.length > 0">
            <mat-option
              *ngFor="let categoryItem of categoryListFiltered"
              [value]="categoryItem.name"
            >
              {{ categoryItem.name }}
            </mat-option>
          </ng-container>
        </mat-autocomplete>
      </mat-form-field>
      <mat-form-field
        floatLabel="auto"
        hideRequiredMarker
        appearance="outline"
        class="formWidth"
      >
        <mat-label class="global_search">
          {{ "ACCOUNTING.STATUS" | translate }}
          <span class="star">*</span></mat-label
        >
        <input
          matInput
          onfocus="this.select()"
          #accoutingStatus
          type="text"
          [matAutocomplete]="accounting_status"
          placeholder="{{ 'ACCOUNTING.STATUS' | translate }}"
          [(ngModel)]="accounting.accountingStatus.name"
          name="status"
          required
          (blur)="onStatusBlur()"
          (keyup)="statusKeyFilter(accounting.accountingStatus.name)"
          [readonly]="readOnly"
        />
        <mat-autocomplete #accounting_status="matAutocomplete">
          <ng-container *ngIf="statusList.length > 0">
            <mat-option
              *ngFor="let statusItem of statusListFiltered"
              [value]="statusItem.name"
            >
              {{ statusItem.name }}
            </mat-option>
          </ng-container>
        </mat-autocomplete>
      </mat-form-field>
    </mat-dialog-content>
    <mat-dialog-actions>
      <button mat-button (click)="cancel()" class="btnCan" mat-dialog-close>
        {{ "ACCOUNTING.CANCEL" | translate }}
      </button>
      <button
        mat-flat-button
        class="btn btn-green"
        (click)="save(editAccounting.form.invalid, accounting)"
        *ngIf="!readOnly"
      >
        {{ "ACCOUNTING.SAVE" | translate }}
      </button>
    </mat-dialog-actions>
  </form>
</div>
