import { Injectable } from '@angular/core';
import { Escrow } from 'src/app/_models/escrow/escrow';
import { EscrowCreateRequest } from 'src/app/_models/escrow/escrowCreateRequest';
import { EscrowUpdateRequest } from 'src/app/_models/escrow/escrowUpdateRequest';
import { TenantService } from '../tenant.service';

@Injectable({
  providedIn: 'root',
})
export class EscrowFactory {
  constructor(private tenantService: TenantService) {}

  public getEscrowUtcDate(escrow: Escrow): Escrow {
    escrow.date = this.tenantService.getTenetUtcDate(escrow.date);
    return escrow;
  }

  public getEscrowTimeZoneDate(escrow: Escrow): Escrow {
    escrow.date = this.tenantService.getTenantTimeZoneDate(escrow.date);
    return escrow;
  }

  public getEscrowUpdateRequestUtcDate(
    escrow: EscrowUpdateRequest
  ): EscrowUpdateRequest {
    escrow.date = this.tenantService.getTenetUtcDate(escrow.date);
    return escrow;
  }

  public getEscrowCreateRequestUtcDate(
    escrow: EscrowCreateRequest
  ): EscrowCreateRequest {
    escrow.date = this.tenantService.getTenetUtcDate(escrow.date);
    return escrow;
  }
}
