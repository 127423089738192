<button mat-icon-button class="close-button" [mat-dialog-close]="true">
  <mat-icon class="close-icon" color="warn">close</mat-icon>
</button>

<h2>{{ "APPLICATION.WELCOME" | translate }}</h2>

<mat-horizontal-stepper [linear]="true" #stepper cdkFocusInitial>
  <mat-step
    [completed]="generalInfo.form.valid"
    label="{{ 'APPLICATION.GENERAL_INFORMATION' | translate }}"
  >
    <form #generalInfo="ngForm">
      <h4 class="example-h2">
        {{ "APPLICATION.INSTRUCTION" | translate }}
      </h4>
      <div class="overflowScroll">
        <mat-card>
          <mat-form-field
            floatLabel="auto"
            hideRequiredMarker
            appearance="outline"
            matTooltip="First name"
            matTooltipPosition="right"
            class="formWidth field"
          >
            <mat-label class="global_search"
              >{{ "DRIVERS.FIRST_NAME" | translate
              }}<span class="star">*</span></mat-label
            >
            <input
              matInput
              name="firstName"
              [(ngModel)]="driverFull.firstname"
              autocomplete="off"
              required
            />
          </mat-form-field>

          <mat-form-field
            floatLabel="auto"
            hideRequiredMarker
            appearance="outline"
            matTooltip="Last name"
            matTooltipPosition="right"
            class="formWidth field"
          >
            <mat-label class="global_search"
              >{{ "DRIVERS.LAST_NAME" | translate
              }}<span class="star">*</span></mat-label
            >
            <input
              matInput
              name="lastName"
              [(ngModel)]="driverFull.lastname"
              autocomplete="off"
              required
            />
          </mat-form-field>
          <!-- SEARCH ADDRESS -->
          <mat-form-field
            floatLabel="auto"
            hideRequiredMarker
            appearance="outline"
            matTooltip="Address"
            matTooltipPosition="right"
            class="add_address field"
          >
            <mat-label class="global_search"
              >{{ "DRIVERS.ADDRESS" | translate
              }}<span class="star">*</span></mat-label
            >
            <input
              matInput
              name="driverAddress"
              [(ngModel)]="driverFull.location"
              [matAutocomplete]="driver_address"
              [formControl]="searchAddress"
              autocomplete="off"
              required
            />
            <mat-icon matSuffix class="icon_search">search</mat-icon>
            <mat-autocomplete
              #driver_address="matAutocomplete"
              name="driveradressAutocomplete"
              [displayWith]="displayLoc"
              (optionSelected)="selectDriverLocation($event.option.value)"
            >
              <ng-container *ngIf="searchResult.length > 0">
                <mat-option
                  *ngFor="let location of searchResult"
                  [value]="location"
                >
                  <span>{{
                    location.address + ", " + location.countryCode
                  }}</span>
                </mat-option>
              </ng-container>
            </mat-autocomplete>
          </mat-form-field>
          <!-- END SEARCH ADDRESS -->

          <mat-card class="question">
            <div
              class="align-questions"
              [ngClass]="{
                'invalid-questions':
                  radioGroupQuestion1.invalid && loadedData && submited
              }"
            >
              <h4 class="example-h2">
                {{ "APPLICATION.QUESTION1" | translate
                }}<span class="star">*</span>
              </h4>

              <div class="example-section">
                <mat-radio-group
                  name="radioGroupQuestion1"
                  #radioGroupQuestion1="ngModel"
                  [(ngModel)]="
                    driverFull.isPresentAtAddressLocationTreeYearsOrMore
                  "
                  required
                >
                  <mat-radio-button class="example-margin" [value]="true">{{
                    "APPLICATION.YES" | translate
                  }}</mat-radio-button>
                  <mat-radio-button class="example-margin" [value]="false">{{
                    "APPLICATION.NO" | translate
                  }}</mat-radio-button>
                </mat-radio-group>
              </div>
            </div>
            <span
              *ngIf="
                driverFull.isPresentAtAddressLocationTreeYearsOrMore != null &&
                !driverFull.isPresentAtAddressLocationTreeYearsOrMore
              "
            >
              <h4 class="example-h2">
                {{ "APPLICATION.LIST_ADDRESES" | translate }}:<span class="star"
                  >*</span
                >
              </h4>

              <!-- DODAVANJE ADRESA SLICNO KAO KOD CUSTOMERA -->

              <app-address-list
                [addressList]="driverFull.locationsPastThreeYears"
              ></app-address-list>

              <mat-form-field
                floatLabel="auto"
                hideRequiredMarker
                appearance="outline"
                class="add_address"
              >
                <mat-label class="global_search"
                  >{{ "DRIVERS.ADDRESS" | translate
                  }}<span
                    *ngIf="
                      !!!(this.driverFull?.locationsPastThreeYears?.length > 0)
                    "
                    class="star"
                    >*</span
                  ></mat-label
                >
                <input
                  matInput
                  [matAutocomplete]="driver_addresses"
                  name="driverAddresses"
                  [formControl]="searchAddresses"
                  autocomplete="off"
                  [required]="
                    driverFull.isPresentAtAddressLocationTreeYearsOrMore !=
                      null &&
                    !driverFull.isPresentAtAddressLocationTreeYearsOrMore &&
                    this.driverFull?.locationsPastThreeYears?.length == 0
                  "
                />
                <mat-icon matSuffix class="icon_search">search</mat-icon>
                <mat-autocomplete
                  name="driverAddressSearch"
                  #driver_addresses="matAutocomplete"
                  [displayWith]="displayAddresses"
                  (optionSelected)="selectDriverAddresses($event.option.value)"
                >
                  <ng-container *ngIf="searchAddressResult.length > 0">
                    <mat-option
                      *ngFor="let location of searchAddressResult"
                      [value]="location"
                    >
                      <span>{{
                        location.address + ", " + location.countryCode
                      }}</span>
                    </mat-option>
                  </ng-container>
                </mat-autocomplete>
              </mat-form-field>
            </span>
          </mat-card>
          <mat-form-field
            floatLabel="auto"
            hideRequiredMarker
            appearance="outline"
            class="formWidth field"
          >
            <mat-label class="global_search"
              >{{ "APPLICATION.DATE_OF_BIRTH" | translate
              }}<span class="star">*</span></mat-label
            >
            <input
              matInput
              name="dateOfBirth"
              [matDatepicker]="dateOfBirth"
              [(ngModel)]="driverFull.dateOfBirth"
              autocomplete="off"
              required
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="dateOfBirth"
            ></mat-datepicker-toggle>
            <mat-datepicker #dateOfBirth></mat-datepicker>
          </mat-form-field>
          <mat-form-field
            floatLabel="auto"
            hideRequiredMarker
            appearance="outline"
            matTooltip="Phone number"
            matTooltipPosition="right"
            class="formWidth field"
          >
            <mat-label class="global_search"
              >{{ "DRIVERS.PHONE_NUMBER" | translate
              }}<span class="star">*</span></mat-label
            >
            <input
              matInput
              name="phoneNumber"
              [(ngModel)]="driverFull.phoneNumber"
              autocomplete="off"
              required
              pattern="[- +()0-9]+"
              maxlength="18"
            />
          </mat-form-field>
          <mat-form-field
            floatLabel="auto"
            hideRequiredMarker
            appearance="outline"
            matTooltip="ex.name@example.com"
            matTooltipPosition="right"
            class="formWidth field"
          >
            <mat-label class="global_search"
              >{{ "DRIVERS.EMAIL" | translate
              }}<span class="star">*</span></mat-label
            >
            <input
              matInput
              type="email"
              [(ngModel)]="driverFull.email"
              email
              name="email"
              pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}?"
              autocomplete="off"
              required
            />
          </mat-form-field>
          <mat-form-field
            floatLabel="auto"
            hideRequiredMarker
            appearance="outline"
            class="formWidth field"
            matTooltip="Social Security Number"
            matTooltipPosition="right"
          >
            <mat-label class="global_search"
              >{{ "APPLICATION.S_S_NUMBER" | translate
              }}<span class="star">*</span></mat-label
            >
            <input
              matInput
              name="socialSecurityNumber"
              [(ngModel)]="driverFull.socialSecurityNumber"
              autocomplete="off"
              pattern="[- +()0-9]+"
              required
            />
          </mat-form-field>
          <br />
          <div
            class="align-questions"
            [ngClass]="{
              'invalid-questions':
                radioGroupQuestion2.invalid && loadedData && submited
            }"
          >
            <h4 class="example-h2">
              {{ "APPLICATION.QUESTION2" | translate
              }}<span class="star">*</span>
            </h4>

            <section class="example-section" required>
              <mat-radio-group
                name="radioGroupQuestion2"
                #radioGroupQuestion2="ngModel"
                [(ngModel)]="driverFull.isKnownByOtherName"
                required
              >
                <mat-radio-button class="example-margin" [value]="true">{{
                  "APPLICATION.YES" | translate
                }}</mat-radio-button>
                <mat-radio-button class="example-margin" [value]="false">{{
                  "APPLICATION.NO" | translate
                }}</mat-radio-button>
              </mat-radio-group>
            </section>
          </div>
          <div
            class="align-questions"
            [ngClass]="{
              'invalid-questions':
                radioGroupQuestion3.invalid && loadedData && submited
            }"
          >
            <h4 class="example-h2">
              {{ "APPLICATION.QUESTION3" | translate
              }}<span class="star">*</span>
            </h4>

            <div class="example-section">
              <mat-radio-group
                name="radioGroupQuestion3"
                #radioGroupQuestion3="ngModel"
                [(ngModel)]="driverFull.canFurnishProofEligibleToWorkInUS"
                required
              >
                <mat-radio-button class="example-margin" [value]="true">{{
                  "APPLICATION.YES" | translate
                }}</mat-radio-button>
                <mat-radio-button class="example-margin" [value]="false">{{
                  "APPLICATION.NO" | translate
                }}</mat-radio-button>
              </mat-radio-group>
            </div>
          </div>
          <div
            class="align-questions"
            [ngClass]="{
              'invalid-questions':
                radioGroupQuestion4.invalid && loadedData && submited
            }"
          >
            <h4 class="example-h2">
              {{ "APPLICATION.QUESTION4" | translate
              }}<span class="star">*</span>
            </h4>

            <mat-radio-group
              name="radioGroupQuestion4"
              #radioGroupQuestion4="ngModel"
              [(ngModel)]="driverFull.isAtLeast23OfAge"
              required
            >
              <mat-radio-button class="example-margin" [value]="true">{{
                "APPLICATION.YES" | translate
              }}</mat-radio-button>
              <mat-radio-button class="example-margin" [value]="false">{{
                "APPLICATION.NO" | translate
              }}</mat-radio-button>
            </mat-radio-group>
          </div>
        </mat-card>
      </div>

      <div>
        <button
          mat-flat-button
          class="buttonNext"
          type="submit"
          (click)="
            next(
              generalInfo.form.invalid,
              driverFull.isPresentAtAddressLocationTreeYearsOrMore != null &&
                !driverFull.isPresentAtAddressLocationTreeYearsOrMore &&
                this.driverFull?.locationsPastThreeYears?.length == 0,
              'step1'
            )
          "
        >
          {{ "APPLICATION.NEXT" | translate }}
        </button>
      </div>
    </form>
  </mat-step>

  <mat-step
    [completed]="licenceInfo.form.valid && !isCdlListEmpty"
    label="{{ 'APPLICATION.LICENSE_INFORMATION' | translate }}"
  >
    <form #licenceInfo="ngForm">
      <div class="overflowScroll">
        <mat-card>
          <div
            class="align-questions"
            [ngClass]="{
              'invalid-questions':
                radioGroupQuestion5.invalid && loadedData && submited
            }"
          >
            <h4 class="example-h2">
              {{ "APPLICATION.QUESTION5" | translate
              }}<span class="star">*</span>
            </h4>

            <section class="example-section">
              <mat-radio-group
                name="radioGroupQuestion5"
                #radioGroupQuestion5="ngModel"
                [(ngModel)]="driverFull.haveHeldValidUsLicenceForPast36Months"
                required
              >
                <mat-radio-button class="example-margin" [value]="true">{{
                  "APPLICATION.YES" | translate
                }}</mat-radio-button>
                <mat-radio-button class="example-margin" [value]="false">{{
                  "APPLICATION.NO" | translate
                }}</mat-radio-button>
              </mat-radio-group>
            </section>
          </div>
          <mat-form-field
            floatLabel="auto"
            hideRequiredMarker
            appearance="outline"
            class="formWidth field"
            matTooltip="Driver License Number"
            matTooltipPosition="right"
          >
            <mat-label class="global_search">
              {{ "APPLICATION.CDL_NUMBER" | translate
              }}<span class="star">*</span></mat-label
            >
            <input
              matInput
              name="driverLicenseNumber"
              [(ngModel)]="driverFull.cdlNumber"
              autocomplete="off"
              required
            />
          </mat-form-field>
          <mat-form-field
            floatLabel="auto"
            hideRequiredMarker
            appearance="outline"
            class="formWidth field"
          >
            <mat-label class="global_search">
              {{ "APPLICATION.EXPIRATION_DOT_CARD" | translate }}</mat-label
            >
            <input
              matInput
              name="expirationDOTcard"
              [matDatepicker]="expirationDOTcard"
              [(ngModel)]="driverFull.expirationDateOfDOTPsysicalCard"
              autocomplete="off"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="expirationDOTcard"
            ></mat-datepicker-toggle>
            <mat-datepicker #expirationDOTcard></mat-datepicker>
          </mat-form-field>
          <div
            class="align-questions"
            [ngClass]="{
              'invalid-questions': isCdlListEmpty && loadedData && submited
            }"
          >
            <h4 class="example-h2">
              {{ "APPLICATION.CDL_ENDORSEMENTS" | translate
              }}<span class="star">*</span>
            </h4>

            <section class="example-section">
              <span *ngFor="let cdl of cdlEndorsmentsList; let i = index">
                <mat-checkbox
                  [(ngModel)]="cdl.active"
                  [checked]="cdl.active"
                  (change)="collectCdlEndorsments()"
                  class="example-margin"
                  name="cdlEndorsments{{ i }}"
                  >{{ cdl.key | translate }}</mat-checkbox
                >
              </span>
            </section>
          </div>
          <mat-form-field
            floatLabel="auto"
            hideRequiredMarker
            appearance="outline"
            class="formWidth field"
          >
            <mat-label class="global_search">{{
              "APPLICATION.HAZMAT_EXPIRATION" | translate
            }}</mat-label>
            <input
              matInput
              name="hazmatExpirationDate"
              [matDatepicker]="hazmatExpirationDate"
              [(ngModel)]="driverFull.hazmatExpirationDate"
              autocomplete="off"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="hazmatExpirationDate"
            ></mat-datepicker-toggle>
            <mat-datepicker #hazmatExpirationDate></mat-datepicker>
          </mat-form-field>
          <mat-form-field
            floatLabel="auto"
            hideRequiredMarker
            appearance="outline"
            class="formWidth field"
          >
            <mat-label class="global_search">{{
              "APPLICATION.TWIC_EXPIRATION" | translate
            }}</mat-label>
            <input
              matInput
              [matDatepicker]="TWICexpirationDate"
              [(ngModel)]="driverFull.twicExpirationDate"
              name="TWICexpirationDate"
              autocomplete="off"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="TWICexpirationDate"
            ></mat-datepicker-toggle>
            <mat-datepicker #TWICexpirationDate></mat-datepicker>
          </mat-form-field>
          <h4 class="example-h2">
            {{ "APPLICATION.INSTRUCTION2" | translate }}
          </h4>
          <mat-form-field class="full-width field" appearance="fill">
            <mat-label class="global_search">{{
              "APPLICATION.NOTE" | translate
            }}</mat-label>
            <textarea
              name="listOfLicencesHeldInPrev5Years"
              [(ngModel)]="driverFull.listOfLicencesHeldInPrev5Years"
              matInput
              placeholder="Note.."
              autocomplete="off"
            ></textarea>
          </mat-form-field>
        </mat-card>
      </div>

      <div>
        <button
          mat-flat-button
          class="buttonNext"
          (click)="next(licenceInfo.form.invalid, isCdlListEmpty, 'step2')"
        >
          {{ "APPLICATION.NEXT" | translate }}
        </button>
        <button
          mat-flat-button
          class="buttonBack"
          color="warn"
          matStepperPrevious
        >
          {{ "APPLICATION.BACK" | translate }}
        </button>
      </div>
    </form>
  </mat-step>

  <mat-step
    [completed]="
      (employmentHistory.form.valid &&
        driverFull.driverEmploymentHistories?.length > 0) ||
      stepPassed['step3']
    "
    label=" {{ 'APPLICATION.EMPLOYMENT_HISTORY' | translate }}"
  >
    <form #employmentHistory="ngForm">
      <div class="driverEmplHist">
        <h3 class="example-h2">
          {{ "APPLICATION.INSTRUCTION3" | translate }}
        </h3>
        <div
          *ngFor="
            let employmentHistory of driverFull.driverEmploymentHistories;
            let i = index
          "
        >
          <mat-card>
            <mat-form-field
              floatLabel="auto"
              hideRequiredMarker
              appearance="outline"
              class="formWidth field"
            >
              <mat-label class="global_search"
                >{{ "APPLICATION.START_DATE" | translate
                }}<span class="star">*</span></mat-label
              >
              <input
                matInput
                name="startDate{{ i }}"
                [matDatepicker]="startDate"
                [(ngModel)]="employmentHistory.startDate"
                autocomplete="off"
                [required]="!!driverFull.driverEmploymentHistories[i]"
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="startDate"
              ></mat-datepicker-toggle>
              <mat-datepicker #startDate></mat-datepicker>
            </mat-form-field>
            <mat-form-field
              floatLabel="auto"
              hideRequiredMarker
              appearance="outline"
              class="formWidth field"
            >
              <mat-label class="global_search"
                >{{ "APPLICATION.ENDING_DATE" | translate
                }}<span class="star">*</span></mat-label
              >
              <input
                matInput
                [matDatepicker]="endingDate"
                [(ngModel)]="employmentHistory.endingDate"
                name="endingDate{{ i }}"
                autocomplete="off"
                [required]="!!driverFull.driverEmploymentHistories[i]"
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="endingDate"
              ></mat-datepicker-toggle>
              <mat-datepicker #endingDate></mat-datepicker>
            </mat-form-field>
            <mat-form-field
              floatLabel="auto"
              hideRequiredMarker
              appearance="outline"
              matTooltip="Employer name"
              matTooltipPosition="right"
              class="formWidth field"
            >
              <mat-label class="global_search"
                >{{ "APPLICATION.EMPLOYER_NAME" | translate
                }}<span class="star">*</span></mat-label
              >
              <input
                matInput
                name="employerName{{ i }}"
                autocomplete="off"
                [(ngModel)]="employmentHistory.employerName"
                [required]="!!driverFull.driverEmploymentHistories[i]"
              />
            </mat-form-field>

            <mat-form-field
              floatLabel="auto"
              hideRequiredMarker
              appearance="outline"
              matTooltip="City, State"
              matTooltipPosition="right"
              class="formWidth field"
            >
              <mat-label class="global_search"
                >{{ "APPLICATION.CITY_STATE" | translate
                }}<span class="star">*</span></mat-label
              >
              <input
                matInput
                name="cityState{{ i }}"
                autocomplete="off"
                [(ngModel)]="employmentHistory.cityState"
                [required]="!!driverFull.driverEmploymentHistories[i]"
              />
            </mat-form-field>

            <mat-form-field
              floatLabel="auto"
              hideRequiredMarker
              appearance="outline"
              class="formWidth field"
            >
              <mat-label class="global_search"
                >{{ "APPLICATION.EMPLOYER_PHONE" | translate
                }}<span class="star">*</span></mat-label
              >
              <input
                matInput
                name="employerPhone{{ i }}"
                [(ngModel)]="employmentHistory.employerPhone"
                autocomplete="off"
                [required]="!!driverFull.driverEmploymentHistories[i]"
                pattern="[- +()0-9]+"
                maxlength="18"
              />
            </mat-form-field>

            <mat-form-field
              floatLabel="auto"
              hideRequiredMarker
              appearance="outline"
              class="formWidth field"
            >
              <mat-label class="global_search"
                >{{ "APPLICATION.POSITION_HELD" | translate
                }}<span class="star">*</span></mat-label
              >
              <input
                matInput
                name="positionHeld{{ i }}"
                [(ngModel)]="employmentHistory.positionHeld"
                autocomplete="off"
                [required]="!!driverFull.driverEmploymentHistories[i]"
              />
            </mat-form-field>
            <mat-form-field
              floatLabel="auto"
              hideRequiredMarker
              appearance="outline"
              class="formWidth field"
            >
              <mat-label class="global_search"
                >{{ "APPLICATION.REASON_FOR_LEAVING" | translate
                }}<span class="star">*</span></mat-label
              >
              <input
                matInput
                name="reasonForLeaving{{ i }}"
                [(ngModel)]="employmentHistory.reasonsForLeaving"
                autocomplete="off"
                [required]="!!driverFull.driverEmploymentHistories[i]"
              />
            </mat-form-field>

            <div
              class="align-questions"
              [ngClass]="{
                'invalid-questions':
                  radioGroupQuestion6.invalid && loadedData && submited
              }"
            >
              <h4 class="example-h2">
                {{ "APPLICATION.QUESTION6" | translate
                }}<span class="star">*</span>
              </h4>

              <section class="example-section">
                <mat-radio-group
                  name="radioGroupQuestion6{{ i }}"
                  #radioGroupQuestion6="ngModel"
                  [(ngModel)]="employmentHistory.isCurrentEmployer"
                  [required]="!!driverFull.driverEmploymentHistories[i]"
                >
                  <mat-radio-button class="example-margin" [value]="true">{{
                    "APPLICATION.YES" | translate
                  }}</mat-radio-button>
                  <mat-radio-button class="example-margin" [value]="false">{{
                    "APPLICATION.NO" | translate
                  }}</mat-radio-button>
                </mat-radio-group>
              </section>
            </div>
            <div
              class="align-questions"
              [ngClass]="{
                'invalid-questions':
                  radioGroupQuestion7.invalid && loadedData && submited
              }"
            >
              <h4 class="example-h2">
                {{ "APPLICATION.QUESTION7" | translate }}
                <span class="star">*</span>
              </h4>

              <section class="example-section">
                <mat-radio-group
                  name="radioGroupQuestion7{{ i }}"
                  #radioGroupQuestion7="ngModel"
                  [(ngModel)]="employmentHistory.canContactEmployer"
                  [required]="!!driverFull.driverEmploymentHistories[i]"
                >
                  <mat-radio-button class="example-margin" [value]="true">{{
                    "APPLICATION.YES" | translate
                  }}</mat-radio-button>
                  <mat-radio-button class="example-margin" [value]="false">{{
                    "APPLICATION.NO" | translate
                  }}</mat-radio-button>
                </mat-radio-group>
              </section>
            </div>
            <div
              class="align-questions"
              [ngClass]="{
                'invalid-questions':
                  radioGroupQuestion8.invalid && loadedData && submited
              }"
            >
              <h4 class="example-h2">
                {{ "APPLICATION.QUESTION8" | translate
                }}<span class="star">*</span>
              </h4>

              <section class="example-section">
                <mat-radio-group
                  name="radioGroupQuestion8{{ i }}"
                  #radioGroupQuestion8="ngModel"
                  [(ngModel)]="employmentHistory.wasDrivingPosition"
                  [required]="!!driverFull.driverEmploymentHistories[i]"
                >
                  <mat-radio-button class="example-margin" [value]="true">{{
                    "APPLICATION.YES" | translate
                  }}</mat-radio-button>
                  <mat-radio-button class="example-margin" [value]="false">{{
                    "APPLICATION.NO" | translate
                  }}</mat-radio-button>
                </mat-radio-group>
              </section>
            </div>
            <mat-form-field
              floatLabel="auto"
              hideRequiredMarker
              appearance="outline"
              class="formWidth field"
            >
              <mat-label class="global_search">{{
                "APPLICATION.TYPE_OF_TRUCK" | translate
              }}</mat-label>
              <mat-select
                name="typeOfTruck{{ i }}"
                class="global_search"
                placeholder="Type of Truck"
                [(ngModel)]="employmentHistory.employmentHistoryTypeOfTruckId"
              >
                <mat-option [value]="1">
                  {{ "APPLICATION.CONVENTIONAL_SLEEPER" | translate }}
                </mat-option>
                <mat-option [value]="2">
                  {{ "APPLICATION.DAU_CAB" | translate }}
                </mat-option>
                <mat-option [value]="3">
                  {{ "APPLICATION.STRAIGHT_TRUCK" | translate }}
                </mat-option>
                <mat-option [value]="4">
                  {{ "APPLICATION.BOX_TRUCK" | translate }}
                </mat-option>
                <mat-option [value]="5">
                  {{ "APPLICATION.DUMP_TRUCK" | translate }}
                </mat-option>
                <mat-option [value]="6">{{
                  "APPLICATION.MIXER" | translate
                }}</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field
              floatLabel="auto"
              hideRequiredMarker
              appearance="outline"
              class="formWidth field"
            >
              <mat-label class="global_search">{{
                "APPLICATION.TYPE_OF_TRAILER" | translate
              }}</mat-label>
              <mat-select
                name="typeOfTrailer{{ i }}"
                class="global_search"
                placeholder="Type of Trailer"
                [(ngModel)]="employmentHistory.employmentHistoryTypeOfTrailerId"
              >
                <mat-option [value]="1">{{
                  "APPLICATION.VAN" | translate
                }}</mat-option>
                <mat-option [value]="2">{{
                  "APPLICATION.REEFER" | translate
                }}</mat-option>
                <mat-option [value]="3">{{
                  "APPLICATION.FLATBED" | translate
                }}</mat-option>
                <mat-option [value]="4">{{
                  "APPLICATION.TANKER" | translate
                }}</mat-option>
                <mat-option [value]="5">{{
                  "APPLICATION.RGN_LOWBOY" | translate
                }}</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field
              floatLabel="auto"
              hideRequiredMarker
              appearance="outline"
              class="formWidth field"
            >
              <mat-label class="global_search">{{
                "APPLICATION.TRAILER_LENGTH" | translate
              }}</mat-label>
              <mat-select
                name="trailerLength{{ i }}"
                class="global_search"
                placeholder="Trailer length"
                [(ngModel)]="employmentHistory.employmentHistoryTrailerLengthId"
              >
                <mat-option [value]="1">{{
                  "APPLICATION.24-38" | translate
                }}</mat-option>
                <mat-option [value]="2">{{
                  "APPLICATION.38-44" | translate
                }}</mat-option>
                <mat-option [value]="3">{{
                  "APPLICATION.45-53" | translate
                }}</mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field
              floatLabel="auto"
              hideRequiredMarker
              appearance="outline"
              matTooltip="Number of states driven"
              matTooltipPosition="right"
              class="formWidth field"
            >
              <mat-label class="global_search">{{
                "APPLICATION.NUMBER_OF_STATES_DRIVEN" | translate
              }}</mat-label>
              <input
                matInput
                name="numberOfStatesDriven{{ i }}"
                [(ngModel)]="employmentHistory.numberOfStatesDriven"
                autocomplete="off"
              />
            </mat-form-field>

            <div
              class="align-questions"
              [ngClass]="{
                'invalid-questions':
                  radioGroupQuestion9.invalid && loadedData && submited
              }"
            >
              <h4 class="example-h2">
                {{ "APPLICATION.QUESTION9" | translate
                }}<span class="star">*</span>
              </h4>

              <section class="example-section">
                <mat-radio-group
                  name="radioGroupQuestion9{{ i }}"
                  #radioGroupQuestion9="ngModel"
                  [(ngModel)]="employmentHistory.wasSubjectToTheFMCSRs"
                  [required]="!!driverFull.driverEmploymentHistories[i]"
                >
                  <mat-radio-button class="example-margin" [value]="true">{{
                    "APPLICATION.YES" | translate
                  }}</mat-radio-button>
                  <mat-radio-button class="example-margin" [value]="false">{{
                    "APPLICATION.NO" | translate
                  }}</mat-radio-button>
                </mat-radio-group>
              </section>
            </div>
            <div
              class="align-questions"
              [ngClass]="{
                'invalid-questions':
                  radioGroupQuestion10.invalid && loadedData && submited
              }"
            >
              <h4 class="example-h2">
                {{ "APPLICATION.QUESTION10" | translate
                }}<span class="star">*</span>
              </h4>
              <section class="example-section">
                <mat-radio-group
                  name="radioGroupQuestion10{{ i }}"
                  #radioGroupQuestion10="ngModel"
                  [(ngModel)]="
                    employmentHistory.wasPositionDesignedAsASafetySensitiveFunction
                  "
                  [required]="!!driverFull.driverEmploymentHistories[i]"
                >
                  <mat-radio-button class="example-margin" [value]="true">{{
                    "APPLICATION.YES" | translate
                  }}</mat-radio-button>
                  <mat-radio-button class="example-margin" [value]="false">{{
                    "APPLICATION.NO" | translate
                  }}</mat-radio-button>
                </mat-radio-group>
              </section>
            </div>
          </mat-card>
          <button
            type="button"
            class="remove-button"
            (click)="deleteHistory(i)"
          >
            {{ "APPLICATION.REMOVE" | translate }}
          </button>
        </div>
        <div class="bottom-line">
          <button type="button" class="addHistory" (click)="addHistory()">
            {{ "APPLICATION.NEW_HISTORY" | translate }}
          </button>
        </div>
      </div>

      <div>
        <button
          mat-flat-button
          class="buttonNext"
          (click)="next(employmentHistory.form.invalid, null, 'step3')"
        >
          {{ "APPLICATION.NEXT" | translate }}
        </button>
        <button
          mat-flat-button
          class="buttonBack"
          color="warn"
          matStepperPrevious
        >
          {{ "APPLICATION.BACK" | translate }}
        </button>
      </div>
    </form>
  </mat-step>

  <mat-step
    [completed]="additionalInfo.form.valid"
    label="{{ 'APPLICATION.ADDITIONAL_INFORMATION' | translate }}"
  >
    <form #additionalInfo="ngForm">
      <div class="overflowScroll">
        <mat-card>
          <div
            class="align-questions"
            [ngClass]="{
              'invalid-questions':
                radioGroupQuestion11.invalid && loadedData && submited
            }"
          >
            <h4 class="example-h2">
              {{ "APPLICATION.QUESTION11" | translate
              }}<span class="star">*</span>
            </h4>
            <section class="example-section">
              <mat-radio-group
                name="radioGroupQuestion11"
                #radioGroupQuestion11="ngModel"
                [(ngModel)]="driverFull.hadMovingViolationsInLast5Years"
                required
              >
                <mat-radio-button class="example-margin" [value]="true">{{
                  "APPLICATION.YES" | translate
                }}</mat-radio-button>
                <mat-radio-button class="example-margin" [value]="false">{{
                  "APPLICATION.NO" | translate
                }}</mat-radio-button>
              </mat-radio-group>
            </section>
          </div>
          <div
            class="align-questions"
            [ngClass]="{
              'invalid-questions':
                radioGroupQuestion12.invalid && loadedData && submited
            }"
          >
            <h4 class="example-h2">
              {{ "APPLICATION.QUESTION12" | translate
              }}<span class="star">*</span>
            </h4>
            <section class="example-section">
              <mat-radio-group
                name="radioGroupQuestion12"
                #radioGroupQuestion12="ngModel"
                [(ngModel)]="driverFull.hadLicenceSuspended"
                required
              >
                <mat-radio-button class="example-margin" [value]="true">{{
                  "APPLICATION.YES" | translate
                }}</mat-radio-button>
                <mat-radio-button class="example-margin" [value]="false">{{
                  "APPLICATION.NO" | translate
                }}</mat-radio-button>
              </mat-radio-group>
            </section>
          </div>
          <div
            class="align-questions"
            [ngClass]="{
              'invalid-questions':
                radioGroupQuestion13.invalid && loadedData && submited
            }"
          >
            <h4 class="example-h2">
              {{ "APPLICATION.QUESTION13" | translate
              }}<span class="star">*</span>
            </h4>
            <section class="example-section" required>
              <mat-radio-group
                name="radioGroupQuestion13"
                #radioGroupQuestion13="ngModel"
                [(ngModel)]="driverFull.hadAccidentsInLast5Years"
                required
              >
                <mat-radio-button class="example-margin" [value]="true">{{
                  "APPLICATION.YES" | translate
                }}</mat-radio-button>
                <mat-radio-button class="example-margin" [value]="false">{{
                  "APPLICATION.NO" | translate
                }}</mat-radio-button>
              </mat-radio-group>
            </section>
          </div>
          <div
            class="align-questions"
            [ngClass]="{
              'invalid-questions':
                radioGroupQuestion14.invalid && loadedData && submited
            }"
          >
            <h4 class="example-h2">
              {{ "APPLICATION.QUESTION14" | translate
              }}<span class="star">*</span>
            </h4>
            <section class="example-section" required>
              <mat-radio-group
                name="radioGroupQuestion14"
                #radioGroupQuestion14="ngModel"
                [(ngModel)]="driverFull.hadDuiDwiOrOvi"
                required
              >
                <mat-radio-button class="example-margin" [value]="true">{{
                  "APPLICATION.YES" | translate
                }}</mat-radio-button>
                <mat-radio-button class="example-margin" [value]="false">{{
                  "APPLICATION.NO" | translate
                }}</mat-radio-button>
              </mat-radio-group>
            </section>
          </div>
          <div
            class="align-questions"
            [ngClass]="{
              'invalid-questions':
                radioGroupQuestion15.invalid && loadedData && submited
            }"
          >
            <h4 class="example-h2">
              {{ "APPLICATION.QUESTION15" | translate
              }}<span class="star">*</span>
            </h4>
            <section class="example-section" required>
              <mat-radio-group
                name="radioGroupQuestion15"
                #radioGroupQuestion15="ngModel"
                [(ngModel)]="driverFull.convictedOfFelony"
                required
              >
                <mat-radio-button class="example-margin" [value]="true">{{
                  "APPLICATION.YES" | translate
                }}</mat-radio-button>
                <mat-radio-button class="example-margin" [value]="false">{{
                  "APPLICATION.NO" | translate
                }}</mat-radio-button>
              </mat-radio-group>
            </section>
          </div>
          <div
            class="align-questions"
            [ngClass]="{
              'invalid-questions':
                radioGroupQuestion16.invalid && loadedData && submited
            }"
          >
            <h4 class="example-h2">
              <section></section>
              {{ "APPLICATION.QUESTION16" | translate
              }}<span class="star">*</span>
            </h4>
            <section class="example-section" required>
              <mat-radio-group
                name="radioGroupQuestion16"
                #radioGroupQuestion16="ngModel"
                [(ngModel)]="driverFull.convictedOfMisdemeanor"
                required
              >
                <mat-radio-button class="example-margin" [value]="true">{{
                  "APPLICATION.YES" | translate
                }}</mat-radio-button>
                <mat-radio-button class="example-margin" [value]="false">{{
                  "APPLICATION.NO" | translate
                }}</mat-radio-button>
              </mat-radio-group>
            </section>
          </div>
        </mat-card>
      </div>

      <div>
        <button
          mat-flat-button
          class="buttonNext"
          (click)="next(additionalInfo.form.invalid, null, 'step4')"
        >
          {{ "APPLICATION.NEXT" | translate }}
        </button>
        <button
          mat-flat-button
          class="buttonBack"
          color="warn"
          matStepperPrevious
        >
          {{ "APPLICATION.BACK" | translate }}
        </button>
      </div>
    </form>
  </mat-step>

  <mat-step
    [completed]="
      (refrences.form.valid && driverFull.driverReferences?.length > 0) ||
      stepPassed['step5']
    "
    label="{{ 'APPLICATION.REFERENCES' | translate }}"
  >
    <form #refrences="ngForm">
      <div class="driverReference">
        <h3 class="example-h2">
          {{ "APPLICATION.INSTRUCTION4" | translate }}
        </h3>
        <div
          *ngFor="
            let driverReference of driverFull.driverReferences;
            let i = index
          "
        >
          <mat-card>
            <mat-form-field
              floatLabel="auto"
              hideRequiredMarker
              appearance="outline"
              class="formWidth field"
            >
              <mat-label class="global_search"
                >{{ "APPLICATION.NAME" | translate
                }}<span class="star">*</span></mat-label
              >
              <input
                matInput
                [(ngModel)]="driverReference.name"
                name="nameOfReference{{ i }}"
                matTooltip="Name"
                matTooltipPosition="right"
                autocomplete="off"
                required
              />
            </mat-form-field>
            <mat-form-field
              floatLabel="auto"
              hideRequiredMarker
              appearance="outline"
              matTooltip="Address"
              matTooltipPosition="right"
              class="add_address field"
            >
              <mat-label class="global_search">{{
                "DRIVERS.ADDRESS" | translate
              }}</mat-label>
              <input
                matInput
                [matAutocomplete]="reference_address"
                [(ngModel)]="driverReference.address"
                (input)="driverReferenceAddressChange($event.target.value)"
                name="addressOfReference{{ i }}"
                id="addressOfReference{{ i }}"
                autocomplete="off"
              />
              <mat-icon matSuffix class="icon_search">search</mat-icon>
              <mat-autocomplete
                #reference_address="matAutocomplete"
                name="referenceDriverLocation{{ i }}"
                id="referenceDriverLocation{{ i }}"
                [displayWith]="displayLocReference"
                (optionSelected)="
                  selectReferenceDriverLocation($event.option.value, i)
                "
              >
                <ng-container *ngIf="searchReferenceResult.length > 0">
                  <mat-option
                    *ngFor="let location of searchReferenceResult"
                    [value]="location"
                  >
                    <span>{{
                      location.address + ", " + location.countryCode
                    }}</span>
                  </mat-option>
                </ng-container>
              </mat-autocomplete>
            </mat-form-field>

            <mat-form-field
              floatLabel="auto"
              hideRequiredMarker
              appearance="outline"
              matTooltip="Phone number"
              matTooltipPosition="right"
              class="formWidth field"
            >
              <mat-label class="global_search"
                >{{ "APPLICATION.PHONE" | translate
                }}<span class="star">*</span></mat-label
              >
              <input
                matInput
                autocomplete="off"
                [(ngModel)]="driverReference.phoneNumber"
                name="phoneOfReference{{ i }}"
                pattern="[- +()0-9]+"
                maxlength="18"
                required
              />
            </mat-form-field>
            <mat-form-field
              floatLabel="auto"
              hideRequiredMarker
              appearance="outline"
              matTooltip="Number of years known"
              matTooltipPosition="right"
              class="formWidth field"
            >
              <mat-label class="global_search">{{
                "APPLICATION.NUBER_OF_YEARS_KNOWN" | translate
              }}</mat-label>
              <input
                matInput
                name="numberOfYearsKnown{{ i }}"
                [(ngModel)]="driverReference.numberOfYearsKnown"
                autocomplete="off"
                pattern="[- +0-9]+"
              />
            </mat-form-field>
          </mat-card>
          <button
            type="button"
            class="remove-button"
            (click)="deleteReference(i)"
          >
            {{ "APPLICATION.REMOVE" | translate }}
          </button>
        </div>

        <div>
          <button type="button" class="addReference" (click)="addReference()">
            {{ "APPLICATION.NEW_REFERENCE" | translate }}
          </button>
        </div>
      </div>

      <div>
        <button
          mat-flat-button
          class="buttonNext"
          (click)="next(refrences.form.invalid, null, 'step5')"
        >
          {{ "APPLICATION.NEXT" | translate }}
        </button>
        <button
          mat-flat-button
          class="buttonBack"
          color="warn"
          matStepperPrevious
        >
          {{ "APPLICATION.BACK" | translate }}
        </button>
      </div>
    </form>
  </mat-step>

  <mat-step
    [completed]="
      (emergencyContact.form.valid &&
        driverFull.driverEmergencyContacts?.length > 0) ||
      stepPassed['step6']
    "
    label="{{ 'APPLICATION.EMERGENCY_CONTACT' | translate }}"
  >
    <form #emergencyContact="ngForm">
      <div class="driverReference">
        <div
          *ngFor="
            let emergencyContact of driverFull.driverEmergencyContacts;
            let i = index
          "
        >
          <mat-card>
            <mat-form-field
              floatLabel="auto"
              hideRequiredMarker
              appearance="outline"
              matTooltip="First name"
              matTooltipPosition="right"
              class="formWidth field"
            >
              <mat-label class="global_search"
                >{{ "APPLICATION.FIRST_NAME" | translate
                }}<span class="star">*</span></mat-label
              >
              <input
                matInput
                name="firstnameContact {{ i }}"
                [(ngModel)]="emergencyContact.firstname"
                autocomplete="off"
                required
              />
            </mat-form-field>
            <mat-form-field
              floatLabel="auto"
              hideRequiredMarker
              appearance="outline"
              matTooltip="Last name"
              matTooltipPosition="right"
              class="formWidth field"
            >
              <mat-label class="global_search"
                >{{ "APPLICATION.LAST_NAME" | translate
                }}<span class="star">*</span></mat-label
              >
              <input
                matInput
                name="lastnameContact {{ i }}"
                [(ngModel)]="emergencyContact.lastname"
                autocomplete="off"
                required
              />
            </mat-form-field>

            <mat-form-field
              floatLabel="auto"
              hideRequiredMarker
              appearance="outline"
              matTooltip="Address"
              matTooltipPosition="right"
              class="add_address field"
            >
              <mat-label class="global_search">{{
                "DRIVERS.ADDRESS" | translate
              }}</mat-label>
              <input
                matInput
                [matAutocomplete]="contact_address"
                [(ngModel)]="emergencyContact.location"
                name="addressContact {{ i }}"
                (input)="driverReferenceAddressChange($event.target.value)"
                autocomplete="off"
              />
              <mat-icon matSuffix class="icon_search">search</mat-icon>
              <mat-autocomplete
                #contact_address="matAutocomplete"
                name="contactDriverLocation"
                [displayWith]="displayLocContact"
                (optionSelected)="
                  selectContactDriverLocation($event.option.value, i)
                "
              >
                <ng-container *ngIf="searchReferenceResult.length > 0">
                  <mat-option
                    *ngFor="let location of searchReferenceResult"
                    [value]="location"
                  >
                    <span>{{
                      location.address + ", " + location.countryCode
                    }}</span>
                  </mat-option>
                </ng-container>
              </mat-autocomplete>
            </mat-form-field>

            <mat-form-field
              floatLabel="auto"
              hideRequiredMarker
              appearance="outline"
              matTooltip="Relationship to you"
              matTooltipPosition="right"
              class="formWidth field"
            >
              <mat-label class="global_search"
                >{{ "APPLICATION.RELATIONSHIP" | translate
                }}<span class="star">*</span></mat-label
              >
              <input
                matInput
                name="relationship {{ i }}"
                [(ngModel)]="emergencyContact.relationship"
                autocomplete="off"
                required
              />
            </mat-form-field>
            <mat-form-field
              floatLabel="auto"
              hideRequiredMarker
              appearance="outline"
              matTooltip="Phone number"
              matTooltipPosition="right"
              class="formWidth field"
            >
              <mat-label class="global_search"
                >{{ "APPLICATION.PHONE_NUMBER" | translate
                }}<span class="star">*</span></mat-label
              >
              <input
                matInput
                name="phoneContact {{ i }}"
                autocomplete="off"
                [(ngModel)]="emergencyContact.phoneNumber"
                required
                pattern="[- +()0-9]+"
                maxlength="18"
              />
            </mat-form-field>
          </mat-card>
          <button
            type="button"
            class="remove-button"
            (click)="deleteContact(i)"
          >
            {{ "APPLICATION.REMOVE" | translate }}
          </button>
        </div>
        <div>
          <button type="button" class="addContact" (click)="addContact()">
            {{ "APPLICATION.NEW_CONTACT" | translate }}
          </button>
        </div>
      </div>
      <div>
        <button
          mat-flat-button
          class="buttonNext"
          (click)="next(emergencyContact.form.invalid, null, 'step6')"
        >
          {{ "APPLICATION.NEXT" | translate }}
        </button>
        <button
          mat-flat-button
          class="buttonBack"
          color="warn"
          matStepperPrevious
        >
          {{ "APPLICATION.BACK" | translate }}
        </button>
      </div>
    </form>
  </mat-step>

  <mat-step
    [completed]="confirm.form.valid"
    label="{{ 'APPLICATION.COMFIRM' | translate }}"
  >
    <form #confirm="ngForm">
      <mat-card>
        <h3 class="example-h7">
          {{ "APPLICATION.STATEMENT" | translate }}
        </h3>
        <h3 class="example-h7">
          {{ "APPLICATION.STATEMENT1" | translate }}
        </h3>
        <h3 class="example-h7">
          {{ "APPLICATION.STATEMENT2" | translate }}
        </h3>
        <h3 class="example-h7">
          {{ "APPLICATION.STATEMENT3" | translate }}
        </h3>
        <h3 class="example-h7">
          {{ "APPLICATION.STATEMENT4" | translate }}
        </h3>
        <div
          class="choose"
          [ngClass]="{
            'invalid-questions':
              (radioGroupAccept.invalid && loadedData && submited) ||
              (submited && !driverFull.finalConfirmation)
          }"
        >
          <h4 class="example-h2">
            {{ "APPLICATION.CHOOSE_ONE" | translate
            }}<span class="star">*</span>
          </h4>
          <section class="example-section">
            <mat-radio-group
              #radioGroupAccept="ngModel"
              name="radioGroupAccept"
              [(ngModel)]="driverFull.finalConfirmation"
              required
            >
              <mat-radio-button class="example-margin" [value]="true">{{
                "APPLICATION.I_ACCEPT" | translate
              }}</mat-radio-button>
              <mat-radio-button class="example-margin" [value]="false">{{
                "APPLICATION.I_DO_NOT_ACCEPT" | translate
              }}</mat-radio-button>
            </mat-radio-group>
          </section>
        </div>
      </mat-card>
      <div>
        <button
          mat-flat-button
          class="buttonNext"
          (click)="
            next(
              confirm.form.invalid,
              !driverFull.finalConfirmation,
              'step7',
              true
            )
          "
        >
          {{ "APPLICATION.DONE" | translate }}
        </button>
        <button
          mat-flat-button
          class="buttonBack"
          color="warn"
          (click)="backConfirm()"
          matStepperPrevious
        >
          {{ "APPLICATION.BACK" | translate }}
        </button>
      </div>
    </form>
  </mat-step>
</mat-horizontal-stepper>
