import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FileSystemFileEntry, NgxFileDropEntry } from 'ngx-file-drop';
import { Subject } from 'rxjs';
import { LoadService, ToastrTranslateService } from 'src/app/_services';
import { DocumentFactory } from 'src/app/_services/factory/document.factory.service';
import { LoadDocument } from '../../../_models/load/loadDocument';

@Component({
  selector: 'app-replace-load-document',
  templateUrl: './replace-load-document.component.html',
  styleUrls: ['./replace-load-document.component.scss'],
})
export class ReplaceLoadDocumentComponent implements OnInit, OnDestroy {
  @Input() previewDocumentId: Subject<number>;
  @Input() previewDocumentBytes: Subject<string>;
  @Input() uploading: Subject<boolean>;
  @Output() documentListUpdated = new EventEmitter<LoadDocument[]>();

  private documentList = [];
  public fileUploadLoading = false;
  public fileUploadErrorMessage = '';
  docTarget: any;
  public files: NgxFileDropEntry[] = [];
  public base64: string;
  public updatedFiles: File[] = [];
  public isExist = false;

  constructor(
    private loadService: LoadService,
    private toastrTranslateService: ToastrTranslateService,
    private documentFactory: DocumentFactory
  ) {}

  ngOnInit() {
    this.previewDocumentId.subscribe((id) => {
      if (id) {
        this.loadService.getDocumentPreview(id).subscribe({
          next: (response) => {
            if (response && response.bytes.length > 0) {
              this.base64 = response.bytes;
              this.isExist = true;
            } else {
              this.toastrTranslateService.error(
                'CONFIRM_DIALOG.FAILED_LOAD_DOCUMENT'
              );
            }
          },
          error: (response) => {
            this.toastrTranslateService.error(
              response,
              'CONFIRM_DIALOG.FAILED_LOAD_DOCUMENT'
            );
          },
        });
      } else {
        this.base64 = null;
        this.isExist = false;
      }
    });

    this.previewDocumentBytes.subscribe((bytes) => {
      if (bytes && bytes.length > 0) {
        this.base64 = bytes;
        this.isExist = true;
      }
    });
  }

  dropped(files: NgxFileDropEntry[]) {
    this.files = files;
    this.updatedFiles = [];
    this.fileUploadLoading = true;
    for (let i = 0; i < files.length; i++) {
      const uploadFile = files[i];
      const file: FileSystemFileEntry = files[i]
        .fileEntry as FileSystemFileEntry;
      if (uploadFile.fileEntry.isFile) {
        if (this.isPDF(uploadFile.relativePath)) {
          file.file((file: File) => {
            this.updatedFiles.push(file);
            const myReader: FileReader = new FileReader();
            myReader.onloadend = (e) => {
              this.docTarget = myReader.result;
              this.docTarget = this.docTarget.substr(28);
              this.documentFactory.addDocument(this.docTarget, file.name);
              this.isDocExist(files.length > 1);
              this.fileUploadLoading = false;
            };
            myReader.readAsDataURL(file);
          });
        }
      }
    }
  }

  private isPDF(path: string): boolean {
    const pathSplit = path.split('.');
    if (pathSplit.length > 0) {
      const extension = pathSplit[pathSplit.length - 1];
      if (extension === 'pdf') {
        return true;
      }
    }
    return false;
  }

  openFileSelector($event) {}

  changeListener($event): void {
    this.readThis($event.target);
  }

  readThis(inputValue: any): void {
    this.isExist = false;
    this.updatedFiles = inputValue.files;
    const file: File = inputValue.files[0];
    const myReader: FileReader = new FileReader();
    myReader.onloadend = (e) => {
      this.docTarget = myReader.result;
      this.docTarget = this.docTarget.substr(28);
      this.documentFactory.addDocument(
        this.docTarget,
        this.updatedFiles[0].name
      );
      this.isDocExist(inputValue.files.lengt > 1);
    };
    myReader.readAsDataURL(file);
  }

  isDocExist(isMultiupload: boolean) {
    if (this.documentFactory.isTargetExist()) {
      this.documentList[0] = new LoadDocument();
      this.documentList[0].bytes = this.documentFactory.getDocumentBytes();
      this.documentList[0].fileNameWithExtension =
        this.documentFactory.getDocumentName();
      this.documentList[0].id = null;
      this.documentList[0].idTemp =
        new Date().getUTCMilliseconds() + Math.random();
      this.documentListUpdated.emit(this.documentList);
      if (!isMultiupload) {
        this.isExist = this.documentFactory.isTargetExist();
        this.base64 = this.documentFactory.getDocumentBytes();
      }
    }
  }

  removeDocument() {
    this.updatedFiles = [];
    this.documentFactory.clearDocument();
    this.isExist = false;
  }

  ngOnDestroy() {
    this.removeDocument();
  }
}
