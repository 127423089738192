import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Location } from 'src/app/_models/location/location';

@Component({
  selector: 'app-address-list',
  templateUrl: './address-list.component.html',
  styleUrls: ['./address-list.component.scss'],
})
export class AddressListComponent {
  @Input() addressList: Location[];
  @Output() addressListUpdates = new EventEmitter<Location[]>();

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.addressList, event.previousIndex, event.currentIndex);
    for (let i = 0; i < this.addressList.length; i++) {
      this.addressList[i].sequence = i;
    }
    this.addressListUpdates.emit(this.addressList);
  }

  deleteRow(x: number) {
    this.addressList.splice(x, 1);
    this.addressListUpdates.emit(this.addressList);
  }
}
