<h2 class="text">
  {{ "CUSTOMER.ADD_CUSTOMER" | translate }}
</h2>
<hr />
<form #editCustomer="ngForm">
  <div>
    <mat-form-field
      floatLabel="auto"
      hideRequiredMarker
      appearance="outline"
      class="customerName"
    >
      <mat-label class="global_search"
        >{{ "CUSTOMER.CUSTOMER_NAME" | translate
        }}<span class="star">*</span></mat-label
      >
      <input
        matInput
        [(ngModel)]="customer.name"
        required
        autocomplete="off"
        name="nameCustomer"
      />
    </mat-form-field>
  </div>
  <div>
    <mat-form-field
      floatLabel="auto"
      hideRequiredMarker
      appearance="outline"
      class="formWidth"
    >
      <mat-label class="global_search">{{
        "CUSTOMER.PHONE_NUMBER" | translate
      }}</mat-label>
      <input
        matInput
        autocomplete="off"
        [(ngModel)]="customer.phoneNumber"
        name="phoneNumber"
        pattern="[- +()0-9]+"
        maxlength="18"
      />
    </mat-form-field>

    <mat-form-field
      floatLabel="auto"
      hideRequiredMarker
      appearance="outline"
      class="formWidth"
    >
      <mat-label class="global_search">{{
        "CUSTOMER.EXT_NUMBER" | translate
      }}</mat-label>
      <input
        matInput
        autocomplete="off"
        [(ngModel)]="customer.ext"
        name="ext"
      />
    </mat-form-field>
  </div>
  <div>
    <mat-form-field
      floatLabel="auto"
      hideRequiredMarker
      appearance="outline"
      class="formWidth"
    >
      <mat-label class="global_search">{{
        "CUSTOMER.OTHER_PHONE" | translate
      }}</mat-label>
      <input
        matInput
        autocomplete="off"
        [(ngModel)]="customer.otherNumber"
        name="otherNumber"
        pattern="[- +()0-9]+"
        maxlength="18"
      />
    </mat-form-field>

    <mat-form-field
      floatLabel="auto"
      hideRequiredMarker
      appearance="outline"
      class="formWidth"
    >
      <mat-label class="global_search">{{
        "CUSTOMER.EXT_OTHER_NUMBER" | translate
      }}</mat-label>
      <input
        matInput
        [(ngModel)]="customer.otherPhoneExt"
        autocomplete="off"
        name="otherPhoneExt"
      />
    </mat-form-field>
  </div>
  <div>
    <mat-form-field
      floatLabel="auto"
      hideRequiredMarker
      appearance="outline"
      class="formWidth"
    >
      <mat-label class="global_search">{{
        "CUSTOMER.EMAIL" | translate
      }}</mat-label>
      <input
        matInput
        email
        [(ngModel)]="customer.email"
        pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}?"
        type="email"
        name="email"
        autocomplete="off"
      />
    </mat-form-field>

    <mat-form-field
      floatLabel="auto"
      hideRequiredMarker
      appearance="outline"
      class="formWidth"
    >
      <mat-label class="global_search">{{
        "CUSTOMER.BILLING_EMAIL" | translate
      }}</mat-label>
      <input
        matInput
        email
        type="email"
        [(ngModel)]="customer.billingEmail"
        pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}?"
        autocomplete="off"
        name="billingEmail"
      />
    </mat-form-field>
  </div>
  <div>
    <mat-form-field
      floatLabel="auto"
      hideRequiredMarker
      appearance="outline"
      class="formWidth"
    >
      <mat-label class="global_search">{{
        "CUSTOMER.MC" | translate
      }}</mat-label>
      <input
        matInput
        #mc
        [(ngModel)]="customer.mc"
        (blur)="checkBrokerFromMc(mc.value)"
        autocomplete="off"
        name="mc"
      />
    </mat-form-field>

    <mat-form-field
      floatLabel="auto"
      hideRequiredMarker
      appearance="outline"
      class="formWidth"
    >
      <mat-label class="global_search">{{
        "CUSTOMER.FF" | translate
      }}</mat-label>
      <input matInput autocomplete="off" [(ngModel)]="customer.ff" name="ff" />
    </mat-form-field>
  </div>
  <div>
    <mat-form-field
      appearance="outline"
      floatLabel="auto"
      class="formWidth"
      floatLabel="always"
    >
      <mat-label>{{ "CUSTOMER.CREDIT_LIMIT" | translate }}</mat-label>
      <input
        matInput
        onfocus="this.select()"
        class="alignLeft"
        autocomplete="off"
        [(ngModel)]="customer.creditLimit"
        name="creditLimit"
      />
      <span matPrefix class="dolarPrefix">$&nbsp;</span>
    </mat-form-field>

    <mat-form-field
      floatLabel="auto"
      hideRequiredMarker
      appearance="outline"
      class="formWidth"
    >
      <mat-label class="global_search">{{
        "CUSTOMER.FEDERAL_ID" | translate
      }}</mat-label>
      <input
        matInput
        [(ngModel)]="customer.federalId"
        autocomplete="off"
        name="federalId"
      />
    </mat-form-field>
  </div>
  <div>
    <mat-form-field
      floatLabel="auto"
      hideRequiredMarker
      appearance="outline"
      class="customerNotes"
    >
      <mat-label class="global_search">{{
        "CUSTOMER.NOTES" | translate
      }}</mat-label>
      <input
        matInput
        [(ngModel)]="customer.notes"
        autocomplete="off"
        name="notes"
      />
    </mat-form-field>
  </div>

  <div>
    <mat-slide-toggle
      color="primary"
      class="toggleCustomer"
      [checked]="toBool(customer.isBlacklisted)"
      (change)="setBlackListValue($event)"
      >{{ "CUSTOMER.BLACK_LISTED" | translate }}</mat-slide-toggle
    >

    <mat-slide-toggle
      color="primary"
      class="toggleCustomer"
      [checked]="toBool(customer.isBroker)"
      (change)="setBrokerValue($event)"
      >{{ "CUSTOMER.IS_BROKER" | translate }}</mat-slide-toggle
    >
    <mat-slide-toggle
      color="primary"
      class="toggleCustomer"
      [checked]="toBool(customer.isActive)"
      (change)="setActiveValue($event)"
      >{{ "CUSTOMER.IS_ACTIVE" | translate }}</mat-slide-toggle
    >
  </div>

  <hr />
  <div>
    <mat-card-title class="contactTitle">{{
      "CUSTOMER.CONTACT" | translate
    }}</mat-card-title>

    <mat-form-field
      floatLabel="auto"
      hideRequiredMarker
      appearance="outline"
      class="formWidth"
    >
      <mat-label class="global_search">{{
        "CUSTOMER.PRIMARY_CONTACT_NAME" | translate
      }}</mat-label>
      <input
        matInput
        [(ngModel)]="customer.primaryContact.name"
        autocomplete="off"
        name="contactName"
      />
    </mat-form-field>

    <mat-form-field
      floatLabel="auto"
      hideRequiredMarker
      appearance="outline"
      class="formWidth"
    >
      <mat-label class="global_search">{{
        "CUSTOMER.PRIMARY_CONTACT_PHONE" | translate
      }}</mat-label>
      <input
        matInput
        name="contactPhone"
        [(ngModel)]="customer.primaryContact.phone"
        autocomplete="off"
        pattern="[- +()0-9]+"
        maxlength="18"
      />
    </mat-form-field>
  </div>
  <div>
    <mat-form-field
      floatLabel="auto"
      hideRequiredMarker
      appearance="outline"
      class="formWidth"
    >
      <mat-label class="global_search">{{
        "CUSTOMER.PRIMARY_CONTACT_EMAIL" | translate
      }}</mat-label>
      <input
        matInput
        email
        type="email"
        [(ngModel)]="customer.primaryContact.mail"
        autocomplete="off"
        name="contactEmail"
      />
    </mat-form-field>

    <mat-form-field
      floatLabel="auto"
      hideRequiredMarker
      appearance="outline"
      class="formWidth"
    >
      <mat-label class="global_search">{{
        "CUSTOMER.PRIMARY_CONTACT_NOTE" | translate
      }}</mat-label>
      <input
        matInput
        [(ngModel)]="customer.primaryContact.note"
        autocomplete="off"
        name="contactNote"
      />
    </mat-form-field>
  </div>

  <hr />
  <div>
    <mat-card-title class="addressTitle">{{
      "CUSTOMER.ADDRESSES" | translate
    }}</mat-card-title>
    <div class="marginLeft">
      <app-address-list
        [addressList]="customer.addresses"
        (addressListUpdates)="eventFromAddresses($event)"
      ></app-address-list>
    </div>
    <mat-slide-toggle
      color="primary"
      [(ngModel)]="isAddressPoBox"
      class="toggleCustomerAddress"
      matTooltip="{{ 'CUSTOMER.ADD_ADDRESS_MANUALLY' | translate }}"
      name="isAddressPoBoxName"
    ></mat-slide-toggle>

    <mat-form-field
      floatLabel="auto"
      hideRequiredMarker
      appearance="outline"
      class="billingAddress"
      *ngIf="!isAddressPoBox"
    >
      <mat-label
        >{{ "CUSTOMER.ADD_ADDRESS" | translate
        }}<span class="star">*</span></mat-label
      >
      <input
        matInput
        #inputCustomerAddress
        autocomplete="off"
        [matAutocomplete]="location"
        [formControl]="searchAddress"
        [required]="requiredAddress"
        (blur)="autofillCustomerAddress()"
        name="customer_address"
      />
      <mat-icon matSuffix class="arrow_down">search</mat-icon>
      <mat-autocomplete
        #location="matAutocomplete"
        [displayWith]="displayFn"
        (optionSelected)="selectAddress($event.option.value)"
      >
        <ng-container *ngIf="searchAddressResult.length > 0">
          <mat-option
            *ngFor="let location of searchAddressResult"
            [value]="location"
          >
            <span>{{ location.address + ", " + location.countryCode }}</span>
          </mat-option>
        </ng-container>
      </mat-autocomplete>
    </mat-form-field>
    <mat-form-field
      floatLabel="auto"
      appearance="outline"
      class="billingAddress"
      hideRequiredMarker
      id="poBox"
      *ngIf="isAddressPoBox"
    >
      <mat-label
        >{{ "CUSTOMER.ADD_ADDRESS" | translate
        }}<span
          *ngIf="isAddressPoBox && customer?.addresses?.length == 0"
          class="star"
          >*</span
        ></mat-label
      >
      <input
        matInput
        [(ngModel)]="poBoxValue"
        (blur)="addAddressManualy()"
        name="poBoxValue"
        [required]="isAddressPoBox && customer?.addresses?.length == 0"
        autocomplete="off"
      />
    </mat-form-field>
    <br />

    <br />
    <mat-slide-toggle
      class="toggleBilling"
      color="primary"
      name="billingSame"
      [checked]="toBool(customer.isBillingSameAsAddress)"
      (change)="customerBillingAddressChange($event)"
      matTooltip="{{ 'CUSTOMER.BILLINGSAMEASADDRESS' | translate }}"
    >
      {{ "CUSTOMER.BILLINGSAMEASADDRESS" | translate }}</mat-slide-toggle
    >
    <br />
    <mat-slide-toggle
      color="primary"
      [(ngModel)]="isBillingAddressPoBox"
      (change)="billingAddressIsPoBox($event)"
      class="toggleCustomerAddress"
      matTooltip="{{ 'CUSTOMER.ADD_BILLING_ADDRESS_MANUALLY' | translate }}"
      name="isBillingAddressPoBoxName"
    ></mat-slide-toggle>

    <mat-form-field
      floatLabel="auto"
      hideRequiredMarker
      appearance="outline"
      class="billingAddress"
      *ngIf="!isBillingAddressPoBox"
    >
      <mat-label class="global_search"
        >{{ "CUSTOMER.BILLING_ADDRESS" | translate
        }}<span class="star">*</span></mat-label
      >
      <input
        [disabled]="customer.isBillingSameAsAddress ? true : null"
        matInput
        #inputBillingAddress
        [(ngModel)]="customer.billingAddress"
        [matAutocomplete]="customer_address"
        [formControl]="searchBillingAddress"
        (blur)="autofillBillingAddress()"
        [required]="!isBillingAddressPoBox"
        name="billingAddress"
      />
      <mat-icon matSuffix class="arrow_down">search</mat-icon>
      <mat-autocomplete
        #customer_address="matAutocomplete"
        [displayWith]="displayFn"
        (optionSelected)="selectBillingAddress($event.option.value)"
      >
        <ng-container *ngIf="searchBillingAddressResult.length > 0">
          <mat-option
            *ngFor="let location of searchBillingAddressResult"
            [value]="location"
          >
            <span>{{ location.address + ", " + location.countryCode }}</span>
          </mat-option>
        </ng-container>
      </mat-autocomplete>
    </mat-form-field>
    <mat-form-field
      floatLabel="auto"
      appearance="outline"
      class="billingAddress"
      hideRequiredMarker
      id="poBox"
      *ngIf="isBillingAddressPoBox"
    >
      <mat-label
        >{{ "CUSTOMER.BILLING_ADDRESS" | translate
        }}<span class="star">*</span></mat-label
      >
      <input
        matInput
        name="poBoxValueBilling"
        [(ngModel)]="poBoxBillingValue"
        [required]="isBillingAddressPoBox"
        (blur)="addBillingAddressManualy()"
        autocomplete="off"
      />
    </mat-form-field>
  </div>

  <div class="buttonDiv">
    <button mat-button class="buttonCancel" (click)="cancelAddCustomer()">
      {{ "CUSTOMER.CANCEL" | translate }}
    </button>
    <button
      mat-flat-button
      class="buttonSaveCustomer"
      (click)="save(editCustomer.form.invalid)"
    >
      {{ "CUSTOMER.SAVE" | translate }}
    </button>
  </div>
</form>
