<div class="modal-dialog formInModal">
  <h2 mat-dialog-title class="text">
    {{ "NEW_LOAD.DEAD_HEAD_H2" | translate }}
  </h2>
  <hr />
  <form #editMilage="ngForm">
    <mat-dialog-content class="box">
      <div class="inlineBlock">
        <mat-form-field
          floatLabel="auto"
          hideRequiredMarker
          appearance="fill"
          class="middleInput"
        >
          <mat-label class="global_search"
            >{{ "NEW_LOAD.DEAD_HEAD" | translate
            }}<span class="star">*</span></mat-label
          >
          <input
            matInput
            [(ngModel)]="deadhead"
            autocomplete="off"
            required
            name="loadDeadhead"
            class="fieldNumber"
            type="number"
          />
        </mat-form-field>
      </div>
    </mat-dialog-content>
    <br />
    <div>
      <mat-dialog-actions class="mat-dialog">
        <button mat-button class="btnCan" mat-dialog-close>
          {{ "NEW_LOAD.CANCEL" | translate }}
        </button>
        <button
          mat-flat-button
          class="btnGreen"
          (click)="changeDeadhead()"
          type="submit"
        >
          {{ "NEW_LOAD.OK" | translate }}
        </button>
      </mat-dialog-actions>
    </div>
  </form>
</div>
