import { CodeModel } from '../code';

export class PayrollSettings {
  id: number;
  type = new CodeModel();
  percentage: number;
  perMileLoaded: number;
  perMileDeadhead: number;
  perStop: number;
  other: number;
}
