import { Injectable } from '@angular/core';
import { DriverModel } from 'src/app/_models/driver/driver';
import { DriverFullModel } from 'src/app/_models/driver/driverFull';
import { TenantService } from '../tenant.service';
import { EmploymentHistoryFactory } from './employmentHistory.factory.service';

@Injectable({
  providedIn: 'root',
})
export class DriverFactory {
  constructor(
    private tenantService: TenantService,
    private employmentHistoryFactory: EmploymentHistoryFactory
  ) {}

  public getDriverUtcDate(driver: DriverModel): DriverModel {
    driver.cdlExpirationDate = this.tenantService.getTenetUtcDate(
      driver.cdlExpirationDate
    );
    driver.medicalCardExpirationDate = this.tenantService.getTenetUtcDate(
      driver.medicalCardExpirationDate
    );
    driver.annualDrivingRecordCheckDueDate = this.tenantService.getTenetUtcDate(
      driver.annualDrivingRecordCheckDueDate
    );
    driver.randomDrugTestDueDate = this.tenantService.getTenetUtcDate(
      driver.randomDrugTestDueDate
    );
    driver.employmentStart = this.tenantService.getTenetUtcDate(
      driver.employmentStart
    );
    driver.employmentEnd = this.tenantService.getTenetUtcDate(
      driver.employmentEnd
    );
    return driver;
  }

  public getFullDriverUtcDate(fullDriver: DriverFullModel): DriverFullModel {
    fullDriver.cdlExpirationDate = this.tenantService.getTenetUtcDate(
      fullDriver.cdlExpirationDate
    );
    fullDriver.medicalCardExpirationDate = this.tenantService.getTenetUtcDate(
      fullDriver.medicalCardExpirationDate
    );
    fullDriver.annualDrivingRecordCheckDueDate =
      this.tenantService.getTenetUtcDate(
        fullDriver.annualDrivingRecordCheckDueDate
      );
    fullDriver.randomDrugTestDueDate = this.tenantService.getTenetUtcDate(
      fullDriver.randomDrugTestDueDate
    );
    fullDriver.employmentStart = this.tenantService.getTenetUtcDate(
      fullDriver.employmentStart
    );
    fullDriver.employmentEnd = this.tenantService.getTenetUtcDate(
      fullDriver.employmentEnd
    );
    fullDriver.dateOfBirth = this.tenantService.getTenetUtcDate(
      fullDriver.dateOfBirth
    );
    fullDriver.expirationDateOfDOTPsysicalCard =
      this.tenantService.getTenetUtcDate(
        fullDriver.expirationDateOfDOTPsysicalCard
      );
    fullDriver.hazmatExpirationDate = this.tenantService.getTenetUtcDate(
      fullDriver.hazmatExpirationDate
    );
    fullDriver.twicExpirationDate = this.tenantService.getTenetUtcDate(
      fullDriver.twicExpirationDate
    );
    fullDriver.driverEmploymentHistories.forEach((history) => {
      this.employmentHistoryFactory.getDriverEmploymentHistoryUtcDate(history);
    });

    return fullDriver;
  }

  public getDriverTimeZoneDate(driver: DriverModel): DriverModel {
    driver.cdlExpirationDate = this.tenantService.getTenantTimeZoneDate(
      driver.cdlExpirationDate
    );
    driver.medicalCardExpirationDate = this.tenantService.getTenantTimeZoneDate(
      driver.medicalCardExpirationDate
    );
    driver.annualDrivingRecordCheckDueDate =
      this.tenantService.getTenantTimeZoneDate(
        driver.annualDrivingRecordCheckDueDate
      );
    driver.randomDrugTestDueDate = this.tenantService.getTenantTimeZoneDate(
      driver.randomDrugTestDueDate
    );
    driver.employmentStart = this.tenantService.getTenantTimeZoneDate(
      driver.employmentStart
    );
    driver.employmentEnd = this.tenantService.getTenantTimeZoneDate(
      driver.employmentEnd
    );

    return driver;
  }

  public getFullDriverTimeZoneDate(
    driverFull: DriverFullModel
  ): DriverFullModel {
    driverFull.cdlExpirationDate = this.tenantService.getTenantTimeZoneDate(
      driverFull.cdlExpirationDate
    );
    driverFull.medicalCardExpirationDate =
      this.tenantService.getTenantTimeZoneDate(
        driverFull.medicalCardExpirationDate
      );
    driverFull.annualDrivingRecordCheckDueDate =
      this.tenantService.getTenantTimeZoneDate(
        driverFull.annualDrivingRecordCheckDueDate
      );
    driverFull.randomDrugTestDueDate = this.tenantService.getTenantTimeZoneDate(
      driverFull.randomDrugTestDueDate
    );
    driverFull.employmentStart = this.tenantService.getTenantTimeZoneDate(
      driverFull.employmentStart
    );
    driverFull.employmentEnd = this.tenantService.getTenantTimeZoneDate(
      driverFull.employmentEnd
    );
    driverFull.dateOfBirth = this.tenantService.getTenantTimeZoneDate(
      driverFull.dateOfBirth
    );
    driverFull.expirationDateOfDOTPsysicalCard =
      this.tenantService.getTenantTimeZoneDate(
        driverFull.expirationDateOfDOTPsysicalCard
      );
    driverFull.hazmatExpirationDate = this.tenantService.getTenantTimeZoneDate(
      driverFull.hazmatExpirationDate
    );
    driverFull.twicExpirationDate = this.tenantService.getTenantTimeZoneDate(
      driverFull.twicExpirationDate
    );
    driverFull.driverEmploymentHistories.forEach((history) => {
      this.employmentHistoryFactory.getDriverEmploymentHistoryTimeZoneDate(
        history
      );
    });

    return driverFull;
  }
}
