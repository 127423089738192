import { Injectable } from '@angular/core';
import { LoadModel } from 'src/app/_models/load/load';
import { TenantService } from '../tenant.service';

@Injectable({
  providedIn: 'root',
})
export class LoadFactory {
  constructor(private tenantService: TenantService) {}

  public getLoadUtcDate(load: LoadModel): LoadModel {
    load.pickupDate = this.tenantService.getTenetUtcDate(load.pickupDate);
    load.deliveryDate = this.tenantService.getTenetUtcDate(load.deliveryDate);
    load.invoiceDate = this.tenantService.getTenetUtcDate(load.invoiceDate);
    return load;
  }

  public getLoadTimeZoneDate(load: LoadModel): LoadModel {
    load.pickupDate = this.tenantService.getTenantTimeZoneDate(load.pickupDate);
    load.deliveryDate = this.tenantService.getTenantTimeZoneDate(
      load.deliveryDate
    );
    load.invoiceDate = this.tenantService.getTenantTimeZoneDate(
      load.invoiceDate
    );
    return load;
  }
}
