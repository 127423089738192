

<!--
src/app/components/authentication-button/
  authentication-button.component.html
-->

<app-login-button *ngIf="(auth.isAuthenticated$ | async) === false">
</app-login-button>

<!-- <app-logout-button *ngIf="auth.isAuthenticated$ | async">
</app-logout-button> -->


