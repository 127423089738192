<div class="modal-dialog">
  <h2 mat-dialog-title>
    <mat-icon class="iconwarn">warning</mat-icon>
  </h2>
  <mat-dialog-content class="mat-typography">
    <p class="text">
      {{ "CONFIRM_DIALOG.CONFIRM_ADD_ACCOUNTING_CODE" | translate }} {{ value }}
    </p>
    <p *ngIf="key == 'status'" class="text">
      {{ "CONFIRM_DIALOG.CONFIRM_ADD_ACCOUNTING_STATUS" | translate }}
    </p>
    <p *ngIf="key == 'type'" class="text">
      {{ "CONFIRM_DIALOG.CONFIRM_ADD_ACCOUNTING_TYPE" | translate }}
    </p>
    <p *ngIf="key == 'category'" class="text">
      {{ "CONFIRM_DIALOG.CONFIRM_ADD_ACCOUNTING_CATEGORY" | translate }}
    </p>
    <p *ngIf="key == 'vendor'" class="text">
      {{ "CONFIRM_DIALOG.CONFIRM_ADD_ACCOUNTING_VENDOR" | translate }}
    </p>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-button class="btnCan" mat-dialog-close>
      {{ "CONFIRM_DIALOG.CANCEL" | translate }}
    </button>
    <button
      mat-flat-button
      class="btn btn-green"
      (click)="confirmAddition()"
      cdkFocusInitial
    >
      {{ "CONFIRM_DIALOG.ADD" | translate }}
    </button>
  </mat-dialog-actions>
</div>
