<h1 mat-dialog-title>{{ "STOP_ADDRESS.MANAGE_STOP_ADDRESS" | translate }}</h1>

<hr />
<mat-card>
  <app-address-list [addressList]="addressList"></app-address-list>

  <div
    mat-dialog-content
    class="mat-dialog-content"
    (stopAddressAdded)="onStopAddressAdded($event)"
  >
    <form [formGroup]="stopFormGroup" (ngSubmit)="onAddItem()">
      <mat-form-field
        floatLabel="auto"
        hideRequiredMarker
        appearance="outline"
        class="address"
      >
        <mat-label class="global_search">{{
          "STOP_ADDRESS.ADDRESS" | translate
        }}</mat-label>

        <input
          matInput
          [matAutocomplete]="stop_address"
          [formControl]="searchAddress"
          class="address"
        />
        <mat-autocomplete
          #stop_address="matAutocomplete"
          [displayWith]="displayFn"
        >
          <ng-container *ngIf="searchResult.length > 0">
            <mat-option
              *ngFor="let location of searchResult"
              [value]="location"
            >
              <span>{{
                location.address +
                  ", " +
                  location.city +
                  ", " +
                  location.country
              }}</span>
            </mat-option>
          </ng-container>
        </mat-autocomplete>
      </mat-form-field>
    </form>
  </div>

  <br />
  <div mat-dialog-actions class="buttons">
    <button mat-raised-button color="warn" (click)="closeDialog()">
      {{ "STOP_ADDRESS.CLOSE" | translate }}
    </button>
    <button
      mat-raised-button
      color="primary"
      type="submit"
      (click)="onAddItem()"
    >
      {{ "STOP_ADDRESS.ADD" | translate }}
    </button>
  </div>
</mat-card>
