import { AccountingModel, AccountingRequest } from 'src/app/_models/accounting';

export class AccountingMapper {
  static getAccountingRequest(
    accountingModel: AccountingModel
  ): AccountingRequest {
    const accountingRequest = new AccountingRequest();
    accountingRequest.accountingCategoryId =
      accountingModel.accountingCategory?.id;
    accountingRequest.accountingStatusId = accountingModel.accountingStatus?.id;
    accountingRequest.accountingTypeId = accountingModel.accountingType?.id;
    accountingRequest.amount = accountingModel.amount;
    accountingRequest.comment = accountingModel.comment;
    accountingRequest.date = accountingModel.date;
    accountingRequest.description = accountingModel.description;
    accountingRequest.driverId = accountingModel.driver?.id;
    accountingRequest.id = accountingModel.id;
    accountingRequest.truckId = accountingModel.truck?.id;
    accountingRequest.vendorId = accountingModel.vendor?.id;
    return accountingRequest;
  }
}
