import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import Common from '../_helpers/common';
import { PayrollListItemDto } from '../_models/_payroll/payrollListIitemDto';
import { Paginator } from '../_models/common/paginator';
import { DriverModel } from '../_models/driver/driver';
import { DriverFullModel } from '../_models/driver/driverFull';
import { Response } from '../_models/response';
import { ResponseData, ResponseDataGeneric } from '../_models/responseData';
import { DriverFactory } from './factory/driver.factory.service';

@Injectable({ providedIn: 'root' })
export class DriverService {
  constructor(private http: HttpClient, private driverFactory: DriverFactory) {}

  getDrivers(): Observable<ResponseData> {
    return this.http
      .get<Response>(`${environment.apiUrl}/drivers`)
      .pipe(map((response) => response.data as ResponseData));
  }

  getAllDrivers(
    paginator?: Paginator,
    isActive?: boolean
  ): Observable<ResponseData> {
    const params = new HttpParams({
      fromObject: {
        PageSize: '' + paginator.pageSize,
        SortBy: '' + paginator.sortBy,
        SortOrder: '' + paginator.sortOrder,
        isActive: Common.getSafeBooleanString(isActive),
      },
    });
    return this.http
      .get<Response>(`${environment.apiUrl}/drivers`, { params: params })
      .pipe(map((response) => response.data as ResponseData));
  }

  getDriver(id: number): Observable<DriverModel> {
    return this.http
      .get<Response>(`${environment.apiUrl}/drivers/${id}`)
      .pipe(map((response) => response.data as DriverModel))
      .pipe(
        tap((responseData) => {
          this.driverFactory.getDriverTimeZoneDate(responseData);
        })
      );
  }

  getFullDriver(id: number): Observable<DriverFullModel> {
    return this.http
      .get<Response>(`${environment.apiUrl}/drivers/${id}/full`)
      .pipe(map((response) => response.data as DriverFullModel))
      .pipe(
        tap((responseData) => {
          this.driverFactory.getFullDriverTimeZoneDate(responseData);
        })
      );
  }

  updateDriver(driver: DriverModel): Observable<DriverModel> {
    driver = this.driverFactory.getDriverUtcDate(driver);
    return this.http
      .put<Response>(`${environment.apiUrl}/drivers`, driver)
      .pipe(map((response) => response.data as DriverModel))
      .pipe(
        tap((responseData) => {
          this.driverFactory.getDriverTimeZoneDate(responseData);
        })
      );
  }

  updateFullDriver(fullDriver: DriverFullModel) {
    fullDriver = this.driverFactory.getFullDriverUtcDate(fullDriver);
    return this.http
      .patch<Response>(
        `${environment.apiUrl}/drivers/${fullDriver.id}/driver-application`,
        fullDriver
      )
      .pipe(map((response) => response.data as DriverFullModel))
      .pipe(
        tap((responseData) => {
          this.driverFactory.getFullDriverTimeZoneDate(responseData);
        })
      );
  }

  addDriver(driver: DriverModel) {
    driver = this.driverFactory.getDriverUtcDate(driver);
    return this.http
      .post<Response>(`${environment.apiUrl}/drivers`, driver)
      .pipe(map((response) => response.data as DriverModel))
      .pipe(
        tap((responseData) => {
          this.driverFactory.getDriverTimeZoneDate(responseData);
        })
      );
  }

  deleteDriver(id: number) {
    return this.http.delete<Response>(`${environment.apiUrl}/drivers/${id}`);
  }

  getPayrollList() {
    return this.http
      .get<Response>(`${environment.apiUrl}/drivers/payroll-list`)
      .pipe(
        map(
          (response) => response.data as ResponseDataGeneric<PayrollListItemDto>
        )
      );
  }
}
