import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastrTranslateService } from '../../../../_services/toastr.translate.service';
import { UserService } from '../../../../_services/user.service';

@Component({
  selector: 'app-change-pass-dialog',
  templateUrl: './change-pass-dialog.component.html',
  styleUrls: ['./change-pass-dialog.component.scss'],
})
export class ChangePassDialogComponent implements OnInit {
  userId: number;
  inputType = 'password';
  newPassword = '';
  repetedNewPassword = '';
  showPassword = false;

  constructor(
    private userService: UserService,
    private toastrTranslateService: ToastrTranslateService,
    private dialogRef: MatDialogRef<ChangePassDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {}

  ngOnInit(): void {
    this.userId = this.data.id;
  }

  showHidePassword() {
    if (this.inputType === 'password') {
      this.inputType = 'text';
      this.showPassword = true;
    } else {
      this.inputType = 'password';
      this.showPassword = false;
    }
  }

  save() {
    this.userService.changePassword(this.userId, this.newPassword).subscribe({
      next: (response) => {
        this.toastrTranslateService.success('CHANGE_PASS.SAVED');
        this.dialogRef.close();
      },
      error: (response) => {
        this.toastrTranslateService.error(response, 'CHANGE_PASS.NOT_SAVED');
      },
    });
  }
}
