<div class="modal-dialog">
  <h2 mat-dialog-title>
    <mat-icon class="iconwarn">warning</mat-icon>
  </h2>
  <mat-dialog-content class="mat-typography">
    <p class="text">{{ "GENERAL.QUESTION_DELETE" | translate }}</p>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-button class="btnCan" cdkFocusInitial mat-dialog-close>
      {{ "NEW_LOAD.CANCEL" | translate }}
    </button>
    <button mat-flat-button class="btn" color="warn" (click)="confirmDelete()">
      <span>{{ "GENERAL.DELETE" | translate }}</span>
    </button>
  </mat-dialog-actions>
</div>
