<div class="modal-dialog">
    <h2 mat-dialog-title>
        <mat-icon class="iconwarn">warning</mat-icon>
    </h2>
    <mat-dialog-content class="mat-typography">
        <p class="dialog-question">{{ "CONFIRM_DIALOG.CHANGE_TIMEZONE" | translate }}</p>
    </mat-dialog-content>
    <mat-dialog-actions>
        <button mat-button class="btnCan" mat-dialog-close cdkFocusInitial>
            {{ "CONFIRM_DIALOG.CANCEL" | translate }}
        </button>
        <button mat-flat-button (click)="confirm()" class="btn" color="warn">
            {{ "CONFIRM_DIALOG.YES" | translate }}
        </button>
    </mat-dialog-actions>
</div>
