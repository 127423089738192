import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-delete-payroll-confirm-popup',
  templateUrl: './delete-payroll-confirm-popup.component.html',
  styleUrls: ['./delete-payroll-confirm-popup.component.scss'],
})
export class DeletePayrollConfirmPopupComponent implements OnInit {
  constructor(
    private dialogRef: MatDialogRef<DeletePayrollConfirmPopupComponent>
  ) {}

  ngOnInit(): void {}
  confirm() {
    this.dialogRef.close(true);
  }
}
