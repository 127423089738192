import { Injectable } from '@angular/core';
import { CodeModel } from 'src/app/_models/code';
import { CodeService } from '../code.service';

@Injectable()
export class CodeFactory {
  private storeStatusList: CodeModel[];
  private storeEquipmentTypeList: CodeModel[];
  private storePayrollTypeList: CodeModel[];
  private storeHaulTypeList: CodeModel[];

  constructor(private codeService: CodeService) {}

  public async getStatusList(): Promise<CodeModel[]> {
    if (!this.storeStatusList) {
      this.storeStatusList = await this.codeService.getStatus();
      this.storeStatusList = this.storeStatusList?.sort(
        (x, y) => x.order - y.order
      );
    }
    return this.storeStatusList;
  }

  public async getEquipmentTypeList(): Promise<CodeModel[]> {
    if (!this.storeEquipmentTypeList) {
      this.storeEquipmentTypeList = await this.codeService.getEquipmentType();
    }
    return this.storeEquipmentTypeList;
  }

  public async getPayrollTypeList(): Promise<CodeModel[]> {
    if (!this.storePayrollTypeList) {
      this.storePayrollTypeList = await this.codeService.getPayrollType();
    }
    return this.storePayrollTypeList;
  }

  public async getHaulTypeList(): Promise<CodeModel[]> {
    if (!this.storeHaulTypeList) {
      this.storeHaulTypeList = await this.codeService.getHaulType();
    }
    return this.storeHaulTypeList;
  }

  public clearCodes() {
    this.storeStatusList = null;
    this.storeEquipmentTypeList = null;
    this.storePayrollTypeList = null;
    this.storeHaulTypeList = null;
  }
}
