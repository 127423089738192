import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommentModel } from 'src/app/_models/comments';

@Component({
  selector: 'app-comment-list',
  templateUrl: 'comment-list.component.html',
  styleUrls: ['comment-list.component.scss'],
})
export class CommentListComponent {
  @Input() commentList: CommentModel[];
  @Output() sendDataToParent = new EventEmitter<CommentModel[]>();
}
