import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-edit-milage-dialog',
  templateUrl: './edit-milage-dialog.component.html',
  styleUrls: ['./edit-milage-dialog.component.scss'],
})
export class EditMilageDialogComponent implements OnInit {
  milage;

  constructor(
    private dialogRef: MatDialogRef<EditMilageDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private milageData: any
  ) {}

  ngOnInit(): void {
    this.milage = this.milageData;
  }
  changeMilage() {
    this.dialogRef.close(this.milage);
  }
}
