import { PayrollSettings } from '../_payroll/payroll';
import { DispatcherModel } from '../dispatcher';
import { Location } from '../location/location';
import { UserModel } from '../user';

export class DriverModel extends UserModel {
  dedicatedDispatcher: DispatcherModel;
  dedicatedDispatcherId: number;
  cdlNumber: string;
  cdlExpirationDate: Date;
  medicalCardExpirationDate: Date;
  annualDrivingRecordCheckDueDate: Date;
  randomDrugTestDueDate: Date;
  employmentStart: Date;
  employmentEnd: Date;
  payrollSettings = new PayrollSettings();
  payrollTypeId: number;
  isActive: boolean;
  addresses: Location[];
  allowLogin: boolean;
}
