import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-add-other',
  templateUrl: './add-other.component.html',
  styleUrls: ['./add-other.component.scss'],
})
export class AddOtherComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
