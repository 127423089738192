import { Injectable } from '@angular/core';
import {
  PayrollCalculation,
  PayrollCalculationCreateRequest,
} from 'src/app/_models/_payroll/payroll-calculation';
import { TenantService } from '../tenant.service';

@Injectable({
  providedIn: 'root',
})
export class PayrollFactory {
  constructor(private tenantService: TenantService) {}

  public getPayrollCalculationCreateRequestUtcDate(
    payyrol: PayrollCalculationCreateRequest
  ): PayrollCalculationCreateRequest {
    payyrol.dateFrom = this.tenantService.getTenetUtcDate(payyrol.dateFrom);
    payyrol.dateTo = this.tenantService.getTenetUtcDate(payyrol.dateTo);
    return payyrol;
  }

  public getPayrollCalculationTimeZoneDate(
    payyrol: PayrollCalculation
  ): PayrollCalculation {
    payyrol.dateFrom = this.tenantService.getTenantTimeZoneDate(
      payyrol.dateFrom
    );
    payyrol.dateTo = this.tenantService.getTenantTimeZoneDate(payyrol.dateTo);
    return payyrol;
  }
}
