<div class="modal-dialog">
<mat-dialog-content class="box">
  <div>
    <h2 mat-dialog-title class="comment-title">
      {{ "LOAD_COMMENT.LOAD" | translate }} {{loadNumber}}
    </h2>
  </div>

  <mat-card>
    
    <app-comment-list [commentList]="commentList"></app-comment-list>
  </mat-card>
</mat-dialog-content>

<mat-dialog-actions class="mat-dialog">
  <button mat-button class="btnCan" mat-dialog-close>
    {{ "LOAD_COMMENT.OK" | translate }}
  </button>
</mat-dialog-actions>
</div>
