import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastrTranslateService, TruckService } from 'src/app/_services';

@Component({
  selector: 'app-comfirm-dialog',
  templateUrl: './comfirm-dialog.component.html',
  styleUrls: ['./comfirm-dialog.component.scss'],
})
export class ComfirmDialogComponent implements OnInit {
  constructor(
    private truckService: TruckService,
    private dialogRef: MatDialogRef<ComfirmDialogComponent>,
    private toastrTranslateService: ToastrTranslateService,
    @Inject(MAT_DIALOG_DATA) private truckId: number
  ) {}

  ngOnInit(): void {}

  deleteTruck() {
    this.truckService.deleteTruck(this.truckId).subscribe({
      next: (response) => {
        if (response.success) {
          this.toastrTranslateService.success('CONFIRM_DIALOG.SUCCESS_DELL');
          this.dialogRef.close({ success: response.success });
        } else {
          this.toastrTranslateService.error(
            response.message,
            'CONFIRM_DIALOG.FAILED_DELL'
          );
        }
      },
      error: (response) => {
        this.toastrTranslateService.error(
          response,
          'CONFIRM_DIALOG.FAILED_DELL'
        );
      },
    });
  }
}
