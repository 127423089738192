import { DispatcherModel } from '../dispatcher';
import { DriverModel } from '../driver/driver';
import { TruckModel } from '../truck';

export class LoadQuickPage {
  truck: TruckModel;
  driver: DriverModel;
  dispatcher: DispatcherModel;
  rate: number;
}
