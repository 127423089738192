import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { Observable } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  first,
  startWith,
  switchMap,
  tap,
} from 'rxjs/operators';
import { PayrollSettings } from 'src/app/_models/_payroll/payroll';
import { CodeModel } from 'src/app/_models/code';
import { Paginator } from 'src/app/_models/common/paginator';
import { DispatcherModel } from 'src/app/_models/dispatcher';
import { DriverModel } from 'src/app/_models/driver/driver';
import { Location } from 'src/app/_models/location/location';
import {
  DispatcherService,
  DriverService,
  LocationService,
  MapService,
  ToastrTranslateService,
  UserService,
} from 'src/app/_services';
import { CodeFactory } from 'src/app/_services/factory/code.factory.service';
import { UserDataService } from 'src/app/_services/user.data.service';
import { ConfirmDialogComponent } from 'src/app/common/confirm-dialog/confirm-dialog.component';
import { InfoDialogComponent } from 'src/app/common/info-dialog/info-dialog.component';

@Component({
  selector: 'app-edit-driver-dialog',
  templateUrl: './edit-driver-dialog.component.html',
  styleUrls: ['./edit-driver-dialog.component.scss'],
})
export class EditDriverDialogComponent implements OnInit {
  minDate: Date;
  searchAddress = new UntypedFormControl();

  isAddressPoBox: boolean = false;

  payrollTypeList: CodeModel[] = [];

  dispatcherList: DispatcherModel[] = [];
  searchResult: Location[];
  locationsData: Location[] = [];
  currentDispatcher = '';
  submited = false;

  myControl = new UntypedFormControl();
  filteredOptions: Observable<string[]>;
  driver: DriverModel;
  driverId: number;
  readOnly: boolean;
  dataLoading = true;
  payrollTypePercentageOrOwnerOperator = false;
  payrollTypePerMile = false;
  payrollType = new CodeModel();
  disableOpenAccountToggle = false;
  @ViewChild('inputAddress') inputAddress: ElementRef;

  constructor(
    private driverService: DriverService,
    private userService: UserService,
    private locationService: LocationService,
    private mapService: MapService,
    private dialogRef: MatDialogRef<EditDriverDialogComponent>,
    private dispatcherService: DispatcherService,
    private codeFactory: CodeFactory,
    private toastrTranslateService: ToastrTranslateService,
    public userDataService: UserDataService,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {
    const currentYear = new Date().getFullYear();
    this.minDate = new Date(currentYear - 11, 12, 99);
    this.codeFactory.getPayrollTypeList().then((data) => {
      this.payrollTypeList = data;
    });
  }

  ngOnInit(): void {
    this.driverId = this.data.id;
    this.readOnly = this.data.readOnly;
    this.driver = new DriverModel();
    this.driver.dedicatedDispatcher = new DispatcherModel();
    if (this.driverId) {
      this.driverService.getDriver(this.driverId).subscribe({
        next: (response) => {
          this.driver = response;
          this.searchAddress.setValue(this.driver.location);
          if (this.driver.location?.poBox) {
            this.isAddressPoBox = true;
          } else {
            this.isAddressPoBox = false;
          }

          this.initPayroll();
          this.disableDriverAccountOpening();
          if (this.driver.dedicatedDispatcher) {
            this.driver.dedicatedDispatcherId =
              this.driver.dedicatedDispatcher.id;
          }
          this.dataLoading = false;
        },
        error: (response) => {
          this.toastrTranslateService.error(response, 'DRIVERS.FAILED');
        },
      });
    } else {
      this.driver.isActive = true;
      this.driver.allowLogin = false;
      this.driver.payrollSettings = new PayrollSettings();
      this.driver.payrollSettings.type = new CodeModel();
      this.dataLoading = false;
    }

    this.searchAddress.valueChanges
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        startWith(''),
        tap(() => {
          this.searchResult = [];
        }),
        switchMap((value) => {
          if (value != '') {
            return this.mapService.searchMap(value);
          } else {
            this.driver.location = new Location();
            return (this.searchResult = []);
          }
        })
      )
      .subscribe((data) => {
        if (data && data.count != 0) {
          this.searchResult = data.collection;
        } else {
          this.searchResult = [];
        }
      });

    let dropDownFakePaginator = new Paginator();
    dropDownFakePaginator.pageSize = 1000;
    dropDownFakePaginator.sortOrder = 'asc';
    dropDownFakePaginator.sortBy = 'firstname';

    this.dispatcherService
      .getAllDispatchers(dropDownFakePaginator, true)
      .pipe(first())
      .subscribe((response) => {
        this.dispatcherList = response.collection;
      });
    this.locationService
      .getLocations()
      .pipe(first())
      .subscribe((response) => {
        this.locationsData = response;
      });
  }
  resendEmail() {
    this.userService.resendActivation(this.driver.id).subscribe({
      next: () => {
        this.toastrTranslateService.success(
          'APPLICATION.RESEND_ACTIVATION_EMAIL_SUCESS'
        );
      },
      error: (response) => {
        this.toastrTranslateService.error(
          response,
          'APPLICATION.RESEND_ACTIVATION_EMAIL_ERROR'
        );
      },
    });
  }

  private initPayroll() {
    if (this.driver.payrollSettings) {
      this.payrollType = this.driver.payrollSettings.type;
      this.payrollTypeChanged(this.driver.payrollSettings.type);
    } else {
      this.driver.payrollSettings = new PayrollSettings();
    }
  }

  payrollTypeChanged(patrollType: CodeModel) {
    this.submited = false;
    this.payrollTypePercentageOrOwnerOperator =
      patrollType != null &&
      Object.keys(patrollType).length > 0 &&
      (patrollType.name === 'Per_Percentage' ||
        patrollType.name === 'Owner_Operator');
    this.payrollTypePerMile =
      patrollType != null &&
      Object.keys(patrollType).length > 0 &&
      patrollType.name === 'Per_Mile';

    this.payrollTypePercentageOrOwnerOperator
      ? this.payrollSettingsOwnerOperator(this.driver.payrollSettings)
      : this.payrollSettingsClearPercentage(this.driver.payrollSettings);
  }

  payrollSettingsClearPercentage(
    payrollSettings: PayrollSettings
  ): PayrollSettings {
    delete payrollSettings['percentage'];
    return payrollSettings;
  }

  payrollSettingsOwnerOperator(
    payrollSettings: PayrollSettings
  ): PayrollSettings {
    delete payrollSettings['other'];
    delete payrollSettings['perMileDeadhead'];
    delete payrollSettings['perMileLoaded'];
    delete payrollSettings['perStop'];
    return payrollSettings;
  }

  autofillAddress() {
    setTimeout(() => {
      let inputValue =
        this.inputAddress.nativeElement.value !=
        this.driver.location.address + ', ' + this.driver.location.countryCode;
      if (!this.driver.location || inputValue) {
        if (this.searchResult.length > 0) {
          this.driver.location = this.searchResult[0];
          this.searchAddress.setValue(this.driver.location);
        } else if (inputValue && this.inputAddress.nativeElement.value) {
          this.clearAddress();
          this.toastrTranslateService.warning('GENERAL.ADDRESS_ISSUE');
        }
      }
    }, 1000);
  }

  clearAddress() {
    this.driver.location = new Location();
    this.driver.location.poBox = '';
    this.searchAddress.setValue('');
  }

  compareObjects(o1: any, o2: any): boolean {
    if (o1 && o2 && Object.keys(o1).length > 0 && Object.keys(o2).length > 0) {
      return o1.id === o2.id;
    }
    return false;
  }

  updateExternalId(driver: DriverModel): DriverModel {
    if (this.payrollType?.id) {
      this.driver.payrollTypeId = this.payrollType.id;
      this.driver.payrollSettings.type = this.payrollType;
    } else {
      this.driver.payrollSettings = new PayrollSettings();
    }

    if (this.driver.dedicatedDispatcher != null) {
      this.driver.dedicatedDispatcherId = this.driver.dedicatedDispatcher.id;
    }
    return driver;
  }

  selectDriverLocation(location: Location) {
    this.driver.location = location;
  }

  displayFn(location: Location): string {
    return location ? location.address + ', ' + location.countryCode : '';
  }

  setActiveValue(e) {
    if (e.checked) {
      this.driver.isActive = true;
    } else {
      this.driver.isActive = false;
    }
  }

  showWarning(driverId: number, activeState: boolean) {
    if (driverId && activeState == false) {
      const dialogWarning = this.dialog.open(ConfirmDialogComponent, {
        disableClose: true,
        position: { top: '20px' },
        data: {
          titleKey1: 'CONFIRM_DIALOG.CONFIRM_DRIVER_DISABLE_WARNING_1',
          titleKey2: 'CONFIRM_DIALOG.CONFIRM_DRIVER_DISABLE_WARNING_2',
        },
      });
      dialogWarning.afterClosed().subscribe((proceeed) => {
        if (!proceeed) {
          this.driver.isActive = true;
        }
      });
    }
  }

  showAddActionInfo() {
    const dialogInfo = this.dialog.open(InfoDialogComponent, {
      disableClose: true,
      position: { top: '20px' },
      data: {
        title: '',
        key1: 'CONFIRM_DIALOG.AFTER_CREATE_DRIVER_INFO_1',
        key2: 'CONFIRM_DIALOG.AFTER_CREATE_DRIVER_INFO_2',
      },
    });
    dialogInfo.afterClosed().subscribe((proceeed) => {
      this.dialogRef.close();
    });
  }

  setDriverAccountOpening(e) {
    if (e.checked) {
      this.driver.allowLogin = true;
    } else {
      this.driver.allowLogin = false;
    }
  }

  disableDriverAccountOpening() {
    this.driver.allowLogin
      ? (this.disableOpenAccountToggle = true)
      : (this.disableOpenAccountToggle = false);
  }

  toBool(boolVariable: any): boolean {
    if (boolVariable && boolVariable != 'undefined') {
      return Boolean(JSON.parse(boolVariable));
    }
    return false;
  }
  save(isInvalid) {
    this.submited = true;
    this.driver = this.updateExternalId(this.driver);
    if (
      isInvalid ||
      (!this.driver.location.address && !this.driver.location.poBox) ||
      !this.driver.payrollSettings?.type?.id
    ) {
      this.toastrTranslateService.warning('GENERAL.FORM_INVALID');
      return;
    }
    if (this.driverId) {
      this.driverService.updateDriver(this.driver).subscribe({
        next: () => {
          this.submited = false;
          this.toastrTranslateService.success(
            'DRIVERS.SAVED',
            this.driver.firstname + ' ' + this.driver.lastname
          );
          this.driver.allowLogin && !this.driver.accountActivated
            ? this.showAddActionInfo()
            : this.dialogRef.close();
        },
        error: (response) => {
          this.toastrTranslateService.error(response, 'DRIVERS.NOT_SAVED');
        },
      });
    } else {
      this.driverService.addDriver(this.driver).subscribe({
        next: () => {
          this.submited = false;
          this.toastrTranslateService.success(
            'DRIVERS.SAVED',
            this.driver.firstname + ' ' + this.driver.lastname
          );
          this.driver.allowLogin
            ? this.showAddActionInfo()
            : this.dialogRef.close();
        },
        error: (response) => {
          this.toastrTranslateService.error(response, 'DRIVERS.NOT_SAVED');
        },
      });
    }
  }
}
