<div class="modal-dialog">
  <p mat-dialog-title class="title">{{ "CONFIRM_DIALOG.LOAD" | translate }}: {{ loadNumber }}</p>
  <mat-dialog-content class="mat-typography d-flex">
    <p class="text">{{ "CONFIRM_DIALOG.CHANGE_STATUS" | translate }} {{ "CONFIRM_DIALOG.WANT_CHANGE_STATUS" | translate }}</p>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-button class="btnCan" mat-dialog-close>
      {{ "CONFIRM_DIALOG.CANCEL" | translate }}
    </button>
    <button
      mat-flat-button
      class="btn btn-green"
      (click)="changeStatus()"
      cdkFocusInitial
    >
      {{ "CONFIRM_DIALOG.YES" | translate }}
    </button>
  </mat-dialog-actions>
</div>
