export class LoadsTotals {
  totalCount: number = 0;
  totalMilage: number = 0;
  totalDeadHead: number = 0;
  totalRate: number = 0;
  totalLumperCost: number = 0;
  totalDetention: number = 0;
  totalOtherCost: number = 0;
  totalTotalInvoiced: number = 0;
  totalAdvancedPayment: number = 0;
  avgRpm: number = 0;
  totalToNu: number = 0;
}
