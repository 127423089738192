import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import {
  Observable,
  debounceTime,
  distinctUntilChanged,
  startWith,
  switchMap,
  tap,
} from 'rxjs';
import { AdminModel } from 'src/app/_models/admin';
import { Location } from 'src/app/_models/location/location';
import { UserModel } from 'src/app/_models/user';
import {
  MapService,
  ToastrTranslateService,
  UserService,
} from 'src/app/_services';
import { AdminService } from 'src/app/_services/admin.service';
import { UserDataService } from 'src/app/_services/user.data.service';
import { InfoDialogComponent } from 'src/app/common/info-dialog/info-dialog.component';

@Component({
  selector: 'app-edit-admins-dialog',
  templateUrl: './edit-admin-dialog.component.html',
  styleUrls: ['./edit-admin-dialog.component.scss'],
})
export class EditAdminDialogComponent implements OnInit {
  searchAddress = new UntypedFormControl();
  @ViewChild('inputAdminAddress') inputAdminAddress: ElementRef;

  admin: AdminModel;
  filteredLocations: Observable<any[]>;
  searchResult: Location[];
  adminId: number;
  readOnly: boolean;
  dataLoading = true;

  isAddressPoBox: boolean = false;

  registerForm: UntypedFormGroup;

  constructor(
    private adminService: AdminService,
    private userService: UserService,
    private dialogRef: MatDialogRef<EditAdminDialogComponent>,
    private mapService: MapService,
    private toastrTranslateService: ToastrTranslateService,
    public userDataService: UserDataService,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {}

  ngOnInit(): void {
    this.adminId = this.data.id;
    this.readOnly = this.data.readOnly;
    this.admin = new UserModel();
    if (this.adminId) {
      this.adminService.getAdmin(this.adminId).subscribe({
        next: (response) => {
          this.admin = response;
          this.searchAddress.setValue(this.admin.location);
          if (this.admin.location && this.admin.location.poBox) {
            this.isAddressPoBox = true;
          } else {
            this.isAddressPoBox = false;
          }
          this.dataLoading = false;
        },
        error: (response) => {
          this.toastrTranslateService.error(response, 'ADMIN.FAILED');
        },
      });
    } else {
      this.admin.isActive = true;
      this.dataLoading = false;
    }
    this.searchAddress.valueChanges
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        startWith(''),
        tap(() => {
          this.searchResult = [];
        }),
        switchMap((value) => {
          if (value != '') {
            return this.mapService.searchMap(value);
          } else {
            this.admin.location = new Location();
            return (this.searchResult = []);
          }
        })
      )
      .subscribe((data) => {
        if (data && data.count != 0) {
          this.searchResult = data.collection;
        } else {
          this.searchResult = [];
        }
      });
  }

  resendEmail() {
    this.userService.resendActivation(this.admin.id).subscribe({
      next: () => {
        this.toastrTranslateService.success(
          'APPLICATION.RESEND_ACTIVATION_EMAIL_SUCESS'
        );
      },
      error: (response) => {
        this.toastrTranslateService.error(
          response,
          'APPLICATION.RESEND_ACTIVATION_EMAIL_ERROR'
        );
      },
    });
  }

  autofillAdminAddress() {
    setTimeout(() => {
      let inputValue =
        this.inputAdminAddress.nativeElement.value !=
        this.admin.location?.address + ', ' + this.admin.location?.countryCode;
      if (!this.admin.location || inputValue) {
        if (this.searchResult.length > 0) {
          this.admin.location = this.searchResult[0];
          this.searchAddress.setValue(this.admin.location);
        } else if (inputValue && this.inputAdminAddress.nativeElement.value) {
          this.clearAddress();
          this.toastrTranslateService.warning('GENERAL.ADDRESS_ISSUE');
        }
      }
    }, 1000);
  }

  clearAddress() {
    this.admin.location = new Location();
    this.searchAddress.setValue('');
  }

  selectAdminLocation(location: Location) {
    this.admin.location = location;
  }

  displayFn(location: Location): string {
    if (location) {
      return location ? location.address + ', ' + location.countryCode : '';
    }
    return '';
  }

  setActiveValue(e) {
    if (e.checked) {
      this.admin.isActive = true;
    } else {
      this.admin.isActive = false;
    }
  }

  toBool(boolVariable: any) {
    if (boolVariable && boolVariable != 'undefined') {
      return Boolean(JSON.parse(boolVariable));
    }
    return false;
  }

  showAddActionInfo() {
    const dialogInfo = this.dialog.open(InfoDialogComponent, {
      disableClose: true,
      position: { top: '20px' },
      data: {
        title: 'CONFIRM_DIALOG.INVITATION_SENT',
        key1: 'CONFIRM_DIALOG.AFTER_CREATE_ADMIN_INFO_1',
        key2: 'CONFIRM_DIALOG.AFTER_CREATE_ADMIN_INFO_2',
      },
    });
    dialogInfo.afterClosed().subscribe((proceeed) => {
      this.dialogRef.close();
    });
  }

  save(isInvalid) {
    if (isInvalid) {
      this.toastrTranslateService.warning('GENERAL.FORM_INVALID');
      return;
    }

    if (!this.admin.location?.state && !this.admin.location?.poBox) {
      this.admin.location = null;
    }
    if (this.adminId) {
      this.adminService.updateAdmin(this.admin).subscribe({
        next: (response) => {
          this.admin = response;
          this.toastrTranslateService.success(
            'ADMIN.SAVED',
            this.admin.firstname + ' ' + this.admin.lastname
          );
          this.dialogRef.close();
        },
        error: (response) => {
          this.toastrTranslateService.error(response, 'ADMIN.NOT_SAVED');
        },
      });
    } else {
      this.adminService.addAdmin(this.admin).subscribe({
        next: (response) => {
          this.admin = response;
          this.toastrTranslateService.success(
            'ADMIN.SAVED',
            this.admin.firstname + ' ' + this.admin.lastname
          );
          this.showAddActionInfo();
        },
        error: (response) => {
          this.toastrTranslateService.error(response, 'ADMIN.NOT_SAVED');
        },
      });
    }
  }
}
