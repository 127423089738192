<div class="wrapperTable" id="wrapperManageTables">
  <div class="addNew">
    <button mat-icon-button class="refresh" (click)="refresh()">
      <mat-icon
        matTooltip="{{ 'MANAGE.REFRESH' | translate }}"
        matTooltipPosition="below"
        >refresh</mat-icon
      >
    </button>
    <button
      *ngIf="userDataService.isAdminDispatcher"
      mat-flat-button
      class="btnDialog"
      (click)="openDialogEditDriver(false)"
    >
      {{ "DRIVERS.ADD_DRIVER_BTN" | translate }}
    </button>
    <mat-form-field class="filterField">
      <input
        matInput
        (keyup)="applyFilter($event.target.value)"
        placeholder="{{ 'DRIVERS.FILTER' | translate }}"
        class="filterInput"
        [value]="searchTerm"
        autocomplete="off"
      />
      <mat-icon matPrefix class="filterIcon">search</mat-icon>
    </mat-form-field>
    <mat-slide-toggle
      [(ngModel)]="showInactive"
      (change)="refresh()"
      color="primary"
      class="activeToggle"
      matTooltip="{{ 'DRIVERS.SHOW_INACTIVE' | translate }}"
      matTooltipPosition="after"
    ></mat-slide-toggle>
  </div>

  <table
    mat-table
    matSort
    matSortDisableClear
    [dataSource]="dataSource"
    class="mat-elevation-z8"
    id="mat-table"
    *ngIf="count > 0"
  >
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef>
        {{ "DRIVERS.ID" | translate }}
      </th>
      <td mat-cell *matCellDef="let driver">
        {{ driver.id }}
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="driverName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ "DRIVERS.FULL_NAME" | translate }}
      </th>
      <td
        mat-cell
        *matCellDef="let driver"
        (click)="openDialogEditDriver(true, driver.id)"
        class="text-truncate"
      >
        <label matTooltip="{{ driver.firstname + ' ' + driver.lastname }}">
          {{ driver.firstname + " " + driver.lastname }}</label
        >
      </td>
      <td mat-footer-cell *matFooterCellDef>
        {{ "DRIVERS.COUNT" | translate }} {{ getCountOfElement(type) }}
      </td>
    </ng-container>

    <ng-container matColumnDef="address">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ "DRIVERS.ADDRESS" | translate }}
      </th>
      <td
        mat-cell
        *matCellDef="let driver"
        class="text-truncate"
        (click)="openDialogEditDriver(true, driver.id)"
      >
        <label
          *ngIf="driver.location"
          matTooltip="{{
            driver.location.address + ', ' + driver.location.countryCode
          }}"
        >
          <label *ngIf="driver.location.city">{{
            driver.location.city + ", " + driver.location.state
          }}</label>
          <label *ngIf="!driver.location.city">{{
            driver.location?.address
          }}</label>
        </label>
        <label
          *ngIf="driver?.location?.poBox"
          matTooltip="{{ driver.location.poBox }}"
          >{{ driver.location.poBox }}</label
        >
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>
    <ng-container matColumnDef="phoneNumber">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>
        {{ "DRIVERS.PHONE_NUMBER" | translate }}
      </th>
      <td
        mat-cell
        *matCellDef="let driver"
        (click)="openDialogEditDriver(true, driver.id)"
        class="number-truncate"
      >
        <label matTooltip="{{ driver.phoneNumber }}">
          {{ driver.phoneNumber }}
        </label>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="dispatcherFullName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="textLeft">
        <label matTooltip="{{ 'DRIVERS.DEDICATED_DISPATCHER' | translate }}">
          {{ "DRIVERS.DISPATCHER" | translate }}
        </label>
      </th>
      <td
        mat-cell
        *matCellDef="let driver"
        (click)="openDialogEditDriver(true, driver.id)"
        class="text-truncate"
      >
        <label
          *ngIf="driver.dedicatedDispatcher"
          matTooltip="{{
            driver.dedicatedDispatcher.firstname +
              ' ' +
              driver.dedicatedDispatcher.lastname
          }}"
          >{{
            driver.dedicatedDispatcher.firstname +
              " " +
              driver.dedicatedDispatcher.lastname
          }}
        </label>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>
    <ng-container matColumnDef="cdlNumber">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>
        {{ "DRIVERS.CDL_NUMBER" | translate }}
      </th>
      <td
        mat-cell
        *matCellDef="let driver"
        (click)="openDialogEditDriver(true, driver.id)"
      >
        <label matTooltip="{{ driver.cdlNumber }}">{{
          driver.cdlNumber
        }}</label>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="cdlExpirationDate">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>
        {{ "DRIVERS.CDL_EXPIRE" | translate }}
      </th>
      <td
        mat-cell
        *matCellDef="let driver"
        (click)="openDialogEditDriver(true, driver.id)"
      >
        <label
          matTooltip="{{
            driver.cdlExpirationDate | tenantDate | date : 'MM/dd/y '
          }}"
          >{{
            driver.cdlExpirationDate | tenantDate | date : "MM/dd/y "
          }}</label
        >
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>
    <ng-container matColumnDef="cdlExpDueNumber">
      <th mat-header-cell mat-sort-header *matHeaderCellDef class="dueMargin">
        <label matTooltip="{{ 'DRIVERS.CDL_EXPDUENUMBER' | translate }}">
          {{ "DRIVERS.CDL_EXPDUENUMBER_SHORT" | translate }}
        </label>
      </th>
      <td
        mat-cell
        *matCellDef="let driver"
        (click)="openDialogEditDriver(true, driver.id)"
      >
        <button
          mat-mini-fab
          *ngIf="driver.cdlExpirationDate"
          [ngClass]="driver.cdlExpirationDate + '' | colorSpanTodayDays"
        >
          {{ driver.cdlExpirationDate | spanTodayDays }}
        </button>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="medicalCardExpirationDate">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>
        <label matTooltip="{{ 'DRIVERS.MEDICAL_EXP_DATE' | translate }}">
          {{ "DRIVERS.MEDICAL_EXP_DATE_SHORT" | translate }}
        </label>
      </th>
      <td
        mat-cell
        *matCellDef="let driver"
        (click)="openDialogEditDriver(true, driver.id)"
        class="text-truncate"
      >
        <label
          matTooltip="{{
            driver.medicalCardExpirationDate | tenantDate | date : 'MM/dd/y '
          }}"
          >{{
            driver.medicalCardExpirationDate | tenantDate | date : "MM/dd/y "
          }}</label
        >
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>
    <ng-container matColumnDef="medicalExpDueNumber">
      <th mat-header-cell mat-sort-header *matHeaderCellDef class="dueMargin">
        <label matTooltip="{{ 'DRIVERS.MEDICAL_EXP_DUE_NUM' | translate }}">
          {{ "DRIVERS.MEDICAL_EXP_DUE_NUM_SHORT" | translate }}
        </label>
      </th>
      <td
        mat-cell
        *matCellDef="let driver"
        (click)="openDialogEditDriver(true, driver.id)"
      >
        <button
          mat-mini-fab
          *ngIf="driver.medicalCardExpirationDate"
          [ngClass]="driver.medicalCardExpirationDate + '' | colorSpanTodayDays"
        >
          {{ driver.medicalCardExpirationDate | spanTodayDays }}
        </button>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="annualDrivingRecordCheckDueDate">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>
        <label matTooltip="{{ 'DRIVERS.ANNUAL_DATE' | translate }}">
          {{ "DRIVERS.ANNUAL_DATE_SHORT" | translate }}
        </label>
      </th>
      <td
        mat-cell
        *matCellDef="let driver"
        (click)="openDialogEditDriver(true, driver.id)"
      >
        <label
          matTooltip="{{
            driver.annualDrivingRecordCheckDueDate
              | tenantDate
              | date : 'MM/dd/y '
          }}"
          >{{
            driver.annualDrivingRecordCheckDueDate
              | tenantDate
              | date : "MM/dd/y "
          }}</label
        >
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>
    <ng-container matColumnDef="annualDrivingCheckNumber">
      <th mat-header-cell mat-sort-header *matHeaderCellDef class="dueMargin">
        <label matTooltip="{{ 'DRIVERS.ANNUAL_DUE' | translate }}">
          {{ "DRIVERS.ANNUAL_DUE_SHORT" | translate }}
        </label>
      </th>
      <td
        mat-cell
        *matCellDef="let driver"
        (click)="openDialogEditDriver(true, driver.id)"
      >
        <button
          mat-mini-fab
          *ngIf="driver.annualDrivingRecordCheckDueDate"
          [ngClass]="
            driver.annualDrivingRecordCheckDueDate + '' | colorSpanTodayDays
          "
        >
          {{ driver.annualDrivingRecordCheckDueDate | spanTodayDays }}
        </button>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="randomDrugTestDueDate">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>
        <label matTooltip="{{ 'DRIVERS.DRUG_TEST_DATE' | translate }}">
          {{ "DRIVERS.DRUG_TEST_DATE_SHORT" | translate }}
        </label>
      </th>
      <td
        mat-cell
        *matCellDef="let driver"
        (click)="openDialogEditDriver(true, driver.id)"
      >
        <label
          matTooltip="{{
            driver.randomDrugTestDueDate | tenantDate | date : 'MM/dd/y '
          }}"
          >{{
            driver.randomDrugTestDueDate | tenantDate | date : "MM/dd/y "
          }}</label
        >
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>
    <ng-container matColumnDef="randomDrugTestDueNumber">
      <th mat-header-cell mat-sort-header *matHeaderCellDef class="dueMargin">
        <label matTooltip="{{ 'DRIVERS.DRUG_DUE_DAY' | translate }}">
          {{ "DRIVERS.DRUG_DUE_DAY_SHORT" | translate }}
        </label>
      </th>
      <td
        mat-cell
        *matCellDef="let driver"
        (click)="openDialogEditDriver(true, driver.id)"
      >
        <button
          mat-mini-fab
          *ngIf="driver.randomDrugTestDueDate"
          [ngClass]="driver.randomDrugTestDueDate + '' | colorSpanTodayDays"
        >
          {{ driver.randomDrugTestDueDate | spanTodayDays }}
        </button>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="employmentStart">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>
        <label matTooltip="{{ 'DRIVERS.EMPLOYMENT_START' | translate }}">
          {{ "DRIVERS.EMPLOYMENT_START_SHORT" | translate }}
        </label>
      </th>
      <td
        mat-cell
        *matCellDef="let driver"
        (click)="openDialogEditDriver(true, driver.id)"
      >
        <label
          matTooltip="{{
            driver.employmentStart | tenantDate | date : 'MM/dd/y '
          }}"
          >{{ driver.employmentStart | tenantDate | date : "MM/dd/y " }}</label
        >
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>
    <ng-container matColumnDef="employmentEnd">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>
        <label matTooltip="{{ 'DRIVERS.EMPLOYMENT_END' | translate }}">
          {{ "DRIVERS.EMPLOYMENT_END_SHORT" | translate }}
        </label>
      </th>
      <td
        mat-cell
        *matCellDef="let driver"
        (click)="openDialogEditDriver(true, driver.id)"
      >
        <label
          matTooltip="{{
            driver.employmentEnd | tenantDate | date : 'MM/dd/y '
          }}"
          >{{ driver.employmentEnd | tenantDate | date : "MM/dd/y " }}</label
        >
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>
    <ng-container matColumnDef="driver_app">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let driver">
        <!-- ToDo MYT-246 -->
        <!-- Raskomentarisati button kada se zavrsi story Driver app -->
        <button
          [disabled]="!userDataService.isAdminDispatcher"
          [matTooltip]="
            userDataService.isAdminDispatcher == true
              ? ('APPLICATION.TOOLTIP' | translate)
              : ('APPLICATION.TOOLTIP_NO_ACCESS' | translate)
          "
          mat-flat-button
          class="btnDialog"
          (click)="openDriverApp(false, driver.id)"
        >
          <span>{{ "DRIVERS.DRIVER_APP" | translate }}</span>
        </button>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>
    <ng-container matColumnDef="isActive">
      <th mat-header-cell *matHeaderCellDef>
        {{ "DRIVERS.ACTIVE" | translate }}
      </th>
      <td
        mat-cell
        *matCellDef="let driver"
        (click)="openDialogEditDriver(true, driver.id)"
      >
        <mat-icon *ngIf="toBool(driver.isActive)" color="primary"
          >check</mat-icon
        >
        <mat-icon *ngIf="!toBool(driver.isActive)" color="warn">close</mat-icon>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>
    <ng-container matColumnDef="safetyInfo">
      <th mat-header-cell *matHeaderCellDef>
        <label matTooltip="{{ 'DRIVERS.SAFETY_INFO' | translate }}">
          {{ "DRIVERS.INFO" | translate }}
        </label>
      </th>

      <td
        mat-cell
        *matCellDef="let driver"
        (click)="openDialogEditDriver(true, driver.id)"
      >
        <a routerLink="/safety-info"
          ><button mat-mini-fab color="basic" class="info">
            {{ "DRIVERS.INFO" | translate }}
          </button></a
        >
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef class="alignCenter">
        {{ "COMMON.ACTION" | translate }}
      </th>
      <td mat-cell *matCellDef="let driver" class="alignCenter">
        <button
          *ngIf="userDataService.isAdminDispatcher"
          mat-button
          class="edit"
          (click)="openDialogEditDriver(false, driver.id)"
        >
          {{ "COMMON.EDIT" | translate }}
        </button>
        <button [matMenuTriggerFor]="menu" mat-icon-button class="icon-color">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item (click)="openDialogEditDriver(true, driver.id)">
            {{ "COMMON.VIEW" | translate }}
          </button>
          <button
            *ngIf="userDataService.isAdminDispatcher"
            mat-menu-item
            (click)="openDialogEditDriver(false, driver.id)"
          >
            {{ "COMMON.EDIT" | translate }}
          </button>
          <button
            *ngIf="userDataService.isAdminDispatcher"
            mat-menu-item
            class="delete-button"
            (click)="openDialogComfirmDeleteDriver(driver.id)"
          >
            {{ "COMMON.DELETE" | translate }}
          </button>
        </mat-menu>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: displayedColumns"
      class="rowCursorPointer"
    ></tr>
    <tr
      mat-footer-row
      *matFooterRowDef="displayedColumns"
      class="table-footer"
    ></tr>
  </table>
  <app-info-nodata
    [hidden]="count > 0"
    [infoKey]="'DRIVERS.NO_DRIVERS'"
    class="no-data"
  ></app-info-nodata>
</div>
