import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, MatSortable } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { first } from 'rxjs/operators';
import { SortData } from 'src/app/_models/common/sortData';
import { CustomerModel } from 'src/app/_models/customer';
import { TableType } from 'src/app/_models/enums/tableType';
import { CustomerService } from 'src/app/_services';
import { LocalStorageService } from 'src/app/_services/local.storage.service';
import { LocalStorageSortService } from 'src/app/_services/local.storage.sort.service';

import { Paginator } from '../../_models/common/paginator';
import { ComfirmDialogCustomerComponent } from './comfirm-dialog-customer/comfirm-dialog-customer.component';
import { EditCustomerDialogComponent } from './edit-customer-dialog/edit-customer-dialog.component';

@Component({
  selector: 'app-customer',
  templateUrl: './customer.component.html',
  styleUrls: ['./customer.component.scss'],
})
export class CustomerComponent implements OnInit, AfterViewInit {
  totalSize = 0;
  pageSizeOptions: number[];
  pageSize;
  currentPage;
  searchTerm = '';
  sortData: SortData;
  showInactive = false;
  customersData: Array<CustomerModel>;
  count: number;

  @ViewChild(MatPaginator) matPaginator: MatPaginator;
  @ViewChild(MatSort) matSort: MatSort;

  constructor(
    private customerService: CustomerService,
    private localStorageService: LocalStorageService,
    private localStorageSortService: LocalStorageSortService,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.dataSource.paginator = this.matPaginator;
    this.initPaginatorOptions();
    this.sortData = this.localStorageSortService.getSortData(
      TableType.ManageCustomers
    );
  }

  dataSource = new MatTableDataSource<CustomerModel>();

  ngAfterViewInit() {
    setTimeout(() => {
      this.matSort.sort({
        id: this.sortData.activeColumn,
        start: this.sortData.order,
      } as MatSortable);
      this.dataSource.sort = this.matSort;
    });

    this.dataSource.sortingDataAccessor = (item, property) => {
      this.localStorageSortService.setSortData(
        TableType.ManageCustomers,
        this.matSort
      );
      return item[property];
    };
    this.matSort.sortChange.subscribe(() => {
      this.refresh();
      this.currentPage = 0;
    });
  }

  toBool(boolVariable: any) {
    if (boolVariable && boolVariable != 'undefined') {
      return Boolean(JSON.parse(boolVariable));
    }
    return false;
  }

  openDialogComfirmDeleteCustomer(id: number) {
    const dialogRef = this.dialog.open(ComfirmDialogCustomerComponent, {
      disableClose: true,
      position: { top: '20px' },
      data: id,
    });
    dialogRef.afterClosed().subscribe(() => {
      this.getCustomers();
    });
  }
  openDialogEditCustomer(readOnly: boolean, id?: number) {
    const dialogRef = this.dialog.open(EditCustomerDialogComponent, {
      disableClose: true,
      position: { top: '20px' },
      data: {
        id,
        readOnly,
      },
    });

    dialogRef.afterClosed().subscribe(() => {
      this.getCustomers();
    });
  }

  searchCustomers(searchTerm: string) {
    this.currentPage = 0;
    this.searchTerm = searchTerm;
    this.getCustomers();
  }

  getCustomers() {
    this.customerService
      .getCustomers(this.getPaginator(), !this.showInactive)
      .pipe(first())
      .subscribe((response) => {
        this.dataSource.data = response.collection;
        setTimeout(() => {
          this.matPaginator.length = response.count;
          this.matPaginator.pageIndex = this.currentPage;
          this.count = response.count;
        });
      });
  }

  private getPaginator(): Paginator {
    const paginator = new Paginator();
    paginator.pageSize = this.pageSize;
    paginator.pageNumber = this.currentPage + 1;
    paginator.searchTerm = this.searchTerm;
    paginator.sortBy = this.matSort?.active;
    paginator.sortOrder = this.matSort?.direction;
    this.localStorageSortService.setSortData(
      TableType.ManageCustomers,
      this.matSort
    );
    return paginator;
  }

  refresh() {
    this.searchTerm = '';
    this.getCustomers();
  }

  initPaginatorOptions() {
    this.pageSize = this.localStorageService.getPagginatorPageSize();
    this.pageSizeOptions = [10, 25, 50, 100];
    this.currentPage = 0;
  }

  onChangePage(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    this.localStorageService.setPagginatorPageSize(event.pageSize);
    this.getCustomers();
  }

  displayedColumns: string[] = [
    'customerName',
    'billingAddress',
    'address',
    'primaryContact',
    'phoneNumber',
    'phoneExtNumber',
    'email',
    'billingEmail',
    'mc',
    'ff',
    'otherNumber',
    'otherPhoneExtNumber',
    'isBlacklisted',
    'isBroker',
    'isActive',
    'creditLimit',
    'federalId',
    'action',
  ];
}
