<div class="wrapperTable" id="wrapperManageTables">
  <div class="addNew" [hidden]="count == null || count == 0">
    <button mat-icon-button class="refresh" (click)="refresh()">
      <mat-icon
        matTooltip="{{ 'MANAGE.REFRESH' | translate }}"
        matTooltipPosition="below"
        >refresh</mat-icon
      >
    </button>
  </div>

  <table
    mat-table
    matSort
    matSortDisableClear
    [dataSource]="dataSource"
    class="mat-elevation-z8"
    [hidden]="count == null || count == 0"
  >
    <ng-container matColumnDef="firstName">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>
        <label matTooltip="{{ 'APPLICATION.FIRST_NAME' | translate }}">{{
          "APPLICATION.FIRST_NAME" | translate
        }}</label>
      </th>
      <td mat-cell *matCellDef="let driver" class="text-truncate">
        <label matTooltip="{{ driver.firstname }}">{{
          driver.firstname
        }}</label>
      </td>
    </ng-container>

    <ng-container matColumnDef="lastName">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>
        {{ "APPLICATION.LAST_NAME" | translate }}
      </th>
      <td mat-cell *matCellDef="let driver" class="text-truncate">
        <label matTooltip="{{ driver.lastname }}">{{ driver.lastname }}</label>
      </td>
    </ng-container>
    <ng-container matColumnDef="address">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>
        {{ "APPLICATION.ADDRESS" | translate }}
      </th>
      <td mat-cell *matCellDef="let driver" class="text-truncate">
        <label
          *ngIf="driver.location"
          matTooltip="{{
            driver.location.address + ', ' + driver.location.countryCode
          }}"
          ><label *ngIf="driver?.location?.city">
            {{ driver.location.city + ", " + driver.location.state }}
          </label>
          <label *ngIf="!driver?.location?.city">
            {{ driver.location.address }}
          </label>
        </label>
        <label
          *ngIf="driver?.location?.poBox"
          matTooltip="{{ driver?.location?.poBox }}"
          >{{ driver?.location?.poBox }}
        </label>
      </td>
    </ng-container>

    <ng-container matColumnDef="phoneNumber">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>
        {{ "APPLICATION.PHONE" | translate }}
      </th>
      <td mat-cell *matCellDef="let driver" class="number-truncate">
        <label matTooltip="{{ driver.phoneNumber }}">
          {{ driver.phoneNumber }}
        </label>
      </td>
    </ng-container>
    <ng-container matColumnDef="email">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>
        {{ "APPLICATION.EMAIL" | translate }}
      </th>
      <td mat-cell *matCellDef="let driver" class="notes-truncate">
        <label matTooltip="{{ driver.email }}">{{ driver.email }}</label>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <div [hidden]="count == null || count == 0 || totalSize == 0">
    <mat-paginator
      (page)="onChangePage($event)"
      class="paging"
      aria-label="Select page"
      [length]="totalSize"
      [showFirstLastButtons]="true"
      [pageIndex]="currentPage"
      [pageSize]="pageSize"
      [pageSizeOptions]="pageSizeOptions"
    >
    </mat-paginator>
  </div>
</div>

<app-info-nodata
  *ngIf="count === 0"
  [infoKey]="'APPLICATION.NO_APPLICATIONS'"
></app-info-nodata>
