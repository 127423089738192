import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import Common from '../_helpers/common';
import { Location } from '../_models/location/location';
import { Response } from '../_models/response';
import { TruckModel } from '../_models/truck';
import { TruckFactory } from './factory/truck.factory.service';

@Injectable({ providedIn: 'root' })
export class TruckService {
  constructor(private http: HttpClient, private truckFactory: TruckFactory) {}

  getTrucks(isActive?: boolean): Observable<TruckModel[]> {
    const params = new HttpParams({
      fromObject: {
        isActive: Common.getSafeBooleanString(isActive),
      },
    });
    return this.http
      .get<Response>(`${environment.apiUrl}/trucks`, { params: params })
      .pipe(map((response) => response.data as TruckModel[]));
  }

  getTruck(truckId: number): Observable<TruckModel> {
    return this.http
      .get<Response>(`${environment.apiUrl}/trucks/${truckId}`)
      .pipe(map((response) => response.data as TruckModel))
      .pipe(
        tap((responseData) => {
          this.truckFactory.getTruckTimeZoneDate(responseData);
        })
      );
  }

  addTruck(truck: TruckModel) {
    truck = this.truckFactory.getTruckUtcDate(truck);
    return this.http
      .post<Response>(`${environment.apiUrl}/trucks`, truck)
      .pipe(map((response) => response.data as TruckModel))
      .pipe(
        tap((responseData) => {
          this.truckFactory.getTruckTimeZoneDate(responseData);
        })
      );
  }

  updateTruck(truck: TruckModel) {
    truck = this.truckFactory.getTruckUtcDate(truck);
    return this.http
      .put<Response>(`${environment.apiUrl}/trucks`, truck)
      .pipe(map((response) => response.data as TruckModel))
      .pipe(
        tap((responseData) => {
          this.truckFactory.getTruckTimeZoneDate(responseData);
        })
      );
  }

  deleteTruck(truckId: number) {
    return this.http.delete<Response>(
      `${environment.apiUrl}/trucks/${truckId}`
    );
  }

  getLastDestination(truckId: number) {
    return this.http
      .get<Response>(`${environment.apiUrl}/trucks/${truckId}/last-destination`)
      .pipe(map((response) => response.data as Location));
  }
}
