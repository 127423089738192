import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSort, MatSortable } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Params } from '@angular/router';
import { SortData } from 'src/app/_models/common/sortData';
import { DriverModel } from 'src/app/_models/driver/driver';
import { TableType } from 'src/app/_models/enums/tableType';
import { Note } from 'src/app/_models/note';
import { TruckModel } from 'src/app/_models/truck';
import {
  DriverService,
  EscrowService,
  LoanService,
  NotesService,
  PayrollService,
  ToastrTranslateService,
  TruckService,
} from 'src/app/_services';
import { LocalStorageSortService } from 'src/app/_services/local.storage.sort.service';

@Component({
  selector: 'app-payroll-notes',
  templateUrl: './payroll-notes.component.html',
  styleUrls: ['./payroll-notes.component.scss'],
})
export class PayrollNotesComponent implements OnInit, AfterViewInit {
  strike: boolean = false;
  campaignOne: UntypedFormGroup;
  textNote: string | null = null;
  amount: number | null = null;
  completed: boolean;

  @ViewChild(MatSort) sort: MatSort;

  loan: number | null = null;
  escrow: number | null = null;

  totalNotesAmount = 0.0;
  driverId = 0;
  sortData: SortData;

  dataSource = new MatTableDataSource<Note>();
  noteCompletedList: Note[] = [];

  noteList: Note[] = [];

  truck: TruckModel;
  total: number;
  truckId: number;
  public form: UntypedFormGroup;
  constructor(
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private truckService: TruckService,
    private notesService: NotesService,
    private toastrTranslateService: ToastrTranslateService,
    private localStorageSortService: LocalStorageSortService,
    private builder: UntypedFormBuilder,
    private loanService: LoanService,
    private escrowService: EscrowService,
    private payrollService: PayrollService,
    private driverService: DriverService
  ) {
    const today = new Date();
    const month = today.getMonth();
    const year = today.getFullYear();

    this.campaignOne = new UntypedFormGroup({
      start: new UntypedFormControl(new Date(year, month, 1)),
      end: new UntypedFormControl(new Date(year, month, 20)),
    });
    this.form = this.builder.group({
      files: ['', Validators.required],
    });
  }

  ngOnInit(): void {
    this.sortData = this.localStorageSortService.getSortData(
      TableType.PayrollNotes
    );
    this.payrollService.curentTotal.subscribe((total) => (this.total = total));
    this.route.parent.params.subscribe((params: Params) => {
      this.truckId = +params['truckId'];
      this.driverId = +params['driverId'];
      this.truckService
        .getTruck(this.truckId)
        .subscribe((truck: TruckModel) => {
          if (!truck.driver) {
            this.driverService
              .getDriver(this.driverId)
              .subscribe((driver: DriverModel) => {
                truck.driver = driver;
                this.GetTabData(truck, this.driverId);
              });
          } else {
            this.GetTabData(truck, this.driverId);
          }
          this.GetTabData(truck, this.driverId);
        });
    });
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.sort.sort({
        id: this.sortData.activeColumn,
        start: this.sortData.order,
      } as MatSortable);
      this.dataSource.sort = this.sort;
    });
    this.dataSource.sortingDataAccessor = (item, property) => {
      this.localStorageSortService.setSortData(
        TableType.PayrollNotes,
        this.sort
      );
      return item[property];
    };
  }

  private GetTabData(truck: TruckModel, driverId) {
    this.truck = truck;
    this.driverId = driverId ? driverId : truck.driver.id;
    this.getNotesList(this.driverId);
    this.getEscrows();
    this.getLoans();
  }

  getLoans() {
    this.loanService.getLoans(this.truck.driver.id).subscribe((loans) => {
      this.loan = loans.map((l) => l.adjustment).reduce((a, b) => a + b, 0);
    });
  }

  getEscrows() {
    this.escrowService.getEscrows(this.truck.driver.id).subscribe((escrows) => {
      this.escrow = escrows.map((l) => l.adjustment).reduce((a, b) => a + b, 0);
    });
  }

  addAmount(isInvalid) {
    if (!isInvalid && this.amount && this.textNote) {
      const request: Note = {
        driverId: this.driverId,
        amount: this.amount,
        text: this.textNote,
        date: new Date(),
        completed: this.completed,
      };
      this.notesService.addNotes(request).subscribe((_) => {
        this.getNotesList(this.driverId);
      });
      this.clear();
    } else {
      this.toastrTranslateService.warning('GENERAL.FORM_INVALID');
    }
  }

  getNotesList(driverId: number) {
    this.notesService.getNotes(this.driverId).subscribe((notes) => {
      notes.sort((x) => (!x.completed ? -1 : 1));
      this.dataSource.data = notes;
    });
  }
  clear() {
    this.textNote = null;
    this.amount = null;
  }

  changeValue(note) {
    if (!note.completed) {
      note.completed = true;
      this.notesService.updateNotes(note).subscribe({
        next: (response) => {
          if (response) {
            this.toastrTranslateService.success('PAYROLL.COMPLETED');
            this.getNotesList(this.driverId);
          }
        },
        error: (response) => {
          this.toastrTranslateService.error(response, 'PAYROLL.ERROR');
        },
      });
    } else {
      note.completed = false;
      this.notesService.updateNotes(note).subscribe({
        next: (response) => {
          if (response) {
            this.toastrTranslateService.success('PAYROLL.NOT_COMPLETED');
            this.getNotesList(this.driverId);
          }
        },
        error: (response) => {
          this.toastrTranslateService.error(response, 'PAYROLL.ERROR');
        },
      });
    }
  }

  refresh() {
    this.getNotesList(this.driverId);
  }

  displayedColumns: string[] = ['date', 'note', 'amount', 'completed'];
}
