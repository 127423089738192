import { Injectable } from '@angular/core';

import { TrailerModel } from 'src/app/_models/trailer';
import { TenantService } from '../tenant.service';

@Injectable({
  providedIn: 'root',
})
export class TailerFactory {
  constructor(private tenantService: TenantService) {}

  public getTrailerUtcDate(trailer: TrailerModel): TrailerModel {
    trailer.registrationExpirationDate = this.tenantService.getTenetUtcDate(
      trailer.registrationExpirationDate
    );
    trailer.annualInspectionExpirationDate = this.tenantService.getTenetUtcDate(
      trailer.annualInspectionExpirationDate
    );
    trailer.quarterlyInspectionDue = this.tenantService.getTenetUtcDate(
      trailer.quarterlyInspectionDue
    );

    return trailer;
  }

  public getTrailerTimeZoneDate(trailer: TrailerModel): TrailerModel {
    trailer.registrationExpirationDate =
      this.tenantService.getTenantTimeZoneDate(
        trailer.registrationExpirationDate
      );
    trailer.annualInspectionExpirationDate =
      this.tenantService.getTenantTimeZoneDate(
        trailer.annualInspectionExpirationDate
      );
    trailer.quarterlyInspectionDue = this.tenantService.getTenantTimeZoneDate(
      trailer.quarterlyInspectionDue
    );

    return trailer;
  }
}
