import {
  AfterViewInit,
  Component,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Location } from 'src/app/_models/location/location';

import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { Subject } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  startWith,
  switchMap,
  tap,
} from 'rxjs/operators';
import { CdlModel } from '../_models/driver/cdl';
import { CdlViewModel } from '../_models/driver/cdlViewModel';
import { DriverFullModel } from '../_models/driver/driverFull';
import { EmergencyContact } from '../_models/driver/emergencyContact';
import { EmploymentHistory } from '../_models/driver/employmentHistory';
import {
  DriverService,
  MapService,
  ToastrTranslateService,
} from '../_services';

@Component({
  selector: 'app-driver-application',
  templateUrl: './driver-application.component.html',
  styleUrls: ['./driver-application.component.scss'],
})
export class DriverApplicationComponent implements OnInit, AfterViewInit {
  searchAddress = new UntypedFormControl();
  searchAddresses = new UntypedFormControl();
  searchAddressContact = new UntypedFormControl();
  $searchAddressTerm: Subject<string | undefined> = new Subject<
    string | undefined
  >();
  searchResult: Location[];
  searchReferenceResult: Location[];
  searchAddressResult: Location[];
  searchResultForContact: Location[];
  addresses;
  eventEditForm: UntypedFormGroup;

  driverFull: DriverFullModel;
  driverEmploymentHistories: EmploymentHistory;
  cdlEndorsmentsList: CdlViewModel[];
  isCdlListEmpty = true;
  driverId: number;
  readOnly: boolean;
  disabled = false;
  loadedData = false;
  submited = false;
  stepPassed: { [key: string]: boolean } = {
    step1: false,
    step2: false,
    step3: false,
    step4: false,
    step5: false,
    step6: false,
    step7: false,
  };

  @ViewChild('stepper', { static: false }) stepper: MatStepper;

  constructor(
    private mapService: MapService,
    private driverService: DriverService,
    private dialogRef: MatDialogRef<DriverApplicationComponent>,
    private toastrTranslateService: ToastrTranslateService,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {}

  ngOnInit(): void {
    this.driverId = this.data.id;
    this.readOnly = this.data.readOnly;
    this.driverFull = new DriverFullModel();

    if (this.driverId) {
      this.driverService.getFullDriver(this.driverId).subscribe({
        next: (response) => {
          this.driverFull = response;
          if (
            !this.driverFull.driverCdlEndorsments ||
            this.driverFull.driverCdlEndorsments.length == 0
          ) {
            this.cdlEndorsmentsList = this.getCdlEndorsmentsView();
          } else {
            this.cdlEndorsmentsList = this.getCdlEndorsmentsView(
              this.driverFull.driverCdlEndorsments
            );
          }
          this.loadedData = true;
        },
        error: (response) => {
          this.toastrTranslateService.error(response, 'DRIVERS.FAILED');
        },
      });
    }

    this.searchAddress.valueChanges
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        startWith(''),
        tap(() => {
          this.searchResult = [];
        }),
        switchMap((value) => {
          if (value != '') {
            return this.mapService.searchMap(value);
          } else {
            return (this.searchResult = []);
          }
        })
      )
      .subscribe((data) => {
        if (data && data.count != 0) {
          this.searchResult = data.collection;
        } else {
          this.searchResult = [];
        }
      });
    this.searchAddresses.valueChanges
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        startWith(''),
        tap(() => {
          this.searchAddressResult = [];
        }),
        switchMap((value) => {
          if (value != '') {
            return this.mapService.searchMap(value);
          } else {
            return (this.searchAddressResult = []);
          }
        })
      )
      .subscribe((data) => {
        if (data && data.count != 0) {
          this.searchAddressResult = data.collection;
        } else {
          this.searchAddressResult = [];
        }
      });

    this.searchAddressContact.valueChanges
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        startWith(''),
        tap(() => {
          this.searchResultForContact = [];
        }),
        switchMap((value) => {
          if (value != '') {
            return this.mapService.searchMap(value);
          } else {
            return (this.searchResultForContact = []);
          }
        })
      )
      .subscribe((data) => {
        if (data && data.count != 0) {
          this.searchResultForContact = data.collection;
        } else {
          this.searchResultForContact = [];
        }
      });
    this.$searchAddressTerm
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        startWith(''),
        tap(() => {
          this.searchReferenceResult = [];
        }),
        switchMap((value) => {
          if (value != '') {
            return this.mapService.searchMap(value);
          } else {
            return (this.searchReferenceResult = []);
          }
        })
      )
      .subscribe((data) => {
        if (data && data.count != 0) {
          this.searchReferenceResult = data.collection;
        } else {
          this.searchReferenceResult = [];
        }
      });
  }

  ngAfterViewInit() {
    this.stepper._stepHeader.forEach((x) => {
      x._elementRef.nativeElement.style.pointerEvents = 'none';
    });
  }

  driverReferenceAddressChange(searchValue: string): void {
    this.$searchAddressTerm.next(searchValue?.trim());
  }

  selectDriverLocation(location: Location) {
    this.driverFull.location = location;
  }

  selectContactDriverLocation(location: Location, index: number) {
    this.driverFull.driverEmergencyContacts[index].location = location;
  }

  selectReferenceDriverLocation(location: Location, index: number) {
    this.driverFull.driverReferences[index].address = location;
  }

  selectDriverAddresses(location: Location) {
    if (!this.driverFull.locationsPastThreeYears) {
      this.driverFull.locationsPastThreeYears = [];
    }
    this.driverFull.locationsPastThreeYears.push(location);
    this.searchAddresses.reset('');
  }

  eventFromAddresses(data) {
    this.driverFull.addresses = data;
  }

  displayLoc(location: Location): string {
    return location ? location.address + ', ' + location.countryCode : '';
  }
  displayAddresses(location: Location): string {
    return location ? location.address + ', ' + location.countryCode : '';
  }
  displayLocContact(location: Location): string {
    return location ? location.address + ', ' + location.countryCode : '';
  }
  displayLocReference(location: Location): string {
    return location ? location.address + ', ' + location.countryCode : '';
  }

  getCdlEndorsmentsView(cdlEndorsments?: CdlModel[]): CdlViewModel[] {
    let cdlList = new Array<CdlViewModel>();
    for (let i = 1; i < 7; i++) {
      cdlList.push({
        driverId: this.driverId,
        driverCdlEndorsementTypeId: i,
        active: false,
        key: 'APPLICATION.CDL_' + i,
      });
    }
    if (cdlEndorsments) {
      cdlEndorsments.forEach((cdl) => {
        cdlList[cdl.driverCdlEndorsementTypeId - 1].active = true;
      });
      if (cdlEndorsments.length == 0) {
        this.isCdlListEmpty = true;
      } else {
        this.isCdlListEmpty = false;
      }
    }
    return cdlList;
  }

  collectCdlEndorsments() {
    let cdlList = new Array<CdlModel>();
    this.cdlEndorsmentsList.forEach((cdl) => {
      if (cdl.active) {
        cdlList.push({
          driverId: cdl.driverId,
          driverCdlEndorsementTypeId: cdl.driverCdlEndorsementTypeId,
        });
      }
    });
    if (cdlList.length == 0) {
      this.isCdlListEmpty = true;
    } else {
      this.isCdlListEmpty = false;
    }
    this.driverFull.driverCdlEndorsments = cdlList;
  }

  addContact() {
    this.driverFull.driverEmergencyContacts.push(new EmergencyContact());
  }

  deleteContact(index) {
    this.driverFull.driverEmergencyContacts.splice(index, 1);
  }

  addReference() {
    this.driverFull.driverReferences.push({
      id: 0,
      name: '',
      driverId: this.driverFull.id,
      address: null,
      phoneNumber: null,
      numberOfYearsKnown: null,
    });
  }

  deleteReference(index) {
    this.driverFull.driverReferences.splice(index, 1);
  }

  addHistory() {
    this.driverFull.driverEmploymentHistories.push({
      id: 0,
      driverId: this.driverFull.id,
      startDate: null,
      endingDate: null,
      employerName: '',
      cityState: '',
      employerPhone: '',
      positionHeld: '',
      reasonsForLeaving: '',
      isCurrentEmployer: null,
      canContactEmployer: null,
      wasDrivingPosition: null,
      employmentHistoryTypeOfTruckId: this.data.employmentHistoryTypeOfTruckId,
      employmentHistoryTypeOfTrailerId:
        this.data.employmentHistoryTypeOfTrailerId,
      employmentHistoryTrailerLengthId:
        this.data.employmentHistoryTrailerLengthId,
      numberOfStatesDriven: '',
      wasSubjectToTheFMCSRs: null,
      wasPositionDesignedAsASafetySensitiveFunction: null,
    });
  }
  deleteHistory(index) {
    this.driverFull.driverEmploymentHistories.splice(index, 1);
  }

  passStep(step: string) {
    this.stepPassed[step] = true;
  }

  next(isInvalid, aditionalCondition, step, finalSubmit?) {
    if (isInvalid || aditionalCondition) {
      this.submited = true;
      this.toastrTranslateService.warning('GENERAL.FORM_INVALID');
      return;
    }
    this.passStep(step);
    this.save(finalSubmit);
  }

  backConfirm() {
    this.driverFull.finalConfirmation = null;
    delete this.driverFull.finalConfirmation;
  }

  private save(finalSubmit?) {
    if (this.driverId) {
      this.driverService.updateFullDriver(this.driverFull).subscribe({
        next: () => {
          this.submited = false;
          if (finalSubmit) {
            this.toastrTranslateService.success(
              'APPLICATION.SUCCESS',
              this.driverFull.firstname + ' ' + this.driverFull.lastname
            );
            this.dialogRef.close();
          } else {
            this.stepper.next();
          }
        },
        error: (response) => {
          this.toastrTranslateService.error(response, 'DRIVERS.NOT_SAVED');
        },
      });
    }
  }
}
