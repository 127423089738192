import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
// import { TenantModel } from 'src/app/_models/tenant';

interface Language {
  value: string;
}

@Component({
  selector: 'app-select_lang',
  templateUrl: './select_lang.component.html',
  styleUrls: ['./select_lang.component.scss'],
})
export class SelectLangComponent {
  // companiesData: Array<TenantModel>;

  languages: Language[];
  selectedLanguage: any;

  constructor(public dialog: MatDialog, public translate: TranslateService) {
    this.selectedLanguage = localStorage.getItem('lang') || 'en';
    translate.use(this.selectedLanguage);
  }

  changeLanguage(lang: string): void {
    this.selectedLanguage = lang;
    localStorage.setItem('lang', lang);
  }

  // dataSource = new MatTableDataSource<TenantModel>();

  ngOnInit() {}
}
