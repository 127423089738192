import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-info-nodata',
  templateUrl: './info-nodata.component.html',
  styleUrls: ['./info-nodata.component.scss'],
})
export class InfoNodataComponent implements OnInit {
  @Input() public infoKey;
  public infoText = '';

  constructor(private translateService: TranslateService) {}

  ngOnInit(): void {
    this.infoText = this.translateService.instant(this.infoKey);
  }
}
