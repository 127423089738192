<div class="modal-dialog">
  <h2 mat-dialog-title>
    <mat-icon class="iconwarn">warning</mat-icon>
  </h2>
  <mat-dialog-content class="mat-typography d-flex">
    <p class="text">
      {{ titleFirstLine }}
    </p>
    <p class="text">
      {{ titleSecondLine }}
    </p>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button
      mat-button
      class="btnCan"
      [mat-dialog-close]="false"
      cdkFocusInitial
    >
      {{ "CONFIRM_DIALOG.CANCEL" | translate }}
    </button>
    <button mat-flat-button class="btn" color="warn" [mat-dialog-close]="true">
      {{ "CONFIRM_DIALOG.DELETE" | translate }}
    </button>
  </mat-dialog-actions>
</div>
