import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@auth0/auth0-angular';
import { AnalyticsCustomerComponent } from './analytics/analytics-customer/analytics-customer.component';
import { AnalyticsTruckComponent } from './analytics/analytics-truck/analytics-truck.component';
import { AnalyticsComponent } from './analytics/analytics.component';
import { HomeComponent } from './home/home.component';
import { AccountingComponent } from './loads/accounting.component';
import { AdvancedSearchComponent } from './loads/advanced-search/advanced-search.component';
import { LoadComponent } from './loads/new-load/load.component';
import { CustomerComponent } from './manage/customer/customer.component';
import { DispatchersComponent } from './manage/dispatchers/dispatchers.component';
import { DriversComponent } from './manage/drivers/drivers.component';
import { ManageComponent } from './manage/manage.component';
import { SafetyInfoComponent } from './manage/safety-info/safety-info.component';
import { TrailersComponent } from './manage/trailers/trailers.component';
import { TrucksComponent } from './manage/trucks/trucks.component';
import { PayrollCalcComponent } from './payroll/payroll-spec/payroll-calc/payroll-calc.component';
import { PayrollEscrowComponent } from './payroll/payroll-spec/payroll-escrow/payroll-escrow.component';
import { PayrollLoanComponent } from './payroll/payroll-spec/payroll-loan/payroll-loan.component';
import { PayrollNotesComponent } from './payroll/payroll-spec/payroll-notes/payroll-notes.component';
import { PayrollPreviewComponent } from './payroll/payroll-spec/payroll-preview/payroll-preview.component';
import { PayrollSpecComponent } from './payroll/payroll-spec/payroll-spec.component';
import { PreviousPayrollsComponent } from './payroll/payroll-spec/previous-payrolls/previous-payrolls.component';
import { PayrollComponent } from './payroll/payroll.component';
import { PayrollsComponent } from './payroll/payrolls/payrolls.component';
import { RecentPayrollsComponent } from './payroll/recent-payrolls/recent-payrolls.component';
import { AddOtherComponent } from './settings/add-other/add-other.component';
import { TenantComponent } from './settings/company-data/tenant.component';
import { SettingsComponent } from './settings/settings.component';
import { AdminsComponent } from './settings/users/administrators/admins.component';
import { UsersComponent } from './settings/users/users.component';
import { SelectLangComponent } from './user-settings/select_lang/select_lang.component';
import { UserSettingsComponent } from './user-settings/user-settings.component';
import { VerifyAccountComponent } from './verify-account/verify-account.component';
import { TransactionsBilingComponent } from './settings/transactions-biling/transactions-biling.component';
import { DriverAppListComponent } from './driver-app-list/driver-app-list.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'verify-account/:activationId',
    component: VerifyAccountComponent,
    canActivate: [],
  },
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'home/:activeButton/:hasHold',
    component: HomeComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'home/:activeButton/:hasHold/:searchTerm',
    component: HomeComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'accounting',
    component: AccountingComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'accounting/:activeButton/:hasHold',
    component: AccountingComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'accounting/:activeButton/:hasHold/:searchValue',
    component: AccountingComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'load-advanced/:loadId',
    component: LoadComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'load/:loadId',
    component: LoadComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'load/:loadId/:previousPage/:activeButton',
    component: LoadComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'load/:loadId/:previousPage/:activeButton/:hasHold',
    component: LoadComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'load/:loadId/:previousPage/:activeButton/:hasHold/:searchTerm',
    component: LoadComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'advanced-search',
    component: AdvancedSearchComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'manage',
    component: ManageComponent,
    children: [
      {
        path: '',
        redirectTo: 'trucks',
        pathMatch: 'full',
      },
      {
        path: 'trucks',
        component: TrucksComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'trailers',
        component: TrailersComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'drivers',
        component: DriversComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'dispatchers',
        component: DispatchersComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'customer',
        component: CustomerComponent,
        canActivate: [AuthGuard],
      },
    ],
    canActivate: [AuthGuard],
  },
  {
    path: 'payroll',
    component: PayrollComponent,
    children: [
      {
        path: '',
        redirectTo: 'payrolls',
        pathMatch: 'full',
      },
      {
        path: 'payrolls',
        component: PayrollsComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'recent',
        component: RecentPayrollsComponent,
        canActivate: [AuthGuard],
      },
    ],
  },
  {
    path: 'payrolls/truck/:truckId/driver/:driverId',
    component: PayrollSpecComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        redirectTo: 'payroll-calculation',
        pathMatch: 'full',
      },
      {
        path: 'payroll-calculation',
        component: PayrollCalcComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'loan',
        component: PayrollLoanComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'escrow',
        component: PayrollEscrowComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'payroll-notes',
        component: PayrollNotesComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'payroll-preview',
        component: PayrollPreviewComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'previous-payrolls',
        component: PreviousPayrollsComponent,
        canActivate: [AuthGuard],
      },
    ],
  },
  {
    path: 'driver-app',
    component: DriverAppListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'analytics',
    component: AnalyticsComponent,
    children: [
      {
        path: '',
        redirectTo: 'analytics-truck',
        pathMatch: 'full',
      },
      {
        path: 'analytics-truck',
        component: AnalyticsTruckComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'analytics-customer',
        component: AnalyticsCustomerComponent,
        canActivate: [AuthGuard],
      },
    ],
    canActivate: [AuthGuard],
  },

  {
    path: 'safety-info',
    component: SafetyInfoComponent,
    canActivate: [AuthGuard],
  },

  {
    path: 'settings',
    component: SettingsComponent,
    children: [
      {
        path: '',
        redirectTo: 'users',
        pathMatch: 'full',
      },
      {
        path: 'company-data',
        component: TenantComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'transactions-and-biling',
        component: TransactionsBilingComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'users',
        component: UsersComponent,
        canActivate: [AuthGuard],
        children: [
          {
            path: '',
            redirectTo: 'admins',
            pathMatch: 'full',
          },
          {
            path: 'admins',
            component: AdminsComponent,
            canActivate: [AuthGuard],
          },
          {
            path: 'users-dispatchers',
            component: DispatchersComponent,
            canActivate: [AuthGuard],
          },
          {
            path: 'users-drivers',
            component: DriversComponent,
            canActivate: [AuthGuard],
          },
        ],
      },
    ],
  },
  {
    path: 'user-settings',
    component: UserSettingsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'select_lang',
    component: SelectLangComponent,
    canActivate: [AuthGuard],
  },
  { path: 'add_other', component: AddOtherComponent, canActivate: [AuthGuard] },
];
@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
