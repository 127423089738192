import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { CodeModel } from 'src/app/_models/code';
import { TrailerModel } from 'src/app/_models/trailer';
import { ToastrTranslateService, TrailerService } from 'src/app/_services';
import { CodeFactory } from 'src/app/_services/factory/code.factory.service';
import { UserDataService } from 'src/app/_services/user.data.service';

@Component({
  selector: 'app-edit-trailer-dialog',
  templateUrl: './edit-trailer-dialog.component.html',
  styleUrls: ['./edit-trailer-dialog.component.scss'],
})
export class EditTrailerDialogComponent implements OnInit {
  minDate: Date;
  trailer: TrailerModel = new TrailerModel();
  equipmentTypeList: CodeModel[] = [];
  myControl = new UntypedFormControl();
  filteredOptions: Observable<string[]>;
  trailerId: number;
  readOnly: boolean;
  dataLoading = true;

  constructor(
    private trailerService: TrailerService,
    private codeFactory: CodeFactory,
    private dialogRef: MatDialogRef<EditTrailerDialogComponent>,
    private toastrTranslateService: ToastrTranslateService,
    public userDataService: UserDataService,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {
    const currentYear = new Date().getFullYear();
    this.minDate = new Date(currentYear - 11, 12, 99);
    this.codeFactory.getEquipmentTypeList().then((data) => {
      this.equipmentTypeList = data;
    });
  }

  ngOnInit(): void {
    this.trailerId = this.data.id;
    this.readOnly = this.data.readOnly;
    this.trailer = new TrailerModel();
    if (this.trailerId) {
      this.trailerService.getTrailer(this.trailerId).subscribe({
        next: (response) => {
          this.trailer = response;
          this.dataLoading = false;
        },
        error: (response) => {
          this.toastrTranslateService.error(response, 'TRAILERS.FAILED');
        },
      });
      if (
        this.trailer.equipmentType &&
        this.trailer.equipmentType.name != null
      ) {
        this.trailer.equipmentTypeId = this.trailer.equipmentType.id;
      }
    } else {
      this.trailer.isActive = true;
      this.dataLoading = false;
    }
  }

  compareObjects(o1: any, o2: any): boolean {
    if (o1 && o2) {
      return o1.id === o2.id;
    }
    return false;
  }

  updateExternalId(trailer: TrailerModel): TrailerModel {
    this.trailer.equipmentTypeId = this.trailer.equipmentType.id;
    return trailer;
  }

  setActiveValue(e) {
    if (e.checked) {
      this.trailer.isActive = true;
    } else {
      this.trailer.isActive = false;
    }
  }

  toBool(boolVariable: any): Boolean {
    if (boolVariable && boolVariable != 'undefined') {
      return Boolean(JSON.parse(boolVariable));
    }
    return false;
  }

  save(isInvalid) {
    if (isInvalid) {
      this.toastrTranslateService.warning('GENERAL.FORM_INVALID');
      return;
    }
    this.trailer = this.updateExternalId(this.trailer);
    if (this.trailerId) {
      this.trailerService.updateTrailer(this.trailer).subscribe({
        next: (response) => {
          this.trailer = response;
          this.toastrTranslateService.success(
            'TRAILERS.SAVED',
            this.trailer.trailerNumber
          );
          this.dialogRef.close();
        },
        error: (response) => {
          this.toastrTranslateService.error(response, 'TRAILERS.FAILED');
        },
      });
    } else {
      this.trailerService.addTrailer(this.trailer).subscribe({
        next: (response) => {
          this.trailer = response;
          this.toastrTranslateService.success(
            'TRAILERS.SAVED',
            this.trailer.trailerNumber
          );
          this.dialogRef.close();
        },
        error: (response) => {
          this.toastrTranslateService.error(response, 'TRAILERS.NOT_SAVED');
        },
      });
    }
  }
}
