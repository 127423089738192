import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-payroll-pdf-preview-popup',
  templateUrl: './payroll-pdf-preview-popup.component.html',
  styleUrls: ['./payroll-pdf-preview-popup.component.scss'],
})
export class PayrollPdfPreviewPopupComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<PayrollPdfPreviewPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Blob
  ) {}
  ngOnInit(): void {}
}
