<div class="wrapperTable" id="wrapperManageTables">
  <div class="addNew">
    <button mat-icon-button class="refresh" (click)="refresh()">
      <mat-icon
        matTooltip="{{ 'MANAGE.REFRESH' | translate }}"
        matTooltipPosition="below"
        >refresh</mat-icon
      >
    </button>
    <button
      mat-flat-button
      class="btnDialog"
      (click)="openDialogEditCustomer(false)"
    >
      {{ "CUSTOMER.ADD_NEW_CUSTOMER" | translate }}
    </button>
    <mat-form-field class="filterField">
      <input
        matInput
        (blur)="searchCustomers($event.target.value)"
        (keydown.enter)="searchCustomers($event.target.value)"
        autocomplete="off"
        [value]="searchTerm"
        placeholder="{{ 'CUSTOMER.FILTER' | translate }}"
        class="filterInput"
      />
      <mat-icon matPrefix class="filterIcon">search</mat-icon>
    </mat-form-field>
    <mat-slide-toggle
      [(ngModel)]="showInactive"
      (change)="refresh()"
      color="primary"
      class="active"
      matTooltip="{{ 'CUSTOMER.SHOW_INACTIVE' | translate }}"
      matTooltipPosition="after"
    ></mat-slide-toggle>
  </div>

  <table
    mat-table
    matSort
    matSortDisableClear
    [dataSource]="dataSource"
    class="mat-elevation-z8"
    [hidden]="count == 0"
  >
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ "CUSTOMER.ID" | translate }}
      </th>
      <td mat-cell *matCellDef="let customer">
        {{ customer.id }}
      </td>
    </ng-container>

    <ng-container matColumnDef="customerName">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>
        <label matTooltip="{{ 'CUSTOMER.CUSTOMER_NAME' | translate }}">{{
          "CUSTOMER.NAME" | translate
        }}</label>
      </th>
      <td
        mat-cell
        *matCellDef="let customer"
        (click)="openDialogEditCustomer(true, customer.id)"
        class="text-truncate"
      >
        <label matTooltip="{{ customer.name }}">{{ customer.name }}</label>
      </td>
    </ng-container>

    <ng-container matColumnDef="billingAddress">
      <th mat-header-cell *matHeaderCellDef>
        {{ "CUSTOMER.BILLING_ADDRESS" | translate }}
      </th>
      <td
        mat-cell
        *matCellDef="let customer"
        (click)="openDialogEditCustomer(true, customer.id)"
        class="text-truncate"
      >
        <label
          *ngIf="customer.billingAddress"
          matTooltip="{{
            customer.billingAddress.address +
              ', ' +
              customer.billingAddress.countryCode
          }}"
          ><label *ngIf="customer?.billingAddress?.city">
            {{
              customer.billingAddress.city +
                ", " +
                customer.billingAddress.state
            }}</label
          >

          <label *ngIf="!customer?.billingAddress?.city">
            {{ customer.billingAddress.address }}
          </label>
        </label>
        <label
          *ngIf="customer?.billingAddress?.poBox"
          matTooltip="{{ customer.billingAddress.poBox }}"
          >{{ customer.billingAddress.poBox }}
        </label>
      </td>
    </ng-container>
    <ng-container matColumnDef="address">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>
        {{ "CUSTOMER.MAIN_ADDRESS" | translate }}
      </th>
      <td
        mat-cell
        *matCellDef="let customer"
        (click)="openDialogEditCustomer(true, customer.id)"
        class="text-truncate"
      >
        <label
          *ngIf="customer.addresses[0]"
          matTooltip="{{
            customer.addresses[0].address +
              ', ' +
              customer.addresses[0].countryCode
          }}"
          ><label *ngIf="customer?.addresses[0]?.city">
            {{
              customer.addresses[0].city + ", " + customer.addresses[0].state
            }}
          </label>
          <label *ngIf="!customer?.addresses[0]?.city">
            {{ customer.addresses[0].address }}
          </label>
        </label>
        <label
          *ngIf="customer?.addresses[0]?.poBox"
          matTooltip="{{ customer?.addresses[0]?.poBox }}"
          >{{ customer?.addresses[0]?.poBox }}
        </label>
      </td>
    </ng-container>

    <ng-container matColumnDef="primaryContact">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>
        <label matTooltip="{{ 'CUSTOMER.PRIMARY_CONTACT' | translate }}">{{
          "CUSTOMER.CONTACT" | translate
        }}</label>
      </th>
      <td
        mat-cell
        *matCellDef="let customer"
        (click)="openDialogEditCustomer(true, customer.id)"
        class="notes-truncate"
      >
        <label
          *ngIf="customer.primaryContact"
          matTooltip="{{ customer.primaryContact.name }}"
          >{{ customer.primaryContact.name }}</label
        >
      </td>
    </ng-container>
    <ng-container matColumnDef="phoneNumber">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>
        {{ "CUSTOMER.PHONE_NUMBER" | translate }}
      </th>
      <td
        mat-cell
        *matCellDef="let customer"
        (click)="openDialogEditCustomer(true, customer.id)"
        class="number-truncate"
      >
        <label matTooltip="{{ customer.phoneNumber }}">
          {{ customer.phoneNumber }}
        </label>
      </td>
    </ng-container>
    <ng-container matColumnDef="phoneExtNumber">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>
        {{ "CUSTOMER.EXT_NUMBER" | translate }}
      </th>
      <td
        mat-cell
        *matCellDef="let customer"
        (click)="openDialogEditCustomer(true, customer.id)"
        class="number-truncate"
      >
        <label matTooltip="{{ customer.ext }}">{{ customer.ext }}</label>
      </td>
    </ng-container>
    <ng-container matColumnDef="email">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>
        {{ "CUSTOMER.EMAIL" | translate }}
      </th>
      <td
        mat-cell
        *matCellDef="let customer"
        (click)="openDialogEditCustomer(true, customer.id)"
        class="notes-truncate"
      >
        <label matTooltip="{{ customer.email }}">{{ customer.email }}</label>
      </td>
    </ng-container>

    <ng-container matColumnDef="billingEmail">
      <th mat-header-cell mat-sort-header *matHeaderCellDef class="nowrap">
        {{ "CUSTOMER.BILLING_EMAIL" | translate }}
      </th>
      <td
        mat-cell
        *matCellDef="let customer"
        (click)="openDialogEditCustomer(true, customer.id)"
        class="notes-truncate"
      >
        <label matTooltip="{{ customer.billingEmail }}">{{
          customer.billingEmail
        }}</label>
      </td>
    </ng-container>

    <ng-container matColumnDef="mc">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>
        {{ "CUSTOMER.MC" | translate }}
      </th>
      <td
        mat-cell
        *matCellDef="let customer"
        (click)="openDialogEditCustomer(true, customer.id)"
        class="number-truncate"
      >
        <label matTooltip="{{ customer.mc }}">{{ customer.mc }}</label>
      </td>
    </ng-container>

    <ng-container matColumnDef="ff">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>
        {{ "CUSTOMER.FF" | translate }}
      </th>
      <td
        mat-cell
        *matCellDef="let customer"
        (click)="openDialogEditCustomer(true, customer.id)"
        class="number-truncate"
      >
        <label matTooltip="{{ customer.ff }}">
          {{ customer.ff }}
        </label>
      </td>
    </ng-container>

    <ng-container matColumnDef="otherNumber">
      <th mat-header-cell mat-sort-header *matHeaderCellDef class="nowrap">
        {{ "CUSTOMER.OTHER_PHONE" | translate }}
      </th>
      <td
        mat-cell
        *matCellDef="let customer"
        (click)="openDialogEditCustomer(true, customer.id)"
        class="number-truncate"
      >
        <label matTooltip="{{ customer.otherNumber }}">{{
          customer.otherNumber
        }}</label>
      </td>
    </ng-container>
    <ng-container matColumnDef="otherPhoneExtNumber">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>
        <label matTooltip="{{ 'CUSTOMER.EXT_OTHER_NUMBER' | translate }}">{{
          "CUSTOMER.EXT_NUMBER" | translate
        }}</label>
      </th>
      <td
        mat-cell
        *matCellDef="let customer"
        (click)="openDialogEditCustomer(true, customer.id)"
        class="number-truncate"
      >
        <label matTooltip="{{ customer.otherPhoneExt }}">{{
          customer.otherPhoneExt
        }}</label>
      </td>
    </ng-container>
    <ng-container matColumnDef="isBlacklisted">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>
        {{ "CUSTOMER.BLACK_LISTED" | translate }}
      </th>
      <td
        mat-cell
        *matCellDef="let customer"
        (click)="openDialogEditCustomer(true, customer.id)"
      >
        <mat-icon *ngIf="toBool(customer.isBlacklisted)" color="primary"
          >check</mat-icon
        >
      </td>
    </ng-container>

    <ng-container matColumnDef="isBroker">
      <th mat-header-cell mat-sort-header *matHeaderCellDef class="nowrap">
        {{ "CUSTOMER.IS_BROKER" | translate }}
      </th>
      <td
        mat-cell
        *matCellDef="let customer"
        (click)="openDialogEditCustomer(true, customer.id)"
      >
        <mat-icon *ngIf="toBool(customer.isBroker)" color="primary"
          >check</mat-icon
        >
      </td>
    </ng-container>

    <ng-container matColumnDef="isActive">
      <th mat-header-cell *matHeaderCellDef class="nowrap">
        {{ "CUSTOMER.IS_ACTIVE" | translate }}
      </th>
      <td
        mat-cell
        *matCellDef="let customer"
        (click)="openDialogEditCustomer(true, customer.id)"
      >
        <mat-icon *ngIf="toBool(customer.isActive)" color="primary"
          >check</mat-icon
        >
        <mat-icon *ngIf="!toBool(customer.isActive)" color="warn"
          >close</mat-icon
        >
      </td>
    </ng-container>

    <ng-container matColumnDef="creditLimit">
      <th mat-header-cell mat-sort-header class="textRight" *matHeaderCellDef>
        {{ "CUSTOMER.CREDIT_LIMIT" | translate }}
      </th>
      <td
        mat-cell
        *matCellDef="let customer"
        (click)="openDialogEditCustomer(true, customer.id)"
        class="numberLimitStyle"
      >
        <label
          *ngIf="customer.creditLimit != 0"
          matTooltip="{{ customer.creditLimit | currency }}"
        >
          {{ customer.creditLimit | currency }}
        </label>
      </td>
    </ng-container>

    <ng-container matColumnDef="federalId">
      <th mat-header-cell mat-sort-header *matHeaderCellDef class="nowrap">
        {{ "CUSTOMER.FEDERAL_ID" | translate }}
      </th>
      <td
        mat-cell
        *matCellDef="let customer"
        (click)="openDialogEditCustomer(true, customer.id)"
      >
        <label matTooltip="{{ customer.federalId }}">{{
          customer.federalId
        }}</label>
      </td>
    </ng-container>

    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef class="alignCenter">
        {{ "COMMON.ACTION" | translate }}
      </th>
      <td mat-cell *matCellDef="let customer" class="alignCenter">
        <button
          mat-button
          class="edit"
          (click)="openDialogEditCustomer(false, customer.id)"
        >
          {{ "COMMON.EDIT" | translate }}
        </button>
        <button [matMenuTriggerFor]="menu" mat-icon-button class="icon-color">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button
            mat-menu-item
            (click)="openDialogEditCustomer(true, customer.id)"
          >
            {{ "COMMON.VIEW" | translate }}
          </button>
          <button
            mat-menu-item
            (click)="openDialogEditCustomer(false, customer.id)"
          >
            {{ "COMMON.EDIT" | translate }}
          </button>
          <button
            mat-menu-item
            class="delete-button"
            (click)="openDialogComfirmDeleteCustomer(customer.id)"
          >
            {{ "COMMON.DELETE" | translate }}
          </button>
        </mat-menu>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>

  <div [hidden]="count == 0">
    <mat-paginator
      (page)="onChangePage($event)"
      class="paging"
      aria-label="Select page"
      [length]="totalSize"
      [showFirstLastButtons]="true"
      [pageIndex]="currentPage"
      [pageSize]="pageSize"
      [pageSizeOptions]="pageSizeOptions"
    >
    </mat-paginator>
  </div>

  <app-info-nodata
    *ngIf="count == 0"
    [infoKey]="'CUSTOMER.NO_CUSTOMERS'"
    class="no-data"
  ></app-info-nodata>
</div>
