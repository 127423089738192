<mat-sidenav-container class="sidenav-container">
  <mat-sidenav
    #drawer
    class="sidenav"
    fixedInViewport
    [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
    [class.menu-close]="!isMenuOpen"
    mode="side"
    opened
    *ngIf="auth.isAuthenticated$ | async"
  >
    <mat-toolbar class="companyName" *ngIf="isMenuOpen">
      <img class="logoFull" src="assets/logo/logoFullRecolor_.png" />
    </mat-toolbar>
    <mat-toolbar class="companyName" *ngIf="!isMenuOpen">
      <img class="logoSmall" src="assets/logo/logo_square.png" />
    </mat-toolbar>
    <mat-nav-list class="navList">
      <a routerLink="/home">
        <mat-list-item [routerLinkActive]="['is-active']"
          ><mat-icon
            matTooltip="{{ (isMenuOpen ? '' : 'MAINNAV.HOME') | translate }}"
            matTooltipPosition="after"
            aria-label="Side nav Home icon"
            class="sideNavIcons material-icons-outlined"
            >home</mat-icon
          >
          <span class="spacerSideNav" *ngIf="isMenuOpen">{{
            "MAINNAV.HOME" | translate
          }}</span></mat-list-item
        ></a
      >
      <a routerLink="/manage">
        <mat-list-item [routerLinkActive]="['is-active']"
          ><mat-icon
            matTooltip="{{ (isMenuOpen ? '' : 'MAINNAV.MANAGE') | translate }}"
            matTooltipPosition="after"
            aria-label="Side nav Manage icon"
            class="sideNavIcons material-icons-outlined"
            >business</mat-icon
          ><span class="spacerSideNav" *ngIf="isMenuOpen">{{
            "MAINNAV.MANAGE" | translate
          }}</span></mat-list-item
        >
      </a>
      <a routerLink="/payroll" *ngIf="userDataService.isAdminDispatcher">
        <mat-list-item [routerLinkActive]="['is-active']"
          ><mat-icon
            matTooltip="{{
              (isMenuOpen ? '' : 'MAINNAV.PAY_ROLL') | translate
            }}"
            matTooltipPosition="after"
            aria-label="Side nav PayRoll icon"
            class="sideNavIcons material-icons-outlined"
            >local_atm</mat-icon
          ><span class="spacerSideNav" *ngIf="isMenuOpen">{{
            "MAINNAV.PAY_ROLL" | translate
          }}</span></mat-list-item
        >
      </a>
      <a routerLink="/accounting" *ngIf="userDataService.isAdminDispatcher">
        <mat-list-item [routerLinkActive]="['is-active']"
          ><mat-icon
            matTooltip="{{
              (isMenuOpen ? '' : 'MAINNAV.ACCOUNTING') | translate
            }}"
            matTooltipPosition="after"
            aria-label="Side nav Home icon"
            class="sideNavIcons material-icons-outlined"
            >library_books</mat-icon
          ><span class="spacerSideNav" *ngIf="isMenuOpen">{{
            "MAINNAV.ACCOUNTING" | translate
          }}</span></mat-list-item
        >
      </a>
      <a routerLink="/driver-app" *ngIf="false">
        <mat-list-item [routerLinkActive]="['is-active']"
          ><mat-icon
            matTooltip="{{
              (isMenuOpen ? '' : 'MAINNAV.DRIVER_APP') | translate
            }}"
            matTooltipPosition="after"
            class="sideNavIcons material-icons-outlined"
            >people_outline</mat-icon
          ><span class="spacerSideNav" *ngIf="isMenuOpen">{{
            "MAINNAV.DRIVER_APP" | translate
          }}</span></mat-list-item
        >
      </a>
      <a routerLink="/analytics">
        <mat-list-item [routerLinkActive]="['is-active']"
          ><mat-icon
            matTooltip="{{
              (isMenuOpen ? '' : 'MAINNAV.ANALYTICS') | translate
            }}"
            matTooltipPosition="after"
            aria-label="Side nav Analytics icon"
            class="sideNavIcons material-icons-outlined"
            >assessment</mat-icon
          ><span class="spacerSideNav" *ngIf="isMenuOpen">{{
            "MAINNAV.ANALYTICS" | translate
          }}</span></mat-list-item
        >
      </a>
      <mat-list-item class="hiddenItem" [routerLinkActive]="['is-active']"
        ><mat-icon
          matTooltip="{{ (isMenuOpen ? '' : 'MAINNAV.CALENDAR') | translate }}"
          matTooltipPosition="after"
          aria-label="Side nav Calendar icon"
          class="sideNavIcons material-icons-outlined"
          >today</mat-icon
        ><span class="spacerSideNav" *ngIf="isMenuOpen">{{
          "MAINNAV.CALENDAR" | translate
        }}</span></mat-list-item
      >
      <mat-list-item class="hiddenItem" [routerLinkActive]="['is-active']"
        ><mat-icon
          matTooltip="{{
            (isMenuOpen ? '' : 'MAINNAV.HELP_CENTER') | translate
          }}"
          matTooltipPosition="after"
          aria-label="Side nav Help Center icon"
          class="sideNavIcons material-icons-outlined"
          >help_center</mat-icon
        ><span class="spacerSideNav" *ngIf="isMenuOpen">{{
          "MAINNAV.HELP_CENTER" | translate
        }}</span></mat-list-item
      >
      <a routerLink="/settings">
        <mat-list-item [routerLinkActive]="['is-active']"
          ><mat-icon
            matTooltip="{{
              (isMenuOpen ? '' : 'MAINNAV.SETTINGS') | translate
            }}"
            matTooltipPosition="after"
            aria-label="Side nav Settings icon"
            class="sideNavIcons material-icons-outlined"
            >settings</mat-icon
          ><span class="spacerSideNav" *ngIf="isMenuOpen">{{
            "MAINNAV.SETTINGS" | translate
          }}</span></mat-list-item
        >
      </a>
    </mat-nav-list>

    <div *ngIf="isMenuOpen" class="div-copyright">
      <div class="d-block">
        <p class="copyright">
          &copy;
          {{ "MAINNAV.COPYRIGHT" | translate }}
        </p>
        <div class="version">
          {{ appVersion }}
        </div>
      </div>
    </div>
    <button
      matTooltipPosition="after"
      class="btnside"
      *ngIf="isButtonVisible === true"
      type="button"
      aria-label="Toggle sidenav"
      mat-icon-button
      (click)="onMenuToggle()"
    >
      <button
        class="navigate_next"
        mat-mini-fab
        *ngIf="!isMenuOpen"
        aria-label="Side nav toggle icon"
      >
        <mat-icon *ngIf="!isMenuOpen">navigate_next</mat-icon>
      </button>
      <button
        class="navigate_before"
        *ngIf="isMenuOpen"
        mat-mini-fab
        aria-label="Side nav toggle icon"
      >
        <mat-icon>navigate_before</mat-icon>
      </button>
    </button>
  </mat-sidenav>

  <!-- FOR SMALL SCREENS -->
  <mat-sidenav-content [ngStyle]="{ 'margin-left.px': contentFit }">
    <mat-toolbar *ngIf="auth.isAuthenticated$ | async">
      <button mat-button [matMenuTriggerFor]="hamMenu" class="hamburgerMenu">
        <mat-icon>menu</mat-icon>
      </button>
      <mat-menu #hamMenu="matMenu">
        <button mat-menu-item>
          <mat-list-item routerLink="/home"
            ><mat-icon>home</mat-icon
            >{{ "MAINNAV.HOME" | translate }}</mat-list-item
          >
        </button>
        <button mat-menu-item>
          <mat-list-item routerLink="/manage"
            ><mat-icon>business</mat-icon
            >{{ "MAINNAV.MANAGE" | translate }}</mat-list-item
          >
        </button>
        <button
          *ngIf="userDataService.isAdminDispatcher"
          mat-menu-item
          routerLink="/payroll"
        >
          <mat-list-item
            ><mat-icon>local_atm</mat-icon
            >{{ "MAINNAV.PAY_ROLL" | translate }}</mat-list-item
          >
        </button>
        <button mat-menu-item *ngIf="userDataService.isAdminDispatcher">
          <mat-list-item routerLink="/accounting"
            ><mat-icon>library_books</mat-icon
            >{{ "MAINNAV.ACCOUNTING" | translate }}</mat-list-item
          >
        </button>
        <button mat-menu-item>
          <mat-list-item routerLink="/analytics"
            ><mat-icon>assessment</mat-icon
            >{{ "MAINNAV.ANALYTICS" | translate }}</mat-list-item
          >
        </button>
        <button mat-menu-item class="hiddenItem">
          <mat-list-item
            ><mat-icon>today</mat-icon
            >{{ "MAINNAV.CALENDAR" | translate }}</mat-list-item
          >
        </button>
        <button mat-menu-item class="hiddenItem">
          <mat-list-item
            ><mat-icon>help_center</mat-icon
            >{{ "MAINNAV.HELP_CENTER" | translate }}</mat-list-item
          >
        </button>
        <button mat-menu-item routerLink="/settings">
          <mat-list-item
            ><mat-icon>settings</mat-icon
            >{{ "MAINNAV.SETTINGS" | translate }}</mat-list-item
          >
        </button>
        <button mat-menu-item class="hiddenItem">
          <mat-icon matBadge="8">notifications</mat-icon>
          {{ "MAINNAV.NOTIFICATIONS" | translate }}
        </button>
      </mat-menu>

      <div class="myDIV">
        <img *ngIf="mainLogo" [src]="mainLogo" class="logoTenantSmall" />
      </div>
      <mat-form-field class="filter-field">
        <input
          matInput
          placeholder="{{ 'MAINNAV.LOAD_SEARCH' | translate }}"
          (keydown.enter)="loadSearch($event.target.value)"
          [(ngModel)]="searchTerm"
          class="filter-input"
          autocomplete="off"
        />
        <mat-icon matPrefix class="filterIcon">search</mat-icon>
      </mat-form-field>
      <div
        class="advanced-search"
        routerLink="/advanced-search"
        matTooltip="{{ 'MAINNAV.ADVANCED_SEARCH' | translate }}"
        matTooltipPosition="after"
      >
        <mat-icon matPrefix>filter_list</mat-icon>
      </div>

      <span class="spacer"></span>

      <mat-nav-list class="inline-list">
        <mat-list-item class="hiddenItem" *ngIf="(isHandset$ | async) === false"
          ><mat-icon class="material-icons-outlined" routerLink="/settings"
            >settings</mat-icon
          ></mat-list-item
        >
        <mat-list-item class="hiddenItem" *ngIf="(isHandset$ | async) === false"
          ><mat-icon class="material-icons-outlined"
            >help_center</mat-icon
          ></mat-list-item
        >
        <mat-list-item
          class="hiddenItem"
          *ngIf="(isHandset$ | async) === false"
        >
          <mat-icon class="material-icons-outlined"
            >mail</mat-icon
          ></mat-list-item
        >
        <mat-list-item class="hiddenItem">
          <select
            #langselect
            (change)="
              translate.use(langselect.value); changeLanguage(langselect.value)
            "
          >
            <option selected value>
              {{ "SETTINGS.SELECT_LANG" | translate }}
            </option>
            <option value="en">en</option>
            <option value="fr">fr</option>
            <option value="es">es</option>
          </select>
        </mat-list-item>

        <mat-list-item class="notifications hiddenItem">
          <mat-icon class="material-icons-outlined" matBadge="8"
            >notifications</mat-icon
          ></mat-list-item
        >
        <mat-list-item class="padding-0-16">
          <button
            mat-raised-button
            class="quickbutton"
            (click)="openDialogQuickLoad()"
          >
            <mat-icon class="quickLoad">offline_bolt</mat-icon>
            <span class="quick">{{ "MAINNAV.LOAD" | translate }}</span>
          </button>
        </mat-list-item>
        <mat-divider
          class="dividerVertical mt-2 pl-2"
          vertical
          *ngIf="(isHandset$ | async) === false"
        ></mat-divider>
        <mat-list-item
          class="padding-0-16"
          *ngIf="auth.user$ | async as user"
          >{{ user.name }}</mat-list-item
        ><img
          src="https://www.w3schools.com/howto/img_avatar.png"
          alt="User picture"
          class="profilePicture"
          [matMenuTriggerFor]="userMenu"
        />
      </mat-nav-list>
      <mat-menu #userMenu="matMenu">
        <button
          mat-menu-item
          *ngIf="(isHandset$ | async) === true"
          class="hiddenItem"
        >
          <mat-icon>add</mat-icon>
          <span>{{ "MAINNAV.CREATE" | translate }}</span>
        </button>
        <button
          mat-menu-item
          *ngIf="(isHandset$ | async) === true"
          class="hiddenItem"
        >
          <mat-icon>folder_open</mat-icon>
          <span>{{ "MAINNAV.PROJECTS" | translate }}</span>
        </button>
        <button mat-menu-item class="hiddenItem">
          <mat-icon>mail</mat-icon>
          <span>{{ "MAINNAV.MAIL" | translate }}</span>
        </button>

        <button mat-menu-item class="hiddenItem">
          <mat-icon>notifications</mat-icon>
          <span>{{ "MAINNAV.NOTIFICATIONS" | translate }}</span>
        </button>
        <button mat-menu-item routerLink="/user-settings">
          <mat-icon>account_circle</mat-icon>
          {{ "MAINNAV.USER_SETTINGS" | translate }}
        </button>

        <button mat-menu-item (click)="logout()">
          <mat-icon>logout</mat-icon>
          {{ "MAINNAV.LOG_OUT" | translate }}
        </button>
      </mat-menu>
    </mat-toolbar>
    <!-- Add Content Here -->
    <ng-content></ng-content>
  </mat-sidenav-content>
</mat-sidenav-container>
