<div>
  <nav mat-tab-nav-bar class="navBarManage">
    <a mat-tab-link routerLink="trucks" routerLinkActive="active">
      {{ "MANAGE.TRUCK" | translate }}
      <span class="margin-left"></span>
      <mat-icon class="iconManageMenu">local_shipping</mat-icon>
    </a>

    <a mat-tab-link routerLink="trailers" routerLinkActive="active">
      {{ "MANAGE.TRAILERS" | translate }}
      <span class="margin-left"></span>
      <mat-icon class="iconManageMenu">rv_hookup</mat-icon>
    </a>

    <a mat-tab-link routerLink="drivers" routerLinkActive="active">
      {{ "MANAGE.DRIVERS" | translate }}
      <span class="margin-left"></span>
      <mat-icon class="iconManageMenu">airline_seat_recline_normal</mat-icon>
    </a>
    <a mat-tab-link routerLink="dispatchers" routerLinkActive="active">
      {{ "MANAGE.DISPATCHERS" | translate }}
      <span class="margin-left"></span>
      <mat-icon class="iconManageMenu">support_agent </mat-icon>
    </a>
    <a mat-tab-link routerLink="customer" routerLinkActive="active">
      {{ "MANAGE.CUSTOMER" | translate }}
      <span class="margin-left"></span>
      <mat-icon class="iconManageMenu">group</mat-icon>
    </a>
  </nav>
  <router-outlet></router-outlet>
</div>
