<div
  class="example-container"
  *ngFor="let amountImpact of data.amountImpacts; let i = index"
>
  <mat-form-field appearance="outline" class="description">
    <mat-label> {{ "ACCOUNT_ITEM.DESCRIPTION" | translate }}</mat-label>
    <input
      type="text"
      (keyup)="
        suggestionsKeyUp($event, amountImpact.payrollAmountImpact.description)
      "
      placeholder="{{ 'ACCOUNT_ITEM.DESCRIPTION' | translate }}"
      matInput
      [(ngModel)]="amountImpact.payrollAmountImpact.description"
      #name="ngModel"
      name="value{{ i }}"
      [matAutocomplete]="auto"
      (focusout)="onAmountInputFocusOut(i)"
    />
    <mat-autocomplete #auto="matAutocomplete">
      <mat-option
        *ngFor="let option of filteredOptions"
        [value]="option.description"
      >
        {{ option.description }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
  <mat-form-field appearance="outline" floatLabel="always" class="amount">
    <mat-label> {{ "ACCOUNT_ITEM.AMOUNT" | translate }}</mat-label>
    <input
      matInput
      type="number"
      class="alignRight"
      onfocus="this.select()"
      placeholder="0"
      [(ngModel)]="amountImpact.amount"
      #name="ngModel"
      name="value{{ i }}"
      autocomplete="off"
      (focusout)="onAmountInputFocusOut(i)"
    />
    <span matTextPrefix>$&nbsp;</span>
  </mat-form-field>
  <div class="closeButton">
    <button
      mat-icon-button
      class="removeItem"
      aria-label="Remove/clear"
      (click)="removevalue(i)"
      matTooltip="{{ 'ACCOUNT_ITEM.REMOVE' | translate }}"
    >
      <mat-icon> close </mat-icon>
    </button>
  </div>
</div>
