import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { firstValueFrom, lastValueFrom } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { map, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import Common from '../_helpers/common';
import { CommentModel } from '../_models/comments';
import { Paginator } from '../_models/common/paginator';
import { LoadModel } from '../_models/load/load';
import { LoadDocument } from '../_models/load/loadDocument';
import { LoadFilter } from '../_models/load/loadFilter';
import { LoadQuickRequest } from '../_models/load/loadQuickRequest';
import { LoadSearchResponse } from '../_models/load/loadSearchResponse';
import { LoadSendEmailRequest } from '../_models/load/loadSendEmailRequest';
import { GenericResponse, Response } from '../_models/response';
import { LoadFactory } from './factory/load.factory.service';
import { TenantService } from './tenant.service';

@Injectable({ providedIn: 'root' })
export class LoadService {
  constructor(
    private http: HttpClient,
    private tenantService: TenantService,
    private loadFactory: LoadFactory
  ) {}

  globalSearch(searchTerm: string): Observable<LoadSearchResponse> {
    let params = new HttpParams().set('GlobalSearch', searchTerm);
    return this.http
      .get<GenericResponse<LoadSearchResponse>>(`${environment.apiUrl}/loads`, {
        params: params,
      })
      .pipe(map((response) => response.data));
  }

  getLoadData(id: number): Observable<LoadModel> {
    let params = new HttpParams().set('searchTerm', id.toString());
    return this.http
      .get<Response>(`${environment.apiUrl}/loads`, { params: params })
      .pipe(map((response) => response.data as LoadModel))
      .pipe(
        tap((responseData) => {
          this.loadFactory.getLoadTimeZoneDate(responseData);
        })
      );
  }

  getLoadById(id: number): Observable<LoadModel> {
    return this.http
      .get<Response>(`${environment.apiUrl}/loads/${id}`)
      .pipe(map((response) => response.data as LoadModel))
      .pipe(
        tap((responseData) => {
          this.loadFactory.getLoadTimeZoneDate(responseData);
        })
      );
  }

  updateLoad(loadData: LoadModel) {
    loadData = this.loadFactory.getLoadUtcDate(loadData);
    return this.http
      .put<Response>(`${environment.apiUrl}/loads`, loadData)
      .pipe(map((response) => response.data as LoadModel))
      .pipe(
        tap((responseData) => {
          this.loadFactory.getLoadTimeZoneDate(responseData);
        })
      );
  }

  changeLoadStatus(id: number, statusId: number): Observable<LoadModel> {
    return this.http
      .get<Response>(`${environment.apiUrl}/loads/${id}/${statusId}`)
      .pipe(map((response) => response.data as LoadModel))
      .pipe(
        tap((responseData) => {
          this.loadFactory.getLoadTimeZoneDate(responseData);
        })
      );
  }

  addLoad(loadData: LoadModel) {
    loadData = this.loadFactory.getLoadUtcDate(loadData);
    return this.http
      .post<Response>(`${environment.apiUrl}/loads`, loadData)
      .pipe(map((response) => response.data as LoadModel))
      .pipe(
        tap((responseData) => {
          this.loadFactory.getLoadTimeZoneDate(responseData);
        })
      );
  }

  addLoadQuick(loadQuickRequest: LoadQuickRequest): Observable<LoadModel> {
    return this.http
      .post<Response>(`${environment.apiUrl}/loads/quick-add`, loadQuickRequest)
      .pipe(map((response) => response.data as LoadModel))
      .pipe(
        tap((responseData) => {
          this.loadFactory.getLoadTimeZoneDate(responseData);
        })
      );
  }

  async getPdfInvoice(loadId: number, includeNotes: boolean) {
    let headers = new HttpHeaders();
    const includeNotesString = includeNotes ? 'true' : 'false';

    let params = new HttpParams({
      fromObject: {
        printInvoice: includeNotesString,
      },
    });

    headers = headers.append('Accept', 'application/pdf');
    let url = `${environment.apiUrl}/loads/${loadId}/invoice`;
    return await lastValueFrom(
      this.http.get(url, {
        headers: headers,
        responseType: 'blob',
        params: params,
      })
    );
  }

  getLoadsByPage(
    paginator?: Paginator,
    statusId?: number,
    statusIdList?: number[],
    filter?: LoadFilter
  ): Observable<LoadSearchResponse> {
    let params = new HttpParams({
      fromObject: {
        PageSize: '' + (paginator.pageSize || 1000),
        PageNumber: '' + (paginator.pageNumber || 1),
        SortBy: '' + (paginator.sortBy || ''),
        SortOrder: '' + (paginator.sortOrder || ''),
        GlobalSearch: '' + (paginator.searchTerm || ''),
        HasHold: '' + (filter?.hasHold || ''),
      },
    });

    if (statusId) {
      params = params.append('StatusId', statusId.toString());
    } else if (statusIdList && statusIdList.length > 0) {
      statusIdList.forEach((statusIdItem) => {
        params = params.append('StatusIds', statusIdItem.toString());
      });
    }
    return this.http
      .get<GenericResponse<LoadSearchResponse>>(`${environment.apiUrl}/loads`, {
        params: params,
      })
      .pipe(map((response) => response.data));
  }

  advancedSearch(
    paginator?: Paginator,
    filter?: LoadFilter
  ): Observable<LoadSearchResponse> {
    let params = this.getAdvanceSerachParams(paginator, filter);

    return this.http
      .get<GenericResponse<LoadSearchResponse>>(
        `${environment.apiUrl}/loads/advanced`,
        {
          params: params,
        }
      )
      .pipe(map((response) => response.data));
  }

  async advancedSearchExportQuickBooks(
    paginator?: Paginator,
    filter?: LoadFilter
  ) {
    //Export will always return document without paging
    paginator.pageSize = null;
    paginator.pageNumber = null;
    let params = this.getAdvanceSerachParams(paginator, filter);

    let headers = new HttpHeaders();
    headers = headers.append('Accept', 'text/csv;encoding:utf-8');
    return await firstValueFrom(
      this.http.get(`${environment.apiUrl}/loads/advanced/quick-books-csv`, {
        headers: headers,
        params: params,
        responseType: 'blob',
      })
    );
  }

  async advancedSearchExport(paginator?: Paginator, filter?: LoadFilter) {
    //Export will always return document without paging
    paginator.pageSize = null;
    paginator.pageNumber = null;
    let params = this.getAdvanceSerachParams(paginator, filter);

    let headers = new HttpHeaders();
    headers = headers.append('Accept', 'application/vnd.ms-excel');
    return await firstValueFrom(
      this.http.get(`${environment.apiUrl}/loads/advanced/csv`, {
        headers: headers,
        params: params,
        responseType: 'blob',
      })
    );
  }

  private getAdvanceSerachParams(paginator: Paginator, filter: LoadFilter) {
    let params = new HttpParams({
      fromObject: {
        PageSize: '' + (paginator.pageSize || 1000),
        PageNumber: '' + (paginator.pageNumber || 1),
        SortBy: '' + (paginator.sortBy || ''),
        SortOrder: '' + (paginator.sortOrder || ''),
      },
    });

    if (filter.deliveryDateFrom) {
      params = params.append(
        'DeliveryDateFrom',
        this.tenantService.getTenetUtcDateString(
          new Date(filter.deliveryDateFrom)
        )
      );
    }

    if (filter.deliveryDateTo) {
      params = params.append(
        'DeliveryDateTo',
        this.tenantService.getTenetUtcDateString(
          Common.getDateEndOfDay(new Date(filter.deliveryDateTo))
        )
      );
    }

    filter.dispatcherList.forEach((dispatcher) => {
      params = params.append('DispatcherIds', dispatcher.id);
    });
    filter.driverList.forEach((driver) => {
      params = params.append('DriverIds', driver.id);
    });
    filter.truckList.forEach((truck) => {
      params = params.append('TruckIds', truck.id);
    });
    filter.statusList.forEach((status) => {
      params = params.append('StatusIds', status.id);
    });
    return params;
  }

  async getDocument(documentId: number) {
    let headers = new HttpHeaders();
    headers = headers.append('Accept', 'application/pdf');
    let url = `${environment.apiUrl}/documents/${documentId}`;
    return await lastValueFrom(
      this.http.get(url, { headers: headers, responseType: 'blob' })
    );
  }

  async getAllDocuments(loadIdList: Array<number>) {
    let headers = new HttpHeaders();
    let requestData = {
      loadIds: loadIdList,
    };

    headers = headers.append('Accept', 'application/pdf');
    let url = `${environment.apiUrl}/documents/get-all`;
    return await lastValueFrom(
      this.http.post(url, requestData, {
        headers: headers,
        responseType: 'blob',
      })
    );
  }

  getDocumentPreview(documentId: number): Observable<LoadDocument> {
    return this.http
      .get<Response>(`${environment.apiUrl}/documents/base64/${documentId}`)
      .pipe(map((response) => response.data as LoadDocument));
  }

  deleteDocument(documentId: number) {
    return this.http.delete<Response>(
      `${environment.apiUrl}/documents/${documentId}`
    );
  }

  addComment(loadId: number, comment: CommentModel): Observable<CommentModel> {
    return this.http
      .post<Response>(`${environment.apiUrl}/loads/${loadId}/comment`, comment)
      .pipe(map((response) => response.data as CommentModel));
  }

  updateComment(
    loadId: number,
    comment: CommentModel
  ): Observable<CommentModel> {
    return this.http
      .patch<Response>(`${environment.apiUrl}/loads/${loadId}/comment`, comment)
      .pipe(map((response) => response.data as CommentModel));
  }

  sendEmail(loadSendEmailRequest: LoadSendEmailRequest) {
    return this.http.post<Response>(
      `${environment.apiUrl}/documents/email-all`,
      loadSendEmailRequest
    );
  }

  addDocument(
    loadId: number,
    fileNameWithExtension: string,
    bytes: string
  ): Observable<LoadDocument> {
    return this.http
      .patch<Response>(`${environment.apiUrl}/loads/${loadId}/add-document`, {
        fileNameWithExtension,
        bytes,
      })
      .pipe(map((response) => response.data as LoadDocument));
  }

  ////// Events
  loadAdded: EventEmitter<any> = new EventEmitter();
  emitLoadAdded() {
    this.loadAdded.emit(null);
  }
}
