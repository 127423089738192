import { Injectable } from '@angular/core';
import { Note } from 'src/app/_models/note';
import { TenantService } from '../tenant.service';

@Injectable({
  providedIn: 'root',
})
export class NoteFactory {
  constructor(private tenantService: TenantService) {}

  public getNoteUtcDate(note: Note): Note {
    note.date = this.tenantService.getTenetUtcDate(note.date);
    return note;
  }

  public getNoteTimeZoneDate(note: Note): Note {
    note.date = this.tenantService.getTenantTimeZoneDate(note.date);

    return note;
  }
}
