import { DriverModel } from 'src/app/_models/driver/driver';
import { Location } from 'src/app/_models/location/location';
import { CdlModel } from './cdl';
import { DriverReference } from './driverReference';
import { EmergencyContact } from './emergencyContact';
import { EmploymentHistory } from './employmentHistory';

export class DriverFullModel extends DriverModel {
  isPresentAtAddressLocationTreeYearsOrMore: boolean;
  locationsPastThreeYears: Location[];
  dateOfBirth: Date;
  socialSecurityNumber: string;
  isKnownByOtherName: boolean;
  canFurnishProofEligibleToWorkInUS: boolean;
  isAtLeast23OfAge: boolean;
  haveHeldValidUsLicenceForPast36Months: boolean;
  cdlNumber: string;
  cdlExpirationDate: Date;
  expirationDateOfDOTPsysicalCard: Date;
  driverCdlEndorsments: CdlModel[];
  hazmatExpirationDate: Date;
  twicExpirationDate: Date;
  listOfLicencesHeldInPrev5Years: string;
  driverEmploymentHistories: EmploymentHistory[];
  hadMovingViolationsInLast5Years: boolean;
  hadLicenceSuspended: boolean;
  hadAccidentsInLast5Years: boolean;
  hadDuiDwiOrOvi: boolean;
  convictedOfFelony: boolean;
  convictedOfMisdemeanor: boolean;
  driverReferences: DriverReference[];
  driverEmergencyContacts: EmergencyContact[];
  finalConfirmation: boolean;
}
