import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import Common from '../_helpers/common';
import { AdminModel } from '../_models/admin';
import { Paginator } from '../_models/common/paginator';
import { Response } from '../_models/response';
import { ResponseData } from '../_models/responseData';

@Injectable({ providedIn: 'root' })
export class AdminService {
  constructor(private http: HttpClient) {}

  getAdmins(): Observable<ResponseData> {
    return this.http
      .get<Response>(`${environment.apiUrl}/admins`)
      .pipe(map((response) => response.data as ResponseData));
  }

  getAllAdmins(
    paginator?: Paginator,
    isActive?: boolean
  ): Observable<ResponseData> {
    const params = new HttpParams({
      fromObject: {
        PageSize: '' + paginator.pageSize,
        SortBy: '' + paginator.sortBy,
        SortOrder: '' + paginator.sortOrder,
        isActive: Common.getSafeBooleanString(isActive),
      },
    });
    return this.http
      .get<Response>(`${environment.apiUrl}/admins`, { params: params })
      .pipe(map((response) => response.data as ResponseData));
  }

  getAdmin(id: number): Observable<AdminModel> {
    return this.http
      .get<Response>(`${environment.apiUrl}/admins/${id}`)
      .pipe(map((response) => response.data as AdminModel));
  }

  updateAdmin(admin: AdminModel) {
    return this.http
      .put<Response>(`${environment.apiUrl}/admins`, admin)
      .pipe(map((response) => response.data as AdminModel));
  }

  addAdmin(admin: AdminModel) {
    return this.http
      .post<Response>(`${environment.apiUrl}/admins`, admin)
      .pipe(map((response) => response.data as AdminModel));
  }

  deleteAdmin(id: number): Observable<Response> {
    return this.http.delete<Response>(`${environment.apiUrl}/admins/${id}`);
  }
}
