<div class="modal-dialog formInModal">
  <div *ngIf="!dataLoading">
    <h2 *ngIf="admin && !admin.id" mat-dialog-title class="text">
      {{ "ADMIN.ADD_ADMIN" | translate }}
    </h2>
    <h2 *ngIf="admin.id" mat-dialog-title>
      <h2 class="text" [hidden]="readOnly">
        {{ "ADMIN.EDIT_ADMIN" | translate }}
        <br />
        {{ admin.firstname + " " + admin.lastname }}
      </h2>
      <h2 class="text" [hidden]="!readOnly">
        {{ "ADMIN.PREVIEW_ADMIN" | translate }}
        <br />
        {{ admin.firstname + " " + admin.lastname }}
      </h2>
    </h2>
  </div>
  <hr />
  <form #editAdmin="ngForm">
    <mat-dialog-content class="box">
      <mat-form-field
        floatLabel="auto"
        hideRequiredMarker
        appearance="outline"
        class="displayNone"
      >
        <mat-label class="global_search">{{
          "ADMIN.ADMIN_ID" | translate
        }}</mat-label>
        <input
          matInput
          name="adminId"
          [(ngModel)]="admin.id"
          autocomplete="off"
        />
      </mat-form-field>
      <mat-form-field
        floatLabel="auto"
        hideRequiredMarker
        appearance="outline"
        class="formWidth"
      >
        <mat-label class="global_search"
          >{{ "ADMIN.FIRST_NAME" | translate
          }}<span class="star">*</span></mat-label
        >
        <input
          matInput
          [(ngModel)]="admin.firstname"
          autocomplete="off"
          required
          [readonly]="readOnly"
          name="firstName"
        />
      </mat-form-field>

      <mat-form-field
        floatLabel="auto"
        hideRequiredMarker
        appearance="outline"
        class="formWidth"
      >
        <mat-label class="global_search">{{
          "ADMIN.MIDDLE_NAME" | translate
        }}</mat-label>
        <input
          matInput
          [(ngModel)]="admin.middlename"
          [readonly]="readOnly"
          autocomplete="off"
          name="middleName"
        />
      </mat-form-field>

      <mat-form-field
        floatLabel="auto"
        hideRequiredMarker
        appearance="outline"
        class="formWidth"
      >
        <mat-label class="global_search"
          >{{ "ADMIN.LAST_NAME" | translate
          }}<span class="star">*</span></mat-label
        >
        <input
          matInput
          [(ngModel)]="admin.lastname"
          autocomplete="off"
          required
          [readonly]="readOnly"
          name="lastName"
        />
      </mat-form-field>
      <br />

      <mat-slide-toggle
        color="primary"
        class="poBoxToggle"
        name="isAddressPoBoxName"
        [(ngModel)]="isAddressPoBox"
        (change)="clearAddress()"
        >{{ "ADMIN.ADD_ADDRESS_MANUALLY" | translate }}</mat-slide-toggle
      >

      <mat-form-field
        floatLabel="auto"
        hideRequiredMarker
        appearance="outline"
        class="address"
        *ngIf="!isAddressPoBox"
      >
        <mat-label class="global_search">{{
          "ADMIN.SEARCH_ADDRESS" | translate
        }}</mat-label>
        <input
          matInput
          #inputAdminAddress
          [matAutocomplete]="admin_address"
          [formControl]="searchAddress"
          (blur)="autofillAdminAddress()"
          class="address"
          [readonly]="readOnly"
          autocomplete="off"
          name="address"
        />
        <mat-icon matSuffix class="icon_search">search</mat-icon>
        <mat-autocomplete
          #admin_address="matAutocomplete"
          [displayWith]="displayFn"
          (optionSelected)="selectAdminLocation($event.option.value)"
        >
          <ng-container *ngIf="searchResult.length > 0">
            <mat-option
              *ngFor="let location of searchResult"
              [value]="location"
            >
              <span>{{
                location.address +
                  ", " +
                  location.state +
                  ", " +
                  location.countryCode
              }}</span>
            </mat-option>
          </ng-container>
        </mat-autocomplete>
      </mat-form-field>

      <mat-form-field
        floatLabel="auto"
        appearance="outline"
        class="inputPObox"
        hideRequiredMarker
        id="poBox"
        *ngIf="isAddressPoBox"
      >
        <mat-label>{{ "ADMIN.ADDRESS" | translate }}</mat-label>
        <input
          matInput
          name="poBox"
          [(ngModel)]="admin.location.poBox"
          autocomplete="off"
        />
      </mat-form-field>

      <br />

      <mat-form-field
        floatLabel="auto"
        hideRequiredMarker
        appearance="outline"
        class="formWidth"
      >
        <mat-label class="global_search"
          >{{ "ADMIN.EMAIL" | translate }}<span class="star">*</span></mat-label
        >
        <input
          matInput
          email
          pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}?"
          type="email"
          [(ngModel)]="admin.email"
          autocomplete="off"
          required
          [readonly]="readOnly || admin.id"
          name="email"
          matTooltip="{{ 'APPLICATION.CAN_NOT_CHANGE_EMAIL' | translate }}"
          [matTooltipDisabled]="readOnly || !admin.id"
        />
      </mat-form-field>

      <mat-form-field
        floatLabel="auto"
        hideRequiredMarker
        appearance="outline"
        class="formWidth"
      >
        <mat-label class="global_search">{{
          "ADMIN.PHONE_NUMBER" | translate
        }}</mat-label>
        <input
          matInput
          [(ngModel)]="admin.phoneNumber"
          autocomplete="off"
          [readonly]="readOnly"
          pattern="[- +()0-9]+"
          maxlength="18"
          name="phone"
        />
      </mat-form-field>

      <mat-slide-toggle
        *ngIf="adminId"
        color="primary"
        [checked]="toBool(admin.isActive)"
        (change)="setActiveValue($event)"
        [disabled]="readOnly"
        >{{ "ADMIN.ACTIVE" | translate }}</mat-slide-toggle
      >
    </mat-dialog-content>
    <div *ngIf="adminId && !admin.accountActivated" class="divNoComplete">
      <label class="labelNoComplete">{{
        "APPLICATION.PROFILE_NOT_COMPLETED" | translate
      }}</label>
    </div>
    <div
      *ngIf="adminId && !admin.accountActivated && !readOnly"
      class="divResend"
    >
      <button class="buttonResend" (click)="resendEmail()" mat-raised-button>
        {{ "APPLICATION.RESEND_ACTIVATION_EMAIL" | translate }}
      </button>
    </div>
    <mat-dialog-actions>
      <button mat-button class="btnCan" mat-dialog-close>
        {{ "ADMIN.CANCEL" | translate }}
      </button>
      <button
        mat-button
        *ngIf="!readOnly && userDataService.isAdminDispatcher"
        class="btn btn-green"
        (click)="save(editAdmin.form.invalid)"
      >
        {{ "ADMIN.SAVE" | translate }}
      </button>
    </mat-dialog-actions>
  </form>
</div>
