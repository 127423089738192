<div class="modal-dialog">
  <h2 mat-dialog-title>
    <mat-icon class="iconwarn">warning</mat-icon>
  </h2>
  <mat-dialog-content class="mat-typography">
    <p class="text">{{ "CONFIRM_DIALOG.PAY_CALCULATION" | translate }}</p>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-flat-button class="btnCan" mat-dialog-close>
      {{ "CONFIRM_DIALOG.CANCEL" | translate }}
    </button>
    <button
      mat-button
      class="btn btn-green"
      (click)="confirm()"
      cdkFocusInitial
    >
      {{ "CONFIRM_DIALOG.YES" | translate }}
    </button>
  </mat-dialog-actions>
</div>
