<div>
  <nav mat-tab-nav-bar class="settings-container">
    <a mat-tab-link routerLink="users" routerLinkActive="active">
      {{ "SETTINGS.USERS" | translate }}
      <span class="margin-left"></span>
      <mat-icon class="iconManageMenu">group</mat-icon>
    </a>

    <a mat-tab-link routerLink="company-data" routerLinkActive="active">
      {{ "SETTINGS.COMPANY_DATA" | translate }}
      <span class="margin-left"></span>
      <mat-icon class="iconManageMenu">tune</mat-icon>
    </a>

    <a
      *ngIf="userDataService.isAdminDispatcher"
      mat-tab-link
      routerLink="transactions-and-biling"
      routerLinkActive="active"
    >
      {{ "SETTINGS.TRANSACTIONS_BILING" | translate }}
      <span class="margin-left"></span>
      <mat-icon>account_balance_wallet</mat-icon>
    </a>
  </nav>
  <router-outlet></router-outlet>
</div>
