import { CodeModel } from './code';
export class TrailerModel {
    id: number;
    trailerNumber: string;
    owner: string;
    licensePlate: string;
    equipmentType: CodeModel;
    equipmentTypeId: number;
    registrationExpirationDate: Date;
    annualInspectionExpirationDate: Date;
    quarterlyInspectionDue: Date;
    isActive: boolean;
}