<div class="wrapper" *ngIf="!showCustomerDiv">
  <button (click)="back()" class="buttonBack">
    <mat-icon aria-label="Back icon" class="back_icon">arrow_back_ios</mat-icon
    >{{ "NEW_LOAD.BACK" | translate }}
  </button>
</div>

<div class="displayInlineBlock">
  <form #editLoad="ngForm">
    <div class="displayInlineBlock" *ngIf="!showCustomerDiv">
      <div class="titleLoad">
        <p *ngIf="!loadData.id" mat-dialog-title class="componentName">
          {{ "NEW_LOAD.ADD_NEW_LOAD" | translate }}
        </p>
        <p
          *ngIf="loadData.id"
          mat-dialog-title
          [ngClass]="{
            long: loadData?.loadNumber?.length > 1,
            verylong: loadData?.loadNumber?.length > 15,
            extralong: loadData?.loadNumber?.length > 20,
            large: loadData?.loadNumber?.length > 30
          }"
        >
          {{ "NEW_LOAD.EDIT_LOAD" | translate }}
          {{ loadData.loadNumber }}
        </p>
      </div>
      <mat-form-field
        floatLabel="auto"
        hideRequiredMarker
        appearance="outline"
        class="middleInput"
        id="statusField"
      >
        <mat-label class="global_search">{{
          "NEW_LOAD.SELECT_STATUS" | translate
        }}</mat-label>
        <mat-select
          class="global_search"
          [compareWith]="compareObjects"
          [(ngModel)]="loadData.status"
          name="loadStatus"
        >
          <ng-container *ngIf="statusTypeList.length > 0">
            <mat-option
              *ngFor="let statusType of statusTypeList"
              [value]="statusType"
            >
              {{ "LOAD_STATUS." + statusType.name | translate }}
            </mat-option>
          </ng-container>
        </mat-select>
      </mat-form-field>
      <div>
        <mat-checkbox
          [checked]="toBool(loadData.hasHold)"
          (change)="toggleHold($event)"
          class="issue-hold"
          >{{ "NEW_LOAD.ISSUE-HOLD" | translate }}</mat-checkbox
        >
      </div>
      <div class="displayBlock">
        <div class="display-inline">
          <mat-form-field
            floatLabel="auto"
            hideRequiredMarker
            appearance="outline"
            class="formWidth"
            required
          >
            <mat-label class="global_search"
              >{{ "NEW_LOAD.SELECT_DRIVER" | translate }}
              <span class="star">*</span>
            </mat-label>

            <mat-select
              [(ngModel)]="loadData.driver"
              required
              class="global_search"
              placeholder="{{ 'NEW_LOAD.SELECT_DRIVER' | translate }}"
              [compareWith]="compareObjects"
              name="loadDriver"
              (selectionChange)="getTrucks()"
            >
              <ng-container *ngIf="driverList.length > 0">
                <mat-option *ngFor="let driver of driverList" [value]="driver">
                  {{ driver.firstname + " " + driver.lastname }}
                </mat-option>
              </ng-container>
            </mat-select>
          </mat-form-field>

          <mat-form-field
            floatLabel="auto"
            hideRequiredMarker
            appearance="outline"
            class="formWidth"
            required
          >
            <mat-label class="global_search"
              >{{ "NEW_LOAD.SELECT_DISPATCHER" | translate }}
              <span class="star">*</span>
            </mat-label>

            <mat-select
              [(ngModel)]="loadData.dispatcher"
              required
              class="global_search"
              placeholder="{{ 'NEW_LOAD.SELECT_DISPATCHER' | translate }}"
              [compareWith]="compareObjects"
              name="loadDispatcher"
            >
              <ng-container *ngIf="dispatcherList.length > 0">
                <mat-option
                  *ngFor="let dispatcher of dispatcherList"
                  [value]="dispatcher"
                >
                  {{ dispatcher.firstname + " " + dispatcher.lastname }}
                </mat-option>
              </ng-container>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="displayBlock">
        <mat-form-field
          floatLabel="auto"
          hideRequiredMarker
          appearance="outline"
          class="formWidth"
          required
        >
          <mat-label class="global_search"
            >{{ "NEW_LOAD.SELECT_TRUCK" | translate }}
            <span class="star">*</span>
          </mat-label>

          <mat-select
            [(ngModel)]="loadData.truck"
            class="global_search"
            required
            placeholder="{{ 'NEW_LOAD.SELECT_TRUCK' | translate }}"
            [compareWith]="compareObjects"
            name="loadTruck"
            (selectionChange)="truckSelectionChange(loadData.truck.id)"
          >
            <ng-container *ngIf="driverTruckList.length > 0">
              <mat-option *ngFor="let truck of driverTruckList" [value]="truck">
                {{ truck.truckNumber }}
              </mat-option>
            </ng-container>
          </mat-select>
        </mat-form-field>

        <mat-form-field
          floatLabel="auto"
          hideRequiredMarker
          appearance="outline"
          class="formWidth"
        >
          <mat-label class="global_search"
            >{{ "NEW_LOAD.SELECT_TRAILER" | translate }}
          </mat-label>

          <mat-select
            [(ngModel)]="loadData.trailer"
            class="global_search"
            placeholder="{{ 'NEW_LOAD.SELECT_TRAILER' | translate }}"
            (selectionChange)="onSelectionTrailerChanged($event)"
            [compareWith]="compareObjects"
            name="loadTrailer"
          >
            <ng-container *ngIf="trailerList.length > 0">
              <mat-option *ngFor="let trailer of trailerList" [value]="trailer">
                {{ trailer.trailerNumber }}
              </mat-option>
            </ng-container>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="displayBlock">
        <mat-form-field
          floatLabel="auto"
          hideRequiredMarker
          appearance="outline"
          class="formWidth"
        >
          <mat-label class="global_search">
            {{ "NEW_LOAD.EQUIPMENT" | translate }}
          </mat-label>
          <mat-select
            class="global_search"
            placeholder="{{ 'NEW_LOAD.EQUIPMENT' | translate }}"
            [compareWith]="compareObjects"
            [(ngModel)]="loadData.equipmentType"
            name="loadEquipmentType"
          >
            <ng-container *ngIf="equipmentTypeList.length > 0">
              <mat-option
                *ngFor="let equipmentType of equipmentTypeList"
                [value]="equipmentType"
              >
                {{ equipmentType.name }}
              </mat-option>
            </ng-container>
          </mat-select>
        </mat-form-field>

        <mat-form-field
          floatLabel="auto"
          hideRequiredMarker
          appearance="outline"
          class="formWidth"
        >
          <mat-label class="global_search"
            >{{ "NEW_LOAD.ORDER" | translate
            }}<span class="star">*</span></mat-label
          >
          <input
            matInput
            onfocus="this.select()"
            [(ngModel)]="loadData.orderNumber"
            autocomplete="off"
            name="loadOrderNumber"
            required
          />
        </mat-form-field>
      </div>

      <div class="displayBlock">
        <mat-form-field
          floatLabel="auto"
          hideRequiredMarker
          appearance="outline"
          class="formWidth"
        >
          <mat-label class="global_search"
            >{{ "NEW_LOAD.CUSTOMER" | translate
            }}<span class="star">*</span></mat-label
          >

          <input
            *ngIf="loadData.customer"
            matInput
            onfocus="this.select()"
            matTooltip="{{ loadData.customer.name }}, MC: {{
              loadData.customer.mc
            }}, email: {{ loadData.customer.email || '' }}, phone: {{
              loadData.customer.phoneNumber
            }}"
            matTooltipPosition="above"
            [matAutocomplete]="customer"
            [formControl]="searchCustomers"
            required
          />

          <input
            *ngIf="!loadData.customer"
            matInput
            onfocus="this.select()"
            matTooltipPosition="above"
            [matAutocomplete]="customer"
            [formControl]="searchCustomers"
            required
          />

          <mat-icon matSuffix class="arrow_down">search</mat-icon>
        </mat-form-field>
        <mat-autocomplete
          #customer="matAutocomplete"
          [displayWith]="displayCustomer"
          (optionSelected)="selectCustomer($event.option.value)"
        >
          <ng-container *ngIf="searchResultCustomers.length > 0">
            <mat-option
              class="table-caption"
              matTooltip="{{ customer.name }}, {{ customer.mc }}, {{
                customer.email || ' '
              }}, {{ customer.phoneNumber || ' ' }}"
              *ngFor="let customer of searchResultCustomers"
              [value]="customer"
            >
              <span
                >{{ customer.name }},
                <span class="propertyCustomer"
                  >{{ "CUSTOMER.MC" | translate }}:
                </span>
                {{ customer.mc }},
                <span class="propertyCustomer"
                  >{{ "CUSTOMER.EMAIL" | translate }}: </span
                >{{ customer.email || " " }},
                <span class="propertyCustomer"
                  >{{ "CUSTOMER.PHONE" | translate }}:
                </span>
                {{ customer.phoneNumber || " " }}</span
              >
            </mat-option>
          </ng-container>
        </mat-autocomplete>

        <div class="hintCustomer">
          <button
            type="button"
            mat-raised-button
            (click)="addNewCustomer()"
            class="addNewCustomer"
          >
            {{ "NEW_LOAD.ADD_NEW_CUSTOMER" | translate }}
          </button>
        </div>
      </div>

      <div class="displayBlock">
        <mat-form-field
          floatLabel="auto"
          hideRequiredMarker
          appearance="outline"
          class="customerContact"
        >
          <mat-label class="global_search">{{
            "NEW_LOAD.PERSON" | translate
          }}</mat-label>
          <input
            matInput
            onfocus="this.select()"
            [(ngModel)]="loadData.contactPerson"
            name="loadContactPerson"
            autocomplete="off"
          />
        </mat-form-field>
      </div>

      <hr />

      <div class="displayBlock">
        <mat-form-field
          floatLabel="auto"
          hideRequiredMarker
          appearance="outline"
          class="address"
        >
          <mat-label class="global_search"
            >{{ "NEW_LOAD.PICKUP_ADDRESS" | translate
            }}<span class="star">*</span></mat-label
          >
          <input
            matInput
            #inputPickupAddress
            onfocus="this.select()"
            [matAutocomplete]="pickup_address"
            [formControl]="searchPickupAddress"
            (blur)="autofillPickupAddress()"
            required
          /><mat-icon matSuffix class="icon_search">search</mat-icon>
          <mat-autocomplete
            #pickup_address="matAutocomplete"
            [displayWith]="displayLocation"
            (optionSelected)="selectPickupLocation($event.option.value)"
          >
            <ng-container *ngIf="searchResult.length > 0">
              <mat-option
                *ngFor="let location of searchResult"
                [value]="location"
              >
                <span>{{
                  location.address + ", " + location.countryCode
                }}</span>
              </mat-option>
            </ng-container>
          </mat-autocomplete>
        </mat-form-field>
      </div>

      <div class="displayBlock">
        <mat-form-field
          floatLabel="auto"
          hideRequiredMarker
          appearance="outline"
          class="address"
          id="deliveryAddress"
        >
          <mat-label class="global_search"
            >{{ "NEW_LOAD.DELIVERY_ADDRESS" | translate
            }}<span class="star">*</span></mat-label
          >
          <input
            matInput
            #inputDeliveryAddress
            onfocus="this.select()"
            [matAutocomplete]="delivery_address"
            [formControl]="searchDeliveryAddress"
            (blur)="autofillDeliveryAddress()"
            id="truncateDelivery"
            required
          /><mat-icon matSuffix class="icon_search">search</mat-icon>
          <mat-autocomplete
            #delivery_address="matAutocomplete"
            [displayWith]="displayLocation"
            (optionSelected)="selectDeliveryLocation($event.option.value)"
          >
            <ng-container *ngIf="searchResult.length > 0">
              <mat-option
                *ngFor="let location of searchResult"
                [value]="location"
              >
                <span>{{
                  location.address + ", " + location.countryCode
                }}</span>
              </mat-option>
            </ng-container>
          </mat-autocomplete>
        </mat-form-field>
        <mat-form-field
          floatLabel="auto"
          hideRequiredMarker
          appearance="outline"
          class="lumperInput"
        >
          <mat-label class="global_search">{{
            "NEW_LOAD.LUMPER_FEE" | translate
          }}</mat-label>
          <input
            matInput
            onfocus="this.select()"
            (change)="calculateTotalInvoiced()"
            [(ngModel)]="loadData.lumperCost"
            type="number"
            name="lumperCost"
            [disabled]="tonuSelected"
            autocomplete="off"
          />
          <span matPrefix class="dolarSymbol">$&nbsp;</span>
        </mat-form-field>
      </div>
      <div class="displayDatePicker">
        <div class="d-flex">
          <mat-form-field
            floatLabel="auto"
            hideRequiredMarker
            appearance="outline"
            class="dateTime"
          >
            <input
              matInput
              onfocus="this.select()"
              (dateInput)="pickupDateChange($event.value)"
              [matDatepicker]="pickup_date"
              [(ngModel)]="loadData.pickupDate"
              name="loadPickupDate"
              required
            /><mat-label
              >{{ "NEW_LOAD.PICKUP_DATE" | translate
              }}<span class="star">*</span></mat-label
            >
            <mat-datepicker-toggle
              matSuffix
              [for]="pickup_date"
            ></mat-datepicker-toggle>
            <mat-datepicker #pickup_date></mat-datepicker>
          </mat-form-field>
          <mat-form-field appearance="outline" class="dateTime">
            <mat-label>{{ "NEW_LOAD.PICKUP_TIME" | translate }}</mat-label>
            <input
              type="time"
              [(ngModel)]="pickupTime"
              (change)="pickupTimeChange($event)"
              matInput
              name="pickTime"
            />
          </mat-form-field>
        </div>
        <div class="d-flex">
          <mat-form-field
            floatLabel="auto"
            hideRequiredMarker
            appearance="outline"
            class="dateTime"
          >
            <input
              matInput
              onfocus="this.select()"
              (dateInput)="deliveryDateChange($event.value)"
              [matDatepicker]="delivery_date"
              [(ngModel)]="loadData.deliveryDate"
              name="loadDeliveryDate"
              required
            />
            <mat-label class="global_search"
              >{{ "NEW_LOAD.DELIVERY_DATE" | translate
              }}<span class="star">*</span></mat-label
            >
            <mat-datepicker-toggle
              matSuffix
              [for]="delivery_date"
            ></mat-datepicker-toggle>
            <mat-datepicker #delivery_date></mat-datepicker>
          </mat-form-field>
          <mat-form-field appearance="outline" class="dateTime">
            <mat-label>{{ "NEW_LOAD.DELIVERY_TIME" | translate }}</mat-label>
            <input
              type="time"
              [(ngModel)]="deliveryTime"
              (change)="deliveryTimeChange($event)"
              matInput
              name="deliverTime"
            />
          </mat-form-field>
        </div>
      </div>

      <hr />

      <h2 class="stops_title">{{ "NEW_LOAD.STOPS_ADDRESS" | translate }}</h2>

      <div class="displayStops" *ngIf="showStops">
        <div cdkDropList #data (cdkDropListDropped)="dropStop($event)">
          <div *ngFor="let address of loadData.stops; let i = index" cdkDrag>
            <div class="stopaddress">
              <b>{{ address.location.sequence }}.</b>&nbsp;&nbsp;{{
                address.location.address
              }}

              <span
                class="material-icons"
                (click)="deleteStop(i)"
                id="icon_close"
              >
                close
              </span>
            </div>
            <div class="display-inline">
              <mat-form-field
                floatLabel="auto"
                hideRequiredMarker
                appearance="outline"
                class="lumperInput"
              >
                <mat-label class="global_search">{{
                  "NEW_LOAD.LUMPER_FEE" | translate
                }}</mat-label>
                <input
                  matInput
                  onfocus="this.select()"
                  [(ngModel)]="address.lumperFee"
                  (change)="calculateTotalInvoiced()"
                  type="number"
                  [disabled]="tonuSelected"
                  [name]="'lumperFee_' + i"
                  autocomplete="off"
                />
                <span matPrefix class="dolarSymbol">$&nbsp;</span>
              </mat-form-field>
            </div>
          </div>
        </div>

        <mat-form-field
          class="example-full-width"
          floatLabel="auto"
          hideRequiredMarker
          appearance="outline"
          class="addressStop"
        >
          <mat-label>{{ "NEW_LOAD.STOP_ADDRESS" | translate }}</mat-label>
          <input
            matInput
            [matAutocomplete]="location"
            [formControl]="searchStopsAddress"
          />

          <mat-icon matSuffix class="arrow_down">search</mat-icon>

          <mat-hint class="hintNumOfStops"
            >{{ "NEW_LOAD.NUM_OF_STOP" | translate }}
            {{ loadData.stops.length }}</mat-hint
          >

          <mat-autocomplete
            #location="matAutocomplete"
            [displayWith]="displayLocation"
            (optionSelected)="selectStopAddress($event.option.value)"
          >
            <ng-container *ngIf="searchResult.length > 0">
              <mat-option
                *ngFor="let location of searchResult"
                [value]="location"
              >
                <span>{{
                  location.address + ", " + location.countryCode
                }}</span>
              </mat-option>
            </ng-container>
          </mat-autocomplete>
        </mat-form-field>
      </div>

      <div class="displayBlock">
        <mat-form-field
          floatLabel="auto"
          hideRequiredMarker
          appearance="outline"
          class="middleInput"
        >
          <mat-label class="global_search">{{
            "NEW_LOAD.TR_HAUL_TYPE" | translate
          }}</mat-label>
          <mat-select
            class="global_search"
            [compareWith]="compareObjects"
            [(ngModel)]="loadData.haulType"
            (selectionChange)="onSelectionHaulTypeChanged($event)"
            [value]="loadData.haulType"
            name="loadHaulType"
          >
            <ng-container *ngIf="haulTypeList.length > 0">
              <mat-option
                *ngFor="let haulType of haulTypeList"
                [value]="haulType"
              >
                {{ "HAUL_TYPE." + haulType.name | translate }}
              </mat-option>
            </ng-container>
          </mat-select>
        </mat-form-field>

        <mat-form-field
          floatLabel="auto"
          hideRequiredMarker
          appearance="outline"
          class="middleInput"
          *ngIf="!loadData.haulType || loadData.haulType.name == 'TONU'"
        >
          <mat-label class="global_search">{{
            "NEW_LOAD.TONU" | translate
          }}</mat-label>
          <input
            matInput
            onfocus="this.select()"
            [(ngModel)]="loadData.toNu"
            (change)="toNuChange()"
            name="loadToNu"
            type="number"
            autocomplete="off"
          />
          <span matPrefix class="dolarSymbol">$&nbsp;</span>
        </mat-form-field>
      </div>
      <div class="displayBlock">
        <mat-form-field
          floatLabel="auto"
          hideRequiredMarker
          appearance="fill"
          class="middleInput"
        >
          <mat-label class="global_search">{{
            "NEW_LOAD.MILAGE" | translate
          }}</mat-label>
          <input
            matInput
            onfocus="this.select()"
            [(ngModel)]="loadData.milage"
            (change)="calculateRatePerMile()"
            [ngClass]="
              loadData.milage == loadData.calculatedMilage
                ? 'fieldMilage'
                : 'fieldAdjastment'
            "
            autocomplete="off"
            name="loadMilage"
            disabled
          />
        </mat-form-field>
        <button
          mat-flat-button
          type="button"
          class="btnDialog adjust-revert"
          (click)="openDialogEditMilage()"
          matTooltip="{{ 'NEW_LOAD.ADJUST_MILAGE' | translate }}"
        >
          {{ "NEW_LOAD.ADJUST" | translate }}
        </button>
        <button
          *ngIf="loadData.milage != loadData.calculatedMilage"
          mat-flat-button
          type="button"
          class="btnDialog adjust-revert"
          (click)="revertMilage()"
          matTooltip="{{ 'NEW_LOAD.REVERT_MILAGE' | translate }}"
        >
          {{ "NEW_LOAD.REVERT" | translate }}
        </button>
      </div>
      <div class="displayBlock">
        <mat-form-field
          floatLabel="auto"
          hideRequiredMarker
          appearance="fill"
          class="middleInput"
        >
          <mat-label class="global_search">{{
            "NEW_LOAD.DEAD_HEAD" | translate
          }}</mat-label>
          <input
            matInput
            onfocus="this.select()"
            [(ngModel)]="loadData.deadhead"
            (change)="calculateRatePerMile()"
            [ngClass]="
              loadData.deadhead == loadData.calculatedDeadhead
                ? 'fieldDeadHead'
                : 'fieldAdjastment'
            "
            autocomplete="off"
            disabled
            name="loadDeadhead"
          />
        </mat-form-field>
        <button
          mat-flat-button
          type="button"
          class="btnDialog adjust-revert"
          (click)="openDialogEditDeadhead()"
          matTooltip="{{ 'NEW_LOAD.ADJUST_DEADHEAD' | translate }}"
        >
          {{ "NEW_LOAD.ADJUST" | translate }}
        </button>
        <button
          *ngIf="loadData.deadhead != loadData.calculatedDeadhead"
          type="button"
          mat-flat-button
          class="btnDialog adjust-revert"
          (click)="revertDeadhead()"
          matTooltip="{{ 'NEW_LOAD.REVERT_MILAGE' | translate }}"
        >
          {{ "NEW_LOAD.REVERT" | translate }}
        </button>
      </div>

      <div class="displayBlock">
        <mat-form-field
          floatLabel="auto"
          hideRequiredMarker
          [appearance]="!tonuSelected ? 'outline' : 'fill'"
          class="middleInput"
        >
          <mat-label class="global_search"
            >{{ "NEW_LOAD.RATE" | translate
            }}<span class="star">*</span></mat-label
          >

          <input
            matInput
            required
            onfocus="this.select()"
            [(ngModel)]="loadData.rate"
            (change)="rateChangeCalculation()"
            autocomplete="off"
            [disabled]="tonuSelected"
            name="loadRate"
            type="number"
          />
          <span matPrefix class="dolarSymbol">$&nbsp;</span>
        </mat-form-field>

        <mat-form-field
          floatLabel="auto"
          hideRequiredMarker
          appearance="fill"
          class="middleInput"
        >
          <mat-label class="global_search">{{
            "NEW_LOAD.TOTAL_LUMPER_FEE" | translate
          }}</mat-label>
          <input
            matInput
            [(ngModel)]="loadData.totalLumperCost"
            disabled
            name="TotalLumperCost"
            type="number"
            autocomplete="off"
          />
          <span matPrefix class="dolarSymbol">$&nbsp;</span>
        </mat-form-field>
        <mat-form-field
          floatLabel="auto"
          hideRequiredMarker
          [appearance]="!tonuSelected ? 'outline' : 'fill'"
          class="middleInput"
        >
          <mat-label class="global_search">{{
            "NEW_LOAD.DETENTION" | translate
          }}</mat-label>
          <input
            matInput
            onfocus="this.select()"
            [(ngModel)]="loadData.detentionCost"
            (change)="calculateTotalInvoiced()"
            [disabled]="tonuSelected"
            name="loadDetentionCost"
            type="number"
            autocomplete="off"
          />
          <span matPrefix class="dolarSymbol">$&nbsp;</span>
        </mat-form-field>
      </div>
      <div class="displayBlock">
        <mat-form-field
          floatLabel="auto"
          hideRequiredMarker
          appearance="outline"
          class="middleInput"
        >
          <mat-label class="global_search">{{
            "NEW_LOAD.OTHER" | translate
          }}</mat-label>
          <input
            matInput
            onfocus="this.select()"
            [(ngModel)]="loadData.otherCost"
            (change)="calculateTotalInvoiced()"
            name="loadOtherCost"
            type="number"
            autocomplete="off"
          />
          <span matPrefix class="dolarSymbol">$&nbsp;</span>
        </mat-form-field>
        <mat-form-field
          appearance="outline"
          *ngIf="loadData.otherCost"
          class="commentOtherCost"
        >
          <mat-label class="label-position">{{
            "NEW_LOAD.OTHER_COST_COMMENT" | translate
          }}</mat-label>
          <input
            matInput
            onfocus="this.select()"
            [(ngModel)]="loadData.otherCostComment"
            name="loadOtherCostComment"
            type="text"
            autocomplete="off"
          />
        </mat-form-field>
      </div>
      <div class="displayBlock">
        <mat-form-field
          floatLabel="auto"
          hideRequiredMarker
          appearance="outline"
          class="formWidth"
        >
          <mat-label class="global_search">{{
            "NEW_LOAD.ADVANCE" | translate
          }}</mat-label>
          <input
            matInput
            onfocus="this.select()"
            [(ngModel)]="loadData.advanedPayment"
            (change)="calculateTotalInvoiced()"
            name="loadAdvancedPayment"
            type="number"
            autocomplete="off"
          />
          <span matPrefix class="dolarSymbol">$&nbsp;</span>
        </mat-form-field>

        <mat-form-field
          floatLabel="auto"
          hideRequiredMarker
          appearance="fill"
          class="middleInput"
          matTooltip="{{ 'LOADS.RPM_CALCULATE' | translate }}"
          matTooltipPosition="right"
        >
          <mat-label>{{ "LOADS.RATE_PER_MILE" | translate }}</mat-label>
          <input
            matInput
            value="{{ loadData.ratePerMile | number : '1.2-2' }}"
            autocomplete="off"
            name="loadMilage"
            type="number"
            disabled
          />
          <span matPrefix class="dolarSymbol">$&nbsp;</span>
        </mat-form-field>
      </div>

      <mat-card
        class="totalInvoice"
        matTooltip="{{ 'NEW_LOAD.TOTAL_INVOICE_CALC' | translate }}"
        >{{ "NEW_LOAD.TOTAL_INVOICE" | translate }}:
        {{ loadData.totalInvoiced | currency }}</mat-card
      >
      <hr />
      <div
        class="commentPreview"
        *ngFor="let commentItem of loadData.comments; let i = index"
      >
        <div class="commentType">
          {{ commentItem.type + ": " }}

          <div class="inlineDisplayIcon">
            <mat-icon
              (click)="openAddCommentDialog(loadData.id, commentItem)"
              class="icon_edit"
              >edit</mat-icon
            >
            <mat-icon class="icon_close" (click)="deleteComment(i)">
              close
            </mat-icon>
          </div>
        </div>
        <br />
        {{ commentItem.text }}
      </div>
      <button
        mat-raised-button
        type="button"
        (click)="openAddCommentDialog(loadData.id)"
      >
        {{ "NEW_LOAD.ADD_COMMENT" | translate }}
      </button>

      <hr />
      <div class="floatRight">
        <button mat-button type="button" class="btnCan" (click)="back()">
          {{ "NEW_LOAD.CANCEL" | translate }}
        </button>
        <button
          mat-flat-button
          class="btnDel"
          (click)="save(editLoad.form.invalid)"
        >
          {{ "NEW_LOAD.SAVE" | translate }}
        </button>
      </div>
    </div>
    <div class="displayInlineBlock" *ngIf="showCustomerDiv">
      <app-add-customer
        (customerChange)="getCustomersFromChild($event)"
      ></app-add-customer>
    </div>
    <div class="displayInlineBlock" id="viewPDF">
      <div class="bigCard" *ngIf="loadData.documents.length > 0">
        <table class="tablePDF">
          <thead>
            <tr>
              <th scope="col">{{ "NEW_LOAD.FILE_NAME" | translate }}</th>
              <th scope="col">{{ "NEW_LOAD.UPLOAD_DATE" | translate }}</th>
              <th scope="col"></th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let file of loadData.documents; index as i">
              <td>{{ file.fileNameWithExtension }}</td>
              <td>
                {{ file.createdDate | tenantDate | date : "MM/dd/yyyy h:mm a" }}
              </td>
              <td>
                <button
                  mat-raised-button
                  class="pdfview_button"
                  (click)="previewDocument(file.id, file.idTemp)"
                >
                  {{ "NEW_LOAD.PREVIEW" | translate }}
                </button>
              </td>
              <td>
                <button
                  mat-raised-button
                  class="pdfclose_button"
                  (click)="
                    openDialogConfirmDeleteLoadDocument(
                      file.fileNameWithExtension,
                      file.id,
                      i,
                      file.idTemp
                    )
                  "
                >
                  {{ "NEW_LOAD.DELETE" | translate }}
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <app-replace-load-document
        [previewDocumentId]="selectedDocumentId"
        [previewDocumentBytes]="selectedDocumentBytes"
        [uploading]="uploading"
        (documentListUpdated)="getDocumentsFromChild($event)"
      ></app-replace-load-document>
    </div>
  </form>
</div>
