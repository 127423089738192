import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { Observable } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  startWith,
  switchMap,
  tap,
} from 'rxjs/operators';
import { DispatcherModel } from 'src/app/_models/dispatcher';
import { UserRoleName } from 'src/app/_models/enums/userRoleName';
import { Location } from 'src/app/_models/location/location';
import { UserRole } from 'src/app/_models/userRole';
import {
  DispatcherService,
  MapService,
  ToastrTranslateService,
  UserService,
} from 'src/app/_services';
import { RoleFactory } from 'src/app/_services/factory/role.factory.service';
import { UserDataService } from 'src/app/_services/user.data.service';
import { InfoDialogComponent } from 'src/app/common/info-dialog/info-dialog.component';

@Component({
  selector: 'app-edit-dispatcher-dialog',
  templateUrl: './edit-dispatcher-dialog.component.html',
  styleUrls: ['./edit-dispatcher-dialog.component.scss'],
})
export class EditDispatcherDialogComponent implements OnInit {
  searchAddress = new UntypedFormControl();
  @ViewChild('inputDispatcherAddress') inputDispatcherAddress: ElementRef;

  dispatcher: DispatcherModel;
  filteredLocations: Observable<any[]>;
  searchResult: Location[];
  dispatcherId: number;
  readOnly: boolean;
  dataLoading = true;
  userRolesList: UserRole[] = [];

  isAddressPoBox: boolean = false;

  registerForm: UntypedFormGroup;

  constructor(
    private dispatcherService: DispatcherService,
    private userService: UserService,
    private dialogRef: MatDialogRef<EditDispatcherDialogComponent>,
    private mapService: MapService,
    private roleFactory: RoleFactory,
    private toastrTranslateService: ToastrTranslateService,
    public dialog: MatDialog,
    public userDataService: UserDataService,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {}

  ngOnInit(): void {
    this.dispatcherId = this.data.id;
    this.readOnly = this.data.readOnly;
    this.dispatcher = new DispatcherModel();
    if (this.dispatcherId) {
      this.dispatcherService.getDispatcher(this.dispatcherId).subscribe({
        next: (response) => {
          this.dispatcher = response;
          this.searchAddress.setValue(this.dispatcher.location);
          if (this.dispatcher.location && this.dispatcher.location.poBox) {
            this.isAddressPoBox = true;
          } else {
            this.isAddressPoBox = false;
          }
          this.dataLoading = false;
        },
        error: (response) => {
          this.toastrTranslateService.error(response, 'DISPATCHERS.FAILED');
        },
      });
    } else {
      this.dispatcher.isActive = true;
      this.dataLoading = false;
    }
    this.searchAddress.valueChanges
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        startWith(''),
        tap(() => {
          this.searchResult = [];
        }),
        switchMap((value) => {
          if (value != '') {
            return this.mapService.searchMap(value);
          } else {
            this.dispatcher.location = new Location();
            return (this.searchResult = []);
          }
        })
      )
      .subscribe((data) => {
        if (data && data.count != 0) {
          this.searchResult = data.collection;
        } else {
          this.searchResult = [];
        }
      });
    this.roleFactory.getEquipmentTypeList().then((data) => {
      this.userRolesList = data;
    });
  }

  resendEmail() {
    this.userService.resendActivation(this.dispatcher.id).subscribe({
      next: () => {
        this.toastrTranslateService.success(
          'APPLICATION.RESEND_ACTIVATION_EMAIL_SUCESS'
        );
      },
      error: (response) => {
        this.toastrTranslateService.error(
          response,
          'APPLICATION.RESEND_ACTIVATION_EMAIL_ERROR'
        );
      },
    });
  }

  autofillDispatcherAddress() {
    setTimeout(() => {
      let inputValue =
        this.inputDispatcherAddress.nativeElement.value !=
        this.dispatcher.location?.address +
          ', ' +
          this.dispatcher.location?.countryCode;
      if (!this.dispatcher.location || inputValue) {
        if (this.searchResult.length > 0) {
          this.dispatcher.location = this.searchResult[0];
          this.searchAddress.setValue(this.dispatcher.location);
        } else if (
          inputValue &&
          this.inputDispatcherAddress.nativeElement.value
        ) {
          this.clearAddress();
          this.toastrTranslateService.warning('GENERAL.ADDRESS_ISSUE');
        }
      }
    }, 1000);
  }

  clearAddress() {
    this.dispatcher.location = new Location();
    this.searchAddress.setValue('');
  }

  selectDispatcherLocation(location: Location) {
    this.dispatcher.location = location;
  }

  displayFn(location: Location): string {
    if (location) {
      return location ? location.address + ', ' + location.countryCode : '';
    }
    return '';
  }

  setActiveValue(e) {
    if (e.checked) {
      this.dispatcher.isActive = true;
    } else {
      this.dispatcher.isActive = false;
    }
  }

  setAdminValue(e) {
    if (e.checked) {
      this.dispatcher.isAdmin = true;
      this.dispatcher.userRole = this.userRolesList.find(
        (x) => x.name == UserRoleName.AdminDispatcher.toString()
      );
    } else {
      this.dispatcher.isAdmin = false;
      this.dispatcher.userRole = this.userRolesList.find(
        (x) => x.name == UserRoleName.Dispatcher.toString()
      );
    }
  }

  toBool(boolVariable: any) {
    if (boolVariable && boolVariable != 'undefined') {
      return Boolean(JSON.parse(boolVariable));
    }
    return false;
  }

  showAddActionInfo() {
    const dialogInfo = this.dialog.open(InfoDialogComponent, {
      disableClose: true,
      position: { top: '20px' },
      data: {
        title: 'CONFIRM_DIALOG.INVITATION_SENT',
        key1: 'CONFIRM_DIALOG.AFTER_CREATE_DISPATCHER_INFO_1',
        key2: 'CONFIRM_DIALOG.AFTER_CREATE_DISPATCHER_INFO_2',
      },
    });
    dialogInfo.afterClosed().subscribe((proceeed) => {
      this.dialogRef.close();
    });
  }

  save(isInvalid) {
    if (isInvalid) {
      this.toastrTranslateService.warning('GENERAL.FORM_INVALID');
      return;
    }

    if (!this.dispatcher.location?.state && !this.dispatcher.location?.poBox) {
      this.dispatcher.location = null;
    }
    if (this.dispatcherId) {
      this.dispatcherService.updateDispatcher(this.dispatcher).subscribe({
        next: (response) => {
          this.dispatcher = response;
          this.toastrTranslateService.success(
            'DISPATCHERS.SAVED',
            this.dispatcher.firstname + ' ' + this.dispatcher.lastname
          );
          this.dialogRef.close();
        },
        error: (response) => {
          this.toastrTranslateService.error(response, 'DISPATCHERS.NOT_SAVED');
        },
      });
    } else {
      this.dispatcherService.addDispatcher(this.dispatcher).subscribe({
        next: (response) => {
          this.dispatcher = response;
          this.toastrTranslateService.success(
            'DISPATCHERS.SAVED',
            this.dispatcher.firstname + ' ' + this.dispatcher.lastname
          );
          this.showAddActionInfo();
        },
        error: (response) => {
          this.toastrTranslateService.error(response, 'DISPATCHERS.NOT_SAVED');
        },
      });
    }
  }
}
