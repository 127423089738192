import { CodeModel } from '../code';
import { DispatcherModel } from '../dispatcher';
import { DriverModel } from '../driver/driver';
import { TruckModel } from '../truck';

export class LoadFilter {
  hasHold: boolean;
  deliveryDateFrom: Date;
  deliveryDateTo: Date;
  dispatcherList = new Array<DispatcherModel>();
  driverList = new Array<DriverModel>();
  truckList = new Array<TruckModel>();
  statusList = new Array<CodeModel>();
}
