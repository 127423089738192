import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LoadModel } from 'src/app/_models/load/load';
import { LoadService, ToastrTranslateService } from 'src/app/_services';
import { ComfirmDialogComponent } from 'src/app/manage/trucks/edit-delete-truck/comfirm-dialog/comfirm-dialog.component';

@Component({
  selector: 'app-confirm-dialog-change-status',
  templateUrl: './confirm-dialog-change-status.component.html',
  styleUrls: ['./confirm-dialog-change-status.component.scss'],
})
export class ConfirmDialogChangeStatusComponent implements OnInit {
  loadData: LoadModel = new LoadModel();
  loadNumber: string;

  constructor(
    private dialogRef: MatDialogRef<ComfirmDialogComponent>,
    private toastrTranslateService: ToastrTranslateService,
    private loadService: LoadService,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {}

  ngOnInit(): void {
    this.loadNumber = this.data.loadNumber;
  }

  changeStatus() {
    this.loadService.getLoadById(this.data.loadId).subscribe((response) => {
      this.loadData = response;
      this.loadData.statusId = this.data.statusId;
      this.updateLoad();
    });
  }

  updateLoad() {
    this.loadService.updateLoad(this.loadData).subscribe({
      next: (response) => {
        if (response) {
          this.loadData = response;
          this.toastrTranslateService.success(
            'CONFIRM_DIALOG.SUCCESS_CHANGE_STATUS',
            this.loadData.loadNumber
          );
          this.dialogRef.close({ success: response });
        } else {
          this.loadData = response;
          this.toastrTranslateService.error(
            'CONFIRM_DIALOG.FAILED_CHANGE_STATUS'
          );
        }
      },
      error: (response) => {
        this.toastrTranslateService.error(
          response,
          'CONFIRM_DIALOG.FAILED_CHANGE_STATUS'
        );
      },
    });
  }
}
