import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { UserService } from '../_services';

@Component({
  selector: 'app-verify-account',
  templateUrl: './verify-account.component.html',
  styleUrls: ['./verify-account.component.scss'],
})
export class VerifyAccountComponent implements OnInit {
  hide = true;
  hideRepeat = true;
  userFullName: string = null;
  activationId: string;
  user: any;
  error: string;

  pwd: string;
  pwdRepeated: string;
  verifyDisabled = true;
  verifyForm: UntypedFormGroup;
  activationSuccess: boolean | null = null;

  constructor(
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private userService: UserService,
    public auth: AuthService,
    private router: Router
  ) {
    this.verifyForm = fb.group({
      newPassword: [
        '',
        [
          Validators.required,
          Validators.pattern(
            '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-_]).{8,}$'
          ),
        ],
      ],
      newPasswordRepeat: ['', [Validators.required]],
    });
  }

  ngOnInit(): void {
    this.auth.isAuthenticated$.subscribe((isAuthenticated) => {
      if (isAuthenticated) {
        this.auth.logout();
      }
    });
    this.route.params.subscribe((params) => {
      this.activationId = params['activationId'];
      this.userService.getByActivationId(this.activationId).subscribe(
        (userResponse) => {
          this.user = userResponse.data;
        },
        (err) => {
          this.router.navigateByUrl('/');
        }
      );
    });
  }

  verify() {
    this.verifyDisabled = false;
    this.userService
      .activate(this.activationId, this.newPassword.value)
      .subscribe(
        (response) => {
          this.activationSuccess = true;
        },
        (err) => {
          this.error = err;
        }
      );
  }

  checkRepeatedPassword() {
    let val = this.newPasswordRepeat.value;

    if (this.newPassword.value != this.newPasswordRepeat.value) {
      this.newPasswordRepeat.setErrors({ repeatError: true });
      this.verifyDisabled = true;
    } else {
      this.newPasswordRepeat.reset();
      this.newPasswordRepeat.setValue(val);

      if (!this.newPassword.errors) {
        this.verifyDisabled = false;
      }
    }
  }

  get newPassword() {
    return this.verifyForm.get('newPassword');
  }
  get newPasswordRepeat() {
    return this.verifyForm.get('newPasswordRepeat');
  }
}
