import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
})
export class ConfirmDialogComponent implements OnInit {
  titleFirstLine = '';
  titleSecondLine = '';
  constructor(
    private translateService: TranslateService,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {}

  ngOnInit(): void {
    this.titleFirstLine = this.translateService.instant(this.data.titleKey1);
    this.titleSecondLine = this.translateService.instant(this.data.titleKey2);
  }
}
