import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, MatSortable } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { saveAs } from 'file-saver-es';
import { first } from 'rxjs';
import Common from 'src/app/_helpers/common';
import { CodeModel } from 'src/app/_models/code';
import { CommentModel } from 'src/app/_models/comments';
import { CustomColumn } from 'src/app/_models/common/customColumn';
import { Paginator } from 'src/app/_models/common/paginator';
import { SortData } from 'src/app/_models/common/sortData';
import { CustomerModel } from 'src/app/_models/customer';
import { DispatcherModel } from 'src/app/_models/dispatcher';
import { DriverModel } from 'src/app/_models/driver/driver';
import { TableType } from 'src/app/_models/enums/tableType';
import { LoadModel } from 'src/app/_models/load/load';
import { LoadDocument } from 'src/app/_models/load/loadDocument';
import { LoadFilter } from 'src/app/_models/load/loadFilter';
import { LoadsTotals } from 'src/app/_models/load/loadsTotals';
import { TruckModel } from 'src/app/_models/truck';
import {
  CodeFactory,
  DispatcherService,
  DriverService,
  LoadService,
  ToastrTranslateService,
  TruckService,
} from 'src/app/_services';
import { LocalStorageSortService } from 'src/app/_services/local.storage.sort.service';
import { UserDataService } from 'src/app/_services/user.data.service';

import { LocalStorageService } from '../../_services/local.storage.service';
import { ConfirmDialogChangeStatusComponent } from '../confirm-dialog-change-status/confirm-dialog-change-status.component';
import { DownloadRcComponent } from '../download-rc/download-rc.component';
import { EmailDocumentsComponent } from '../email-documents/email-documents.component';
import { LoadsCommentComponent } from '../loads-comment-dialog/loads-comment.component';
import { PrintInvoiceDialogComponent } from '../new-load/print-invoice-dialog/print-invoice-dialog.component';
import LoadsHelper from '../utils/loads-helper';

@Component({
  selector: 'app-advanced-search',
  templateUrl: './advanced-search.component.html',
  styleUrls: ['./advanced-search.component.scss'],
})
export class AdvancedSearchComponent implements OnInit, AfterViewInit {
  displayedColumns = [];

  public allColumnList: CustomColumn[] = [];
  public selectedColumnList: CustomColumn[] = [];
  selectedColumnStorageKey = 'selectedColumnsAdvancedSearch';

  loadsData: LoadModel[] = [];
  filter = new LoadFilter();
  count: number;

  pageSize;
  currentPage;
  sortData: SortData;
  totalSize = 0;
  pageSizeOptions: number[];
  @ViewChild(MatPaginator) matPaginator: MatPaginator;
  @ViewChild(MatSort) matSort: MatSort;
  public pendingCount = 0;
  loadsTotals = new LoadsTotals();

  dataSource = new MatTableDataSource<LoadModel>();

  statusTypeList: CodeModel[] = [];
  loading = false;

  driverList: DriverModel[] = [];
  dispatcherList: DispatcherModel[] = [];
  truckList: TruckModel[] = [];

  Export: boolean = true;
  visible: boolean = false;

  constructor(
    private loadService: LoadService,
    private loadsHelper: LoadsHelper,
    private codeFactory: CodeFactory,
    private driverService: DriverService,
    private dispatcherService: DispatcherService,
    private truckService: TruckService,
    private toastrTranslateService: ToastrTranslateService,
    private localStorageService: LocalStorageService,
    private localStorageSortService: LocalStorageSortService,
    private dialog: MatDialog,
    public userDataService: UserDataService
  ) {
    this.codeFactory.getStatusList().then((data) => {
      this.statusTypeList = data;
    });
  }

  ngOnInit(): void {
    this.initPaginatorOptions();
    this.initFilters();
    this.initializeColumnProperties();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.matSort.sort({
        id: this.sortData.activeColumn,
        start: this.sortData.order,
      } as MatSortable);
      this.dataSource.sort = this.matSort;
    });
    this.dataSource.sortingDataAccessor = (item, property) => {
      this.localStorageSortService.setSortData(
        TableType.AdvancedSearch,
        this.matSort
      );
      return item[property];
    };
    this.matSort.sortChange.subscribe(() => {
      this.refresh();
      this.currentPage = 0;
    });
  }

  refresh() {
    this.localStorageService.setAdvancedSerachFilter(this.filter);
    this.getLoadData();
  }

  getLoadData(resetCurrentPage?: boolean, status?: string) {
    if (resetCurrentPage) {
      this.currentPage = 0;
    }

    this.loadService
      .advancedSearch(this.getPaginator(), this.filter)
      .pipe(first())
      .subscribe((response) => {
        response = Common.fixMixCityLocation(response);
        this.dataSource = new MatTableDataSource<LoadModel>(
          response.loads.collection
        );
        this.loadsData = response.loads.collection;
        this.pendingCount = response.pendingLoadsCount;
        this.count = response.loads.count;
        this.getTotals(this.loadsData);
        setTimeout(() => {
          this.matPaginator.length = response.loads.count;
          this.matPaginator.pageIndex = this.currentPage;
        });
      });
  }

  async export() {
    try {
      this.loading = true;
      const data = await this.loadService.advancedSearchExport(
        this.getPaginator(),
        this.filter
      );
      const blob = new Blob([data], {
        type: 'application/vnd.ms-excel',
      });

      const fileName =
        'Load_Export' + new Date().toLocaleDateString() + '.xlsx';
      saveAs(blob, fileName);
      this.loading = false;
    } catch (error) {
      this.loading = false;
      this.toastrTranslateService.error(error, 'COMMON.ERROR_DATA');
    }
  }

  async exportQuickBooks() {
    try {
      this.loading = true;
      const data = await this.loadService.advancedSearchExportQuickBooks(
        this.getPaginator(),
        this.filter
      );
      const blob = new Blob([data], {
        type: 'text/csv;encoding:utf-8',
      });

      const fileName =
        'Load_Export_QuickBooks' + new Date().toLocaleDateString() + '.csv';
      saveAs(blob, fileName);
      this.loading = false;
    } catch (error) {
      this.loading = false;
      this.toastrTranslateService.error(error, 'COMMON.ERROR_DATA');
    }
  }

  private initFilters() {
    let fakeDownPaginator = new Paginator();
    fakeDownPaginator.pageSize = 1000;
    fakeDownPaginator.sortOrder = 'asc';
    fakeDownPaginator.sortBy = 'firstname';

    this.getDrivers(fakeDownPaginator);
    this.getDispatechers(fakeDownPaginator);
    this.getTrucks();
    this.filter = this.localStorageService.getAdvancedSerachFilter();
  }

  initializeColumnProperties() {
    this.selectedColumnList = this.localStorageService.getSelectedColumns(
      this.selectedColumnStorageKey
    );
    if (this.selectedColumnList?.length > 0) {
      this.displayedColumns = [];
      this.selectedColumnList.forEach((element) => {
        this.displayedColumns.push(element.name);
      });
      this.pushToAllColumns(this.getDefaultDisplayedColumns());
    } else {
      this.displayedColumns = this.getDefaultDisplayedColumns();
      this.allColumnList = [];
      this.displayedColumns = this.getDefaultDisplayedColumns();
      this.pushToAllColumns(this.displayedColumns);
      this.selectedColumnList = this.allColumnList;
    }
  }

  private pushToAllColumns(displayedColumns: string[]) {
    displayedColumns.forEach((element, index) => {
      let id = '';
      let elementClean = element.toLowerCase().replace('_', '');
      for (let i = 0; i < elementClean.length; i++) {
        id = id + (elementClean.charCodeAt(i) - 97 + 1).toString();
      }
      this.allColumnList.push({
        id: +id,
        possition: index,
        name: element,
        isActive: true,
      });
    });
  }

  clearSelectedColumns() {
    this.localStorageService.clearSelectedColumns(
      this.selectedColumnStorageKey
    );
    this.initializeColumnProperties();
  }

  toggleColumn() {
    this.displayedColumns = [];
    this.selectedColumnList.forEach((element) => {
      this.displayedColumns.push(element.name);
    });
    this.localStorageService.addSelectedColumns(
      this.selectedColumnList,
      this.selectedColumnStorageKey
    );
  }

  private getDrivers(fakePagginator: Paginator) {
    this.driverService
      .getAllDrivers(fakePagginator, true)
      .pipe(first())
      .subscribe((response) => {
        this.driverList = response.collection;
      });
  }

  private getDispatechers(fakePagginator: Paginator) {
    this.dispatcherService
      .getAllDispatchers(fakePagginator, true)
      .pipe(first())
      .subscribe((response) => {
        this.dispatcherList = response.collection;
      });
  }

  private getTrucks() {
    this.truckService
      .getTrucks(true)
      .pipe(first())
      .subscribe((response) => {
        this.truckList = response;
      });
  }

  private getTotals(loads: LoadModel[]) {
    this.loadsTotals = this.loadsHelper.getTotals(loads);
  }

  compareObjects(o1: any, o2: any): boolean {
    if (o1 && o2) {
      return o1.id === o2.id;
    }
    return false;
  }

  openDownloadRC(
    loadId: number,
    loadNumber: string,
    documentList: Array<LoadDocument>,
    statusName: string
  ) {
    const documentsDownloadDialogRef = this.dialog.open(DownloadRcComponent, {
      disableClose: false,
      position: { top: '130px' },
      data: { loadId, loadNumber, documentList, statusName },
    });
    documentsDownloadDialogRef.afterClosed().subscribe(() => {
      this.refresh();
    });
  }

  sendMailToCustomer(
    loadId: number,
    loadNumber: string,
    customer: CustomerModel,
    documentList: Array<LoadDocument>
  ) {
    const documentsDialogRef = this.dialog.open(EmailDocumentsComponent, {
      disableClose: true,
      position: { top: '130px' },
      data: { loadId, loadNumber, customer, documentList },
    });
  }

  openPrintInoviceDialog(loadId: number, statusName: string) {
    const printInvoiceDialogRef = this.dialog.open(
      PrintInvoiceDialogComponent,
      {
        disableClose: true,
        //position: { top: '20px' },
        data: { loadId, statusName },
      }
    );
    printInvoiceDialogRef.afterClosed().subscribe(() => {
      this.refresh();
    });
  }

  openDialogConfirmStatus(
    loadId: number,
    statusId: number,
    loadNumber: string
  ) {
    const dialogRef = this.dialog.open(ConfirmDialogChangeStatusComponent, {
      disableClose: true,
      position: { top: '20px' },
      data: { loadId, statusId, loadNumber },
    });
    dialogRef.afterClosed().subscribe(() => {
      this.getLoadData();
    });
  }

  public onChangePage(event?: PageEvent): PageEvent {
    this.currentPage = event.pageIndex;
    this.pageSize = event.pageSize;
    this.localStorageService.setPagginatorPageSize(event.pageSize);
    this.getLoadData();
    return event;
  }

  public clearSelectedFilters() {
    this.filter = new LoadFilter();
    this.refresh();
  }

  public clearSelectedDicpatchers(filter: LoadFilter) {
    filter.dispatcherList = [];
    this.refresh();
  }

  public clearSelectedDrivers(filter: LoadFilter) {
    filter.driverList = [];
    this.refresh();
  }

  public clearSelectedStatuses(filter: LoadFilter) {
    filter.statusList = [];
    this.refresh();
  }

  public clearSelectedTrucks(filter: LoadFilter) {
    filter.truckList = [];
    this.refresh();
  }

  openCommentsDialog(commentList: CommentModel[], loadNumber: string) {
    const dialogRef = this.dialog.open(LoadsCommentComponent, {
      disableClose: true,
      position: { top: '20px' },
      data: { commentList, loadNumber },
    });
  }

  initPaginatorOptions() {
    this.dataSource.paginator = this.matPaginator;
    this.sortData = this.localStorageSortService.getSortData(
      TableType.AdvancedSearch
    );
    this.pageSize = this.localStorageService.getPagginatorPageSize();
    this.pageSizeOptions = [10, 25, 50, 100];
    this.currentPage = 0;
  }

  private getPaginator(): Paginator {
    const paginator = new Paginator();
    paginator.pageSize = this.pageSize;
    paginator.pageNumber = this.currentPage + 1;
    paginator.sortBy = this.matSort?.active;
    paginator.sortOrder = this.matSort?.direction;
    this.localStorageSortService.setSortData(
      TableType.AdvancedSearch,
      this.matSort
    );
    return paginator;
  }

  getDefaultDisplayedColumns(): string[] {
    return [
      'loadNumber',
      'status',
      'driver',
      'dispatcher',
      'truck',
      'trailer',
      'eq_type',
      'customer',
      'origin',
      'destination',
      'pickup_date',
      'delivery_date',
      'milage',
      'dead_head',
      'rate',
      'rpm',
      'tonu',
      'haul_type',
      'order',
      'note',
      'rc',
      'sendMail',
      'print_ivoice',
      'columns',
    ];
  }
}
