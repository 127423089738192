// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
export const environment = {
  production: false,
  auth0: {
    domain: 'test-mytruckboard.us.auth0.com',
    clientId: 'vZp3tJl2xVJiPbaVYDJxNMxaQTjn10v2',
    redirectUri: window.location.origin,
    audience: 'https://api.test.mytruckboard.com',
  },
  apiUrl: 'https://api.test.mytruckboard.net/api/v1',
  stripeAddNewUrl: 'https://buy.stripe.com/test_fZe6sn869eOogSc5kk',
  clearLocalStorage: [],
  appVersion: '2.06.26.0-dev',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
