import { GeoLocation } from './geoLocation';
import { VehicleProperites } from './vehicleProperites';

export class RouteRequest {
  supportingPoints? = new Array<GeoLocation>();
  originPoint = new GeoLocation();
  destinationPoint = new GeoLocation();
  maxAlternatives?: number;
  travelMode?: string;
  vehicleProperites?: VehicleProperites;
}
