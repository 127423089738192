import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import Common from '../_helpers/common';
import { Response } from '../_models/response';
import { TrailerModel } from '../_models/trailer';
import { TailerFactory } from './factory/trailer.factory.service';

@Injectable({ providedIn: 'root' })
export class TrailerService {
  constructor(private http: HttpClient, private tailerFactory: TailerFactory) {}

  getTrailers(isActive?: boolean): Observable<TrailerModel[]> {
    const params = new HttpParams({
      fromObject: {
        isActive: Common.getSafeBooleanString(isActive),
      },
    });
    return this.http
      .get<Response>(`${environment.apiUrl}/trailers`, { params: params })
      .pipe(map((response) => response.data as TrailerModel[]));
  }

  getTrailer(id: number): Observable<TrailerModel> {
    return this.http
      .get<Response>(`${environment.apiUrl}/trailers/${id}`)
      .pipe(map((response) => response.data as TrailerModel))
      .pipe(
        tap((responseData) => {
          this.tailerFactory.getTrailerTimeZoneDate(responseData);
        })
      );
  }

  updateTrailer(trailer: TrailerModel) {
    trailer = this.tailerFactory.getTrailerUtcDate(trailer);
    return this.http
      .put<Response>(`${environment.apiUrl}/trailers`, trailer)
      .pipe(map((response) => response.data as TrailerModel))
      .pipe(
        tap((responseData) => {
          this.tailerFactory.getTrailerTimeZoneDate(responseData);
        })
      );
  }

  addTrailer(trailer: TrailerModel) {
    trailer = this.tailerFactory.getTrailerUtcDate(trailer);
    return this.http
      .post<Response>(`${environment.apiUrl}/trailers`, trailer)
      .pipe(map((response) => response.data as TrailerModel))
      .pipe(
        tap((responseData) => {
          this.tailerFactory.getTrailerTimeZoneDate(responseData);
        })
      );
  }

  deleteTrailer(id: number) {
    return this.http.delete<Response>(`${environment.apiUrl}/trailers/${id}`);
  }
}
