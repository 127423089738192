import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort, MatSortable } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { first } from 'rxjs';
import Common from 'src/app/_helpers/common';
import { AdminModel } from 'src/app/_models/admin';
import { Paginator } from 'src/app/_models/common/paginator';
import { SortData } from 'src/app/_models/common/sortData';
import { TableType } from 'src/app/_models/enums/tableType';
import { Location } from 'src/app/_models/location/location';
import { LocationService, ToastrTranslateService } from 'src/app/_services';
import { AdminService } from 'src/app/_services/admin.service';
import { LocalStorageSortService } from 'src/app/_services/local.storage.sort.service';
import { UserDataService } from 'src/app/_services/user.data.service';
import { ConfirmDialogComponent } from 'src/app/common/confirm-dialog/confirm-dialog.component';
import { InfoDialogComponent } from '../../../common/info-dialog/info-dialog.component';
import { ChangePassDialogComponent } from './change-pass-dialog/change-pass-dialog.component';
import { EditAdminDialogComponent } from './edit-admin-dialog/edit-admin-dialog.component';

@Component({
  selector: 'app-admins',
  templateUrl: './admins.component.html',
  styleUrls: ['./admins.component.scss'],
})
export class AdminsComponent implements OnInit {
  public locationsData: Location[] = [];
  adminsData: Array<AdminModel>;
  searchTerm = '';
  sortData: SortData;
  showInactive = false;

  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private adminService: AdminService,
    private locationService: LocationService,
    private localStorageSortService: LocalStorageSortService,
    private toastrTranslateService: ToastrTranslateService,
    public userDataService: UserDataService,
    public dialog: MatDialog
  ) {}

  dataSource = new MatTableDataSource<AdminModel>();

  ngOnInit() {
    this.sortData = this.localStorageSortService.getSortData(
      TableType.SettingsAdmins
    );
    this.getAdmins();
    this.locationService
      .getLocations()
      .pipe(first())
      .subscribe({
        next: (response) => {
          this.locationsData = response;
        },
      });
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.sort.sort({
        id: this.sortData.activeColumn,
        start: this.sortData.order,
      } as MatSortable);
      this.dataSource.sort = this.sort;
    });
    this.dataSource.sortingDataAccessor = (item, property) => {
      this.localStorageSortService.setSortData(
        TableType.SettingsAdmins,
        this.sort
      );
      switch (property) {
        case 'adminName':
          return item.firstname + item.lastname;
        case 'location':
          if (item.location) {
            return (
              Common.getSafeString(item.location.address) +
              Common.getSafeString(item.location.countryCode)
            );
          }
          return '';
        default:
          return item[property];
      }
    };
  }

  applyFilter(filterValue: string) {
    this.searchTerm = filterValue;
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }

  toBool(boolVariable: any) {
    if (boolVariable && boolVariable != 'undefined') {
      return Boolean(JSON.parse(boolVariable));
    }
    return false;
  }

  openDialogComfirmDeleteAdmin(id: number) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      disableClose: true,
      position: { top: '20px' },
      data: {
        titleKey1: 'CONFIRM_DIALOG.QUESTION',
        titleKey2: 'CONFIRM_DIALOG.WANT_DELETE_ADMIN',
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.deleteAdmin(id, dialogRef);
      }
    });
  }

  openDialogEditAdmin(readOnly: boolean, id?: number) {
    const dialogRef = this.dialog.open(EditAdminDialogComponent, {
      disableClose: true,
      position: { top: '20px' },
      data: {
        id,
        readOnly,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.getAdmins();
    });
  }

  openDialogChangePass(id: number, accountActivated: boolean) {
    if (accountActivated) {
      const dialogRef = this.dialog.open(ChangePassDialogComponent, {
        disableClose: true,
        position: { top: '20px' },
        data: {
          id,
        },
      });
      dialogRef.afterClosed().subscribe((result) => {
        this.getAdmins();
      });
    } else {
      const dialogInfo = this.dialog.open(InfoDialogComponent, {
        disableClose: true,
        position: { top: '20px' },
        data: {
          key1: 'CONFIRM_DIALOG.USER_NOT_ACTIVATED',
          key2: 'CONFIRM_DIALOG.USER_NOT_ACTIVATED_1',
        },
      });
      dialogInfo.afterClosed().subscribe((proceeed) => {
        this.getAdmins();
      });
    }
  }

  deleteAdmin(id: number, dialogRef) {
    this.adminService.deleteAdmin(id).subscribe({
      next: (response) => {
        if (response.success) {
          this.toastrTranslateService.success('CONFIRM_DIALOG.SUCCESS_DELL');
          this.getAdmins();
          dialogRef.close({ success: response.success });
        } else {
          this.toastrTranslateService.error(
            response.message,
            'CONFIRM_DIALOG.FAILED_DELL'
          );
        }
      },
      error: (response) => {
        this.toastrTranslateService.error(
          response,
          'CONFIRM_DIALOG.FAILED_DELL'
        );
      },
    });
  }

  getAdmins() {
    this.adminService
      .getAllAdmins(this.getPaginator(), !this.showInactive)
      .pipe(first())
      .subscribe((admins) => {
        this.dataSource.data = admins.collection;
        this.adminsData = admins.collection;
      });
  }

  private getPaginator(): Paginator {
    const dropDownPaginator = new Paginator();
    dropDownPaginator.pageSize = 1000;
    dropDownPaginator.sortBy = this.sortData.activeColumn;
    dropDownPaginator.sortOrder = this.sortData.order;
    return dropDownPaginator;
  }

  getCountOfElement(value) {
    return this.adminsData ? this.adminsData.length : 0;
  }

  refresh() {
    this.applyFilter('');
    this.getAdmins();
  }

  displayedColumns: string[] = [
    'adminName',
    'email',
    'location',
    'phoneNumber',
    'isActive',
    'action',
  ];
}
