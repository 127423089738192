import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Note } from '../_models/note';
import { GenericResponse, Response } from '../_models/response';
import { ResponseDataGeneric } from '../_models/responseData';
import { NoteFactory } from './factory/note.factory.service';

@Injectable({ providedIn: 'root' })
export class NotesService {
  constructor(private http: HttpClient, private noteFactory: NoteFactory) {}

  getNotes(driverId): Observable<Note[]> {
    return this.http
      .get<GenericResponse<ResponseDataGeneric<Note>>>(
        `${environment.apiUrl}/notes/driver/${driverId}`
      )
      .pipe(map((response) => response.data.collection as Note[]));
  }
  updateNotes(note: Note) {
    //noteDate is already in UTC format
    return this.http
      .put<Response>(`${environment.apiUrl}/notes`, note)
      .pipe(map((response) => response.data as Note))
      .pipe(
        tap((responseData) => {
          this.noteFactory.getNoteTimeZoneDate(responseData);
        })
      );
  }

  addNotes(note: Note) {
    return this.http
      .post<Response>(`${environment.apiUrl}/notes`, note)
      .pipe(map((response) => response.data as Note))
      .pipe(
        tap((responseData) => {
          this.noteFactory.getNoteTimeZoneDate(responseData);
        })
      );
  }

  deleteNotes(id: number) {
    return this.http.delete<Response>(`${environment.apiUrl}/notes/${id}`);
  }
}
