import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CommentModel } from 'src/app/_models/comments';
import { LoadService } from 'src/app/_services';
import { ToastrTranslateService } from 'src/app/_services/toastr.translate.service';

@Component({
  selector: 'load-comment-dialog',
  templateUrl: 'load-comment-dialog.component.html',
  styleUrls: ['./load-comment-dialog.component.scss'],
})
export class LoadCommentDialogComponent implements OnInit {
  selectedCommentType = [];

  commentTypes = [
    'Load note',
    'Paperwork note',
    'Additional note',
    'Invoiced note',
  ];
  commentItem = new CommentModel();

  constructor(
    public dialogRef: MatDialogRef<LoadCommentDialogComponent>,
    private toastrTranslateService: ToastrTranslateService,
    private loadService: LoadService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    if (this.data.comment) {
      this.commentItem.id = this.data.comment.id;
      this.commentItem.sequence = this.data.comment.sequence;
      this.commentItem.text = this.data.comment.text;
      this.commentItem.type = this.data.comment.type;
    }
  }

  submit(isInvalid) {
    if (isInvalid) {
      this.toastrTranslateService.warning('GENERAL.FORM_INVALID');
      return;
    }
    if (this.data.loadId && this.data.loadId != 0) {
      if (this.commentItem.id) {
        this.updateComment(this.data.loadId, this.commentItem);
      } else {
        this.addComment(this.data.loadId, this.commentItem);
      }
    } else {
      this.dialogRef.close(this.commentItem);
    }
  }

  addComment(loadId: number, comment: CommentModel) {
    this.loadService.addComment(loadId, comment).subscribe({
      next: (response) => {
        if (response) {
          this.toastrTranslateService.success(
            'CONFIRM_DIALOG.SUCCESS_SAVE_COMMENT'
          );
          this.dialogRef.close(response);
        } else {
          this.toastrTranslateService.error(
            'CONFIRM_DIALOG.FAILED_SAVE_COMMENT'
          );
        }
      },
      error: (response) => {
        this.toastrTranslateService.error(
          response,
          'CONFIRM_DIALOG.FAILED_SAVE_COMMENT'
        );
      },
    });
  }

  updateComment(loadId: number, comment: CommentModel) {
    this.loadService.updateComment(loadId, comment).subscribe({
      next: (response) => {
        if (response) {
          this.toastrTranslateService.success(
            'CONFIRM_DIALOG.SUCCESS_SAVE_COMMENT'
          );
          response.sequence = comment.sequence;
          this.dialogRef.close(response);
        } else {
          this.toastrTranslateService.error(
            'CONFIRM_DIALOG.FAILED_SAVE_COMMENT'
          );
        }
      },
      error: (response) => {
        this.toastrTranslateService.error(
          response,
          'CONFIRM_DIALOG.FAILED_SAVE_COMMENT'
        );
      },
    });
  }

  cancel() {
    this.dialogRef.close();
  }
}
