import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, MatSortable } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { first } from 'rxjs/operators';
import Common from 'src/app/_helpers/common';
import { Paginator } from 'src/app/_models/common/paginator';
import { SortData } from 'src/app/_models/common/sortData';
import { TableType } from 'src/app/_models/enums/tableType';
import { AnalyticsService } from 'src/app/_services/analytics.service';
import { LocalStorageService } from 'src/app/_services/local.storage.service';
import { LocalStorageSortService } from 'src/app/_services/local.storage.sort.service';

import { AnalyticsCustomer } from '../../_models/analytics/analytics.customer';

export interface CustomerTable {}

@Component({
  selector: 'app-analytics-customer',
  templateUrl: './analytics-customer.component.html',
  styleUrls: ['./analytics-customer.component.scss'],
})
export class AnalyticsCustomerComponent implements OnInit {
  totalSize = 0;
  pageSizeOptions: number[];
  pageSize;
  currentPage;
  searchTerm = '';
  sortData: SortData;
  public totalTotalSales = 0;
  public totalAvgInvoiceAge = 0;
  public totalOpenInvoices = 0;
  public totalPaidInvoices = 0;
  count: number;

  startDate = new Date(new Date().getFullYear(), 0, 1);
  endDate = Common.getDateEndOfDay(new Date());
  sumTotalSales = 0;
  sumOpenInvoices = 0;
  sumPaidInvoices = 0;

  displayedColumns: string[] = [
    'customerName',
    'totalSales',
    'avgInvoiceAge',
    'openInvoices',
    'paidInvoices',
  ];

  dataSource = new MatTableDataSource<AnalyticsCustomer>();

  @ViewChild(MatPaginator) matPaginator: MatPaginator;
  @ViewChild(MatSort) matSort: MatSort;

  constructor(
    private analyticsService: AnalyticsService,
    private localStorageService: LocalStorageService,
    private localStorageSortService: LocalStorageSortService
  ) {}

  ngOnInit(): void {
    this.dataSource.paginator = this.matPaginator;
    this.initPaginatorOptions();
    this.sortData = this.localStorageSortService.getSortData(
      TableType.AnalyticsCustomer
    );
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.matSort.sort({
        id: this.sortData.activeColumn,
        start: this.sortData.order,
      } as MatSortable);
      this.dataSource.sort = this.matSort;
    });
    this.dataSource.sortingDataAccessor = (item, property) => {
      this.localStorageSortService.setSortData(
        TableType.AnalyticsCustomer,
        this.matSort
      );
      return item[property];
    };
    this.matSort.sortChange.subscribe(() => {
      this.refresh();
      this.currentPage = 0;
    });
  }

  refresh() {
    this.searchTerm = '';
    this.getAnalyticsCustomer();
  }

  initPaginatorOptions() {
    this.pageSize = this.localStorageService.getPagginatorPageSize();
    this.pageSizeOptions = [10, 25, 50, 100];
    this.currentPage = 0;
  }

  onChangePage(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    this.localStorageService.setPagginatorPageSize(event.pageSize);
    this.getAnalyticsCustomer();
  }

  getAnalyticsCustomer() {
    this.analyticsService
      .getCustomerData(this.startDate, this.endDate, this.getPaginator())
      .pipe(first())
      .subscribe((response) => {
        this.dataSource.data = response.collection;
        this.sumOpenInvoices = response.sumOpenInvoices;
        this.sumPaidInvoices = response.sumPaidInvoices;
        this.sumTotalSales = response.sumTotalSales;
        this.count = response.count;
        setTimeout(() => {
          this.matPaginator.length = response.count;
          this.matPaginator.pageIndex = this.currentPage;
        });
      });
  }

  searchCustomers(searchTerm: string) {
    this.currentPage = 0;
    this.searchTerm = searchTerm;
    this.getAnalyticsCustomer();
  }

  getPaginator(): Paginator {
    const paginator = new Paginator();
    paginator.pageSize = this.pageSize;
    paginator.pageNumber = this.currentPage + 1;
    paginator.searchTerm = this.searchTerm;
    paginator.sortBy = this.matSort?.active;
    paginator.sortOrder = this.matSort?.direction;
    this.localStorageSortService.setSortData(
      TableType.AnalyticsCustomer,
      this.matSort
    );
    return paginator;
  }
}
