<div class="wrapperTable">
  <div class="datePickersDiv custom">
    <div class="refreshDiv">
      <button mat-icon-button class="refresh" (click)="refresh()">
        <mat-icon
          matTooltip="{{ 'MANAGE.REFRESH' | translate }}"
          matTooltipPosition="below"
          >refresh</mat-icon
        >
      </button>
    </div>
    <mat-form-field class="matDatepickerStart" appearance="outline">
      <input
        matInput
        onfocus="this.select()"
        [matDatepicker]="startDatePicker"
        (dateInput)="refresh()"
        placeholder="Start Date"
        [(ngModel)]="filter.deliveryDateFrom"
      />
      <mat-label>{{ "ADVANCED.DELIVERY_DATE_FROM" | translate }}</mat-label>

      <mat-datepicker-toggle
        matSuffix
        [for]="startDatePicker"
      ></mat-datepicker-toggle>
      <mat-datepicker #startDatePicker></mat-datepicker>
    </mat-form-field>
    <mat-form-field class="matDatepickerEnd" appearance="outline">
      <input
        matInput
        onfocus="this.select()"
        [matDatepicker]="endDatePicker"
        (dateInput)="refresh()"
        placeholder="End Date"
        [min]="filter.deliveryDateFrom"
        [(ngModel)]="filter.deliveryDateTo"
      /><mat-label>{{ "ADVANCED.DELIVERY_DATE_TO" | translate }}</mat-label>
      <mat-datepicker-toggle
        matSuffix
        [for]="endDatePicker"
      ></mat-datepicker-toggle>
      <mat-datepicker #endDatePicker></mat-datepicker>
    </mat-form-field>

    <mat-form-field
      *ngIf="userDataService.isAdminDispatcher"
      floatLabel="auto"
      hideRequiredMarker
      appearance="outline"
      class="formWidth"
    >
      <mat-label class="global_search"
        >{{ "ADVANCED.DISPATCHER" | translate }}
      </mat-label>
      <mat-select
        #dispatcher
        name="dispatcher"
        class="global_search"
        [(ngModel)]="filter.dispatcherList"
        (selectionChange)="refresh()"
        multiple
        placeholder="{{ 'ADVANCED.DISPATCHER' | translate }}"
        [compareWith]="compareObjects"
      >
        <ng-container *ngIf="dispatcherList.length > 0">
          <mat-option
            *ngFor="let dispatcher of dispatcherList"
            [value]="dispatcher"
          >
            {{ dispatcher.firstname + " " + dispatcher.lastname }}
          </mat-option>

          <div *ngIf="dispatcherList.length > 0" class="p-10">
            <a
              *ngIf="filter.dispatcherList.length > 0"
              (click)="clearSelectedDicpatchers(filter); dispatcher.close()"
              >{{ "COMMON.CLEAR_SELECTION" | translate }}</a
            >
            <div class="numbering">
              {{ filter.dispatcherList.length }}/{{ dispatcherList.length }}
            </div>
          </div>
        </ng-container>
      </mat-select>
    </mat-form-field>

    <mat-form-field
      floatLabel="auto"
      hideRequiredMarker
      appearance="outline"
      class="formWidth"
    >
      <mat-label class="global_search"
        >{{ "ADVANCED.DRIVER" | translate }}
      </mat-label>

      <mat-select
        name="driver"
        #driver
        class="global_search"
        [(ngModel)]="filter.driverList"
        (selectionChange)="refresh()"
        multiple
        placeholder="{{ 'ADVANCED.DRIVER' | translate }}"
        [compareWith]="compareObjects"
      >
        <ng-container *ngIf="driverList.length > 0">
          <mat-option *ngFor="let driver of driverList" [value]="driver">
            {{ driver.firstname + " " + driver.lastname }}
          </mat-option>
          <div class="p-10">
            <a
              *ngIf="filter.driverList.length > 0"
              (click)="clearSelectedDrivers(filter); driver.close()"
              >{{ "COMMON.CLEAR_SELECTION" | translate }}</a
            >
            <div class="numbering">
              {{ filter.driverList.length }}/{{ driverList.length }}
            </div>
          </div>
        </ng-container>
      </mat-select>
    </mat-form-field>

    <mat-form-field
      floatLabel="auto"
      hideRequiredMarker
      appearance="outline"
      class="formWidth"
    >
      <mat-label class="global_search"
        >{{ "ADVANCED.TRUCK" | translate }}
      </mat-label>
      <mat-select
        name="truck"
        #truck
        class="global_search"
        [(ngModel)]="filter.truckList"
        (selectionChange)="refresh()"
        multiple
        placeholder="{{ 'ADVANCED.TRUCK' | translate }}"
        [compareWith]="compareObjects"
      >
        <ng-container *ngIf="truckList.length > 0">
          <mat-option *ngFor="let truck of truckList" [value]="truck">
            {{ truck.truckNumber }}
          </mat-option>
          <div class="p-10">
            <a
              *ngIf="filter.truckList.length > 0"
              (click)="clearSelectedTrucks(filter); truck.close()"
              >{{ "COMMON.CLEAR_SELECTION" | translate }}</a
            >
            <div class="numbering">
              {{ filter.truckList.length }}/{{ truckList.length }}
            </div>
          </div>
        </ng-container>
      </mat-select>
    </mat-form-field>

    <mat-form-field
      floatLabel="auto"
      hideRequiredMarker
      appearance="outline"
      class="formWidth"
    >
      <mat-label class="global_search"
        >{{ "ADVANCED.STATUS" | translate }}
      </mat-label>

      <mat-select
        name="status"
        #status
        class="global_search"
        [(ngModel)]="filter.statusList"
        (selectionChange)="refresh()"
        multiple
        placeholder="{{ 'ADVANCED.STATUS' | translate }}"
        [compareWith]="compareObjects"
      >
        <ng-container *ngIf="statusTypeList.length > 0">
          <mat-option
            *ngFor="let statusType of statusTypeList"
            [value]="statusType"
          >
            {{ "LOAD_STATUS." + statusType.name | translate }}
          </mat-option>
          <div class="p-10">
            <a
              *ngIf="filter.statusList.length > 0"
              (click)="clearSelectedStatuses(filter); status.close()"
              >{{ "COMMON.CLEAR_SELECTION" | translate }}</a
            >
            <div class="numbering">
              {{ filter.statusList.length }}/{{ statusTypeList.length }}
            </div>
          </div>
        </ng-container>
      </mat-select>
    </mat-form-field>
    <div class="vertical-line"></div>
    <div
      *ngIf="
        filter.dispatcherList.length > 0 ||
        filter.driverList.length > 0 ||
        filter.truckList.length > 0 ||
        filter.statusList.length > 0 ||
        filter.deliveryDateFrom ||
        filter.deliveryDateTo
      "
      (click)="clearSelectedFilters(filter)"
      class="reset"
    >
      {{ "ADVANCED.RESET" | translate }}
    </div>

    <div class="m-auto div-shadow">
      <div class="row">
        <div class="text-align-end">
          <button
            mat-raised-button
            (click)="export()"
            [disabled]="loading || this.dataSource?.data?.length == 0"
            class="mat-raised-button export-button"
          >
            {{ "ADVANCED.EXPORT" | translate }}
          </button>
          <button
            mat-button
            [matMenuTriggerFor]="theMenu"
            class="mat-raised-button arrow-button"
            [disabled]="loading || this.dataSource?.data?.length == 0"
          >
            <mat-icon>keyboard_arrow_down</mat-icon>
          </button>
          <mat-menu #theMenu="matMenu">
            <button
              mat-menu-item
              class="export-text"
              (click)="export()"
              [disabled]="loading || this.dataSource?.data?.length == 0"
            >
              {{ "ADVANCED.EXCEL" | translate }}
            </button>
            <button
              mat-menu-item
              class="export-text"
              (click)="exportQuickBooks()"
              [disabled]="loading || this.dataSource?.data?.length == 0"
            >
              {{ "ADVANCED.EXPORT_QUICKBOOKS" | translate }}
            </button>
            <other-buttons-component></other-buttons-component>
          </mat-menu>
        </div>
      </div>
    </div>
  </div>
  <div class="width-table-div" [hidden]="count === 0">
    <div class="inline-table">
      <div class="mat-elevation-z8">
        <table
          mat-table
          matSort
          matSortDisableClear
          [dataSource]="dataSource"
          class="mat-elevation-z8"
        >
          <ng-container matColumnDef="loadNumber">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
              {{ "LOADS.LOAD" | translate }}
            </th>
            <td
              mat-cell
              *matCellDef="let load"
              routerLink="{{ '/load-advanced/' + load.id }}"
              class="link"
            >
              <label
                matTooltip="{{ 'LOADS.EDIT_LOAD' | translate }} {{
                  load.loadNumber
                }}"
                >{{ load.loadNumber }}</label
              >
            </td>
            <td mat-footer-cell *matFooterCellDef>
              {{ "LOADS.COUNT" | translate }} {{ loadsTotals.totalCount }}
            </td>
          </ng-container>

          <ng-container matColumnDef="driver" class="driver">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
              {{ "LOADS.DRIVER" | translate }}
            </th>
            <td
              mat-cell
              *matCellDef="let load"
              routerLink="{{ '/load-advanced/' + load.id }}"
              class="text-truncate"
              id="driverName"
            >
              <label
                *ngIf="load.driver"
                class="link"
                matTooltip="{{
                  load.driver.firstname + ' ' + load.driver.lastname
                }}"
                >{{ load.driver.firstname + " " + load.driver.lastname }}</label
              >
            </td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>

          <ng-container matColumnDef="dispatcher">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
              {{ "LOADS.DISPATCHER" | translate }}
            </th>
            <td
              mat-cell
              *matCellDef="let load"
              routerLink="{{ '/load-advanced/' + load.id }}"
              class="dispatcherName"
            >
              <label
                *ngIf="load.dispatcher"
                class="link"
                matTooltip="{{
                  load.dispatcher.firstname + ' ' + load.dispatcher.lastname
                }}"
                >{{
                  load.dispatcher.firstname + " " + load.dispatcher.lastname
                }}</label
              >
            </td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>

          <ng-container matColumnDef="truck">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
              {{ "LOADS.TRUCK" | translate }}
            </th>
            <td
              mat-cell
              *matCellDef="let load"
              routerLink="{{ '/load-advanced/' + load.id }}"
              class="middle-truncate"
            >
              <label
                *ngIf="load.truck"
                class="link"
                matTooltip="{{ load.truck.truckNumber }}"
                >{{ load.truck.truckNumber }}
              </label>
            </td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>

          <ng-container matColumnDef="trailer">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
              {{ "LOADS.TRAILER" | translate }}
            </th>
            <td
              mat-cell
              *matCellDef="let load"
              routerLink="{{ '/load-advanced/' + load.id }}"
            >
              <label
                *ngIf="load.trailer"
                class="link"
                matTooltip="{{ load.trailer.trailerNumber }}"
                >{{ load.trailer.trailerNumber }}
              </label>
            </td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>

          <ng-container matColumnDef="eq_type">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
              <label matTooltip="{{ 'LOADS.EQ_TYPE' | translate }}">{{
                "LOADS.EQT" | translate
              }}</label>
            </th>
            <td
              mat-cell
              *matCellDef="let load"
              routerLink="{{ '/load-advanced/' + load.id }}"
              class="text-truncate"
            >
              <label
                *ngIf="load.equipmentType"
                class="link"
                matTooltip="{{ load.equipmentType.name }}"
              >
                {{ "EQ_TYPE_SHORT." + load.equipmentType.name | translate }}
              </label>
            </td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>

          <ng-container matColumnDef="customer">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
              {{ "LOADS.CUSTOMER" | translate }}
            </th>
            <td
              mat-cell
              *matCellDef="let load"
              routerLink="{{ '/load-advanced/' + load.id }}"
              class="middle-truncate"
            >
              <label
                *ngIf="load.customer"
                class="link"
                matTooltip="{{
                  load.customer.name ||
                    '' + ', ' + load.customer.email ||
                    '' + ', ' + load.customer.phoneNumber ||
                    ''
                }}"
                >{{ load.customer.name }}
              </label>
            </td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>

          <ng-container matColumnDef="origin">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
              {{ "LOADS.ORIGIN" | translate }}
            </th>
            <td
              mat-cell
              *matCellDef="let load"
              routerLink="{{ '/load-advanced/' + load.id }}"
              class="address-truncate"
            >
              <label
                *ngIf="load.originLocation"
                class="link"
                matTooltip="{{
                  load.originLocation.address +
                    ', ' +
                    load.originLocation.countryCode
                }}"
              >
                <label *ngIf="load.originLocation.city">
                  {{
                    load.originLocation.city + ", " + load.originLocation.state
                  }}
                </label>
                <label *ngIf="!load.originLocation.city">
                  {{ load.originLocation.address }}
                </label>
              </label>
            </td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>

          <ng-container matColumnDef="destination">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
              {{ "LOADS.DESTINATION" | translate }}
            </th>
            <td
              mat-cell
              *matCellDef="let load"
              routerLink="{{ '/load-advanced/' + load.id }}"
              class="address-truncate"
            >
              <label
                *ngIf="load.destinationLocation"
                class="link"
                matTooltip="{{
                  load.destinationLocation.address +
                    ', ' +
                    load.destinationLocation.countryCode
                }}"
              >
                <label *ngIf="load.destinationLocation.city">
                  {{
                    load.destinationLocation.city +
                      ", " +
                      load.destinationLocation.state
                  }}
                </label>
                <label *ngIf="!load.destinationLocation.city">
                  {{ load.destinationLocation.address }}
                </label>
              </label>
            </td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>

          <ng-container matColumnDef="pickup_date">
            <th mat-header-cell mat-sort-header="pickupDate" *matHeaderCellDef>
              {{ "LOADS.PICKUP_DATE" | translate }}
            </th>
            <td
              mat-cell
              *matCellDef="let load"
              routerLink="{{ '/load-advanced/' + load.id }}"
              class="textRight"
            >
              {{ load.pickupDate | tenantDate : "MM/dd/y " }}
            </td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>

          <ng-container matColumnDef="delivery_date">
            <th
              mat-header-cell
              mat-sort-header="deliveryDate"
              *matHeaderCellDef
            >
              {{ "LOADS.DELIVERY_DATE" | translate }}
            </th>
            <td
              mat-cell
              *matCellDef="let load"
              routerLink="{{ '/load-advanced/' + load.id }}"
              class="textRight"
            >
              {{ load.deliveryDate | tenantDate }}
            </td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>

          <ng-container matColumnDef="milage">
            <th
              mat-header-cell
              mat-sort-header="milage"
              *matHeaderCellDef
              class="textRight padingHeaderMilage"
            >
              {{ "LOADS.MILAGE" | translate }}
            </th>
            <td
              mat-cell
              *matCellDef="let load"
              routerLink="{{ '/load-advanced/' + load.id }}"
              class="link padingLeft"
              type="number"
            >
              {{ load.milage | number : "1.2-2" }}
            </td>
            <td mat-footer-cell *matFooterCellDef>
              {{ loadsTotals.totalMilage | number : "1.2-2" }}
            </td>
          </ng-container>

          <ng-container matColumnDef="dead_head">
            <th
              mat-header-cell
              mat-sort-header="deadhead"
              class="textRight padingHeaderDh"
              *matHeaderCellDef
            >
              <label matTooltip="{{ 'LOADS.DEAD_HEAD_SHORT' | translate }}">{{
                "LOADS.DEAD_HEAD_SHORT" | translate
              }}</label>
            </th>
            <td
              mat-cell
              *matCellDef="let load"
              routerLink="{{ '/load-advanced/' + load.id }}"
              class="link padingLeft"
              type="number"
            >
              {{ load.deadhead | number : "1.2-2" }}
            </td>
            <td mat-footer-cell *matFooterCellDef>
              {{ loadsTotals.totalDeadHead | number : "1.2-2" }}
            </td>
          </ng-container>

          <ng-container matColumnDef="rate">
            <th
              mat-header-cell
              mat-sort-header="rate"
              class="padingHeaderRate"
              *matHeaderCellDef
            >
              {{ "LOADS.RATE" | translate }}
            </th>
            <td
              mat-cell
              *matCellDef="let load"
              routerLink="{{ '/load-advanced/' + load.id }}"
              class="link padingLeft"
              type="number"
            >
              {{ load.rate | currency }}
            </td>
            <td mat-footer-cell *matFooterCellDef>
              {{ loadsTotals.totalRate | currency }}
            </td>
          </ng-container>

          <ng-container matColumnDef="lumper_fee">
            <th
              mat-header-cell
              mat-sort-header="lumperCost"
              class="textRight padingHeader"
              *matHeaderCellDef
            >
              {{ "LOADS.LUMPER_FEE" | translate }}
            </th>
            <td
              mat-cell
              *matCellDef="let load"
              routerLink="{{ '/load-advanced/' + load.id }}"
              class="textRight padingLeft"
              type="number"
            >
              <label
                matTooltip="Loading and unloading fee - Coast that is payed for person who performed loading/unloading of the truck"
                >{{ load.lumperCost | currency }}</label
              >
            </td>
            <td class="textRight" mat-footer-cell *matFooterCellDef>
              {{ loadsTotals.totalLumperCost | currency }}
            </td>
          </ng-container>

          <ng-container matColumnDef="detention_fee">
            <th
              mat-header-cell
              mat-sort-header="detentionCost"
              class="textRight padingHeader"
              *matHeaderCellDef
            >
              {{ "LOADS.DETENTION_FEE" | translate }}
            </th>
            <td
              mat-cell
              *matCellDef="let load"
              routerLink="{{ '/load-advanced/' + load.id }}"
              class="textRight padingLeft"
              type="number"
            >
              <label
                matTooltip="Coast that is payed if driver had waiting time to load or unload"
                >{{ load.detentionCost | currency }}</label
              >
            </td>
            <td class="textRight" mat-footer-cell *matFooterCellDef>
              {{ loadsTotals.totalDetention | currency }}
            </td>
          </ng-container>

          <ng-container matColumnDef="other">
            <th
              mat-header-cell
              mat-sort-header="otherCost"
              class="textRight padingHeader"
              *matHeaderCellDef
            >
              {{ "LOADS.OTHER_COSTS" | translate }}
            </th>
            <td
              mat-cell
              *matCellDef="let load"
              routerLink="{{ '/load-advanced/' + load.id }}"
              class="textRight padingLeft"
              type="number"
            >
              <label
                matTooltip="Other coast; If there are other coast (that is not lumper and detention fee) here will be entered"
                >{{ load.otherCost | currency }}</label
              >
            </td>
            <td class="textRight" mat-footer-cell *matFooterCellDef>
              {{ loadsTotals.totalOtherCost | currency }}
            </td>
          </ng-container>

          <ng-container matColumnDef="total_invoice">
            <th
              mat-header-cell
              mat-sort-header="totalInvoiced"
              class="textRight padingHeader"
              *matHeaderCellDef
            >
              {{ "LOADS.TOTAL_INVOICE" | translate }}
            </th>
            <td
              mat-cell
              *matCellDef="let load"
              routerLink="{{ '/load-advanced/' + load.id }}"
              class="textRight padingLeft"
              type="number"
            >
              <label
                matTooltip="Rate + Lumper fee + Detention fee + Other - Advance"
                >{{ load.totalInvoiced | currency }}</label
              >
            </td>
            <td class="textRight" mat-footer-cell *matFooterCellDef>
              {{ loadsTotals.totalTotalInvoiced | currency }}
            </td>
          </ng-container>

          <ng-container matColumnDef="advance">
            <th
              mat-header-cell
              mat-sort-header="advanedPayment"
              class="textRight padingHeader"
              *matHeaderCellDef
            >
              {{ "LOADS.ADVANCE" | translate }}
            </th>
            <td
              mat-cell
              *matCellDef="let load"
              routerLink="{{ '/load-advanced/' + load.id }}"
              class="textRight padingLeft"
              type="number"
            >
              <label matTooltip="Advance payment from shipper">{{
                load.advanedPayment | currency
              }}</label>
            </td>
            <td class="textRight" mat-footer-cell *matFooterCellDef>
              {{ loadsTotals.totalAdvancedPayment | currency }}
            </td>
          </ng-container>

          <ng-container matColumnDef="rpm">
            <th
              mat-header-cell
              mat-sort-header="ratePerMile"
              class="textRight padingHeader"
              *matHeaderCellDef
            >
              <label matTooltip="{{ 'LOADS.RATE_PER_MILE' | translate }}">
                {{ "LOADS.RPM" | translate }}
              </label>
            </th>
            <td
              mat-cell
              *matCellDef="let load"
              routerLink="{{ '/load-advanced/' + load.id }}"
              class="link padingLeft"
              type="number"
            >
              <label matTooltip="{{ 'LOADS.RPM_CALCULATE' | translate }}">{{
                load.ratePerMile | currency
              }}</label>
            </td>
            <td mat-footer-cell *matFooterCellDef>
              <label
                matTooltip="{{ 'LOADS.RPM_AVERAGE' | translate }}"
                matTooltipPosition="after"
              >
                {{ loadsTotals.avgRpm | currency }}
              </label>
            </td>
          </ng-container>

          <ng-container matColumnDef="tonu">
            <th
              mat-header-cell
              mat-sort-header="toNu"
              class="textRight padingHeader"
              *matHeaderCellDef
            >
              {{ "LOADS.TONU" | translate }}
            </th>
            <td
              mat-cell
              *matCellDef="let load"
              routerLink="{{ '/load-advanced/' + load.id }}"
              class="link padingLeft"
              type="number"
            >
              {{ load.toNu | currency }}
            </td>
            <td mat-footer-cell *matFooterCellDef>
              {{ loadsTotals.totalToNu | currency }}
            </td>
          </ng-container>

          <ng-container matColumnDef="status">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
              {{ "LOADS.STATUS" | translate }}
            </th>
            <td mat-cell *matCellDef="let load">
              <mat-form-field
                floatLabel="auto"
                hideRequiredMarker
                class="matFormStatus {{ load.status.name }}"
              >
                <mat-select
                  class="global_search"
                  name="loadStatus"
                  [compareWith]="compareObjects"
                  [(ngModel)]="load.status"
                  (selectionChange)="
                    openDialogConfirmStatus(
                      load.id,
                      load.status.id,
                      load.loadNumber
                    )
                  "
                >
                  <ng-container *ngIf="statusTypeList.length > 0">
                    <mat-option
                      *ngFor="let statusType of statusTypeList"
                      [value]="statusType"
                      class="statusOption"
                      style="font-weight: bold"
                    >
                      {{ "LOAD_STATUS." + statusType.name | translate }}
                    </mat-option>
                  </ng-container>
                </mat-select>
              </mat-form-field>
            </td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>

          <ng-container matColumnDef="rc">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let load" class="link" id="downloadRC">
              <span
                *ngIf="
                  load?.status?.name == 'INVOICED' ||
                  load?.status?.name == 'PAID' ||
                  load?.status?.name == 'COMPLETED' ||
                  load?.status?.name == 'DELIVERED'
                "
                (click)="
                  openDownloadRC(
                    load.id,
                    load.loadNumber,
                    load.documents,
                    load.status.name
                  )
                "
                class="material-icons"
                matTooltip="{{ 'LOADS.DOWNLOAD_DOCUMENTS' | translate }}"
              >
                <mat-icon class="download"> download</mat-icon>
              </span>
            </td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>
          <ng-container matColumnDef="sendMail">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let load" class="link" id="sendMail">
              <span
                *ngIf="
                  load?.status?.name == 'INVOICED' ||
                  load?.status?.name == 'PAID'
                "
                (click)="
                  sendMailToCustomer(
                    load.id,
                    load.loadNumber,
                    load.customer,
                    load.documents
                  )
                "
                class="material-icons"
                matTooltip="{{ 'LOADS.SEND_MAIL' | translate }}"
              >
                <mat-icon class="mail"> mail</mat-icon>
              </span>
            </td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>

          <ng-container matColumnDef="print_ivoice">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let load">
              <button
                class="btnDialog invoice"
                (click)="openPrintInoviceDialog(load.id, load.status.name)"
              >
                <span>
                  <mat-icon class="printIcon"> local_printshop</mat-icon>
                </span>
                <span
                  class="spanDisplay"
                  matTooltip="{{ 'LOADS.PRINT_INVOICE' | translate }}"
                >
                  {{ "LOADS.INVOICE" | translate }}
                </span>
              </button>
            </td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>

          <ng-container matColumnDef="order">
            <th mat-header-cell mat-sort-header="orderNumber" *matHeaderCellDef>
              {{ "LOADS.ORDER" | translate }}
            </th>
            <td
              mat-cell
              *matCellDef="let load"
              routerLink="{{ '/load-advanced/' + load.id }}"
              class="link"
            >
              {{ load.orderNumber }}
            </td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>

          <ng-container matColumnDef="comment">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
              {{ "LOADS.COMMENT" | translate }}
            </th>
            <td mat-cell *matCellDef="let load">
              <button
                [disabled]="!load.comments || load.comments.length == 0"
                (click)="openCommentsDialog(load.comments, load.loadNumber)"
                class="button-comment"
              >
                <mat-icon
                  class="material-icons"
                  [matBadge]="load.comments.length"
                  [matBadgeHidden]="!load.comments || load.comments.length == 0"
                  matBadgeColor="primary"
                  matTooltip="{{ 'LOADS.VIEW_COMMENT' | translate }}"
                  >comment</mat-icon
                >
              </button>
            </td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>
          <ng-container matColumnDef="note">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
              {{ "LOADS.NOTE" | translate }}
            </th>
            <td mat-cell *matCellDef="let load">
              <button
                [disabled]="!load.comments || load.comments.length == 0"
                (click)="openCommentsDialog(load.comments, load.loadNumber)"
                class="button-comment"
              >
                <mat-icon
                  class="material-icons"
                  [matBadge]="load.comments.length"
                  [matBadgeHidden]="!load.comments || load.comments.length == 0"
                  matBadgeColor="primary"
                  matTooltip="{{ 'LOADS.VIEW_COMMENT' | translate }}"
                  >comment</mat-icon
                >
              </button>
            </td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>

          <ng-container matColumnDef="haul_type">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
              <label matTooltip="{{ 'LOADS.HAUL_TYPE' | translate }}">{{
                "LOADS.HAUL_TYPE_SHORT" | translate
              }}</label>
            </th>
            <td
              mat-cell
              *matCellDef="let load"
              routerLink="{{ '/load-advanced/' + load.id }}"
              class="number-truncate"
            >
              <label
                *ngIf="load.haulType"
                class="link"
                matTooltip="{{ 'HAUL_TYPE.' + load.haulType.name | translate }}"
                >{{
                  "HAUL_TYPE_SHORT." + load.haulType.name | translate
                }}</label
              >
            </td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>

          <ng-container matColumnDef="ageing">
            <th mat-header-cell *matHeaderCellDef>
              {{ "LOADS.AGEING" | translate }}
            </th>
            <td
              mat-cell
              *matCellDef="let load"
              routerLink="{{ '/load-advanced/' + load.id }}"
              class="link"
            >
              <label matTooltip="{{ 'LOADS.AGEING_DEF' | translate }}">{{
                load.ageing
              }}</label>
            </td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>

          <ng-container matColumnDef="cs">
            <th mat-header-cell *matHeaderCellDef>
              {{ "LOADS.C/S" | translate }}
            </th>
            <td
              mat-cell
              *matCellDef="let load"
              routerLink="{{ '/load-advanced/' + load.id }}"
              class="link"
            >
              'c/s'
            </td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>

          <ng-container matColumnDef="pod_scans">
            <th mat-header-cell *matHeaderCellDef>
              {{ "LOADS.POD_SCANS" | translate }}
            </th>
            <td mat-cell *matCellDef="let load" class="link"></td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>

          <ng-container matColumnDef="invoiced">
            <th mat-header-cell *matHeaderCellDef>
              {{ "LOADS.INVOICED" | translate }}
            </th>
            <td mat-cell *matCellDef="let load" class="link">'Invoiced'</td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>

          <ng-container matColumnDef="BOL">
            <th mat-header-cell *matHeaderCellDef>
              {{ "LOADS.BOL" | translate }}
            </th>
            <td mat-cell *matCellDef="let load" class="link">
              <label
                matTooltip="Open PDF/JPEG of BOL type of
        uploaded document (POD)"
              >
              </label>
            </td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>

          <ng-container matColumnDef="columns">
            <th
              mat-header-cell
              *matHeaderCellDef
              style="padding: 10px"
              matTooltip="{{ 'ADVANCED.CONFIGURE_COLUMNS' | translate }}"
              matTooltipClass="columns-tooltip"
              matTooltipPosition="below"
            >
              <div type="button" class="select-column-div">
                <mat-select
                  #columnsSelector
                  name="columnsSelector"
                  class="global_search"
                  style="padding-top: 8px"
                  [(ngModel)]="selectedColumnList"
                  (selectionChange)="toggleColumn()"
                  multiple
                  [compareWith]="compareObjects"
                >
                  <mat-select-trigger>
                    <mat-icon>view_column</mat-icon>
                  </mat-select-trigger>
                  <ng-container *ngIf="allColumnList.length > 0">
                    <span *ngFor="let column of allColumnList">
                      <mat-option
                        [value]="column"
                        [ngClass]="{ hidden: column.name == 'columns' }"
                      >
                        {{ "COLUMN." + column.name | translate }}
                      </mat-option>
                    </span>
                    <div class="p-10">
                      <a
                        (click)="
                          clearSelectedColumns(); columnsSelector.close()
                        "
                        >{{ "COMMON.RESET_SELECTION" | translate }}</a
                      >
                    </div>
                  </ng-container>
                </mat-select>
              </div>
            </th>
            <td mat-cell *matCellDef="let load" class="link"></td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>

          <tr
            mat-header-row
            *matHeaderRowDef="displayedColumns; sticky: true"
          ></tr>
          <tr
            mat-row
            *matRowDef="let load; columns: displayedColumns"
            class="link"
          ></tr>
          <tr
            mat-footer-row
            *matFooterRowDef="displayedColumns"
            class="table-footer"
          ></tr>
        </table>
      </div>
      <div [hidden]="count === 0">
        <mat-paginator
          (page)="onChangePage($event)"
          class="paginator"
          aria-label="Select page"
          [length]="totalSize"
          [showFirstLastButtons]="true"
          [pageIndex]="currentPage"
          [pageSize]="pageSize"
          [pageSizeOptions]="pageSizeOptions"
        >
        </mat-paginator>
      </div>
    </div>
  </div>
  <app-info-nodata
    *ngIf="count == 0"
    [infoKey]="'LOADS.NO_LOADS'"
  ></app-info-nodata>
</div>
