import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';
import { Paginator } from 'src/app/_models/common/paginator';
import { DriverModel } from 'src/app/_models/driver/driver';
import { TruckModel } from 'src/app/_models/truck';
import {
  DriverService,
  ToastrTranslateService,
  TruckService,
} from 'src/app/_services';
import { UserDataService } from 'src/app/_services/user.data.service';
import { ConfirmDialogComponent } from 'src/app/common/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-edit-truck-dialog',
  templateUrl: './edit-truck-dialog.component.html',
  styleUrls: ['./edit-truck-dialog.component.scss'],
})
export class EditTruckDialogComponent implements OnInit {
  minDate: Date;
  truck: TruckModel = new TruckModel();
  currentDriver = '';
  driverList: DriverModel[] = [];
  myControl = new UntypedFormControl();
  truckId: number;
  readOnly: boolean;
  dataLoading = true;

  constructor(
    private truckService: TruckService,
    private driversService: DriverService,
    private dialogRef: MatDialogRef<EditTruckDialogComponent>,
    private toastrTranslateService: ToastrTranslateService,
    private route: ActivatedRoute,
    public userDataService: UserDataService,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {
    const currentYear = new Date().getFullYear();
    this.minDate = new Date(currentYear - 11, 12, 99);
  }

  ngOnInit(): void {
    this.truckId = this.data.id;
    this.readOnly = this.data.readOnly;
    this.truck = new TruckModel();
    this.truck.driver = new DriverModel();
    this.route.params.subscribe((params) => {
      if (this.truckId) {
        this.truckService.getTruck(this.truckId).subscribe({
          next: (response) => {
            this.truck = response;
            if (this.truck.driver) {
              this.truck.driverId = this.truck.driver.id;
            }
            this.dataLoading = false;
          },
          error: (response) => {
            this.toastrTranslateService.error(response, 'TRUCKS.FAILED');
          },
        });
      } else {
        this.truck.isActive = true;
        this.dataLoading = false;
      }
    });

    let dropDownPaginator = new Paginator();
    dropDownPaginator.pageSize = 1000;
    dropDownPaginator.sortOrder = 'asc';
    dropDownPaginator.sortBy = 'firstname';
    this.driversService
      .getAllDrivers(dropDownPaginator, true)
      .pipe(first())
      .subscribe((response) => {
        this.driverList = response.collection;
      });
  }

  compareObjects(o1: any, o2: any): boolean {
    if (o1 && o2) {
      return o1.id === o2.id;
    }
    return false;
  }

  updateExternalId(truck: TruckModel): TruckModel {
    if (this.truck.driver != null) {
      this.truck.driverId = this.truck.driver.id;
    }
    return truck;
  }

  setActiveValue(e) {
    if (e.checked) {
      this.truck.isActive = true;
    } else {
      this.truck.isActive = false;
    }
  }

  showWarning(truckId: number, activeState: boolean) {
    if (truckId && activeState == false) {
      const dialogWarning = this.dialog.open(ConfirmDialogComponent, {
        disableClose: true,
        position: { top: '20px' },
        data: {
          titleKey1: 'CONFIRM_DIALOG.CONFIRM_TRUCK_DISABLE_WARNING_1',
          titleKey2: 'CONFIRM_DIALOG.CONFIRM_TRUCK_DISABLE_WARNING_2',
        },
      });
      dialogWarning.afterClosed().subscribe((proceeed) => {
        if (!proceeed) {
          this.truck.isActive = true;
        }
      });
    }
  }

  setDriverOwnerValue(e) {
    if (e.checked) {
      this.truck.owner =
        this.truck.driver.firstname + ' ' + this.truck.driver.lastname;
      this.truck.isDriverOwner = true;
    } else {
      this.truck.isDriverOwner = false;
    }
  }

  toBool(boolVariable: any): boolean {
    if (boolVariable && boolVariable != 'undefined') {
      return Boolean(JSON.parse(boolVariable));
    }
    return false;
  }

  save(isInvalid) {
    if (isInvalid) {
      this.toastrTranslateService.warning('GENERAL.FORM_INVALID');
      return;
    }
    this.truck = this.updateExternalId(this.truck);
    if (this.truckId) {
      //Privmreneno
      //TODO izbaciti kada se prikupi sa ekrana
      // this.truck.annualInspectionExpirationDate = new Date();
      //this.truck.quarterlyInspectionDue = new Date();
      //  this.truck.registrationExpirationDate = new Date();

      this.truckService.updateTruck(this.truck).subscribe({
        next: (response) => {
          this.truck = response;
          this.toastrTranslateService.success(
            'TRUCKS.SAVED',
            this.truck.truckNumber
          );
          this.dialogRef.close();
        },
        error: (response) => {
          this.toastrTranslateService.error(response, 'TRUCKS.FAILED');
        },
      });
    } else {
      this.truckService.addTruck(this.truck).subscribe({
        next: (response) => {
          this.truck = response;
          this.toastrTranslateService.success(
            'TRUCKS.SAVED',
            this.truck.truckNumber
          );
          this.dialogRef.close();
        },
        error: (response) => {
          this.toastrTranslateService.error(response, 'TRUCKS.NOT_SAVED');
        },
      });
    }
  }
}
