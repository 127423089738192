<div class="modal-dialog formInModal">
  <div *ngIf="!dataLoading">
    <h2 *ngIf="trailer && !trailer.id" mat-dialog-title class="text f-16">
      {{ "TRAILERS.ADD_TRAILER" | translate }}
    </h2>
    <h2 *ngIf="trailer.id" mat-dialog-title class="mb-0">
      <h2 class="text f-16" [hidden]="readOnly">
        <div class="text f-16">
          {{ "TRAILERS.EDIT_TRAILER" | translate }}
        </div>
        <div>
          {{ trailer.trailerNumber }}
        </div>
      </h2>
      <h2 class="text f-16" [hidden]="!readOnly">
        <div class="text f-16">
          {{ "TRAILERS.PREVIEW_TRAILER" | translate }}
        </div>
        <div>
          {{ trailer.trailerNumber }}
        </div>
      </h2>
    </h2>
  </div>
  <hr />
  <form #editTrailer="ngForm">
    <mat-dialog-content class="mat-typography">
      <mat-form-field
        floatLabel="auto"
        hideRequiredMarker
        appearance="outline"
        class="formWidth"
      >
        <mat-label class="global_search">
          {{ "TRAILERS.TRAILER_NAME" | translate }} <span class="star">*</span>
        </mat-label>
        <input
          matInput
          [(ngModel)]="trailer.trailerNumber"
          [readonly]="readOnly"
          name="trailerName"
          required
          autocomplete="off"
        />
      </mat-form-field>
      <mat-form-field
        floatLabel="auto"
        hideRequiredMarker
        appearance="outline"
        class="formWidth"
      >
        <mat-label class="global_search">
          {{ "TRAILERS.OWNER_NAME" | translate }}
        </mat-label>
        <input
          matInput
          [(ngModel)]="trailer.owner"
          [readonly]="readOnly"
          name="ownerName"
          autocomplete="off"
        />
      </mat-form-field>
      <br />
      <mat-form-field
        floatLabel="auto"
        hideRequiredMarker
        appearance="outline"
        class="formWidth"
      >
        <mat-label class="global_search">
          {{ "TRAILERS.LICENSE_PLATE" | translate }} <span class="star">*</span>
        </mat-label>
        <input
          matInput
          [(ngModel)]="trailer.licensePlate"
          [readonly]="readOnly"
          name="licensePlate"
          required
          autocomplete="off"
        />
      </mat-form-field>

      <mat-form-field
        floatLabel="auto"
        hideRequiredMarker
        appearance="outline"
        class="formWidth"
      >
        <mat-label class="global_search">
          {{ "TRAILERS.EQ_TYPE" | translate }}<span class="star">*</span>
        </mat-label>
        <mat-select
          name="eq_type"
          class="global_search"
          placeholder="{{ 'TRAILERS.EQ_TYPE' | translate }}"
          [compareWith]="compareObjects"
          [(ngModel)]="trailer.equipmentType"
          [readonly]="readOnly"
          [disabled]="readOnly"
          required
        >
          <ng-container *ngIf="equipmentTypeList.length > 0">
            <mat-option
              *ngFor="let equipmentType of equipmentTypeList"
              [value]="equipmentType"
            >
              {{ equipmentType.name }}
            </mat-option>
          </ng-container>
        </mat-select>
      </mat-form-field>

      <br />
      <mat-form-field
        floatLabel="auto"
        hideRequiredMarker
        appearance="outline"
        class="formWidth"
      >
        <mat-label class="global_search">
          {{ "TRAILERS.REGISTRATION_EXPIRATION_DATE" | translate }}
          <span class="star">*</span>
        </mat-label>
        <input
          matInput
          [matDatepicker]="picker1"
          [(ngModel)]="trailer.registrationExpirationDate"
          [readonly]="readOnly"
          name="registrationExpDate"
          required
          autocomplete="off"
          [min]="minDate"
        />
        <mat-datepicker-toggle matSuffix [for]="picker1" *ngIf="!readOnly">
        </mat-datepicker-toggle>
        <mat-datepicker #picker1> </mat-datepicker>
      </mat-form-field>
      <mat-form-field
        floatLabel="auto"
        hideRequiredMarker
        appearance="outline"
        class="formWidth"
      >
        <mat-label class="global_search">
          {{ "TRAILERS.ANNUAL_INSPECTION_EXPIRATION_DATE" | translate }}
          <span class="star">*</span>
        </mat-label>
        <input
          matInput
          [readonly]="readOnly"
          [matDatepicker]="picker2"
          [(ngModel)]="trailer.annualInspectionExpirationDate"
          name="annualInspExpDate"
          required
          autocomplete="off"
          [min]="minDate"
        />
        <mat-datepicker-toggle matSuffix [for]="picker2" *ngIf="!readOnly">
        </mat-datepicker-toggle>
        <mat-datepicker #picker2> </mat-datepicker>
      </mat-form-field>
      <br />
      <mat-form-field
        floatLabel="auto"
        hideRequiredMarker
        appearance="outline"
        class="formWidth"
      >
        <mat-label class="global_search">
          {{ "TRAILERS.QUARTERLY_INSPECTION" | translate }}
        </mat-label>
        <input
          matInput
          [readonly]="readOnly"
          [matDatepicker]="picker3"
          [(ngModel)]="trailer.quarterlyInspectionDue"
          [readonly]="readOnly"
          name="quarterlyInspDue"
          autocomplete="off"
          [min]="minDate"
        />
        <mat-datepicker-toggle matSuffix [for]="picker3" *ngIf="!readOnly">
        </mat-datepicker-toggle>
        <mat-datepicker #picker3> </mat-datepicker>
      </mat-form-field>
      <mat-slide-toggle
        [disabled]="readOnly"
        color="primary"
        [checked]="toBool(trailer.isActive)"
        (change)="setActiveValue($event)"
      >
        {{ "TRAILERS.ACTIVE" | translate }}
      </mat-slide-toggle>
    </mat-dialog-content>
    <mat-dialog-actions>
      <button mat-button class="btnCan" mat-dialog-close>
        {{ "TRAILERS.CANCEL" | translate }}
      </button>

      <button
        mat-flat-button
        class="btn btn-green"
        (click)="save(editTrailer.form.invalid)"
        *ngIf="!readOnly && userDataService.isAdminDispatcher"
      >
        {{ "TRAILERS.SAVE" | translate }}
      </button>
    </mat-dialog-actions>
  </form>
</div>
