import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ComfirmDialogComponent } from 'src/app/manage/trucks/edit-delete-truck/comfirm-dialog/comfirm-dialog.component';

@Component({
  selector: 'app-pay-calculation-confirm-dialog',
  templateUrl: './pay-calculation-confirm-dialog.component.html',
  styleUrls: ['./pay-calculation-confirm-dialog.component.scss'],
})
export class PayCalculationConfirmDialogComponent implements OnInit {
  constructor(private dialogRef: MatDialogRef<ComfirmDialogComponent>) {}

  ngOnInit(): void {}
  confirm() {
    this.dialogRef.close(true);
  }
}
