import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSort, MatSortable } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Params } from '@angular/router';
import { debounce } from 'lodash-es';
import { PayrollCalculation } from 'src/app/_models/_payroll/payroll-calculation';
import { SortData } from 'src/app/_models/common/sortData';
import { DriverModel } from 'src/app/_models/driver/driver';
import { TableType } from 'src/app/_models/enums/tableType';
import { TruckModel } from 'src/app/_models/truck';
import {
  DriverService,
  PayrollService,
  TenantService,
  ToastrTranslateService,
  TruckService,
} from 'src/app/_services';
import { LocalStorageSortService } from 'src/app/_services/local.storage.sort.service';
import { AddPayrollCommentComponent } from '../../add-payroll-comment/add-payroll-comment.component';
import { DeletePayrollConfirmPopupComponent } from '../../delete-payroll-confirm-popup/delete-payroll-confirm-popup.component';
import { PayCalculationConfirmDialogComponent } from '../../pay-calculation-confirm-dialog/pay-calculation-confirm-dialog.component';
import { PayrollPdfPreviewPopupComponent } from '../../payroll-pdf-preview-popup/payroll-pdf-preview-popup.component';

@Component({
  selector: 'app-previous-payrolls',
  templateUrl: './previous-payrolls.component.html',
  styleUrls: ['./previous-payrolls.component.scss'],
})
export class PreviousPayrollsComponent implements OnInit, AfterViewInit {
  driverId: number;
  sortData: SortData;
  constructor(
    private route: ActivatedRoute,
    private payrollService: PayrollService,
    private dialog: MatDialog,
    public toastrTranslateService: ToastrTranslateService,
    private localStorageSortService: LocalStorageSortService,
    private truckService: TruckService,
    private driverService: DriverService,
    private tenantService: TenantService
  ) {}

  @ViewChild(MatSort) sort: MatSort;

  driver: DriverModel;
  truck: TruckModel;
  truckId: number;

  prevPayrolls: PayrollCalculation[] = [];
  dataSource = new MatTableDataSource<PayrollCalculation>();
  sum: number = 0;
  periodForm: UntypedFormGroup;

  public displayedColumns: string[] = [
    'driverName',
    'truckNumber',
    'dateFrom',
    'dateTo',
    'total',
    'paid',
    'paidBy',
    'reportPreview',
    'comment',
    'markAsPaid',
    'star',
  ];

  ngOnInit(): void {
    this.periodForm = new UntypedFormGroup({
      start: new UntypedFormControl(null),
      end: new UntypedFormControl(null),
    });
    this.sortData = this.localStorageSortService.getSortData(
      TableType.PayrollPrevious
    );
    this.route.parent.params.subscribe((params: Params) => {
      this.truckId = +params['truckId'];
      this.driverId = +params['driverId'];
      this.truckService
        .getTruck(this.truckId)
        .subscribe((truck: TruckModel) => {
          if (!truck.driver) {
            this.driverService
              .getDriver(this.driverId)
              .subscribe((driver: DriverModel) => {
                truck.driver = driver;
                this.GetTabData(truck);
              });
          } else {
            this.GetTabData(truck);
          }
        });
    });
    this.onDateChange = debounce(this.onDateChange, 1000);
  }

  private GetTabData(truck: TruckModel) {
    this.truck = truck;
    this.driver = this.truck.driver;
    this.loadPrevPayrolls();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.sort.sort({
        id: this.sortData.activeColumn,
        start: this.sortData.order,
      } as MatSortable);
      this.dataSource.sort = this.sort;
    });
    this.dataSource.sortingDataAccessor = (item, property) => {
      this.localStorageSortService.setSortData(
        TableType.PayrollPrevious,
        this.sort
      );
      switch (property) {
        case 'paid':
          return this.toBool(item.isPaid);
        default:
          return item[property];
      }
    };
  }

  refresh() {
    this.loadPrevPayrolls();
  }

  loadPrevPayrolls() {
    let start: Date = null;
    let end: Date = null;

    if (this.periodForm.get('start').value) {
      start = this.tenantService.getTenetUtcDate(
        this.periodForm.get('start').value
      );
    }

    if (this.periodForm.get('end').value) {
      end = this.tenantService.getTenetUtcDate(
        this.periodForm.get('end').value
      );
    }

    this.payrollService
      .getPrevousPayrollsForDriverAndTruck(
        this.driver.id,
        this.truckId,
        start,
        end
      )
      .subscribe((res) => {
        this.prevPayrolls = res;
        this.dataSource.data = this.prevPayrolls;
        this.sum = 0;
        for (let i = 0; i < this.prevPayrolls.length; i++)
          this.sum += this.prevPayrolls[i].total;
        this.displayNewlyCreatedPayrollCalcIfRedirected();
      });
  }

  /**
   * If redirected from submit payroll -> display newly created payroll calculation pdf
   */
  displayNewlyCreatedPayrollCalcIfRedirected() {
    this.route.queryParams.subscribe((queryParams) => {
      if (queryParams['openid']) {
        let newpcId = +queryParams['openid'];
        if (newpcId) {
          let newpc = this.prevPayrolls.find((x) => x.id == newpcId);
          if (newpc) {
            this.onPreview(newpc);
          }
        }
      }
    });
  }

  onDateChange(e) {
    if (
      this.periodForm.get('start').value &&
      this.periodForm.get('end').value
    ) {
      this.loadPrevPayrolls();
    }
  }

  markPaid(payroll: PayrollCalculation, isPaid: boolean) {
    const dialogRef = this.dialog.open(PayCalculationConfirmDialogComponent, {
      disableClose: true,
      position: { top: '20vh' },
    });
    dialogRef.afterClosed().subscribe((response) => {
      if (response) {
        this.payrollService.payrollSwitchPaid(payroll.id, isPaid).subscribe({
          next: (res) => {
            var index = this.prevPayrolls.findIndex((x) => x.id == payroll.id);
            this.prevPayrolls[index].isPaid = res.isPaid;
            this.prevPayrolls[index].paidBy = res.paidBy;
            this.prevPayrolls[index].paidDate = res.paidDate;
            this.prevPayrolls[index].paidById = res.paidById;
            if (isPaid) {
              this.toastrTranslateService.success('PAYROLL.PAID_SUCCESS');
            } else {
              this.toastrTranslateService.success('PAYROLL.UNPAID_SUCCESS');
            }
            this.dataSource.data = this.prevPayrolls;
          },
          error: (res) => {
            this.toastrTranslateService.error(res, 'PAYROLL.ERROR');
          },
        });
      }
    });
  }
  toBool(boolVariable: any) {
    if (boolVariable && boolVariable != 'undefined') {
      return Boolean(JSON.parse(boolVariable));
    }
    return false;
  }
  async onPreview(payroll: PayrollCalculation) {
    try {
      let data = await this.payrollService.getPdfForPayrollId(payroll.id);
      var file = new Blob([data], { type: 'application/pdf' });
      const dialogRef = this.dialog.open(PayrollPdfPreviewPopupComponent, {
        width: '1024px',
        data: file,
      });
    } catch (error) {
      this.toastrTranslateService.error(error, 'COMMON.ERROR_DATA');
    }
  }

  onDelete(payroll: PayrollCalculation) {
    const dialogRef = this.dialog.open(DeletePayrollConfirmPopupComponent, {
      width: '450px',
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        this.payrollService
          .deletePayrollCalculation(payroll.id)
          .subscribe((res) => {
            this.prevPayrolls = this.prevPayrolls.filter(
              (p) => p.id != payroll.id
            );
            this.toastrTranslateService.success('PAYROLL.PAYROLL_DELETED');
            this.dataSource.data = this.prevPayrolls;
          });
      }
    });
  }

  onComment(payroll) {
    const dialogRef = this.dialog.open(AddPayrollCommentComponent, {
      width: '450px',
      data: { comment: payroll.comment },
    });
    dialogRef.afterClosed().subscribe((comment) => {
      if (comment !== false) {
        this.payrollService
          .setPayrollCalculationComment(payroll.id, comment)
          .subscribe((res) => {
            payroll.comment = comment;
            this.toastrTranslateService.success('PAYROLL.COMMENT_SAVED');
          });
      }
    });
  }

  getCommentShort(comment: string): string {
    if (!comment) comment = '';
    if (comment.length <= 40) return comment;
    return comment.substring(0, 40) + '...';
  }
}
