import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Location } from '../_models/location/location';
import { Response } from '../_models/response';

@Injectable({ providedIn: 'root' })
export class LocationService {
  constructor(private http: HttpClient) {}

  getLocations(): Observable<Location[]> {
    return this.http
      .get<Response>(`${environment.apiUrl}/locations`)
      .pipe(map((response) => response.data as Location[]));
  }

  getLocation(id: number): Observable<Location> {
    return this.http
      .get<Response>(`${environment.apiUrl}/locations/${id}`)
      .pipe(map((response) => response.data as Location));
  }

  addLocation(location: Location) {
    return this.http
      .post<Response>(`${environment.apiUrl}/locations`, location)
      .pipe(map((response) => response.data as Location));
  }

  updateLocation(location: Location) {
    return this.http
      .put<Response>(`${environment.apiUrl}/locations`, location)
      .pipe(map((response) => response.data as Location));
  }

  deleteLocation(id: number) {
    return this.http.delete<Response>(`${environment.apiUrl}/locations/${id}`);
  }
}
