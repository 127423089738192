<div class="modal-dialog formInModal">
  <h2 class="title" class="titlePrintInvoice">
    {{ "PRINT_INVOICE_DIALOG.PRINT_INVOICE_TITLE" | translate }}
  </h2>

  <hr />
  <mat-dialog-content class="box">
    <div style="margin-bottom: 10px">
      <mat-slide-toggle color="primary" [(ngModel)]="isNoteIncluded">
        {{ "PRINT_INVOICE_DIALOG.INCLUDE_NOTES" | translate }}
      </mat-slide-toggle>
    </div>
    <div>
      <mat-slide-toggle
        color="primary"
        [(ngModel)]="isStatusChanged"
        [disabled]="!isStatusChangeReady"
      >
        {{ "PRINT_INVOICE_DIALOG.INVOICED_QUESTION" | translate }}
      </mat-slide-toggle>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions class="dialog-actions">
    <button mat-button class="btnCan" mat-dialog-close>
      {{ "PRINT_INVOICE_DIALOG.CANCEL" | translate }}
    </button>
    <button
      mat-flat-button
      class="btnDel"
      (click)="print()"
      [disabled]="isLoading"
    >
      {{ "PRINT_INVOICE_DIALOG.PRINT" | translate }}
    </button>
  </mat-dialog-actions>
</div>
