import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DriverService, ToastrTranslateService } from 'src/app/_services';
import { ComfirmDialogComponent } from '../../../trucks/edit-delete-truck/comfirm-dialog/comfirm-dialog.component';

@Component({
  selector: 'app-comfirm-dialog-driver',
  templateUrl: './comfirm-dialog-driver.component.html',
  styleUrls: ['./comfirm-dialog-driver.component.scss'],
})
export class ComfirmDialogDriverComponent {
  constructor(
    private driverService: DriverService,
    private dialogRef: MatDialogRef<ComfirmDialogComponent>,
    private toastrTranslateService: ToastrTranslateService,
    @Inject(MAT_DIALOG_DATA) private driverId: number
  ) {}

  @ViewChild('test') myDivElementRef: ElementRef;

  deleteDriver() {
    this.driverService.deleteDriver(this.driverId).subscribe({
      next: (response) => {
        if (response.success) {
          this.toastrTranslateService.success('CONFIRM_DIALOG.SUCCESS_DELL');
          this.dialogRef.close({ success: response.success });
        } else {
          this.toastrTranslateService.error(
            response.message,
            'CONFIRM_DIALOG.FAILED_DELL'
          );
        }
      },
      error: (response) => {
        this.toastrTranslateService.error(
          response,
          'CONFIRM_DIALOG.FAILED_DELL'
        );
      },
    });
  }
}
