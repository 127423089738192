import {
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import {
  debounceTime,
  distinctUntilChanged,
  startWith,
  switchMap,
  tap,
} from 'rxjs/operators';
import { CustomerModel } from 'src/app/_models/customer';
import { Location } from 'src/app/_models/location/location';
import { PrimaryContact } from 'src/app/_models/primaryContact';
import {
  CustomerService,
  MapService,
  ToastrTranslateService,
} from 'src/app/_services';

@Component({
  selector: 'app-add-customer',
  templateUrl: './add-customer.component.html',
  styleUrls: ['./add-customer.component.scss'],
})
export class AddCustomerComponent implements OnInit {
  @Output() customerChange = new EventEmitter<CustomerModel>();

  searchAddress = new UntypedFormControl();
  searchBillingAddress = new UntypedFormControl();
  searchAddressResult: Location[];
  searchBillingAddressResult: Location[];

  showAddAddressFlag = false;

  dataLoading = true;
  requiredAddress = true;
  isAddressPoBox: boolean = false;
  isBillingAddressPoBox: boolean = false;
  addresses;
  poBoxValue = '';
  poBoxBillingValue = '';
  @ViewChild('inputCustomerAddress') inputCustomerAddress: ElementRef;
  @ViewChild('inputBillingAddress') inputBillingAddress: ElementRef;

  customer: CustomerModel = new CustomerModel();

  constructor(
    private customerService: CustomerService,
    private toastrTranslateService: ToastrTranslateService,
    private mapService: MapService
  ) {}

  ngOnInit(): void {
    this.customer.isActive = true;
    this.customer.addresses = [];
    this.customer.primaryContact = new PrimaryContact();
    this.recalculateRequredAddress();
    this.searchAddress.valueChanges
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        startWith(''),
        tap(() => {
          this.searchAddressResult = [];
        }),
        switchMap((value) => {
          if (value != '') {
            return this.mapService.searchMap(value);
          } else {
            this.customer.addresses = null;
            return (this.searchAddressResult = []);
          }
        })
      )
      .subscribe((data) => {
        if (data && data.count != 0) {
          this.searchAddressResult = data.collection;
        } else {
          this.searchAddressResult = [];
        }
      });

    this.searchBillingAddress.valueChanges
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        startWith(''),
        tap(() => {
          this.searchBillingAddressResult = [];
        }),
        switchMap((value) => {
          if (value != '') {
            return this.mapService.searchMap(value);
          } else {
            this.customer.billingAddress = null;
            return (this.searchBillingAddressResult = []);
          }
        })
      )
      .subscribe((data) => {
        if (data && data.count != 0) {
          this.searchBillingAddressResult = data.collection;
        } else {
          this.searchBillingAddressResult = [];
        }
      });
  }

  cancelAddCustomer() {
    this.customerChange.emit(null);
  }

  checkBrokerFromMc(mc) {
    mc ? (this.customer.isBroker = true) : (this.customer.isBroker = false);
  }

  toBool(boolVariable: any): Boolean {
    if (boolVariable && boolVariable != 'undefined') {
      return Boolean(JSON.parse(boolVariable));
    }
    return false;
  }

  setBlackListValue(e) {
    if (e.checked) {
      this.customer.isBlacklisted = true;
    } else {
      this.customer.isBlacklisted = false;
    }
  }

  setBrokerValue(e) {
    if (e.checked) {
      this.customer.isBroker = true;
    } else {
      this.customer.isBroker = false;
    }
  }

  setActiveValue(e) {
    if (e.checked) {
      this.customer.isActive = true;
    } else {
      this.customer.isActive = false;
    }
  }

  selectAddress(location: Location) {
    if (!this.customer.addresses) {
      this.customer.addresses = [];
    }
    location.sequence = this.customer.addresses.length - 1;
    this.customer.addresses.push(location);
    this.requiredAddress = false;
    if (this.customer.isBillingSameAsAddress) {
      this.customer.billingAddress = this.customer.addresses[0];
    }
    this.showAddAddressFlag = false;
    this.searchAddress.setValue(null);
    this.poBoxValue = '';
  }

  displayFn(location: Location): string {
    return location ? location.address + ', ' + location.countryCode : '';
  }

  setCustomerBillingAddress(isBillingSameAsAddress: boolean) {
    if (isBillingSameAsAddress) {
      this.customer.billingAddress = this.customer.addresses[0];
      this.customer.billingAddressId = this.customer.billingAddress.id;
      this.customer.isBillingSameAsAddress = true;
      if (this.customer?.billingAddress?.poBox) {
        this.isBillingAddressPoBox = true;
        this.poBoxBillingValue = this.customer.billingAddress.poBox;
      }
    } else {
      this.customer.isBillingSameAsAddress = false;
    }
  }

  customerBillingAddressChange(e) {
    this.setCustomerBillingAddress(e.checked);
  }

  eventFromAddresses(data) {
    this.customer.addresses = data;
    this.setCustomerBillingAddress(this.customer.isBillingSameAsAddress);
    this.recalculateRequredAddress();
  }

  selectBillingAddress(location: Location) {
    if ((this.customer.billingAddress = location)) {
      this.customer.isBillingSameAsAddress = false;
    }
  }

  recalculateRequredAddress() {
    this.requiredAddress = this.customer.addresses.length == 0;
    if (this.customer?.billingAddress?.poBox) {
      this.isBillingAddressPoBox = true;
      this.poBoxBillingValue = this.customer.billingAddress.poBox;
    }
  }

  clearBillingAddress() {
    this.customer.billingAddress = new Location();
    this.customer.billingAddress.poBox = '';
    this.searchBillingAddress.setValue('');
  }

  autofillCustomerAddress() {
    setTimeout(() => {
      if (
        this.inputCustomerAddress?.nativeElement.value &&
        this.searchAddressResult.length > 0
      ) {
        this.selectAddress(this.searchAddressResult[0]);
      } else if (this.inputCustomerAddress?.nativeElement.value) {
        this.inputCustomerAddress.nativeElement.value = '';
        this.toastrTranslateService.warning('GENERAL.ADDRESS_ISSUE');
      }
    }, 1000);
  }

  addAddressManualy() {
    if (this.isAddressPoBox) {
      const location = new Location();
      location.poBox = this.poBoxValue;
      this.selectAddress(location);
      this.isAddressPoBox = false;
      this.recalculateRequredAddress();
    }
  }

  addBillingAddressManualy() {
    if (this.isBillingAddressPoBox) {
      this.clearBillingAddress();
      this.customer.billingAddress.poBox = this.poBoxBillingValue;
      this.customer.isBillingSameAsAddress = false;
    }
  }

  billingAddressIsPoBox(e) {
    this.customer.isBillingSameAsAddress = false;
  }

  autofillBillingAddress() {
    setTimeout(() => {
      let inputValue =
        this.inputBillingAddress.nativeElement.value !=
        this.customer.billingAddress.address +
          ', ' +
          this.customer.billingAddress.countryCode;
      if (!this.customer.billingAddress || inputValue) {
        if (this.searchBillingAddressResult.length > 0) {
          this.customer.billingAddress = this.searchBillingAddressResult[0];
          this.searchBillingAddress.setValue(this.customer.billingAddress);
          this.customer.isBillingSameAsAddress = false;
        } else if (inputValue && this.inputBillingAddress.nativeElement.value) {
          this.clearBillingAddress();
          this.toastrTranslateService.warning('GENERAL.ADDRESS_ISSUE');
        }
      }
    }, 1000);
  }

  save(isInvalid) {
    if (isInvalid || this.requiredAddress || !this.customer.billingAddress) {
      this.toastrTranslateService.warning('GENERAL.FORM_INVALID');
      return;
    }
    this.customerService.addCustomers(this.customer).subscribe({
      next: (response) => {
        this.customer = response;
        this.toastrTranslateService.success('CUSTOMER.SAVED');
        this.customerChange.emit(this.customer);
      },
      error: (response) => {
        this.toastrTranslateService.error(response, 'CUSTOMER.NOT_SAVED');
      },
    });
  }
}
