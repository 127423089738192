<div>
  <app-upload-logo
    *ngIf="mainLogo"
    [fileToShow]="mainLogo"
    (imageUpdated)="mainLogoUpdated($event)"
    [readOnly]="!userDataService.isAdminDispatcher"
  ></app-upload-logo>
</div>
<div class="center">
  <div class="companyName">
    <div>
      <mat-form-field
        floatLabel="auto"
        appearance="outline"
        class="companyWidth"
      >
        <mat-label>{{ "COMPANY.NAME" | translate }} </mat-label>
        <input
          matInput
          [(ngModel)]="tenant.name"
          name="companyName"
          autocomplete="off"
          [readonly]="!userDataService.isAdminDispatcher"
          required
        />
      </mat-form-field>
    </div>

    <mat-form-field
      floatLabel="auto"
      appearance="outline"
      class="full-width"
      id="keyPerson"
    >
      <mat-label>{{ "COMPANY.KEY_PERSON" | translate }}</mat-label>
      <input
        matInput
        [(ngModel)]="tenant.keyPerson"
        name="keyPerson"
        autocomplete="off"
        [readonly]="!userDataService.isAdminDispatcher"
      />
    </mat-form-field>

    <mat-form-field
      floatLabel="auto"
      appearance="outline"
      class="inputCompanyField"
      id="contactPhone"
    >
      <mat-label>{{ "COMPANY.CONTACT_PHONE" | translate }}</mat-label>
      <input
        matInput
        [(ngModel)]="tenant.contactPhone"
        name="contactPhone"
        autocomplete="off"
        [readonly]="!userDataService.isAdminDispatcher"
      />
    </mat-form-field>

    <div>
      <mat-slide-toggle
        color="primary"
        [(ngModel)]="isAddressPoBox"
        class="poBoxToggle"
        (change)="clearAddress()"
        [disabled]="!userDataService.isAdminDispatcher"
        >{{ "COMPANY.PO_BOX_IS_ADDRESS" | translate }}</mat-slide-toggle
      >

      <mat-form-field
        floatLabel="auto"
        appearance="outline"
        class="full-width"
        id="address"
        *ngIf="!isAddressPoBox"
      >
        <mat-label>{{ "COMPANY.ADDRESSES" | translate }}</mat-label>
        <input
          matInput
          #inputTenantAddress
          [matAutocomplete]="company_address"
          [formControl]="searchAddress"
          (blur)="autofillTenantAddress()"
          (input)="onAddressChange($event.target.value)"
          name="addresses"
          autocomplete="off"
          [required]="!isAddressPoBox"
          [readonly]="!userDataService.isAdminDispatcher"
        />
        <mat-icon matSuffix class="icon_search">search</mat-icon>
        <mat-autocomplete
          #company_address="matAutocomplete"
          [displayWith]="displayLoc"
          (optionSelected)="selectCompanyLocation($event.option.value)"
        >
          <ng-container *ngIf="searchResult.length > 0">
            <mat-option
              *ngFor="let location of searchResult"
              [value]="location"
            >
              <span>{{ location.address + ", " + location.countryCode }}</span>
            </mat-option>
          </ng-container>
        </mat-autocomplete>
      </mat-form-field>

      <mat-form-field
        floatLabel="auto"
        appearance="outline"
        class="inputPObox"
        id="poBox"
        *ngIf="isAddressPoBox"
      >
        <mat-label>{{ "COMPANY.PO_BOX" | translate }}</mat-label>
        <input
          matInput
          name="poBox"
          [required]="isAddressPoBox"
          [(ngModel)]="tenant.addresses[0].poBox"
          autocomplete="off"
          [readonly]="!userDataService.isAdminDispatcher"
        />
      </mat-form-field>
    </div>
  </div>

  <div>
    <mat-form-field floatLabel="auto" appearance="outline" class="full-width">
      <mat-label>{{ "COMPANY.CONTACT_EMAIL" | translate }}</mat-label>
      <input
        matInput
        [(ngModel)]="tenant.contactEmail"
        name="contactEmail"
        autocomplete="off"
        [readonly]="!userDataService.isAdminDispatcher"
      />
    </mat-form-field>

    <mat-form-field
      floatLabel="auto"
      appearance="outline"
      class="inputCompanyField"
      id="fax"
    >
      <mat-label>{{ "COMPANY.FAX" | translate }}</mat-label>
      <input
        matInput
        [(ngModel)]="tenant.fax"
        name="fax"
        autocomplete="off"
        [readonly]="!userDataService.isAdminDispatcher"
      />
    </mat-form-field>

    <div>
      <mat-form-field floatLabel="auto" appearance="outline" class="otherMail">
        <mat-label>{{ "COMPANY.ADDITIONAL_EMAIL_1" | translate }}</mat-label>
        <input
          matInput
          name="otherEmail1"
          [(ngModel)]="tenant.otherEmail1"
          autocomplete="off"
          [readonly]="!userDataService.isAdminDispatcher"
        />
      </mat-form-field>
      <mat-form-field
        floatLabel="auto"
        appearance="outline"
        class="otherMail"
        id="otherMail"
      >
        <mat-label>{{ "COMPANY.ADDITIONAL_EMAIL_2" | translate }}</mat-label>
        <input
          matInput
          [(ngModel)]="tenant.otherEmail2"
          name="otherEmail2"
          autocomplete="off"
          [readonly]="!userDataService.isAdminDispatcher"
        />
      </mat-form-field>
    </div>

    <div>
      <mat-form-field
        floatLabel="auto"
        appearance="outline"
        class="inputCompanyField"
      >
        <mat-label>{{ "COMPANY.MC_NUMBER" | translate }}</mat-label>
        <input
          matInput
          [(ngModel)]="tenant.mcNumber"
          name="mcnumber"
          autocomplete="off"
          required
          [readonly]="!userDataService.isAdminDispatcher"
        />
      </mat-form-field>
      <mat-form-field
        floatLabel="auto"
        appearance="outline"
        class="inputCompanyField"
        id="ditnumber"
      >
        <mat-label>{{ "COMPANY.DOT_NUMBER" | translate }}</mat-label>
        <input
          matInput
          [(ngModel)]="tenant.dotNumber"
          name="dotnumber"
          autocomplete="off"
          [readonly]="!userDataService.isAdminDispatcher"
        />
      </mat-form-field>

      <mat-form-field
        floatLabel="auto"
        appearance="outline"
        class="inputCompanyField"
        id="ein"
      >
        <mat-label>{{ "COMPANY.EIN" | translate }}</mat-label>
        <input
          matInput
          [(ngModel)]="tenant.ein"
          name="ein"
          autocomplete="off"
          [readonly]="!userDataService.isAdminDispatcher"
        />
      </mat-form-field>
    </div>

    <div>
      <mat-form-field
        floatLabel="auto"
        hideRequiredMarker
        appearance="outline"
        class="full-width"
      >
        <mat-label class="global_search"
          >{{ "COMPANY.TIMEZONE" | translate }}
        </mat-label>

        <mat-select
          name="timezone"
          [(ngModel)]="tenant.timeZone"
          class="global_search"
          placeholder="{{ 'COMPANY.TIMEZONE' | translate }}"
          [compareWith]="compareObjects"
          (selectionChange)="openDialogChangeTimezone()"
          [disabled]="!userDataService.isAdminDispatcher"
        >
          <ng-container *ngIf="timezoneList.length > 0">
            <mat-option
              *ngFor="let timezone of timezoneList"
              [value]="timezone"
            >
              {{ timezone.name }}
            </mat-option>
          </ng-container>
        </mat-select>
      </mat-form-field>

      <!-- <mat-form-field
        floatLabel="auto"
        appearance="outline"
        class="otherMail"
        id="loadNumberFormat"
        matTooltip="{{ 'COMPANY.TOOLTIP_LOAD_NUM_FORMAT' | translate }}"
        matTooltipPosition="right"
      >
        <mat-label>{{ "COMPANY.LOAD_NUM_FORMAT" | translate }}</mat-label>
        <input
          matInput
          [(ngModel)]="tenant.loadNumberFormat"
          disabled="tenant.loadNumberFormat"
          name="loadNumberFormat"
          autocomplete="off"
        />
      </mat-form-field> -->
      <mat-form-field
        floatLabel="auto"
        appearance="outline"
        class="inputCompanyField"
        id="due"
      >
        <mat-label> {{ "COMPANY.DUE_DAYS" | translate }}</mat-label>
        <input
          matInput
          required
          onfocus="this.select()"
          [(ngModel)]="tenant.invoiceDueDays"
          name="invoiceDueDays"
          autocomplete="off"
          type="number"
          [readonly]="!userDataService.isAdminDispatcher"
        />
      </mat-form-field>
    </div>
  </div>

  <div>
    <mat-form-field
      floatLabel="auto"
      appearance="outline"
      class="standardPaymentTerms"
    >
      <mat-label>{{ "COMPANY.PAYMENT_TERMS" | translate }}</mat-label>
      <textarea
        matInput
        [(ngModel)]="tenant.paymentTerms"
        placeholder="{{ 'COMPANY.PAYMENT_TERMS' | translate }}"
        [readonly]="!userDataService.isAdminDispatcher"
      ></textarea>
    </mat-form-field>
  </div>

  <div>
    <mat-form-field floatLabel="auto" appearance="outline" class="invoice_note">
      <mat-label>{{ "COMPANY.INVOICE_NOTE" | translate }}</mat-label>
      <textarea
        matInput
        class="noteTextarea"
        [(ngModel)]="tenant.invoiceNote"
        placeholder="{{ 'COMPANY.NOTE' | translate }}"
        rows="5"
        [readonly]="!userDataService.isAdminDispatcher"
      ></textarea>
    </mat-form-field>
  </div>

  <div class="action-div">
    <button
      *ngIf="userDataService.isAdminDispatcher"
      mat-flat-button
      class="buttonSave"
      [disabled]="
        !tenant.name ||
        !tenant.mcNumber ||
        (!isAddressPoBox && tenant.addresses.length == 0) ||
        (!isAddressPoBox &&
          tenant.addresses.length > 0 &&
          !tenant.addresses[0].state) ||
        (isAddressPoBox && !tenant.addresses[0].poBox)
      "
      (click)="save()"
    >
      <span> {{ "COMPANY.SAVE" | translate }} </span>
    </button>
  </div>
</div>
