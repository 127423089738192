import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommentModel } from 'src/app/_models/comments';
import { LoadModel } from 'src/app/_models/load/load';

@Component({
  selector: 'app-loads-comment',
  templateUrl: 'loads-comment.component.html',
  styleUrls: ['./loads-comment.component.scss'],
})
export class LoadsCommentComponent implements OnInit {
  @Input() loads: LoadModel;
  loadNumber: string;

  commentList: CommentModel[];
  constructor(@Inject(MAT_DIALOG_DATA) private data: any) {}

  ngOnInit(): void {
    this.commentList = this.data.commentList;
    this.loadNumber = this.data.loadNumber;
  }
}
