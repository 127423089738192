<app-main-nav>
  <router-outlet></router-outlet>
</app-main-nav>
<ngx-spinner
  bdColor="rgba(0,0,0,0)"
  size="default"
  color="#a3a0fb"
  type="ball-spin-clockwise"
  [fullScreen]="false"
></ngx-spinner>
