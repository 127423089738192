import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import Common from '../_helpers/common';
import { AnalyticsCustomerPage } from '../_models/analytics/analytics.customer.page';
import { AnalyticsTruckPage } from '../_models/analytics/analytics.truck.page';
import { Paginator } from '../_models/common/paginator';
import { Response } from '../_models/response';
import { TenantService } from './tenant.service';

@Injectable({ providedIn: 'root' })
export class AnalyticsService {
  constructor(private http: HttpClient, private tenantService: TenantService) {}

  getTruckData(dateFrom: Date, dateTo: Date): Observable<AnalyticsTruckPage> {
    const params = new HttpParams({
      fromObject: {
        dateFrom: this.tenantService.getTenetUtcDateString(dateFrom),
        dateTo: this.tenantService.getTenetUtcDateString(
          Common.getDateEndOfDay(new Date(dateTo))
        ),
        PageSize: 9999,
      },
    });
    return this.http
      .get<Response>(`${environment.apiUrl}/analytics/truck-data`, {
        params: params,
      })
      .pipe(map((response) => response.data as AnalyticsTruckPage));
  }

  getCustomerData(
    dateFrom: Date,
    dateTo: Date,
    paginator: Paginator
  ): Observable<AnalyticsCustomerPage> {
    const params = new HttpParams({
      fromObject: {
        PageSize: '' + paginator.pageSize,
        PageNumber: '' + paginator.pageNumber,
        SortBy: '' + paginator.sortBy,
        SortOrder: '' + paginator.sortOrder,
        CustomerName: '' + paginator.searchTerm,
        DateFrom: this.tenantService.getTenetUtcDateString(dateFrom),
        DateTo: this.tenantService.getTenetUtcDateString(
          Common.getDateEndOfDay(new Date(dateTo))
        ),
      },
    });
    return this.http
      .get<Response>(`${environment.apiUrl}/analytics/customer-data`, {
        params: params,
      })
      .pipe(map((response) => response.data as AnalyticsCustomerPage));
  }
}
