import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  first,
  startWith,
  switchMap,
  tap,
} from 'rxjs/operators';
import { LoadModel } from 'src/app/_models/load/load';
import { Location } from 'src/app/_models/location/location';
import { LocationService, MapService } from 'src/app/_services';

@Component({
  selector: 'app-stop-address',
  templateUrl: './stop-address.component.html',
  styleUrls: ['./stop-address.component.scss'],
})
export class StopAddressComponent implements OnInit {
  @Output() stopAddressAdded = new EventEmitter<Location>();

  searchTerm = new UntypedFormControl();
  filteredLocations: Observable<any[]>;
  locationsData: Location[] = [];
  searchResult: Location[];
  searchAddress = new UntypedFormControl();
  loadsData: LoadModel;
  myControl = new UntypedFormControl();
  addressList: Location[];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<StopAddressComponent>,
    private formBuilder: UntypedFormBuilder,
    private mapService: MapService,
    private locationService: LocationService
  ) {}

  ngOnInit(): void {
    this.addressList = this.data.addressList;
    this.searchAddress.valueChanges
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        startWith(''),
        tap(() => {
          this.searchResult = [];
        }),
        switchMap((value) => {
          if (value != '') {
            return this.mapService.searchMap(value);
          } else {
            return (this.searchResult = []);
          }
        })
      )
      .subscribe((data) => {
        if (data && data.count != 0) {
          this.searchResult = data.collection;
        } else {
          this.searchResult = [];
        }
      });
    this.locationService
      .getLocations()
      .pipe(first())
      .subscribe((response) => {
        this.locationsData = response;
      });
  }

  stopFormGroup = this.formBuilder.group({
    address: ['', Validators.required],
  });

  onStopAddressAdded(stopAddress: Location) {
    stopAddress.sequence = this.addressList.length + 1;
    this.addressList.push(stopAddress);
  }

  displayFn(location: Location): string {
    return location
      ? location.address + ', ' + location.city + ', ' + location.country
      : '';
  }

  onAddItem() {
    const newStopAddress = this.stopFormGroup.value.location;

    this.stopAddressAdded.emit(newStopAddress);
    this.stopFormGroup.reset();
  }
  closeDialog(): void {
    this.dialogRef.close();
  }
}
