import { CodeModel } from '../code';
import { DriverModel } from '../driver/driver';
import { TruckModel } from '../truck';
import { VendorModel } from '../vendor';

export class AccountingModel {
  id: number;
  accountingType = new CodeModel();
  accountingStatus = new CodeModel();
  accountingCategory = new CodeModel();
  driver: DriverModel;
  truck: TruckModel;
  date: Date;
  vendor = new VendorModel();
  description: string;
  amount: number;
  comment: string;
}
