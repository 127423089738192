import { Injectable } from '@angular/core';
import { TruckModel } from 'src/app/_models/truck';
import { TenantService } from '../tenant.service';

@Injectable({
  providedIn: 'root',
})
export class TruckFactory {
  constructor(private tenantService: TenantService) {}

  public getTruckUtcDate(truck: TruckModel): TruckModel {
    truck.annualInspectionExpirationDate = this.tenantService.getTenetUtcDate(
      truck.annualInspectionExpirationDate
    );
    truck.quarterlyInspectionDue = this.tenantService.getTenetUtcDate(
      truck.quarterlyInspectionDue
    );
    truck.registrationExpirationDate = this.tenantService.getTenetUtcDate(
      truck.registrationExpirationDate
    );
    return truck;
  }

  public getTruckTimeZoneDate(truck: TruckModel): TruckModel {
    truck.annualInspectionExpirationDate =
      this.tenantService.getTenantTimeZoneDate(
        truck.annualInspectionExpirationDate
      );
    truck.quarterlyInspectionDue = this.tenantService.getTenantTimeZoneDate(
      truck.quarterlyInspectionDue
    );
    truck.registrationExpirationDate = this.tenantService.getTenantTimeZoneDate(
      truck.registrationExpirationDate
    );

    return truck;
  }
}
