<div class="search-form">
  <div>
    <button mat-icon-button class="refresh" (click)="refresh()">
      <mat-icon
        matTooltip="{{ 'MANAGE.REFRESH' | translate }}"
        matTooltipPosition="below"
        >refresh</mat-icon
      >
    </button>
    <mat-form-field class="datepay-form-field" appearance="fill">
      <mat-label>{{ "PAYROLL.SELECT_DATE" | translate }}</mat-label>
      <mat-date-range-input
        [formGroup]="periodForm"
        [rangePicker]="campaignOnePicker"
        [comparisonStart]="periodForm.value.start"
        [comparisonEnd]="periodForm.value.end"
      >
        <input
          (dateInput)="onDateChange($event)"
          matStartDate
          placeholder="Start date"
          formControlName="start"
        />
        <input
          (dateInput)="onDateChange($event)"
          matEndDate
          placeholder="End date"
          formControlName="end"
        />
      </mat-date-range-input>
      <mat-datepicker-toggle
        color="primary"
        matSuffix
        [for]="campaignOnePicker"
      ></mat-datepicker-toggle>
      <mat-date-range-picker #campaignOnePicker></mat-date-range-picker>
    </mat-form-field>
  </div>
</div>
<div class="tableAndSum">
  <table
    mat-table
    matSort
    matSortDisableClear
    [dataSource]="dataSource"
    class="mat-elevation-z1 prev-payrolls-table"
  >
    <ng-container matColumnDef="driverName">
      <th mat-header-cell *matHeaderCellDef>
        {{ "PAYROLL.DRIVER" | translate }}
      </th>
      <td mat-cell *matCellDef="let payroll">
        {{ driver.firstname + " " + driver.lastname }}
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>
    <ng-container matColumnDef="truckNumber">
      <th mat-header-cell *matHeaderCellDef>
        {{ "PAYROLL.TRUCK1" | translate }}
      </th>
      <td mat-cell *matCellDef="let payroll">
        {{ truck.truckNumber }}
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>
    <ng-container matColumnDef="dateFrom">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>
        {{ "PAYROLL.PAYROLL_DATE_FROM" | translate }}
      </th>
      <td mat-cell *matCellDef="let payroll">
        {{ payroll.dateFrom | tenantDate | date : "MM/dd/y " }}
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>
    <ng-container matColumnDef="dateTo">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>
        {{ "PAYROLL.PAYROLL_DATE_TO" | translate }}
      </th>
      <td mat-cell *matCellDef="let payroll">
        {{ payroll.dateTo | tenantDate | date : "MM/dd/y " }}
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>
    <ng-container matColumnDef="total">
      <th
        mat-header-cell
        mat-sort-header
        class="amountHeader"
        *matHeaderCellDef
      >
        {{ "PAYROLL.PAYROLL_AMOUNT" | translate }}
      </th>
      <td mat-cell *matCellDef="let payroll" class="alignRight">
        {{ payroll.total | currency }}
      </td>
      <td mat-footer-cell *matFooterCellDef class="alignRight">
        {{ sum | currency }}
      </td>
    </ng-container>
    <ng-container matColumnDef="paid">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>
        {{ "PAYROLL.PAID" | translate }}
      </th>
      <td mat-cell *matCellDef="let payroll">
        <mat-icon *ngIf="toBool(payroll.isPaid)" color="primary"
          >check</mat-icon
        >
        <mat-icon *ngIf="!toBool(payroll.isPaid)" color="warn">close</mat-icon>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>
    <ng-container matColumnDef="paidBy">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>
        {{ "PAYROLL.PAID_BY" | translate }}
      </th>
      <td mat-cell *matCellDef="let payroll">
        {{ payroll.paidBy?.firstname }} {{ payroll.paidBy?.lastname }}
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>
    <ng-container matColumnDef="reportPreview">
      <th mat-header-cell *matHeaderCellDef class="alignCenter">
        {{ "PAYROLL.PREVIEW1" | translate }}
      </th>
      <td mat-cell *matCellDef="let payroll" class="alignCenter">
        <button
          (click)="onPreview(payroll)"
          matTooltip="Preview"
          class="commentIcon"
          mat-icon-button
          aria-label="Preview"
        >
          <mat-icon>remove_red_eye</mat-icon>
        </button>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>
    <ng-container matColumnDef="markAsPaid">
      <th mat-header-cell *matHeaderCellDef class="alignCenter">
        {{ "PAYROLL.ACTION" | translate }}
      </th>
      <td mat-cell *matCellDef="let payroll" class="alignCenter">
        <button [matMenuTriggerFor]="menu" mat-icon-button>
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button
            mat-menu-item
            *ngIf="!payroll.isPaid"
            (click)="markPaid(payroll, true)"
          >
            {{ "PAYROLL.MARK_PAID" | translate }}
          </button>
          <button
            mat-menu-item
            *ngIf="payroll.isPaid"
            (click)="markPaid(payroll, false)"
          >
            {{ "PAYROLL.MARK_UNPAID" | translate }}
          </button>
          <button
            mat-menu-item
            [disabled]="payroll.isPaid"
            (click)="onDelete(payroll)"
            class="delete-payroll"
          >
            {{ "PAYROLL.DELETE" | translate }}
          </button>
        </mat-menu>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="comment">
      <th mat-header-cell *matHeaderCellDef class="alignCenter">
        {{ "PAYROLL.COMMENT1" | translate }}
      </th>
      <td mat-cell *matCellDef="let payroll" class="alignCenter">
        <span
          (click)="onComment(payroll)"
          class="material-icons commentIcon"
          aria-label="Comment"
          [matTooltip]="payroll.comment"
          matTooltipPosition="before"
          [matBadge]="1"
          [matBadgeHidden]="!payroll.comment"
          matBadgeColor="primary"
          matBadgeSize="small"
          matBadgePosition="above after"
        >
          <mat-icon class="iconCommentPadding">comment</mat-icon>
        </span>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="star" class="more_vert">
      <th mat-header-cell *matHeaderCellDef class="more_vert"></th>
      <td mat-cell *matCellDef="let payroll" class="more_vert">
        <button mat-icon-button [matMenuTriggerFor]="menu" class="more_vert">
          <mat-icon class="more_vert"
            >more_vert
            <mat-menu #menu="matMenu">
              <button
                *ngIf="!payroll.isPaid"
                (click)="markPaid(payroll, true)"
                mat-flat-button
                class="btnPay"
              >
                {{ "PAYROLL.PAID" | translate }}
              </button>
              <button
                *ngIf="payroll.isPaid"
                (click)="markPaid(payroll, false)"
                mat-flat-button
                class="btnPay"
              >
                {{ "PAYROLL.UNPAID" | translate }}
              </button>
              <button
                (click)="onPreview(payroll)"
                matTooltip="Preview"
                class="commentIcon"
                mat-icon-button
                aria-label="Preview"
              >
                <mat-icon>remove_red_eye</mat-icon>
              </button>
              <button
                [disabled]="payroll.isPaid"
                (click)="onDelete(payroll)"
                mat-flat-button
                class="btnPayDel"
                color="warn"
              >
                {{ "PAYROLL.DELETE" | translate }}
              </button>
              <span
                class="material-icons"
                (click)="onComment(payroll)"
                matBadgeColor="primary"
                matBadge="1"
                [matTooltip]="'PAYROLL.COMMENT' | translate"
                >comment</span
              >

              <span class="spaceNote">{{
                getCommentShort(payroll.comment)
              }}</span>
            </mat-menu>
          </mat-icon>
        </button>
      </td>
      <td mat-footer-cell *matFooterCellDef class="more_vert"></td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr
      mat-row
      *matRowDef="let load; columns: displayedColumns"
      class="link"
    ></tr>
    <tr
      mat-footer-row
      *matFooterRowDef="displayedColumns"
      class="table-footer"
    ></tr>
  </table>
</div>
