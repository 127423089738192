import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (checkNewVersion() && environment.clearLocalStorage?.length > 0) {
  environment.clearLocalStorage.forEach((key) => {
    clearLocalStorageItem(key);
  });
}

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));

function checkNewVersion(): boolean {
  if (environment.appVersion != localStorage.getItem('appVersion')) {
    localStorage.setItem('appVersion', environment.appVersion);
    return true;
  } else {
    return false;
  }
}

function clearLocalStorageItem(key) {
  Object.keys(localStorage)
    .filter((x) => x.includes(key))
    .forEach((x) => localStorage.removeItem(x));
}
