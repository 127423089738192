<div class="wrapperTable" id="wrapperManageTables">
  <div class="addNew">
    <button mat-icon-button class="refresh" (click)="refresh()">
      <mat-icon
        matTooltip="{{ 'MANAGE.REFRESH' | translate }}"
        matTooltipPosition="below"
        >refresh</mat-icon
      >
    </button>
    <button
      *ngIf="userDataService.isAdminDispatcher"
      mat-flat-button
      class="btnDialog"
      (click)="openDialogEditAdmin(false)"
    >
      {{ "ADMIN.ADD_ADMIN_BTN" | translate }}
    </button>
    <mat-form-field class="filterField">
      <input
        matInput
        (keyup)="applyFilter($event.target.value)"
        placeholder="{{ 'ADMIN.FILTER' | translate }}"
        class="filterInput"
        [value]="searchTerm"
        autocomplete="off"
      />
      <mat-icon matPrefix class="filterIcon">search</mat-icon>
    </mat-form-field>
    <mat-slide-toggle
      [(ngModel)]="showInactive"
      (change)="refresh()"
      color="primary"
      class="active"
      matTooltip="{{ 'ADMIN.SHOW_INACTIVE' | translate }}"
      matTooltipPosition="after"
    ></mat-slide-toggle>
  </div>

  <table
    mat-table
    matSort
    matSortDisableClear
    [dataSource]="dataSource"
    class="mat-elevation-z8"
    id="mat-table"
  >
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef>
        {{ "ADMIN.POSITION" | translate }}
      </th>
      <td mat-cell *matCellDef="let admin" class="displayNone">
        {{ admin.id }}
      </td>
      <td mat-footer-cell *matFooterCellDef class="displayNone"></td>
    </ng-container>

    <ng-container matColumnDef="adminName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ "ADMIN.ADMIN_NAME" | translate }}
      </th>
      <td
        mat-cell
        *matCellDef="let admin"
        (click)="openDialogEditAdmin(true, admin.id)"
        class="text-truncate"
      >
        <label
          *ngIf="admin.firstname + admin.lastname"
          matTooltip="{{ admin.firstname + ' ' + admin.lastname }}"
          >{{ admin.firstname + " " + admin.lastname }}</label
        >
      </td>
      <td mat-footer-cell *matFooterCellDef>
        {{ "ADMIN.COUNT" | translate }} {{ getCountOfElement(type) }}
      </td>
    </ng-container>

    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ "ADMIN.EMAIL" | translate }}
      </th>
      <td
        mat-cell
        *matCellDef="let admin"
        (click)="openDialogEditAdmin(true, admin.id)"
        class="text-truncate"
      >
        <label matTooltip="{{ admin.email }}">{{ admin.email }}</label>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="location">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ "ADMIN.ADDRESS" | translate }}
      </th>
      <td
        mat-cell
        *matCellDef="let admin"
        (click)="openDialogEditAdmin(true, admin.id)"
        class="text-truncate"
      >
        <label
          *ngIf="admin.location"
          matTooltip="{{
            admin.location.address + ', ' + admin.location.countryCode
          }}"
        >
          <label *ngIf="admin.location.city">{{
            admin.location.city + ", " + admin.location.state
          }}</label>
          <label *ngIf="!admin.location.city">{{
            admin.location?.address
          }}</label>
        </label>
        <label
          *ngIf="admin?.location?.poBox"
          matTooltip="{{ admin.location.poBox }}"
          >{{ admin.location.poBox }}
        </label>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="phoneNumber">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ "ADMIN.PHONE_NUMBER" | translate }}
      </th>
      <td
        mat-cell
        *matCellDef="let admin"
        (click)="openDialogEditAdmin(true, admin.id)"
        class="text-truncate"
      >
        <label matTooltip="{{ admin.phoneNumber }}">{{
          admin.phoneNumber
        }}</label>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="isActive">
      <th mat-header-cell *matHeaderCellDef>
        {{ "ADMIN.ACTIVE" | translate }}
      </th>
      <td
        mat-cell
        *matCellDef="let admin"
        (click)="openDialogEditAdmin(true, admin.id)"
      >
        <mat-icon *ngIf="toBool(admin.isActive)" color="primary"
          >check</mat-icon
        >
        <mat-icon *ngIf="!toBool(admin.isActive)" color="warn">close</mat-icon>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef class="alignCenter">
        {{ "PAYROLL.ACTION" | translate }}
      </th>
      <td mat-cell *matCellDef="let admin" class="alignCenter">
        <button
          *ngIf="userDataService.isAdminDispatcher"
          mat-button
          class="edit"
          (click)="openDialogEditAdmin(false, admin.id)"
        >
          {{ "ADMIN.EDIT" | translate }}
        </button>
        <button [matMenuTriggerFor]="menu" mat-icon-button class="icon-color">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item (click)="openDialogEditAdmin(true, admin.id)">
            {{ "ADMIN.VIEW" | translate }}
          </button>
          <button
            *ngIf="userDataService.isAdminDispatcher"
            mat-menu-item
            (click)="openDialogEditAdmin(false, admin.id)"
          >
            {{ "ADMIN.EDIT" | translate }}
          </button>

          <button
            *ngIf="userDataService.isAdminDispatcher"
            mat-menu-item
            (click)="openDialogChangePass(admin.id, admin.accountActivated)"
          >
            {{ "ADMIN.CHANGE_PASS" | translate }}
          </button>

          <button
            *ngIf="userDataService.isAdminDispatcher"
            mat-menu-item
            class="delete-button"
            (click)="openDialogComfirmDeleteAdmin(admin.id)"
          >
            {{ "ADMIN.DELETE" | translate }}
          </button>
        </mat-menu>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: displayedColumns"
      class="rowCursorPointer"
    ></tr>
    <tr
      mat-footer-row
      *matFooterRowDef="displayedColumns"
      class="table-footer"
    ></tr>
  </table>
</div>
