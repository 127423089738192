import { Injectable } from '@angular/core';
import { EmploymentHistory } from 'src/app/_models/driver/employmentHistory';
import { TenantService } from '../tenant.service';

@Injectable({
  providedIn: 'root',
})
export class EmploymentHistoryFactory {
  constructor(private tenantService: TenantService) {}

  public getDriverEmploymentHistoryUtcDate(
    employmentHistory: EmploymentHistory
  ): EmploymentHistory {
    employmentHistory.startDate = this.tenantService.getTenetUtcDate(
      employmentHistory.startDate
    );
    employmentHistory.endingDate = this.tenantService.getTenetUtcDate(
      employmentHistory.endingDate
    );
    employmentHistory.endingDate = this.tenantService.getTenetUtcDate(
      employmentHistory.endingDate
    );
    return employmentHistory;
  }

  public getDriverEmploymentHistoryTimeZoneDate(
    employmentHistory: EmploymentHistory
  ): EmploymentHistory {
    employmentHistory.startDate = this.tenantService.getTenantTimeZoneDate(
      employmentHistory.startDate
    );
    employmentHistory.endingDate = this.tenantService.getTenantTimeZoneDate(
      employmentHistory.endingDate
    );
    employmentHistory.endingDate = this.tenantService.getTenantTimeZoneDate(
      employmentHistory.endingDate
    );
    return employmentHistory;
  }
}
