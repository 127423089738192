<div class="modal-dialog formInModal">
  <div *ngIf="!dataLoading">
    <h2 *ngIf="customer && !customer.id" mat-dialog-title class="text f-16">
      {{ "CUSTOMER.ADD_CUSTOMER" | translate }}
    </h2>
    <h2 *ngIf="customer.id" mat-dialog-title class="mb-0">
      <h2 class="text f-16" [hidden]="!readOnly">
        <div class="text f-16">
          {{ "CUSTOMER.PREVIEW_CUSTOMER" | translate }}
        </div>
        <div>
          {{ customer.name }}
        </div>
      </h2>
      <h2 class="text f-16" [hidden]="readOnly">
        <div class="text f-16">
          {{ "CUSTOMER.EDIT_CUSTOMER" | translate }}
        </div>
        <div>
          {{ customer.name }}
        </div>
      </h2>
    </h2>
  </div>
  <hr />
  <form #editCustomer="ngForm">
    <mat-dialog-content class="box">
      <div class="inlineBlock">
        <mat-form-field
          floatLabel="auto"
          hideRequiredMarker
          appearance="outline"
          class="example-full-width"
        >
          <mat-label class="global_search"
            >{{ "CUSTOMER.CUSTOMER_NAME" | translate
            }}<span class="star">*</span></mat-label
          >
          <input
            matInput
            [(ngModel)]="customer.name"
            required
            [readonly]="readOnly"
            autocomplete="off"
            name="customerName"
          />
        </mat-form-field>

        <br />

        <mat-form-field
          floatLabel="auto"
          hideRequiredMarker
          appearance="outline"
          class="formWidth"
        >
          <mat-label class="global_search">{{
            "CUSTOMER.PHONE_NUMBER" | translate
          }}</mat-label>
          <input
            matInput
            [(ngModel)]="customer.phoneNumber"
            [readonly]="readOnly"
            autocomplete="off"
            name="phoneNumber"
            pattern="[- +()0-9]+"
            maxlength="18"
          />
        </mat-form-field>

        <mat-form-field
          floatLabel="auto"
          hideRequiredMarker
          appearance="outline"
          class="formWidth"
        >
          <mat-label class="global_search">{{
            "CUSTOMER.EXT_NUMBER" | translate
          }}</mat-label>
          <input
            matInput
            [(ngModel)]="customer.ext"
            [readonly]="readOnly"
            autocomplete="off"
            name="ext"
          />
        </mat-form-field>
        <br />
        <mat-form-field
          floatLabel="auto"
          hideRequiredMarker
          appearance="outline"
          class="formWidth"
        >
          <mat-label class="global_search">{{
            "CUSTOMER.OTHER_PHONE" | translate
          }}</mat-label>
          <input
            matInput
            [(ngModel)]="customer.otherNumber"
            autocomplete="off"
            [readonly]="readOnly"
            autocomplete="off"
            name="otherPhone"
            pattern="[- +()0-9]+"
            maxlength="18"
          />
        </mat-form-field>

        <mat-form-field
          floatLabel="auto"
          hideRequiredMarker
          appearance="outline"
          class="formWidth"
        >
          <mat-label class="global_search">{{
            "CUSTOMER.EXT_OTHER_NUMBER" | translate
          }}</mat-label>
          <input
            matInput
            [(ngModel)]="customer.otherPhoneExt"
            [readonly]="readOnly"
            autocomplete="off"
            name="otherPhoneExt"
          />
        </mat-form-field>

        <br />

        <mat-form-field
          floatLabel="auto"
          hideRequiredMarker
          appearance="outline"
          class="formWidth"
        >
          <mat-label class="global_search">{{
            "CUSTOMER.EMAIL" | translate
          }}</mat-label>
          <input
            matInput
            email
            pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}?"
            type="email"
            name="email"
            [(ngModel)]="customer.email"
            autocomplete="off"
            [readonly]="readOnly"
            autocomplete="off"
          />
        </mat-form-field>

        <mat-form-field
          floatLabel="auto"
          hideRequiredMarker
          appearance="outline"
          class="formWidth"
        >
          <mat-label class="global_search">{{
            "CUSTOMER.BILLING_EMAIL" | translate
          }}</mat-label>
          <input
            matInput
            email
            type="email"
            pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}?"
            [(ngModel)]="customer.billingEmail"
            autocomplete="off"
            [readonly]="readOnly"
            autocomplete="off"
            name="billingEmail"
          />
        </mat-form-field>
        <br />

        <mat-form-field
          floatLabel="auto"
          hideRequiredMarker
          appearance="outline"
          class="formWidth"
        >
          <mat-label class="global_search">{{
            "CUSTOMER.MC" | translate
          }}</mat-label>
          <input
            matInput
            #mc
            [(ngModel)]="customer.mc"
            (blur)="checkBrokerFromMc(mc.value)"
            [readonly]="readOnly"
            autocomplete="off"
            name="mc"
          />
        </mat-form-field>

        <mat-form-field
          floatLabel="auto"
          hideRequiredMarker
          appearance="outline"
          class="formWidth"
        >
          <mat-label class="global_search">{{
            "CUSTOMER.FF" | translate
          }}</mat-label>
          <input
            matInput
            [(ngModel)]="customer.ff"
            [readonly]="readOnly"
            autocomplete="off"
            name="ff"
          />
        </mat-form-field>
        <br />
        <mat-form-field
          appearance="outline"
          floatLabel="auto"
          class="formWidth"
          floatLabel="always"
        >
          <mat-label>{{ "CUSTOMER.CREDIT_LIMIT" | translate }}</mat-label>
          <input
            matInput
            [(ngModel)]="customer.creditLimit"
            onfocus="this.select()"
            [readonly]="readOnly"
            class="alignLeft"
            autocomplete="off"
            name="creditLimit"
          />
          <span matPrefix class="dolarPrefix">$&nbsp;</span>
        </mat-form-field>

        <mat-form-field
          floatLabel="auto"
          hideRequiredMarker
          appearance="outline"
          class="formWidth"
        >
          <mat-label class="global_search">{{
            "CUSTOMER.FEDERAL_ID" | translate
          }}</mat-label>
          <input
            matInput
            [(ngModel)]="customer.federalId"
            [readonly]="readOnly"
            autocomplete="off"
            name="federalId"
          />
        </mat-form-field>
        <br />

        <mat-form-field
          floatLabel="auto"
          hideRequiredMarker
          appearance="outline"
          class="add_address"
        >
          <mat-label class="global_search">{{
            "CUSTOMER.NOTES" | translate
          }}</mat-label>
          <input
            matInput
            [(ngModel)]="customer.notes"
            [readonly]="readOnly"
            autocomplete="off"
            name="notes"
          />
        </mat-form-field>
        <br />
        <mat-slide-toggle
          [disabled]="readOnly"
          color="primary"
          [checked]="toBool(customer.isBlacklisted)"
          (change)="setBlackListValue($event)"
          >{{ "CUSTOMER.BLACK_LISTED" | translate }}</mat-slide-toggle
        >

        <mat-slide-toggle
          [disabled]="readOnly"
          color="primary"
          [checked]="toBool(customer.isBroker)"
          (change)="setBrokerValue($event)"
          >{{ "CUSTOMER.IS_BROKER" | translate }}</mat-slide-toggle
        >
        <mat-slide-toggle
          [disabled]="readOnly"
          color="primary"
          [checked]="toBool(customer.isActive)"
          (change)="setActiveValue($event)"
          >{{ "CUSTOMER.IS_ACTIVE" | translate }}</mat-slide-toggle
        >
      </div>
      <div class="addressesCard">
        <mat-card>
          <mat-card-title class="card_title">{{
            "CUSTOMER.CONTACT" | translate
          }}</mat-card-title>

          <mat-form-field
            floatLabel="auto"
            hideRequiredMarker
            appearance="outline"
            class="formWidth"
          >
            <mat-label class="global_search">{{
              "CUSTOMER.PRIMARY_CONTACT_NAME" | translate
            }}</mat-label>
            <input
              matInput
              [(ngModel)]="customer.primaryContact.name"
              [readonly]="readOnly"
              autocomplete="off"
              name="contactName"
            />
          </mat-form-field>

          <mat-form-field
            floatLabel="auto"
            hideRequiredMarker
            appearance="outline"
            class="formWidth"
          >
            <mat-label class="global_search">{{
              "CUSTOMER.PRIMARY_CONTACT_PHONE" | translate
            }}</mat-label>
            <input
              matInput
              name="contactPhone"
              [(ngModel)]="customer.primaryContact.phone"
              [readonly]="readOnly"
              autocomplete="off"
              pattern="[- +()0-9]+"
              maxlength="18"
            />
          </mat-form-field>

          <br />

          <mat-form-field
            floatLabel="auto"
            hideRequiredMarker
            appearance="outline"
            class="formWidth"
          >
            <mat-label class="global_search">{{
              "CUSTOMER.PRIMARY_CONTACT_EMAIL" | translate
            }}</mat-label>
            <input
              matInput
              email
              type="email"
              [(ngModel)]="customer.primaryContact.mail"
              [readonly]="readOnly"
              autocomplete="off"
              name="contactEmail"
            />
          </mat-form-field>

          <mat-form-field
            floatLabel="auto"
            hideRequiredMarker
            appearance="outline"
            class="formWidth"
          >
            <mat-label class="global_search">{{
              "CUSTOMER.PRIMARY_CONTACT_NOTE" | translate
            }}</mat-label>
            <input
              matInput
              [(ngModel)]="customer.primaryContact.note"
              [readonly]="readOnly"
              autocomplete="off"
              name="contactNote"
            />
          </mat-form-field>
        </mat-card>

        <mat-card>
          <mat-card-title class="card_title">{{
            "CUSTOMER.ADDRESSES" | translate
          }}</mat-card-title>

          <app-address-list
            [addressList]="customer.addresses"
            (addressListUpdates)="eventFromAddresses($event)"
          ></app-address-list>

          <mat-slide-toggle
            [disabled]="readOnly"
            color="primary"
            [(ngModel)]="isAddressPoBox"
            class="toggle"
            matTooltip="{{ 'CUSTOMER.ADD_ADDRESS_MANUALLY' | translate }}"
            name="isAddressPoBoxName"
          ></mat-slide-toggle>

          <mat-form-field
            floatLabel="auto"
            hideRequiredMarker
            appearance="outline"
            class="fieldaddress"
            *ngIf="!isAddressPoBox"
          >
            <mat-label
              >{{ "CUSTOMER.ADD_ADDRESS" | translate
              }}<span *ngIf="requiredAddress" class="star">*</span></mat-label
            >
            <input
              matInput
              #inputCustomerAddress
              [matAutocomplete]="location"
              [formControl]="searchAddress"
              [required]="requiredAddress"
              (blur)="autofillCustomerAddress()"
              [readonly]="readOnly"
              name="customerAddress"
            />
            <mat-icon matSuffix class="arrow_down">search</mat-icon>
            <mat-autocomplete
              #location="matAutocomplete"
              [displayWith]="displayFn"
              (optionSelected)="selectAddress($event.option.value)"
            >
              <ng-container *ngIf="searchAddressResult.length > 0">
                <mat-option
                  *ngFor="let location of searchAddressResult"
                  [value]="location"
                >
                  <span>{{
                    location.address + ", " + location.countryCode
                  }}</span>
                </mat-option>
              </ng-container>
            </mat-autocomplete>
          </mat-form-field>

          <mat-form-field
            floatLabel="auto"
            appearance="outline"
            class="fieldaddress"
            hideRequiredMarker
            id="poBox"
            *ngIf="isAddressPoBox"
          >
            <mat-label
              >{{ "CUSTOMER.ADD_ADDRESS" | translate
              }}<span
                *ngIf="isAddressPoBox && customer?.addresses?.length == 0"
                class="star"
                >*</span
              ></mat-label
            >
            <input
              matInput
              [(ngModel)]="poBoxValue"
              (blur)="addAddressManualy()"
              name="poBoxValue"
              [required]="isAddressPoBox && customer?.addresses?.length == 0"
              autocomplete="off"
            />
          </mat-form-field>
          <br />

          <mat-slide-toggle
            [disabled]="readOnly"
            class="toggleBilling"
            color="primary"
            name="billingSame"
            [checked]="toBool(customer.isBillingSameAsAddress)"
            (change)="customerBillingAddressChange($event)"
            matTooltip="{{ 'CUSTOMER.BILLINGSAMEASADDRESS' | translate }}"
          >
            {{ "CUSTOMER.BILLINGSAMEASADDRESS" | translate }}</mat-slide-toggle
          >
          <br />
          <mat-slide-toggle
            [disabled]="readOnly"
            color="primary"
            [(ngModel)]="isBillingAddressPoBox"
            (change)="billingAddressIsPoBox($event)"
            class="toggle"
            matTooltip="{{
              'CUSTOMER.ADD_BILLING_ADDRESS_MANUALLY' | translate
            }}"
            name="isBillingAddressPoBoxName"
          ></mat-slide-toggle>

          <mat-form-field
            floatLabel="auto"
            hideRequiredMarker
            appearance="outline"
            class="fieldaddress"
            *ngIf="!isBillingAddressPoBox"
          >
            <mat-label class="global_search"
              >{{ "CUSTOMER.BILLING_ADDRESS" | translate
              }}<span class="star">*</span></mat-label
            >
            <input
              [disabled]="customer.isBillingSameAsAddress ? true : null"
              matInput
              #inputBillingAddress
              [(ngModel)]="customer.billingAddress"
              [matAutocomplete]="customer_address"
              [formControl]="searchBillingAddress"
              (blur)="autofillBillingAddress()"
              [required]="!isBillingAddressPoBox"
              [readonly]="readOnly"
              name="isBilling"
            />
            <mat-icon matSuffix class="arrow_down">search</mat-icon>
            <mat-autocomplete
              #customer_address="matAutocomplete"
              [displayWith]="displayFn"
              (optionSelected)="selectBillingAddress($event.option.value)"
            >
              <ng-container *ngIf="searchBillingAddressResult.length > 0">
                <mat-option
                  *ngFor="let location of searchBillingAddressResult"
                  [value]="location"
                >
                  <span>{{
                    location.address + ", " + location.countryCode
                  }}</span>
                </mat-option>
              </ng-container>
            </mat-autocomplete>
          </mat-form-field>
          <mat-form-field
            floatLabel="auto"
            appearance="outline"
            class="fieldaddress"
            hideRequiredMarker
            id="poBox"
            *ngIf="isBillingAddressPoBox"
          >
            <mat-label
              >{{ "CUSTOMER.BILLING_ADDRESS" | translate
              }}<span class="star">*</span></mat-label
            >
            <input
              matInput
              name="poBoxValueBilling"
              [(ngModel)]="poBoxBillingValue"
              [required]="isBillingAddressPoBox"
              (blur)="addBillingAddressManualy()"
              autocomplete="off"
            />
          </mat-form-field>
        </mat-card>
      </div>
    </mat-dialog-content>
    <br />
    <div>
      <mat-dialog-actions class="mat-dialog">
        <button mat-button class="btnCan" mat-dialog-close>
          {{ "CUSTOMER.CANCEL" | translate }}
        </button>
        <button
          mat-button
          class="btn btn-green"
          (click)="save(editCustomer.form.invalid)"
          *ngIf="!readOnly"
        >
          <span>{{ "CUSTOMER.SAVE" | translate }}</span>
        </button>
      </mat-dialog-actions>
    </div>
  </form>
</div>
