import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-edit-deadhead-dialog',
  templateUrl: './edit-deadhead-dialog.component.html',
  styleUrls: ['./edit-deadhead-dialog.component.scss'],
})
export class EditDeadheadDialogComponent implements OnInit {
  deadhead;

  constructor(
    private dialogRef: MatDialogRef<EditDeadheadDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private deadheadData: any
  ) {}

  ngOnInit(): void {
    this.deadhead = this.deadheadData;
  }
  changeDeadhead() {
    this.dialogRef.close(this.deadhead);
  }
}
