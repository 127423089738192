<div>
  <nav mat-tab-nav-bar>
    <a mat-tab-link routerLink="payroll-calculation" routerLinkActive="active">
      {{ "PAYROLL.PAYROLL" | translate }}
      <span class="margin-left"></span>
    </a>

    <a mat-tab-link routerLink="loan" routerLinkActive="active">
      {{ "PAYROLL.LOAN" | translate }}
      <span class="margin-left"></span>
    </a>

    <a mat-tab-link routerLink="escrow" routerLinkActive="active">
      {{ "PAYROLL.ESCROW" | translate }}
      <span class="margin-left"></span>
    </a>
    <a mat-tab-link routerLink="payroll-notes" routerLinkActive="active">
      {{ "PAYROLL.NOTES" | translate }}
      <span class="margin-left"></span>
    </a>
    <a mat-tab-link routerLink="payroll-preview" routerLinkActive="active">
      {{ "PAYROLL.PREVIEW" | translate }}
      <span class="margin-left"></span>
    </a>
    <a mat-tab-link routerLink="previous-payrolls" routerLinkActive="active">
      {{ "PAYROLL.PREVIOUS_PAYROLLS" | translate }}
      <span class="margin-left"></span>
    </a>
  </nav>
  <router-outlet></router-outlet>
</div>
