<div>
  <nav mat-tab-nav-bar class="navBarManage">
    <a mat-tab-link routerLink="payrolls" routerLinkActive="active">
      {{ "PAYROLL.PAYROLLS" | translate }}
      <span class="margin-left"></span>
      <mat-icon class="iconManageMenu">local_atm</mat-icon>
    </a>

    <a mat-tab-link routerLink="recent" routerLinkActive="active">
      {{ "RECENT_PAYROLLS.TITLE" | translate }}
      <span class="margin-left"></span>
      <mat-icon class="iconManageMenu">history</mat-icon>
    </a>
  </nav>

  <router-outlet></router-outlet>
</div>
