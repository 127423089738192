import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CodeModel } from '../_models/code';
import { Response } from '../_models/response';
import { ResponseData } from '../_models/responseData';

@Injectable({ providedIn: 'root' })
export class VendorService {
  constructor(private http: HttpClient) {}

  getVendorList(vendorName: string): Observable<any> {
    const params = new HttpParams({
      fromObject: {
        vendorName: vendorName,
      },
    });
    return this.http
      .get<Response>(`${environment.apiUrl}/vendors`, {
        params: params,
      })
      .pipe(map((response) => response.data as ResponseData));
  }

  addVendor(vendor: CodeModel) {
    return this.http
      .post<Response>(`${environment.apiUrl}/vendors`, vendor)
      .pipe(map((response) => response.data as CodeModel));
  }

  deleteVendor(id: number) {
    return this.http.delete<Response>(`${environment.apiUrl}/vendors/${id}`);
  }
}
