import { Injectable } from '@angular/core';
import { Loan } from 'src/app/_models/loan/loan';
import { LoanCreateRequest } from 'src/app/_models/loan/loanCreateRequest';
import { LoanUpdateRequest } from 'src/app/_models/loan/loanUpdateRequest';
import { TenantService } from '../tenant.service';

@Injectable({
  providedIn: 'root',
})
export class LoanFactory {
  constructor(private tenantService: TenantService) {}

  public getLoanUtcDate(loan: Loan): Loan {
    loan.date = this.tenantService.getTenetUtcDate(loan.date);
    return loan;
  }

  public getLoanTimeZoneDate(loan: Loan): Loan {
    loan.date = this.tenantService.getTenantTimeZoneDate(loan.date);
    return loan;
  }

  public getLoanUpdateRequestUtcDate(
    loan: LoanUpdateRequest
  ): LoanUpdateRequest {
    loan.date = this.tenantService.getTenetUtcDate(loan.date);
    return loan;
  }

  public getLoanCreateRequestUtcDate(
    loan: LoanCreateRequest
  ): LoanCreateRequest {
    loan.date = this.tenantService.getTenetUtcDate(loan.date);
    return loan;
  }
}
