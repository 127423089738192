import { Component, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort, MatSortable } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { first } from 'rxjs/operators';
import Common from 'src/app/_helpers/common';
import { Paginator } from 'src/app/_models/common/paginator';
import { SortData } from 'src/app/_models/common/sortData';
import { DispatcherModel } from 'src/app/_models/dispatcher';
import { TableType } from 'src/app/_models/enums/tableType';
import { Location } from 'src/app/_models/location/location';
import { DispatcherService, LocationService } from 'src/app/_services';
import { LocalStorageSortService } from 'src/app/_services/local.storage.sort.service';
import { UserDataService } from 'src/app/_services/user.data.service';
import { InfoDialogComponent } from 'src/app/common/info-dialog/info-dialog.component';
import { ChangePassDialogComponent } from '../../settings/users/administrators/change-pass-dialog/change-pass-dialog.component';
import { ComfirmDialogDispatcherComponent } from './edit-delete-dispatcher/comfirm-dialog-dispatcher/comfirm-dialog-dispatcher.component';
import { EditDispatcherDialogComponent } from './edit-delete-dispatcher/edit-dispatcher-dialog/edit-dispatcher-dialog.component';

@Component({
  selector: 'app-dispatchers',
  templateUrl: './dispatchers.component.html',
  styleUrls: ['./dispatchers.component.scss'],
})
export class DispatchersComponent {
  public locationsData: Location[] = [];
  dispatchersData: Array<DispatcherModel>;
  searchTerm = '';
  sortData: SortData;
  showInactive = false;
  count: number;

  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private dispatcherService: DispatcherService,
    private locationService: LocationService,
    private localStorageSortService: LocalStorageSortService,
    public userDataService: UserDataService,
    public dialog: MatDialog
  ) {}

  dataSource = new MatTableDataSource<DispatcherModel>();

  ngOnInit() {
    this.sortData = this.localStorageSortService.getSortData(
      TableType.ManageDispatchers
    );
    this.getDispatchers();
    this.locationService
      .getLocations()
      .pipe(first())
      .subscribe({
        next: (response) => {
          this.locationsData = response;
        },
      });
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.sort.sort({
        id: this.sortData.activeColumn,
        start: this.sortData.order,
      } as MatSortable);
      this.dataSource.sort = this.sort;
    });
    this.dataSource.sortingDataAccessor = (item, property) => {
      this.localStorageSortService.setSortData(
        TableType.ManageDispatchers,
        this.sort
      );
      switch (property) {
        case 'dispatcherName':
          return item.firstname + item.lastname;
        case 'location':
          if (item.location) {
            return (
              Common.getSafeString(item.location.address) +
              Common.getSafeString(item.location.countryCode)
            );
          }
          return '';
        default:
          return item[property];
      }
    };
  }

  applyFilter(filterValue: string) {
    this.searchTerm = filterValue;
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }

  toBool(boolVariable: any) {
    if (boolVariable && boolVariable != 'undefined') {
      return Boolean(JSON.parse(boolVariable));
    }
    return false;
  }
  openDialogChangePass(id: number, accountActivated: boolean) {
    if (accountActivated) {
      const dialogRef = this.dialog.open(ChangePassDialogComponent, {
        disableClose: true,
        position: { top: '20px' },
        data: {
          id,
        },
      });
      dialogRef.afterClosed().subscribe((result) => {
        this.getDispatchers();
      });
    } else {
      const dialogInfo = this.dialog.open(InfoDialogComponent, {
        disableClose: true,
        position: { top: '20px' },
        data: {
          key1: 'CONFIRM_DIALOG.USER_NOT_ACTIVATED',
          key2: 'CONFIRM_DIALOG.USER_NOT_ACTIVATED_1',
        },
      });
      dialogInfo.afterClosed().subscribe((proceeed) => {
        this.getDispatchers();
      });
    }
  }

  openDialogComfirmDeleteDispatcher(id: number) {
    const dialogRef = this.dialog.open(ComfirmDialogDispatcherComponent, {
      disableClose: true,
      position: { top: '20px' },
      data: id,
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.getDispatchers();
    });
  }
  openDialogEditDispatcher(readOnly: boolean, id?: number) {
    const dialogRef = this.dialog.open(EditDispatcherDialogComponent, {
      disableClose: true,
      position: { top: '20px' },
      data: {
        id,
        readOnly,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.getDispatchers();
    });
  }

  getDispatchers() {
    this.dispatcherService
      .getAllDispatchers(this.getPaginator(), !this.showInactive)
      .pipe(first())
      .subscribe((dispatchers) => {
        this.dataSource.data = dispatchers.collection;
        this.dispatchersData = dispatchers.collection;
        this.count = dispatchers.count;
      });
  }

  private getPaginator(): Paginator {
    const dropDownPaginator = new Paginator();
    dropDownPaginator.pageSize = 1000;
    dropDownPaginator.sortBy = this.sortData.activeColumn;
    dropDownPaginator.sortOrder = this.sortData.order;
    return dropDownPaginator;
  }

  getCountOfElement(value) {
    return this.dispatchersData ? this.dispatchersData.length : 0;
  }
  refresh() {
    this.applyFilter('');
    this.getDispatchers();
  }
  displayedColumns: string[] = [
    'dispatcherName',
    'location',
    'phoneNumber',
    'isAdmin',
    'isActive',
    'action',
  ];
}
