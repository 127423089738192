<div class="search-form" *ngIf="count > 0">
  <div>
    <div class="datepicker-pay">
      <button mat-icon-button class="refresh" (click)="refresh()">
        <mat-icon
          matTooltip="{{ 'PAYROLL.REFRESH' | translate }}"
          matTooltipPosition="below"
          >refresh</mat-icon
        >
      </button>
      <mat-form-field class="matDatepickerStart" appearance="outline">
        <input
          matInput
          onfocus="this.select()"
          [matDatepicker]="startDatePicker"
          (dateInput)="refresh()"
          placeholder="{{ 'PAYROLL.END_DATE_FROM' | translate }}"
          [(ngModel)]="startDate"
        />
        <mat-label>{{ "PAYROLL.END_DATE_FROM" | translate }}</mat-label>

        <mat-datepicker-toggle
          matSuffix
          [for]="startDatePicker"
        ></mat-datepicker-toggle>
        <mat-datepicker #startDatePicker></mat-datepicker>
      </mat-form-field>

      <mat-form-field class="matDatepickerEnd" appearance="outline">
        <input
          matInput
          onfocus="this.select()"
          (dateInput)="refresh()"
          [matDatepicker]="endDatePicker"
          placeholder="{{ 'PAYROLL.END_DATE_TO' | translate }}"
          [min]="startDate"
          [(ngModel)]="endDate"
        /><mat-label>{{ "PAYROLL.END_DATE_TO" | translate }}</mat-label>
        <mat-datepicker-toggle
          matSuffix
          [for]="endDatePicker"
        ></mat-datepicker-toggle>
        <mat-datepicker #endDatePicker></mat-datepicker>
      </mat-form-field>
    </div>
  </div>
  <button
    mat-raised-button
    (click)="exportCsv()"
    [disabled]="loading || this.dataSource?.data?.length == 0"
    class="mat-raised-button export-button"
  >
    {{ "ACCOUNTING.EXPORT" | translate }}
  </button>
</div>
<div class="tableAndSum">
  <table
    mat-table
    matSort
    matSortDisableClear
    [dataSource]="dataSource"
    class="mat-elevation-z1 prev-payrolls-table"
    [hidden]="count == 0"
  >
    <ng-container matColumnDef="driver">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>
        {{ "PAYROLL.DRIVER" | translate }}
      </th>
      <td mat-cell *matCellDef="let payroll">
        {{ payroll.driver }}
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>
    <ng-container matColumnDef="truck">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>
        {{ "PAYROLL.TRUCK1" | translate }}
      </th>
      <td mat-cell *matCellDef="let payroll">
        {{ payroll.truck }}
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>
    <ng-container matColumnDef="payrollDateFrom">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>
        {{ "PAYROLL.PAYROLL_DATE_FROM" | translate }}
      </th>
      <td mat-cell *matCellDef="let payroll">
        {{ payroll.payrollDateFrom | tenantDate | date : "MM/dd/y " }}
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>
    <ng-container matColumnDef="payrollDateTo">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>
        {{ "PAYROLL.PAYROLL_DATE_TO" | translate }}
      </th>
      <td mat-cell *matCellDef="let payroll">
        {{ payroll.payrollDateTo | tenantDate | date : "MM/dd/y " }}
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>
    <ng-container matColumnDef="payrollAmount">
      <th
        mat-header-cell
        mat-sort-header
        class="amountHeader"
        *matHeaderCellDef
      >
        {{ "PAYROLL.PAYROLL_AMOUNT" | translate }}
      </th>
      <td mat-cell *matCellDef="let payroll" class="alignRight">
        {{ payroll.payrollAmount | currency }}
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>
    <ng-container matColumnDef="paid">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>
        {{ "PAYROLL.PAID" | translate }}
      </th>
      <td mat-cell *matCellDef="let payroll">
        <mat-icon *ngIf="!paid" color="primary">check</mat-icon>
        <mat-icon *ngIf="paid" color="warn">close</mat-icon>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>
    <ng-container matColumnDef="paidBy">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>
        {{ "PAYROLL.PAID_BY" | translate }}
      </th>
      <td mat-cell *matCellDef="let payroll">
        {{ payroll.paidBy }}
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>
    <ng-container matColumnDef="reportPreview">
      <th mat-header-cell *matHeaderCellDef class="alignCenter">
        {{ "PAYROLL.PREVIEW1" | translate }}
      </th>
      <td mat-cell *matCellDef="let payroll" class="alignCenter">
        <button
          (click)="onPreview(payroll.id)"
          matTooltip="Preview"
          class="commentIcon"
          mat-icon-button
          aria-label="Preview"
        >
          <mat-icon>remove_red_eye</mat-icon>
        </button>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>
    <ng-container matColumnDef="comment">
      <th mat-header-cell *matHeaderCellDef class="alignCenter">
        {{ "PAYROLL.COMMENT1" | translate }}
      </th>
      <td mat-cell *matCellDef="let payroll" class="alignCenter">
        <span
          (click)="onComment(payroll)"
          class="material-icons commentIcon"
          aria-label="Comment"
          [matTooltip]="note"
          matTooltipPosition="before"
          [matBadge]="1"
          [matBadgeHidden]="!payroll.note"
          matBadgeColor="primary"
          matBadgeSize="small"
          matBadgePosition="above after"
        >
          <mat-icon class="iconCommentPadding">comment</mat-icon>
        </span>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>
    <ng-container matColumnDef="markAsPaid">
      <th mat-header-cell *matHeaderCellDef class="alignCenter">
        {{ "PAYROLL.ACTION" | translate }}
      </th>
      <td mat-cell *matCellDef="let payroll" class="alignCenter">
        <button [matMenuTriggerFor]="menu" mat-icon-button>
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button
            mat-menu-item
            *ngIf="!payroll.paid"
            (click)="markPaid(payroll, true)"
          >
            {{ "PAYROLL.MARK_PAID" | translate }}
          </button>
          <button
            mat-menu-item
            *ngIf="payroll.paid"
            (click)="markPaid(payroll, false)"
          >
            {{ "PAYROLL.MARK_UNPAID" | translate }}
          </button>
        </mat-menu>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    <tr
      mat-footer-row
      *matFooterRowDef="displayedColumns"
      class="table-footer"
    ></tr>
  </table>
  <div class="divPaginator" [hidden]="count == 0">
    <mat-paginator
      (page)="onChangePage($event)"
      class="paging"
      aria-label="Select page"
      [length]="totalSize"
      [showFirstLastButtons]="true"
      [pageIndex]="currentPage"
      [pageSize]="pageSize"
      [pageSizeOptions]="pageSizeOptions"
    >
    </mat-paginator>
  </div>
  <app-info-nodata
    *ngIf="count == 0"
    [infoKey]="'PAYROLL.NO_RECENTLY_CLOSED_PAYROLL'"
    class="no-data"
  ></app-info-nodata>
</div>
