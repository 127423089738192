import { Injectable } from '@angular/core';
import { AccountingModel } from 'src/app/_models/accounting';
import { TenantService } from '../tenant.service';

@Injectable({
  providedIn: 'root',
})
export class AccountingFactory {
  constructor(private tenantService: TenantService) {}

  public getAccountingUtcDate(accounting: AccountingModel): AccountingModel {
    accounting.date = this.tenantService.getTenetUtcDate(accounting.date);
    return accounting;
  }

  public getAccountingTimeZoneDate(
    accounting: AccountingModel
  ): AccountingModel {
    accounting.date = this.tenantService.getTenantTimeZoneDate(accounting.date);
    return accounting;
  }
}
