import { CodeModel } from './code';
import { Location } from './location/location';

export class TenantModel {
  clientId: number;
  name: string;
  keyPerson: number;
  mcNumber: string;
  dotNumber: string;
  ein: string;
  addresses: Location[];
  contactEmail: string;
  contactPhone: string;
  mainLogoId: number;
  invoiceLogoId: number;
  invoiceNote: string;
  delete: boolean;
  edit: boolean;
  fax: string;
  otherEmail1: string;
  otherEmail2: string;
  loadNumberFormat: string;
  timeZoneId: number;
  timeZone: CodeModel;
  invoiceDueDays: number;
  paymentTerms: string;
}
