<h2 mat-dialog-title class="text">{{ "PAYROLL.COMMENT" | translate }}</h2>
<div mat-dialog-content>
  <mat-form-field style="width: 100%" appearance="fill">
    <mat-label>{{ "PAYROLL.COMMENT" | translate }}:</mat-label>
    <textarea
      rows="5"
      class="txtarea-commnet"
      matInput
      [(ngModel)]="data.comment"
    ></textarea>
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button
    mat-flat-button
    class="btnCan"
    mat-dialog-close
    (click)="onCloseClick()"
  >
    {{ "PAYROLL.CLOSE" | translate }}
  </button>
  <button
    mat-flat-button
    class="btn btn-green"
    [mat-dialog-close]="data.comment"
    cdkFocusInitial
  >
    {{ "PAYROLL.SAVE" | translate }}
  </button>
</div>
