<div class="modal-dialog formInModal">
  <div *ngIf="!dataLoading">
    <h2 *ngIf="truck && !truck.id" mat-dialog-title class="text f-16">
      {{ "TRUCKS.ADD_TRUCK" | translate }}
    </h2>
    <h2 *ngIf="truck.id" mat-dialog-title class="mb-0">
      <h2 class="text f-16" [hidden]="!readOnly">
        <div class="text f-16">
          {{ "TRUCKS.PREVIEW_TRUCK" | translate }}
        </div>
        <div>
          {{ truck.truckNumber }}
        </div>
      </h2>
      <h2 class="text f-16" [hidden]="readOnly">
        <div class="text f-16">
          {{ "TRUCKS.EDIT_TRUCK" | translate }}
        </div>
        <div>
          {{ truck.truckNumber }}
        </div>
      </h2>
    </h2>
  </div>
  <hr />
  <form #editTruck="ngForm">
    <mat-dialog-content class="mat-typography">
      <div class="d-flex">
        <mat-form-field
          floatLabel="auto"
          hideRequiredMarker
          appearance="outline"
          class="formWidth"
        >
          <mat-label class="global_search"
            >{{ "TRUCKS.TRUCK_NO" | translate }} <span class="star">*</span>
          </mat-label>
          <input
            matInput
            [(ngModel)]="truck.truckNumber"
            required
            [readonly]="readOnly"
            name="truckNumber"
            autocomplete="off"
          />
        </mat-form-field>

        <mat-form-field
          floatLabel="auto"
          hideRequiredMarker
          appearance="outline"
          class="formWidth"
        >
          <mat-label class="global_search"
            >{{ "TRUCKS.DRIVER" | translate }}
          </mat-label>

          <mat-select
            name="driver"
            [(ngModel)]="truck.driver"
            class="global_search"
            placeholder="{{ 'TRUCKS.DRIVER' | translate }}"
            [compareWith]="compareObjects"
            [readonly]="readOnly"
            [disabled]="readOnly"
          >
            <ng-container *ngIf="driverList.length > 0">
              <mat-option *ngFor="let driver of driverList" [value]="driver">
                {{ driver.firstname + " " + driver.lastname }}
              </mat-option>
            </ng-container>
          </mat-select>
        </mat-form-field>
      </div>

      <br />
      <mat-slide-toggle
        [disabled]="!truck.driver || readOnly"
        class="driversame"
        color="primary"
        [checked]="toBool(truck.isDriverOwner)"
        (change)="setDriverOwnerValue($event)"
        >{{ "TRUCKS.DRIVERISOWNER" | translate }}</mat-slide-toggle
      >
      <br />
      <mat-form-field
        floatLabel="auto"
        hideRequiredMarker
        appearance="outline"
        class="formWidth"
      >
        <mat-label class="global_search">{{
          "TRUCKS.OWNER" | translate
        }}</mat-label>
        <input
          [disabled]="truck.isDriverOwner ? true : null"
          matInput
          [(ngModel)]="truck.owner"
          [readonly]="readOnly"
          name="isOwner"
          autocomplete="off"
        />
      </mat-form-field>
      <mat-form-field
        floatLabel="auto"
        hideRequiredMarker
        appearance="outline"
        class="formWidth"
      >
        <mat-label class="global_search"
          >{{ "TRUCKS.LICENSE_PLATE" | translate }}
          <span class="star">*</span></mat-label
        >
        <input
          matInput
          [(ngModel)]="truck.licensePlate"
          [readonly]="readOnly"
          required
          name="licensePlate"
          autocomplete="off"
        />
      </mat-form-field>
      <br />
      <mat-form-field
        floatLabel="auto"
        hideRequiredMarker
        appearance="outline"
        class="formWidth"
      >
        <mat-label class="global_search"
          >{{ "TRUCKS.VIN_NUMBER" | translate }}
        </mat-label>
        <input
          matInput
          [(ngModel)]="truck.vinNumber"
          [readonly]="readOnly"
          name="vinNumber"
          autocomplete="off"
        />
      </mat-form-field>
      <mat-form-field
        floatLabel="auto"
        hideRequiredMarker
        appearance="outline"
        class="formWidth"
      >
        <mat-label class="global_search"
          >{{ "TRUCKS.REGISTRATION_EXPIRATION_DATE" | translate }}
          <span class="star">*</span></mat-label
        >
        <input
          matInput
          [matDatepicker]="picker1"
          [(ngModel)]="truck.registrationExpirationDate"
          required
          [readonly]="readOnly"
          autocomplete="off"
          name="registrExpDate"
          [min]="minDate"
        />
        <mat-datepicker-toggle
          *ngIf="!readOnly"
          matSuffix
          [for]="picker1"
        ></mat-datepicker-toggle>
        <mat-datepicker #picker1></mat-datepicker>
      </mat-form-field>
      <br />
      <mat-form-field
        floatLabel="auto"
        hideRequiredMarker
        appearance="outline"
        class="formWidth"
      >
        <mat-label class="global_search"
          >{{ "TRUCKS.ANNUAL_INSPECTION_EXPIRATION_DATE" | translate }}
          <span class="star">*</span></mat-label
        >
        <input
          matInput
          [matDatepicker]="picker2"
          [(ngModel)]="truck.annualInspectionExpirationDate"
          required
          [readonly]="readOnly"
          autocomplete="off"
          name="annualInspExpDate"
          [min]="minDate"
        />
        <mat-datepicker-toggle
          matSuffix
          *ngIf="!readOnly"
          [for]="picker2"
        ></mat-datepicker-toggle>
        <mat-datepicker #picker2></mat-datepicker>
      </mat-form-field>
      <mat-form-field
        floatLabel="auto"
        hideRequiredMarker
        appearance="outline"
        class="formWidth"
      >
        <mat-label class="global_search">{{
          "TRUCKS.QUARTERLY_INSPECTION" | translate
        }}</mat-label>
        <input
          matInput
          [matDatepicker]="picker3"
          [(ngModel)]="truck.quarterlyInspectionDue"
          [readonly]="readOnly"
          autocomplete="off"
          name="quarterlyInspDue"
          [min]="minDate"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="picker3"
          *ngIf="!readOnly"
        ></mat-datepicker-toggle>
        <mat-datepicker #picker3></mat-datepicker>
      </mat-form-field>
    </mat-dialog-content>
    <br />
    <mat-slide-toggle
      [disabled]="readOnly"
      color="primary"
      [checked]="toBool(truck.isActive)"
      (change)="setActiveValue($event); showWarning(truck.id, truck.isActive)"
      >{{ "TRUCKS.ACTIVE" | translate }}</mat-slide-toggle
    >

    <mat-dialog-actions>
      <button mat-button class="btnCan" mat-dialog-close>
        {{ "TRUCKS.CANCEL" | translate }}
      </button>

      <button
        mat-button
        class="btn btn-green"
        (click)="save(editTruck.form.invalid)"
        *ngIf="!readOnly && userDataService.isAdminDispatcher"
      >
        {{ "TRUCKS.SAVE" | translate }}
      </button>
    </mat-dialog-actions>
  </form>
</div>
