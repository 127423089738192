import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/internal/operators/map';
import { Response } from 'src/app/_models/response';
import { UserModel } from 'src/app/_models/user';
import { UserDataService } from 'src/app/_services/user.data.service';
import { environment } from 'src/environments/environment';
@Injectable({ providedIn: 'root' })
export class UserService {
  private userSubject: BehaviorSubject<UserModel>;
  public user: Observable<UserModel>;
  constructor(
    private router: Router,
    private http: HttpClient,
    private userDataService: UserDataService
  ) {
    this.userSubject = new BehaviorSubject<UserModel>(null);
    this.user = this.userSubject.asObservable();
  }
  public get userValue(): UserModel {
    return this.userSubject.value;
  }

  logout(): any {
    // remove user from local storage and set current user to null
    localStorage.removeItem('user');
    this.userSubject.next(null);
    this.userDataService.clearCurentUser();
  }
  register(user: UserModel): any {
    return this.http.post(`${environment.apiUrl}/users/register`, user);
  }
  getAll(): any {
    return this.http.get<UserModel[]>(`${environment.apiUrl}/users`);
  }
  getByEmail(email: string): Observable<any> {
    const params = new HttpParams({
      fromObject: {
        Email: email,
      },
    });
    return this.http
      .get<Response>(`${environment.apiUrl}/users/`, {
        params: params,
      })
      .pipe(map((response) => response.data as any));
  }

  delete(id: number): any {
    return this.http.delete(`${environment.apiUrl}/users/${id}`);
  }

  getByActivationId(activationId: string): any {
    return this.http.get(
      `${environment.apiUrl}/users/activation/${activationId}`
    );
  }
  activate(activationId: string, password: string): any {
    return this.http.post(`${environment.apiUrl}/users/activation`, {
      activationId: activationId,
      password: password,
    });
  }

  changePassword(userId: number, newPassword: string): any {
    return this.http.post(`${environment.apiUrl}/users/reset-password`, {
      userId: userId,
      newPassword: newPassword,
    });
  }

  resendActivation(userId: number): any {
    return this.http.post<Response>(
      `${environment.apiUrl}/users/${userId}/resend-email`,
      {}
    );
  }
}
