import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-info-dialog',
  templateUrl: './info-dialog.component.html',
  styleUrls: ['./info-dialog.component.scss'],
})
export class InfoDialogComponent implements OnInit {
  infoText = '';
  infoTextPlus = '';
  title = '';

  constructor(
    private translateService: TranslateService,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {}

  ngOnInit(): void {
    this.data.title
      ? (this.title = this.translateService.instant(this.data.title))
      : (this.title = '');
    this.data.key1
      ? (this.infoText = this.translateService.instant(this.data.key1))
      : (this.infoText = '');
    this.data.key2
      ? (this.infoTextPlus = this.translateService.instant(this.data.key2))
      : (this.infoTextPlus = '');
  }
}
